import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { Link } from 'react-router-dom';
import { paths } from '../../../utils/constants/routes';
import { PORTFOLIO_FIELDS } from '../../../utils/constants/fields';
import { formatNumber, formatNumberRange, isElementVisibleOnScreen } from '../../../utils/utils';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import { useEffect, Fragment } from 'react';
import useHistory from '../../../hooks/useHistory';
import { Tooltip } from 'react-tooltip';
import IconButton, {
  ICON_BUTTON_ICONS,
  ICON_BUTTON_TYPES
} from '../../../components/buttons/IconButton';

const PortfoliosList = observer(({ showOnlyForReview = false }) => {
  const { fundsStore, utilsStore, outreachStore } = useStore();
  const { lastHref, navigate } = useHistory();
  const state = useLocalObservable(() => ({
    deleteConfirmations: {},
    toggleDeleteConfirmation: (id) => {
      state.deleteConfirmations[id] = !state.deleteConfirmations[id];
      if (state.deleteConfirmations[id]) {
        setTimeout(() => {
          const deleteBtnDOM = document.getElementById(`delete-portfolio-list-entry-${id}`);
          if (!deleteBtnDOM) {
            return;
          }

          const isVisible = isElementVisibleOnScreen(deleteBtnDOM);
          if (isVisible) {
            return;
          }

          const entryDOM = document.getElementById(`portfolio-list-entry-${id}`);
          if (!entryDOM) {
            return;
          }

          entryDOM.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
        }, 10);
      }
    }
  }));

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  const disableCreateButton = fundsStore.isLoadingCompanyFunds || !fundsStore.companyFunds.length;
  const showDisableCreateButtonTooltip =
    !fundsStore.isLoadingCompanyFunds && !fundsStore.companyFunds.length;

  return (
    <>
      {fundsStore.portfolios.length !== 0 && (
        <div className="table-responsive">
          <div className="header">
            <div className="item">Portfolio name</div>
            <div className="item">Revenue (€M)</div>
            <div className="item">EBITDA (€M)</div>
            <div className="item">Geo focus</div>
            <div className="item">Industries</div>
            <div className="item">EV ticket (€M)</div>
          </div>
          {(showOnlyForReview
            ? fundsStore.portfolios.filter(
                (f) =>
                  f.isImported &&
                  new Date(f.userUpdatedAt || 0).getTime() < new Date(f.createdAt).getTime()
              )
            : fundsStore.portfolios
          ).map((entry) => {
            const isLoading = fundsStore.portfoliosInAction[entry.id];
            const showDeleteConfirm = !isLoading && state.deleteConfirmations[entry.id];
            const showActionButtons = !isLoading && !showDeleteConfirm;

            const linkedOutreaches = outreachStore.outreaches.filter(
              (o) => o.objectData.id === entry.id
            );

            return (
              <Fragment key={entry.id}>
                <div className="row" id={`portfolio-list-entry-${entry.id}`}>
                  <div className="item strong">
                    <div className="label">Portfolio name</div>
                    <div className="value">{entry[PORTFOLIO_FIELDS.NAME.NAME]}</div>
                  </div>
                  <div className="item">
                    <div className="label">Revenue (€M)</div>
                    <div className="value">
                      {formatNumber(entry[PORTFOLIO_FIELDS.REVENUE.NAME])}
                    </div>
                  </div>
                  <div className="item">
                    <div className="label">EBITDA</div>
                    <div className="value">{formatNumber(entry[PORTFOLIO_FIELDS.EBITDA.NAME])}</div>
                  </div>
                  <div className="item">
                    <div className="label">Geo focus</div>
                    <div className="value">
                      {utilsStore
                        .shortenList(
                          utilsStore.getOptionName(entry, PORTFOLIO_FIELDS.GEOGRAPHIES),
                          3
                        )
                        .join(', ')}
                    </div>
                  </div>
                  <div className="item">
                    <div className="label">Industries</div>
                    <div className="value">
                      {utilsStore
                        .shortenList(
                          utilsStore.getOptionName(entry, PORTFOLIO_FIELDS.INDUSTRIES),
                          3
                        )
                        .join(', ')}
                    </div>
                  </div>
                  <div className="item">
                    <div className="label">EV ticket (€M)</div>
                    <div className="value">
                      {formatNumberRange(
                        entry[PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MIN.NAME],
                        entry[PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MAX.NAME]
                      )}
                    </div>
                  </div>
                  <div className="actions">
                    {isLoading && <GeneralLoader />}
                    {showActionButtons && (
                      <>
                        <IconButton
                          type={ICON_BUTTON_TYPES.BLUE}
                          innerText="Edit"
                          filled={true}
                          onClick={() => {
                            navigate(paths.PORTOFLIO_COMPANIES_EDIT + '?portfolioId=' + entry.id);
                          }}
                          wrapClassName="listing-action-text-btn"
                        />
                        <IconButton
                          icon={ICON_BUTTON_ICONS.TRASH}
                          withBorder={false}
                          onClick={() => state.toggleDeleteConfirmation(entry.id)}
                          wrapClassName="listing-action-delete-btn"
                        />
                      </>
                    )}
                    {showDeleteConfirm && (
                      <>
                        <IconButton
                          innerText="Cancel"
                          onClick={() => state.toggleDeleteConfirmation(entry.id)}
                          wrapClassName="listing-action-text-btn"
                        />
                        <IconButton
                          id={`delete-portfolio-list-entry-${entry.id}`}
                          type={ICON_BUTTON_TYPES.RED}
                          filled
                          innerText="Delete"
                          onClick={() => fundsStore.deletePortfolio(entry)}
                          wrapClassName="listing-action-text-btn"
                        />
                      </>
                    )}
                  </div>
                </div>
                {/* {showDeleteConfirm && !!linkedOutreaches.length && (
                  <div className="outreach-deletion-row">
                    <div className="outreach-deletion-row-text">
                      This portfolio has {linkedOutreaches.length} outreach(es). They will be
                      deleted after the confirmation.
                    </div>
                    <IconButton
                      icon={ICON_BUTTON_ICONS.INFO_CIRCLE}
                      type={ICON_BUTTON_TYPES.RED}
                      withBorder={false}
                      withFill={false}
                    />
                  </div>
                )} */}
              </Fragment>
            );
          })}
        </div>
      )}
      {!showOnlyForReview && (
        <Link
          className={'create-fund-panel' + (disableCreateButton ? ' disabled' : '')}
          to={disableCreateButton ? lastHref : paths.PORTOFLIO_COMPANIES_CREATE}
          id="pc-create-btn">
          <div className="icon-add"></div>
          <div className="text">Create new portfolio</div>
          {showDisableCreateButtonTooltip && (
            <Tooltip
              anchorSelect="#pc-create-btn"
              content="At least one fund should be created in your company, before creating a portfolio company profile."
              multiline={true}
              variant="light"
              opacity={1}
              noArrow
            />
          )}
        </Link>
      )}
    </>
  );
});

export default PortfoliosList;
