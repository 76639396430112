import { observer, useLocalObservable } from 'mobx-react';
import { useEffect } from 'react';
import {
  selectedProjectTabPageSize,
  SELECTED_PROJECT_TABS,
  encodeProjectsParams
} from './constants';
import Pagination from '../../../components/navigation/Pagination';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';
import FeedbackSearchResultsTabEntry from './FeedbackSearchResultsTabEntry';

const FeedbackSearchResultsTabView = observer(
  ({
    project = {},
    results = [],
    feedbacks = [],
    forTab = SELECTED_PROJECT_TABS.RESULTS,
    params = {},
    excludeResult = () => {},
    isExcludingResult = false,
    displayMessagePopup,
    onChangeFeedback
  }) => {
    const { navigate } = useHistory();

    const state = useLocalObservable(() => ({
      forTab: forTab,
      setForTab: (value = '') => (state.forTab = value),
      get currentResults() {
        return state.forTab === SELECTED_PROJECT_TABS.RESULTS
          ? results.filter((r) => !r.isExcluded)
          : results.filter((r) => r.isExcluded);
      }
    }));

    useEffect(() => {
      state.setForTab(forTab);
    }, [state, forTab]);

    const isFund = project.searchType === 'funds';
    const isPortfolio = project.searchType === 'portfolios';
    const isProfile = project.searchType === 'investprofiles';

    const showPagination = state.currentResults.length > selectedProjectTabPageSize;
    const pages = Math.ceil(state.currentResults.length / selectedProjectTabPageSize) || 1;

    const getEntryFeedbacks = (entryId) => {
      return feedbacks.filter((feedback) => feedback.resultId === entryId);
    };

    return (
      <div className="table-responsive">
        {state.currentResults.length === 0 ? (
          <div className="no-data">No items for this tab</div>
        ) : (
          <>
            <div className="header">
              <div className="item">
                {isFund
                  ? 'Fund name'
                  : isPortfolio
                  ? 'Portfolio name'
                  : isProfile
                  ? 'Profile name'
                  : 'Name'}
              </div>
              <div className="item">Industry focus</div>
              <div className="item">Geo focus</div>
              <div className="item text-align-right-desktop">Enterprise value (€M)</div>
              <div className="item text-align-right-desktop">Equity value (€M)</div>
              <div className="item feedback-item">Feedback</div>
            </div>

            {state.currentResults
              .slice(
                (params.selectedProjectTabPage - 1) * selectedProjectTabPageSize,
                params.selectedProjectTabPage * selectedProjectTabPageSize
              )
              .map((entry) => (
                <FeedbackSearchResultsTabEntry
                  key={entry.id}
                  entry={entry}
                  project={project}
                  feedbacks={getEntryFeedbacks(entry.id)}
                  type={project.searchType}
                  forTab={forTab}
                  excludeResult={excludeResult}
                  isExcludingResult={isExcludingResult}
                  displayMessagePopup={displayMessagePopup}
                  onChangeFeedback={onChangeFeedback}
                  params={params}
                />
              ))}

            <Pagination
              show={showPagination}
              onPageChange={(selected) => {
                navigate(
                  paths.TEAM +
                    '?params=' +
                    encodeProjectsParams({ ...params, selectedProjectTabPage: selected + 1 })
                );
                setTimeout(() => {
                  const el = document.getElementById('team-project-view-tab-1');
                  const header = document.querySelector('header');
                  if (el) {
                    const headerHeight = header?.clientHeight || 0;
                    window.scrollTo({ top: el.offsetTop - headerHeight, behavior: 'smooth' });
                  }
                }, 100);
              }}
              page={(params.selectedProjectTabPage || 1) - 1}
              pages={pages}
            />
          </>
        )}
      </div>
    );
  }
);

export default FeedbackSearchResultsTabView;
