import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { paths } from '../../../utils/constants/routes';
import { API_ENDPOINTS } from '../../../api/endpoints';
import { useEffect } from 'react';
import StaticPageLayout from '../../../components/layouts/StaticPageLayout';
import MobileFilters from './components/MobileFilters';
import {
  SEARCH_FIELDS,
  getErrorFields,
  PORTFOLIO_FIELDS,
  FUND_FIELDS,
  compareFields,
  trimStateFields,
  isEmptyValue,
  INVEST_PROFILE_FIELDS,
  SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA,
  FUND_SEARCH_CATEGORY_FIELDS,
  PORTFOLIO_SEARCH_CATEGORY_FIELDS,
  PROFILER_SEARCH_FIELDS,
  PROFILER_SEARCH_CATEGORY_FIELDS
} from '../../../utils/constants/fields';
import {
  getFilledSearchCategoriesCount,
  mapFieldsToState,
  formatNumber,
  getProfilerFilledSearchCategories
} from '../../../utils/utils';
import { mapData } from '../../../api/dataMappers';
import FundResult from './components/FundResult';
import PortfolioResult from './components/PortfolioResult';
import { UI_OPTIONS } from '../../../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../../../utils/constants/uiOptionKeys';
import {
  PROFILER_SEARCH_CATEGORIES,
  SEARCH_CATEGORIES
} from '../../../utils/constants/searchCategories';
import { observable, runInAction } from 'mobx';
import { DETAILS_POUP_TABS } from './constants/tabs';
import DetailsPopup from './components/DetailsPopup';
import MessagePopup from './components/MessagePopup';
import FundsInvestmentCriteriaDesktopFilters from './components/FundsInvestmentCriteriaDesktopFilters';
import { getFundsInvestmentCriteriaSearchFields } from './constants/fundsInvestmentCriteriaSearchFields';
import SaveSearchPopup from './components/SaveSearchPopup';
import NoResults from './components/NoResults';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import GeneralError from '../../../components/errors/GeneralError';
import useHistory from '../../../hooks/useHistory';
import { eventDownloadSearch } from '../../../ga4/ga4';
import InvestProfileResult from './components/InvestProfileResult';
import Pagination from '../../../components/navigation/Pagination';
import IconButton, {
  ICON_BUTTON_ICONS,
  ICON_BUTTON_TYPES
} from '../../../components/buttons/IconButton';
import { HEADER_MESSAGE_TYPES } from '../../../utils/constants/header';
import MassOutreachPopup from './components/MassOutreachPopup';
import agentSearchSortOptions from './constants/agentSearchSortOptions';
import { getPortfoliosInvestmentCriteriaSearchFields } from './constants/portfoliosInvestmentCriteriaSearchFields';
import PortfoliosInvestmentCriteriaDesktopFilters from './components/PortfoliosInvestmentCriteriaDesktopFilters';
import { getProfilerCompanyCriteriaSearchFields } from './constants/profilerCompanyCriteriaSearchFields';
import CompanyDetails from '../ProfilerResults/components/CompanyDetails';
import ProfilerSaveSearchPopup from './components/ProfilerSaveSearchPopup';
import ProfilerCompanyCriteriaDesktopFilters from './components/ProfilerCompanyCriteriaDesktopFilters';
import { ACCOUNT_TYPES } from '../../../utils/constants/auth';
import CompanyResult from './components/CompanyResult';

const savedState = observable({
  savedScrollY: {},
  saveScrollY: (searchId = null) => {
    savedState.savedScrollY[searchId] = window.scrollY;
  }
});

const pageSize = 8;

const SearchResults = observer(() => {
  const { navigate, location } = useHistory();
  const {
    projectStore,
    profilerProjectStore,
    outreachStore,
    utilsStore,
    makeRequest,
    authStore,
    profilerAccessStore
  } = useStore();

  const state = useLocalObservable(() => {
    const urlParams = new URLSearchParams(location.search);
    let searchType;
    let searchCategoryFunds;
    let searchCategoryPortfolios;
    let searchCategoryProfiler;
    try {
      searchType = JSON.parse(decodeURIComponent(urlParams.get(SEARCH_FIELDS.SEARCH_TYPE.NAME)));
      const searchCategory = JSON.parse(decodeURIComponent(urlParams.get('searchCategory')));
      if (searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler && searchCategory) {
        searchCategoryProfiler = searchCategory;
      } else if (searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds && searchCategory) {
        searchCategoryFunds = searchCategory;
      } else if (
        searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'] &&
        searchCategory
      ) {
        searchCategoryPortfolios = searchCategory;
      }
    } catch (err) {
      // w/e
    }

    if (
      ![
        UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds,
        UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'],
        UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Corporates,
        UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler
      ].includes(searchType?.[0])
    ) {
      searchType = null;
    }

    let isReExecute = false;
    try {
      const _isReExecute = JSON.parse(decodeURIComponent(urlParams.get('isReExecute')));
      if (_isReExecute) {
        isReExecute = _isReExecute;
      }
    } catch (err) {
      // w/e
    }

    return {
      isRendered: false,
      setIsRendered: (value = false) => (state.isRendered = value),
      searchType: searchType || [UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds],
      loadingSearchType: searchType || [UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds],
      isReExecute: isReExecute,
      results: [],
      fullResultsCount: 0,
      page: 1,
      pages: 1,
      searchId: null,
      paginatedSearchId: null,
      isLoading: false,
      isInitialLoading: true,
      isMobileFiltersDisplayed: false,
      toggleMobileFilters: () => {
        state.isMobileFiltersDisplayed = !state.isMobileFiltersDisplayed;
        if (state.isMobileFiltersDisplayed) {
          window.scrollTo({ top: 0 });
        }
      },
      // F/P/I details popup [ START ]
      detailsPopupDisplayed: null,
      displayDetailsPopup: (entry) => {
        if (entry) {
          utilsStore.lockScroll(true);
        } else {
          utilsStore.lockScroll(false);
        }
        state.detailsPopupDisplayed = entry;
      },
      detailsPopupSelectedTab: DETAILS_POUP_TABS.GENERAL,
      setDetailsPopupSelectedTab: (value) => {
        state.detailsPopupSelectedTab = value;
      },
      // F/P/I details popup [  END  ]
      // Profiler company details [ START ]
      profilerCompanyDetailsDisplayed: null,
      displayProfilerCompanyDetailsDetails: (entry) => {
        if (entry) {
          savedState.saveScrollY(state.searchId);
        } else {
          setTimeout(() => {
            const savedScrollY = savedState.savedScrollY[state.searchId] || 0;
            window.scrollTo({ top: savedScrollY });
            runInAction(() => delete savedState.savedScrollY[state.searchId]);
          }, 20);
        }
        state.profilerCompanyDetailsDisplayed = entry;
      },
      // Profiler company details [  END  ]
      // Outreach popups [ START ]
      messagePopupDisplayed: null,
      displayMessagePopup: (entry) => {
        if (entry) {
          utilsStore.lockScroll(true);
        } else {
          utilsStore.lockScroll(false);
        }
        state.messagePopupDisplayed = entry;
      },
      massOutreachPopupDisplayed: false,
      toggleMassOutreachPopup: (toggle = false) => {
        state.massOutreachPopupDisplayed = toggle;
        if (toggle) {
          utilsStore.lockScroll(true);
        } else {
          utilsStore.lockScroll(false);
        }
      },
      // Outreach popups [  END  ]
      saveSearchPopupDisplayed: null,
      toggleSaveProjectPopup: (entry) => {
        state.saveSearchPopupDisplayed = entry;
        if (entry) {
          utilsStore.lockScroll(true);
        } else {
          utilsStore.lockScroll(false);
        }
      },
      saveProfilerSearchPopupDisplayed: null,
      toggleProfilerSaveProjectPopup: (entry) => {
        state.saveProfilerSearchPopupDisplayed = entry;
        if (entry) {
          utilsStore.lockScroll(true);
        } else {
          utilsStore.lockScroll(false);
        }
      },
      resultsError: null,
      failedReqParams: null,
      getResults: (searchFields = {}, searchId = null, searchCategory) => {
        state.isLoading = true;
        state.resultsError = false;
        state.searchId = null;
        state.loadingSearchType = searchFields[SEARCH_FIELDS.SEARCH_TYPE.NAME];

        let body = {};

        if (state.loadingSearchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler) {
          if (
            searchCategory ===
            UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company criteria']
          ) {
            body = { ...mapData(searchFields, PROFILER_SEARCH_FIELDS, true) };
          }
          body.isReExecute = state.isReExecute;
        } else if (state.loadingSearchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds) {
          if (
            searchCategory ===
            UI_OPTIONS[UI_OPTION_KEYS.FUND_SEARCH_CATEGORY]['Investment criteria']
          ) {
            body = { ...mapData(searchFields, SEARCH_FIELDS, true) };
          }
        } else if (
          state.loadingSearchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']
        ) {
          if (
            searchCategory ===
            UI_OPTIONS[UI_OPTION_KEYS.PORTFOLIO_SEARCH_CATEGORY]['Investment criteria']
          ) {
            body = { ...mapData(searchFields, SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA, true) };
          }
        }

        if (['number', 'string'].includes(typeof searchId)) {
          body.searchId = searchId;
        }

        body.searchCategory = searchCategory;

        makeRequest({
          endpoint:
            state.loadingSearchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler
              ? API_ENDPOINTS.GET_PROFILER_SEARCH_RESULTS
              : API_ENDPOINTS.GET_SEARCH_RESULTS,
          body,
          onSuccess: ({ results, searchId: newSearchId, totalCount = 0, isCached = false }) => {
            if (!state.isRendered) {
              return;
            }

            const mapFields =
              state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
                ? FUND_FIELDS
                : state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']
                ? PORTFOLIO_FIELDS
                : state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Corporates
                ? INVEST_PROFILE_FIELDS
                : {};

            state.currentSearch = JSON.parse(JSON.stringify(searchFields));
            state.searchType = searchFields[SEARCH_FIELDS.SEARCH_TYPE.NAME];
            state.failedReqParams = null;
            state.results =
              state.loadingSearchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler
                ? profilerAccessStore.isProfilerDemo
                  ? results.slice(0, pageSize)
                  : results
                : mapData(results, mapFields);
            state.fullResultsCount = totalCount || results.length;
            state.searchId = newSearchId;
            state.isReExecute = false;

            if (
              state.loadingSearchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler &&
              profilerAccessStore.isProfilerDemo &&
              !isCached
            ) {
              authStore.userInfo.profilerSearchExecutedCount += 1;
            }
          },
          onError: (errorMessage) => {
            if (!state.isRendered) {
              return;
            }

            state.resultsError = errorMessage || 'Failed to obtain search results';
            state.failedReqParams = JSON.parse(
              JSON.stringify([searchFields, searchId, searchCategory])
            );

            if (
              state.loadingSearchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler &&
              profilerAccessStore.isProfilerDemo
            ) {
              authStore.refreshProfilerDemoCounter();
            }
          },
          onFinally: () => {
            if (!state.isRendered) {
              return;
            }

            state.isLoading = false;
            if (state.isInitialLoading) {
              state.isInitialLoading = false;
            }
          }
        });
      },
      fundsInAction: {},
      portfoliosInAction: {},
      investProfilesInAction: {},
      currentSearch: {},
      // funds investment criteria fields
      fundsInvestmentCriteriaFields: {
        ...mapFieldsToState(SEARCH_FIELDS),
        [SEARCH_FIELDS.SEARCH_TYPE.NAME]: searchType || [
          UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
        ]
      },
      setFundsInvestmentCriteriaFieldValue: (field = {}, value) => {
        state.fundsInvestmentCriteriaFields[field.NAME] = value;
      },
      get fundsInvestmentCriteriaFieldsCount() {
        return getFilledSearchCategoriesCount(state.fundsInvestmentCriteriaFields);
      },
      fundsInvestmentCriteriaSearchSectionsToggle: Object.fromEntries(
        Object.values(SEARCH_CATEGORIES).map((section) => [section, false])
      ),
      toggleFundsInvestmentCriteriaSearchSection: (section) => {
        state.fundsInvestmentCriteriaSearchSectionsToggle[section] =
          !state.fundsInvestmentCriteriaSearchSectionsToggle[section];
        if (state.fundsInvestmentCriteriaSearchSectionsToggle[section]) {
          setTimeout(() => {
            const sectionDOM = document.getElementById(`desktop-filter-category-${section}`);
            if (sectionDOM && state.fundsInvestmentCriteriaSearchSectionsToggle[section]) {
              const parentDOM = sectionDOM.parentElement;
              parentDOM.scrollTo({ behavior: 'smooth', top: sectionDOM.offsetTop + 1 });
            }
          }, 10);
        }
      },
      onFundsInvestmentCriteriaSubmitErrorState: false,
      setOnFundsInvestmentCriteriaSubmitErrorState: (value = false) =>
        (state.onFundsInvestmentCriteriaSubmitErrorState = value),
      get fundsInvestmentCriteriaValidationFields() {
        return getErrorFields(Object.values(SEARCH_FIELDS), state.fundsInvestmentCriteriaFields);
      },
      get isFundsInvestmentCriteriaSearchDisabled() {
        return (
          state.isLoading ||
          state.isInitialLoading ||
          state.fundsInvestmentCriteriaValidationFields.invalidFields.filter((f) => !f.isOnSubmit)
            .length ||
          (state.onFundsInvestmentCriteriaSubmitErrorState &&
            state.fundsInvestmentCriteriaValidationFields.invalidFields.length) ||
          !compareFields(state.fundsInvestmentCriteriaFields, state.currentSearch)
        );
      },
      // portfolios investment criteria fields
      portfoliosInvestmentCriteriaFields: {
        ...mapFieldsToState(SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA),
        [SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.SEARCH_TYPE.NAME]: [
          UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']
        ]
      },
      setPortfoliosInvestmentCriteriaFieldValue: (field = {}, value) => {
        state.portfoliosInvestmentCriteriaFields[field.NAME] = value;
      },
      onPortfoliosInvestmentCriteriaSubmitErrorState: false,
      setOnPortfoliosInvestmentCriteriaSubmitErrorState: (value = false) =>
        (state.onPortfoliosInvestmentCriteriaSubmitErrorState = value),
      get portfoliosInvestmentCriteriaValidationFields() {
        return getErrorFields(
          Object.values(SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA),
          state.portfoliosInvestmentCriteriaFields
        );
      },
      get isPortfoliosInvestmentCriteriaSearchDisabled() {
        return (
          state.isLoading ||
          state.isInitialLoading ||
          state.portfoliosInvestmentCriteriaValidationFields.invalidFields.filter(
            (f) => !f.isOnSubmit
          ).length ||
          (state.onPortfoliosInvestmentCriteriaSubmitErrorState &&
            state.portfoliosInvestmentCriteriaValidationFields.invalidFields.length) ||
          !compareFields(state.portfoliosInvestmentCriteriaFields, state.currentSearch)
        );
      },
      // profiler company criteria fields
      profilerCompanyCriteriaFields: {
        ...mapFieldsToState(PROFILER_SEARCH_FIELDS),
        [PROFILER_SEARCH_FIELDS.SEARCH_TYPE.NAME]: [UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler]
      },
      setProfilerCompanyCriteriaFieldValue: (field = {}, value) => {
        state.profilerCompanyCriteriaFields[field.NAME] = value;
      },
      get profilerCompanyCriteriaFieldsCount() {
        return getProfilerFilledSearchCategories(state.profilerCompanyCriteriaFields);
      },
      profilerCompanyCriteriaDearchSectionsToggle: Object.fromEntries(
        Object.values(PROFILER_SEARCH_CATEGORIES).map((section) => [section, false])
      ),
      toggleProfilerCompanyCriteriaSearchSection: (section) => {
        state.profilerCompanyCriteriaDearchSectionsToggle[section] =
          !state.profilerCompanyCriteriaDearchSectionsToggle[section];
        if (state.profilerCompanyCriteriaDearchSectionsToggle[section]) {
          setTimeout(() => {
            const sectionDOM = document.getElementById(`desktop-filter-category-${section}`);
            if (sectionDOM && state.profilerCompanyCriteriaDearchSectionsToggle[section]) {
              const parentDOM = sectionDOM.parentElement;
              parentDOM.scrollTo({ behavior: 'smooth', top: sectionDOM.offsetTop + 1 });
            }
          }, 10);
        }
      },
      onProfilerCompanyCriteriaSubmitErrorState: false,
      setOnProfilerCompanyCriteriaSubmitErrorState: (value = false) =>
        (state.onProfilerCompanyCriteriaSubmitErrorState = value),
      get profilerCompanyCriteriaValidationFields() {
        return getErrorFields(
          Object.values(PROFILER_SEARCH_FIELDS),
          state.profilerCompanyCriteriaFields
        );
      },
      get isProfilerCompanyCriteriaSearchDisabled() {
        return (
          state.isLoading ||
          state.isInitialLoading ||
          state.profilerCompanyCriteriaValidationFields.invalidFields.filter((f) => !f.isOnSubmit)
            .length ||
          (state.onProfilerCompanyCriteriaSubmitErrorState &&
            state.profilerCompanyCriteriaValidationFields.invalidFields.length) ||
          !compareFields(state.profilerCompanyCriteriaFields, state.currentSearch) ||
          state.isProfilerCompanyCriteriaSearchFieldsDisabled
        );
      },
      get isProfilerCompanyCriteriaSearchFieldsDisabled() {
        return profilerAccessStore.isProfilerDemo && profilerAccessStore.demoSearchExceededLimit;
      },
      //
      isSavingSearch: false,
      isProfilerSavingSearch: false,
      get projectId() {
        return (
          state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler
            ? profilerProjectStore.allProjects
            : projectStore.allProjects
        ).find((p) => p.searchId === state.searchId)?.id;
      },
      onSearch: (e) => {
        e?.preventDefault?.();

        if (
          state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.SEARCH_TYPE.NAME][0] ===
          UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler
        ) {
          if (
            state.profilerSearchCategoryFields[
              PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME
            ][0] === UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company criteria']
          ) {
            trimStateFields(state.profilerCompanyCriteriaFields);
            if (state.profilerCompanyCriteriaValidationFields.invalidFields.length) {
              if (!state.onProfilerCompanyCriteriaSubmitErrorState) {
                state.setOnProfilerCompanyCriteriaSubmitErrorState(true);
              }
              return;
            }

            if (state.isMobileFiltersDisplayed) {
              state.toggleMobileFilters();
            }

            const fieldsParams = Object.entries(state.profilerCompanyCriteriaFields)
              .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
              .join('&');
            navigate(
              paths.SEARCH_RESULTS +
                `?searchCategory=${encodeURIComponent(
                  state.profilerSearchCategoryFields[
                    PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME
                  ][0]
                )}&page=1&${fieldsParams}`
            );
            document.getElementsByClassName('page-search-listing')[0].scrollTo({ top: 0 });
          }
        } else if (
          state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.SEARCH_TYPE.NAME][0] ===
          UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
        ) {
          if (
            state.fundsSearchCategoryFields[FUND_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME][0] ===
            UI_OPTIONS[UI_OPTION_KEYS.FUND_SEARCH_CATEGORY]['Investment criteria']
          ) {
            trimStateFields(state.fundsInvestmentCriteriaFields);
            if (state.fundsInvestmentCriteriaValidationFields.invalidFields.length) {
              if (!state.onFundsInvestmentCriteriaSubmitErrorState) {
                state.setOnFundsInvestmentCriteriaSubmitErrorState(true);
              }
              return;
            }

            if (state.isMobileFiltersDisplayed) {
              state.toggleMobileFilters();
            }

            state.selectedSort = 'relevance';

            const fieldsParams = Object.entries(state.fundsInvestmentCriteriaFields)
              .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
              .join('&');
            navigate(
              paths.SEARCH_RESULTS +
                `?searchCategory=${encodeURIComponent(
                  state.fundsSearchCategoryFields[
                    FUND_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME
                  ][0]
                )}&page=1&${fieldsParams}`
            );
            document.getElementsByClassName('page-search-listing')[0].scrollTo({ top: 0 });
          }
        } else if (
          state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.SEARCH_TYPE.NAME][0] ===
          UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']
        ) {
          if (
            state.portfoliosSearchCategoryFields[
              PORTFOLIO_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME
            ][0] === UI_OPTIONS[UI_OPTION_KEYS.PORTFOLIO_SEARCH_CATEGORY]['Investment criteria']
          ) {
            trimStateFields(state.portfoliosInvestmentCriteriaFields);
            if (state.portfoliosInvestmentCriteriaValidationFields.invalidFields.length) {
              if (!state.onPortfoliosInvestmentCriteriaSubmitErrorState) {
                state.setOnPortfoliosInvestmentCriteriaSubmitErrorState(true);
              }
              return;
            }

            if (state.isMobileFiltersDisplayed) {
              state.toggleMobileFilters();
            }

            state.selectedSort = 'relevance';

            const fieldsParams = Object.entries(state.portfoliosInvestmentCriteriaFields)
              .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
              .join('&');
            navigate(
              paths.SEARCH_RESULTS +
                `?searchCategory=${encodeURIComponent(
                  state.portfoliosSearchCategoryFields[
                    PORTFOLIO_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME
                  ][0]
                )}&page=1&${fieldsParams}`
            );
            document.getElementsByClassName('page-search-listing')[0].scrollTo({ top: 0 });
          }
        }
      },
      onSubmitMessage: (id, subject, message, documents, onSuccess, onError) => {
        const isFund = state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds;
        const isPortfolio =
          state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'];
        const actionObjKey = isFund
          ? 'fundsInAction'
          : isPortfolio
          ? 'portfoliosInAction'
          : 'investProfilesInAction';
        const actionObj = state[actionObjKey];

        actionObj[id] = true;
        outreachStore.sendOutreach(
          state.projectId,
          [id],
          subject,
          message,
          documents,
          onSuccess,
          onError,
          () => {
            actionObj[id] = false;
          }
        );
      },
      onMassOutreach: (ids = [], subject, message, documents, onSuccess, onError) => {
        outreachStore.sendOutreach(
          state.projectId,
          ids,
          subject,
          message,
          documents,
          onSuccess,
          onError
        );
      },
      onSaveSearch: (searchName, searchReason, onSuccess, onError, onFinally) => {
        state.isSavingSearch = true;
        projectStore.saveProject(
          state.searchId,
          searchName,
          searchReason,
          onSuccess,
          onError,
          () => {
            state.isSavingSearch = false;
            onFinally();
          }
        );
      },
      onProfilerSaveSearch: (searchName, searchReason, onSuccess, onError, onFinally) => {
        state.isProfilerSavingSearch = true;
        profilerProjectStore.saveProject(
          state.searchId,
          searchName,
          searchReason,
          onSuccess,
          onError,
          () => {
            state.isProfilerSavingSearch = false;
            onFinally();
          }
        );
      },
      excludingResults: {},
      onExcludeResult: (entry, exclude = true) => {
        runInAction(() => (state.excludingResults[entry.id] = true));
        makeRequest({
          endpoint:
            state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler
              ? API_ENDPOINTS.EXCLUDE_PROFILER_PROJECT_RESULT
              : API_ENDPOINTS.EXCLUDE_PROJECT_RESULT,
          body: {
            ...(state.projectId ? { projectId: state.projectId } : { searchId: state.searchId }),
            resultId: entry.id,
            exclude
          },
          onSuccess: () => {
            entry.isExcluded = exclude;
          },
          onError: (errorMessage) => {
            utilsStore.setHeaderMessage(
              errorMessage ||
                `Failed to ${exclude ? 'exclude' : 'restore'} result with name ${entry.name}.`,
              HEADER_MESSAGE_TYPES.ERROR
            );
          },
          onFinally: () => {
            state.excludingResults[entry.id] = false;
          }
        });
      },
      isExcludedExpanded: false,
      toggleExcludedExpanded: () => (state.isExcludedExpanded = !state.isExcludedExpanded),
      uiFormStateFundsInvestmentCriteria: {
        isEBITDAFieldFocused: false,
        isEBITDAMarginFieldFocused: false,
        get hasRevenueValue() {
          return !!state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.REVENUE.NAME]?.length;
        },
        get isEBITDAFieldDisabled() {
          return (
            !state.uiFormStateFundsInvestmentCriteria.hasRevenueValue ||
            state.uiFormStateFundsInvestmentCriteria.isEBITDAMarginFieldFocused
          );
        },
        get isEBITDAMarginFieldDisabled() {
          return (
            !state.uiFormStateFundsInvestmentCriteria.hasRevenueValue ||
            state.uiFormStateFundsInvestmentCriteria.isEBITDAFieldFocused
          );
        },
        onRevenueChange: (revenue) => {
          const hasValue = !!revenue?.length;
          if (hasValue) {
            const EBITDAValue = state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.EBITDA.NAME];
            const EBITDAMarginValue =
              state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.EBITDA_MARGIN.NAME];
            if (EBITDAValue) {
              state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.EBITDA_MARGIN.NAME] = formatNumber(
                ((+EBITDAValue / +revenue) * 100).toFixed(2),
                ''
              );
            } else if (EBITDAMarginValue) {
              state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.EBITDA.NAME] = formatNumber(
                ((+revenue * +EBITDAMarginValue) / 100).toFixed(2),
                ''
              );
            }
          }
        },
        onEBITDAChange: (EBITDA) => {
          const hasValue = !!EBITDA?.length;
          if (hasValue) {
            const revenue = state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.REVENUE.NAME];
            state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.EBITDA_MARGIN.NAME] = formatNumber(
              ((+EBITDA / +revenue) * 100).toFixed(2),
              ''
            );
          }
        },
        onEBITDAFocus: () => {
          state.uiFormStateFundsInvestmentCriteria.isEBITDAFieldFocused = true;
        },
        onEBITDABlur: () => {
          state.uiFormStateFundsInvestmentCriteria.isEBITDAFieldFocused = false;
        },
        onEBITDAMarginChange: (EBITDAMargin) => {
          const hasValue = !!EBITDAMargin?.length;
          if (hasValue) {
            const revenue = state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.REVENUE.NAME];
            state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.EBITDA.NAME] = formatNumber(
              ((+revenue * +EBITDAMargin) / 100).toFixed(2),
              ''
            );
          }
        },
        onEBITDAMarginFocus: () => {
          state.uiFormStateFundsInvestmentCriteria.isEBITDAMarginFieldFocused = true;
        },
        onEBITDAMarginBlur: () => {
          state.uiFormStateFundsInvestmentCriteria.isEBITDAMarginFieldFocused = false;
        }
      },
      selectedSort: 'relevance',
      selectSortOption: (value = '') => {
        state.selectedSort = value;
        const urlParams = new URLSearchParams(location.search);
        const searchCategory = urlParams.get('searchCategory');
        const fieldsParams = Object.entries(state.currentSearch)
          .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
          .join('&');
        navigate(
          paths.SEARCH_RESULTS +
            `?searchCategory=${searchCategory}&page=${1}&${
              state.searchId ? `searchId=${state.searchId}&` : ''
            }${fieldsParams}`
        );
        setTimeout(() => {
          if (
            utilsStore.windowWidth > 1200 &&
            window.scrollY &&
            Math.floor(window.scrollY) > 24 + 23 - 16
          ) {
            window.scrollTo({ top: 24 + 23 - 16, behavior: 'smooth' });
          }
        }, 100);
      },
      isSortToggled: false,
      toggleSort: () => (state.isSortToggled = !state.isSortToggled),
      get sortOptions() {
        return agentSearchSortOptions.filter(
          (o) => o.onlyForSearchType === state.searchType?.[0] || o.onlyForSearchType === 'all'
        );
      },
      get sortedResults() {
        const results = state.results.slice().filter((entry) => !entry.isExcluded);

        if (state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler) {
          // no sorts for company results
        } else {
          // F/P/I sort
          if (state.selectedSort === 'name-asc') {
            results.sort((a, b) =>
              (a.name || a.companyName || '').localeCompare(b.name || b.companyName || '', 'en', {
                sensitivity: 'base'
              })
            );
          } else if (state.selectedSort === 'name-desc') {
            results.sort((a, b) =>
              (b.name || b.companyName || '').localeCompare(a.name || a.companyName || '', 'en', {
                sensitivity: 'base'
              })
            );
          } else if (state.selectedSort !== 'relevance') {
            const [propKey, typeSort] = state.selectedSort.split('-');
            const sorts = {
              asc: (propKey) => (a, b) =>
                +(a[propKey] || Number.MAX_SAFE_INTEGER) - +(b[propKey] || Number.MAX_SAFE_INTEGER),
              desc: (propKey) => (a, b) =>
                +(b[propKey] || -Number.MAX_SAFE_INTEGER / 3) -
                +(a[propKey] || -Number.MAX_SAFE_INTEGER / 3)
            };
            results.sort(sorts[typeSort](propKey));
          }
        }

        return results;
      },
      get fundsCategories() {
        return Object.entries(UI_OPTIONS[UI_OPTION_KEYS.FUND_SEARCH_CATEGORY]).map(
          ([name, value]) => ({
            name,
            value,
            disabled:
              value !== UI_OPTIONS[UI_OPTION_KEYS.FUND_SEARCH_CATEGORY]['Investment criteria']
          })
        );
      },
      get portfoliosCategories() {
        return Object.entries(UI_OPTIONS[UI_OPTION_KEYS.PORTFOLIO_SEARCH_CATEGORY]).map(
          ([name, value]) => ({
            name,
            value,
            disabled:
              value !== UI_OPTIONS[UI_OPTION_KEYS.PORTFOLIO_SEARCH_CATEGORY]['Investment criteria']
          })
        );
      },
      get profilerCategories() {
        return Object.entries(UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]).map(
          ([name, value]) => ({
            name,
            value,
            disabled:
              value !== UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company criteria']
          })
        );
      },
      fundsSearchCategoryFields: {
        [FUND_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME]: [
          searchCategoryFunds ||
            UI_OPTIONS[UI_OPTION_KEYS.FUND_SEARCH_CATEGORY]['Investment criteria']
        ]
      },
      setFundsSearchCategoryFieldValue: (field = {}, value) => {
        state.fundsSearchCategoryFields[field.NAME] = value;
      },
      portfoliosSearchCategoryFields: {
        [PORTFOLIO_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME]: [
          searchCategoryPortfolios ||
            UI_OPTIONS[UI_OPTION_KEYS.PORTFOLIO_SEARCH_CATEGORY]['Investment criteria']
        ]
      },
      setPortfoliosSearchCategoryFieldValue: (field = {}, value) => {
        state.portfoliosSearchCategoryFields[field.NAME] = value;
      },
      profilerSearchCategoryFields: {
        [PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME]: [
          searchCategoryProfiler ||
            UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company criteria']
        ]
      },
      setProfilerSearchCategoryFieldValue: (field = {}, value) => {
        state.profilerSearchCategoryFields[field.NAME] = value;
      },
      get layout() {
        return utilsStore.windowWidth < 1201 ? 'mobile' : 'desktop';
      }
    };
  });

  useEffect(() => {
    state.setIsRendered(true);
    return () => {
      state.setIsRendered(false);
      runInAction(() => delete savedState.savedScrollY[state.searchId]);
    };
  }, [state]);

  useEffect(() => {
    return () => {
      // close all the popups when exiting the page so the lock scroll can be released
      if (state.detailsPopupDisplayed) {
        state.displayDetailsPopup(null);
      }

      if (state.profilerCompanyDetailsDisplayed) {
        state.displayProfilerCompanyDetailsDetails(null);
      }

      if (state.messagePopupDisplayed) {
        state.displayMessagePopup(null);
      }

      if (state.massOutreachPopupDisplayed) {
        state.toggleMassOutreachPopup(null);
      }

      if (state.saveSearchPopupDisplayed) {
        state.toggleSaveProjectPopup(null);
      }

      if (state.saveProfilerSearchPopupDisplayed) {
        state.toggleProfilerSaveProjectPopup(null);
      }

      if (state.isMobileFiltersDisplayed) {
        state.toggleMobileFilters();
      }
    };
  }, [state]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    let hasInvalidParam = false;
    let searchFields = null;
    let searchType = null;
    let searchCategory = null;

    const allowedCategories = {
      [UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds]: state.fundsCategories
        .filter(({ disabled }) => !disabled)
        .map(({ value }) => value),
      [UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']]: state.portfoliosCategories
        .filter(({ disabled }) => !disabled)
        .map(({ value }) => value),
      [UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler]: state.profilerCategories
        .filter(({ disabled }) => !disabled)
        .map(({ value }) => value)
    };

    try {
      searchType = JSON.parse(decodeURIComponent(urlParams.get('searchType')))[0];
      if (!Object.values(UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]).includes(searchType)) {
        hasInvalidParam = true;
      }
      searchCategory = decodeURIComponent(urlParams.get('searchCategory'));
      if (!allowedCategories[searchType].includes(searchCategory)) {
        hasInvalidParam = true;
      }
    } catch (err) {
      hasInvalidParam = true;
    }

    try {
      if (searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler) {
        if (
          searchCategory === UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company criteria']
        ) {
          searchFields = Object.values(PROFILER_SEARCH_FIELDS).reduce((fields, f) => {
            fields[f.NAME] = JSON.parse(decodeURIComponent(urlParams.get(f.NAME)));
            if (f.REQUIRED && isEmptyValue(fields[f.NAME])) {
              hasInvalidParam = true;
            }
            return fields;
          }, {});
        }
      } else if (searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds) {
        if (
          searchCategory === UI_OPTIONS[UI_OPTION_KEYS.FUND_SEARCH_CATEGORY]['Investment criteria']
        ) {
          searchFields = Object.values(SEARCH_FIELDS).reduce((fields, f) => {
            fields[f.NAME] = JSON.parse(decodeURIComponent(urlParams.get(f.NAME)));
            if (f.REQUIRED && isEmptyValue(fields[f.NAME])) {
              hasInvalidParam = true;
            }
            return fields;
          }, {});
        }
      } else if (searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']) {
        if (
          searchCategory ===
          UI_OPTIONS[UI_OPTION_KEYS.PORTFOLIO_SEARCH_CATEGORY]['Investment criteria']
        ) {
          searchFields = Object.values(SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA).reduce(
            (fields, f) => {
              fields[f.NAME] = JSON.parse(decodeURIComponent(urlParams.get(f.NAME)));
              if (f.REQUIRED && isEmptyValue(fields[f.NAME])) {
                hasInvalidParam = true;
              }
              return fields;
            },
            {}
          );
        }
      } else {
        hasInvalidParam = true;
      }
    } catch (err) {
      hasInvalidParam = true;
    }

    if (hasInvalidParam) {
      runInAction(() => {
        state.resultsError = 'Invalid search params were found in the URL.';
        if (state.isInitialLoading) {
          state.isInitialLoading = false;
        }
      });
      return;
    } else {
      if (searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler) {
        if (
          searchCategory === UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company criteria']
        ) {
          runInAction(() => {
            const initial = mapFieldsToState(PROFILER_SEARCH_FIELDS);
            Object.entries(searchFields).forEach(([fieldName, fieldValue]) => {
              state.profilerCompanyCriteriaFields[fieldName] = fieldValue || initial[fieldName];
            });
            state.profilerCompanyCriteriaFields[PROFILER_SEARCH_FIELDS.SEARCH_TYPE.NAME] = [
              searchType
            ];
          });
        }
      } else if (searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds) {
        if (
          searchCategory === UI_OPTIONS[UI_OPTION_KEYS.FUND_SEARCH_CATEGORY]['Investment criteria']
        ) {
          runInAction(() => {
            const initial = mapFieldsToState(SEARCH_FIELDS);
            Object.entries(searchFields).forEach(([fieldName, fieldValue]) => {
              state.fundsInvestmentCriteriaFields[fieldName] = fieldValue || initial[fieldName];
            });
          });
        }
      } else if (searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']) {
        if (
          searchCategory ===
          UI_OPTIONS[UI_OPTION_KEYS.PORTFOLIO_SEARCH_CATEGORY]['Investment criteria']
        ) {
          runInAction(() => {
            const initial = mapFieldsToState(SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA);
            Object.entries(searchFields).forEach(([fieldName, fieldValue]) => {
              state.portfoliosInvestmentCriteriaFields[fieldName] =
                fieldValue || initial[fieldName];
            });
          });
        }
      }

      let initial = {};

      if (searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler) {
        if (
          searchCategory === UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company criteria']
        ) {
          initial = mapFieldsToState(PROFILER_SEARCH_FIELDS);
        }
      } else if (searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds) {
        if (
          searchCategory === UI_OPTIONS[UI_OPTION_KEYS.FUND_SEARCH_CATEGORY]['Investment criteria']
        ) {
          initial = mapFieldsToState(SEARCH_FIELDS);
        }
      } else if (searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']) {
        if (
          searchCategory ===
          UI_OPTIONS[UI_OPTION_KEYS.PORTFOLIO_SEARCH_CATEGORY]['Investment criteria']
        ) {
          initial = mapFieldsToState(SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA);
        }
      }

      const queryFields = Object.fromEntries(
        Object.entries(searchFields).map(([fieldName, fieldValue]) => [
          fieldName,
          fieldValue || initial[fieldName]
        ])
      );

      if (compareFields(queryFields, state.currentSearch)) {
        const foundSearchId = urlParams.get('searchId');
        state.getResults(searchFields, foundSearchId, searchCategory);
      }
    }
  }, [state, location.search]);

  useEffect(() => {
    if (state.isLoading || state.isInitialLoading || !Object.values(state.currentSearch).length) {
      return;
    }

    const urlParams = new URLSearchParams(location.search);
    const searchCategory = decodeURIComponent(urlParams.get('searchCategory'));

    const fieldsParams = Object.entries(state.currentSearch)
      .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
      .join('&');

    let pages = Math.ceil(state.results.filter((r) => !r.isExcluded).length / pageSize) || 1;
    const foundPage = urlParams.get('page');
    let page = foundPage ? +foundPage : 1;
    if (isNaN(page) || page < 1 || page > pages) {
      if (isNaN(page) || page < 1) {
        page = 1;
        navigate(
          paths.SEARCH_RESULTS +
            `?searchCategory=${encodeURIComponent(searchCategory)}&page=1&${
              state.searchId ? `searchId=${state.searchId}&` : ''
            }${fieldsParams}`
        );
      } else if (page > pages) {
        page = pages || 1;
        navigate(
          paths.SEARCH_RESULTS +
            `?searchCategory=${encodeURIComponent(searchCategory)}&page=${pages}&${
              state.searchId ? `searchId=${state.searchId}&` : ''
            }${fieldsParams}`
        );
      }
    } else {
      if (state.page !== page || state.pages !== pages) {
        runInAction(() => {
          state.page = page || 1;
          state.pages = pages;
        });
      }
    }

    if (state.paginatedSearchId !== state.searchId) {
      runInAction(() => (state.paginatedSearchId = state.searchId));
      navigate(
        paths.SEARCH_RESULTS +
          `?searchCategory=${searchCategory}&page=${page}&${
            state.searchId ? `searchId=${state.searchId}&` : ''
          }${fieldsParams}`
      );
    }
  }, [state, location.search, state.searchId, state.results.filter((r) => !r.isExcluded).length]);

  useEffect(() => {
    const handleClickOutsideSort = (event) => {
      const isClickInsideSortMenu = event.target.closest('.sort-menu-wrap');
      const isClickInsideSortButtonDesktop = event.target.closest('#sort-btn-desktop');
      const isClickInsideSortButtonMobile = event.target.closest('#sort-btn-mobile');

      if (
        isClickInsideSortMenu ||
        isClickInsideSortButtonDesktop ||
        isClickInsideSortButtonMobile
      ) {
        // do nothing
      } else {
        if (state.isSortToggled) {
          state.toggleSort();
        }
      }
    };

    document.addEventListener('click', handleClickOutsideSort);

    return () => {
      document.removeEventListener('click', handleClickOutsideSort);
    };
  }, [state]);

  const profilerCompanyCriteriaFields = getProfilerCompanyCriteriaSearchFields(state);
  const fundsInvestmentCriteriaFields = getFundsInvestmentCriteriaSearchFields(
    state,
    state.uiFormStateFundsInvestmentCriteria,
    profilerAccessStore.canUseProfilerSearch,
    authStore.userAccountType === ACCOUNT_TYPES.AGENT
  );
  const portfoliosInvestmentCriteriaFields = getPortfoliosInvestmentCriteriaSearchFields(state);

  const isLoading = state.isLoading || state.isInitialLoading;
  const hasResults = !!state.results.length;
  const hasError = !!state.resultsError;
  const showDetailsPopup = !!state.detailsPopupDisplayed;
  const showCompanyDetailsPopup = !!state.profilerCompanyDetailsDisplayed;
  const showMessagePopup = !!state.messagePopupDisplayed;
  const showMassOutreachPopup = !!state.massOutreachPopupDisplayed;
  const showSaveSearchPopup = !!state.saveSearchPopupDisplayed;
  const showProfilerSaveSearchPopup = !!state.saveProfilerSearchPopupDisplayed;
  const showNoResults = !isLoading && !hasError && !hasResults;
  const showResults = !isLoading && !hasError && hasResults;
  const showPaging = showResults && state.results.length > pageSize;
  const canDownload = ['number', 'string'].includes(typeof state.searchId) && hasResults;
  const disableSaveSearch = !canDownload || projectStore.isLoadingProjects;
  const disableProfilerSaveSearch = !canDownload || profilerProjectStore.isLoadingProjects;
  const canSaveSearch = canDownload && !state.projectId;
  const fillSaveSearchIcon = !!state.projectId;
  const disableMassOutreach =
    !state.projectId ||
    !hasResults ||
    projectStore.isLoadingProjects ||
    outreachStore.isSendingOutreach;

  const excludedResults = state.results.filter((entry) => entry.isExcluded);
  const currentSearchType = state.loadingSearchType?.[0] || state.searchType?.[0];

  if (showCompanyDetailsPopup) {
    return (
      <StaticPageLayout page="search-listing" hideMobileFooter={true}>
        <CompanyDetails
          company={state.profilerCompanyDetailsDisplayed}
          displayDetails={state.displayProfilerCompanyDetailsDetails}
          project={{ ownerId: authStore.currentUserId }}
        />
      </StaticPageLayout>
    );
  }

  return (
    <StaticPageLayout page="search-listing" hideMobileFooter={true}>
      {showDetailsPopup && (
        <DetailsPopup
          searchType={state.searchType}
          detailsPopupDisplayed={state.detailsPopupDisplayed}
          detailsPopupSelectedTab={state.detailsPopupSelectedTab}
          setDetailsPopupSelectedTab={state.setDetailsPopupSelectedTab}
          displayDetailsPopup={state.displayDetailsPopup}
        />
      )}
      {showMessagePopup && (
        <MessagePopup
          messagePopupDisplayed={state.messagePopupDisplayed}
          displayMessagePopup={state.displayMessagePopup}
          submitMessage={state.onSubmitMessage}
          actionObj={
            state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
              ? state.fundsInAction
              : state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']
              ? state.portfoliosInAction
              : state.investProfilesInAction
          }
          forSearchType={state.searchType[0]}
        />
      )}
      {showMassOutreachPopup && (
        <MassOutreachPopup
          toggleMassOutreachPopup={state.toggleMassOutreachPopup}
          submitMessage={state.onMassOutreach}
          projectId={state.projectId}
          entries={state.results.filter((r) => !r.isExcluded)}
        />
      )}
      {showSaveSearchPopup && (
        <SaveSearchPopup
          toggleSaveProjectPopup={state.toggleSaveProjectPopup}
          onSaveSearch={state.onSaveSearch}
        />
      )}
      {showProfilerSaveSearchPopup && (
        <ProfilerSaveSearchPopup
          toggleSaveProjectPopup={state.toggleProfilerSaveProjectPopup}
          onSaveSearch={state.onProfilerSaveSearch}
        />
      )}
      {state.isMobileFiltersDisplayed && (
        <MobileFilters
          state={state}
          fundsInvestmentCriteriaFields={fundsInvestmentCriteriaFields}
          portfoliosInvestmentCriteriaFields={portfoliosInvestmentCriteriaFields}
          profilerCompanyCriteriaFields={profilerCompanyCriteriaFields}
        />
      )}
      {!state.isMobileFiltersDisplayed && (
        <div className="listing-layout">
          {state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.SEARCH_TYPE.NAME][0] ===
            UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler && (
            <>
              {state.profilerSearchCategoryFields[
                PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME
              ][0] === UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company criteria'] && (
                <ProfilerCompanyCriteriaDesktopFilters
                  searchTypeField={fundsInvestmentCriteriaFields[SEARCH_FIELDS.SEARCH_TYPE.ID]}
                  fields={profilerCompanyCriteriaFields}
                  fieldsCount={state.profilerCompanyCriteriaFieldsCount}
                  searchSectionsToggle={state.profilerCompanyCriteriaDearchSectionsToggle}
                  toggleSearchSection={state.toggleProfilerCompanyCriteriaSearchSection}
                  onSearch={state.onSearch}
                  isSearchDisabled={state.isProfilerCompanyCriteriaSearchDisabled}
                  categoryFields={state.profilerSearchCategoryFields}
                  setCategoryFieldValue={state.setProfilerSearchCategoryFieldValue}
                  categoryOptions={state.profilerCategories.filter(({ disabled }) => !disabled)}
                  isLoading={state.isLoading || state.isInitialLoading}
                  isProfilerCompanyCriteriaSearchFieldsDisabled={
                    state.isProfilerCompanyCriteriaSearchFieldsDisabled
                  }
                />
              )}
            </>
          )}
          {state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.SEARCH_TYPE.NAME][0] ===
            UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds && (
            <>
              {state.fundsSearchCategoryFields[
                FUND_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME
              ][0] === UI_OPTIONS[UI_OPTION_KEYS.FUND_SEARCH_CATEGORY]['Investment criteria'] && (
                <FundsInvestmentCriteriaDesktopFilters
                  fields={fundsInvestmentCriteriaFields}
                  fieldsCount={state.fundsInvestmentCriteriaFieldsCount}
                  searchSectionsToggle={state.fundsInvestmentCriteriaSearchSectionsToggle}
                  toggleSearchSection={state.toggleFundsInvestmentCriteriaSearchSection}
                  onSearch={state.onSearch}
                  isSearchDisabled={state.isFundsInvestmentCriteriaSearchDisabled}
                  categoryFields={state.fundsSearchCategoryFields}
                  setCategoryFieldValue={state.setFundsSearchCategoryFieldValue}
                  categoryOptions={state.fundsCategories.filter(({ disabled }) => !disabled)}
                  isLoading={state.isLoading || state.isInitialLoading}
                />
              )}
            </>
          )}
          {state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.SEARCH_TYPE.NAME][0] ===
            UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'] && (
            <>
              {state.portfoliosSearchCategoryFields[
                PORTFOLIO_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME
              ][0] ===
                UI_OPTIONS[UI_OPTION_KEYS.PORTFOLIO_SEARCH_CATEGORY]['Investment criteria'] && (
                <PortfoliosInvestmentCriteriaDesktopFilters
                  searchTypeField={fundsInvestmentCriteriaFields[SEARCH_FIELDS.SEARCH_TYPE.ID]}
                  fields={portfoliosInvestmentCriteriaFields}
                  onSearch={state.onSearch}
                  isSearchDisabled={state.isPortfoliosInvestmentCriteriaSearchDisabled}
                  categoryFields={state.portfoliosSearchCategoryFields}
                  setCategoryFieldValue={state.setPortfoliosSearchCategoryFieldValue}
                  categoryOptions={state.portfoliosCategories.filter(({ disabled }) => !disabled)}
                  isLoading={state.isLoading || state.isInitialLoading}
                />
              )}
            </>
          )}

          <div className="results">
            <div className="mobile-filters">
              <div className="total-number">
                {!state.isInitialLoading && !state.isLoading
                  ? `${state.results.length} results`
                  : ' '}
              </div>
              <div className="filters-download-container">
                <div className="filters-btn" onClick={state.toggleMobileFilters}>
                  Filters
                </div>
                {currentSearchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler && (
                  <div className="actions">
                    <IconButton
                      icon={ICON_BUTTON_ICONS.FLOPPY_2_FILL}
                      type={ICON_BUTTON_TYPES.BLUE}
                      filled={fillSaveSearchIcon}
                      onClick={() => {
                        if (canSaveSearch && !state.isProfilerSavingSearch) {
                          state.toggleProfilerSaveProjectPopup(true);
                        }
                      }}
                      disabled={disableProfilerSaveSearch || state.isProfilerSavingSearch}
                      tooltipText={state.projectId ? 'Search saved as project' : 'Save search'}
                    />
                    <IconButton
                      icon={ICON_BUTTON_ICONS.DOWNLOAD}
                      type={ICON_BUTTON_TYPES.BLUE}
                      onClick={() => {
                        if (!profilerAccessStore.canDownloadProfilerSearchResults) {
                          return;
                        }

                        let downloadSearchUrl = `${process.env.REACT_APP_API_BASEURL}/public/`;
                        if (profilerAccessStore.isProfilerDemo) {
                          downloadSearchUrl += 'downloadProfilerDemoSearch?';
                        } else {
                          downloadSearchUrl += 'downloadProfilerSearch?';
                        }
                        window.open(
                          `${downloadSearchUrl}${state.projectId ? 'projectId' : 'searchId'}=${
                            state.projectId || state.searchId
                          }`,
                          '_blank',
                          'noopener, noreferrer'
                        );
                      }}
                      disabled={
                        !canDownload || !profilerAccessStore.canDownloadProfilerSearchResults
                      }
                      tooltipText={
                        profilerAccessStore.canDownloadProfilerSearchResults
                          ? 'Download search'
                          : 'Download feature is disabled. Please contact us: support@transact.digital'
                      }
                    />
                  </div>
                )}
                {currentSearchType !== UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler && (
                  <div className="actions">
                    <IconButton
                      icon={ICON_BUTTON_ICONS.FLOPPY_2_FILL}
                      type={ICON_BUTTON_TYPES.BLUE}
                      filled={fillSaveSearchIcon}
                      onClick={() => {
                        if (canSaveSearch && !state.isSavingSearch) {
                          state.toggleSaveProjectPopup(true);
                        }
                      }}
                      disabled={disableSaveSearch || state.isSavingSearch}
                      tooltipText={state.projectId ? 'Search saved as project' : 'Save search'}
                    />
                    <IconButton
                      icon={ICON_BUTTON_ICONS.CHAT_RIGHT_DOTS}
                      type={ICON_BUTTON_TYPES.BLUE}
                      tooltipText={
                        !state.projectId
                          ? 'Save search result to initiate mass outreach'
                          : 'Mass outreach'
                      }
                      disabled={disableMassOutreach}
                      onClick={() => state.toggleMassOutreachPopup(true)}
                    />
                    <IconButton
                      icon={ICON_BUTTON_ICONS.DOWNLOAD}
                      type={ICON_BUTTON_TYPES.BLUE}
                      disabled={!canDownload || !authStore.userInfo.isCognitoExportSearch}
                      onClick={() => {
                        if (authStore.userInfo.isCognitoExportSearch) {
                          eventDownloadSearch();
                          window.open(
                            `${process.env.REACT_APP_API_BASEURL}/public/downloadSearch?searchId=${state.searchId}`,
                            '_blank',
                            'noopener, noreferrer'
                          );
                        }
                      }}
                      tooltipText={
                        authStore.userInfo.isCognitoExportSearch
                          ? 'Download search'
                          : 'Download feature is disabled. Please contact us: support@transact.digital'
                      }
                    />
                    <IconButton
                      id="sort-btn-mobile"
                      icon={
                        state.selectedSort !== 'relevance'
                          ? ICON_BUTTON_ICONS.OCTI_LIST_ORDERED
                          : ICON_BUTTON_ICONS.OCTI_LIST_UNORDERED
                      }
                      type={ICON_BUTTON_TYPES.BLUE}
                      tooltipText={
                        !isLoading && hasResults
                          ? state.selectedSort === 'relevance'
                            ? 'Sort'
                            : agentSearchSortOptions.find((o) => o.value === state.selectedSort)
                                ?.name || 'Sort'
                          : 'Sort is unavailable at this moment'
                      }
                      disabled={isLoading || !hasResults}
                      onClick={state.toggleSort}
                      filled={state.isSortToggled || state.selectedSort !== 'relevance'}
                    />
                    {state.isSortToggled && (
                      <div className="sort-menu-wrap">
                        {state.sortOptions.map((option) => {
                          return (
                            <IconButton
                              key={option.value}
                              hoverType={ICON_BUTTON_TYPES.BLUE}
                              withBorder={false}
                              innerText={option.shortName || option.name}
                              onClick={() =>
                                state.selectSortOption(option.value) & state.toggleSort()
                              }
                              disabled={state.selectedSort === option.value}
                              filled={state.selectedSort === option.value}
                              {...(option.icon ? { iconOnRight: true, icon: option.icon } : {})}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            {currentSearchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler && (
              <div className="results-header">
                <div className="cols">
                  <div className="col profiler-company-name">Company name</div>
                  <div className="col">Company HQ</div>
                  <div className="col">Industry</div>
                  <div className="col">Value chain</div>
                  <div className="col">Products</div>
                  <div className="col right-align profiler-revenue">Revenue (€M)</div>
                  <div className="col right-align profiler-employees">Employees</div>
                </div>
                <div className="actions">
                  <div className="total-num">
                    {!state.isInitialLoading && !state.isLoading
                      ? `${state.results.length} results`
                      : ' '}
                  </div>
                  <div className="buttons">
                    <IconButton
                      icon={ICON_BUTTON_ICONS.FLOPPY_2_FILL}
                      type={ICON_BUTTON_TYPES.BLUE}
                      filled={fillSaveSearchIcon}
                      onClick={() => {
                        if (canSaveSearch && !state.isProfilerSavingSearch) {
                          state.toggleProfilerSaveProjectPopup(true);
                        }
                      }}
                      disabled={disableProfilerSaveSearch || state.isProfilerSavingSearch}
                      tooltipText={state.projectId ? 'Search saved as project' : 'Save search'}
                    />
                    <IconButton
                      icon={ICON_BUTTON_ICONS.DOWNLOAD}
                      type={ICON_BUTTON_TYPES.BLUE}
                      onClick={() => {
                        if (!profilerAccessStore.canDownloadProfilerSearchResults) {
                          return;
                        }

                        let downloadSearchUrl = `${process.env.REACT_APP_API_BASEURL}/public/`;
                        if (profilerAccessStore.isProfilerDemo) {
                          downloadSearchUrl += 'downloadProfilerDemoSearch?';
                        } else {
                          downloadSearchUrl += 'downloadProfilerSearch?';
                        }
                        window.open(
                          `${downloadSearchUrl}${state.projectId ? 'projectId' : 'searchId'}=${
                            state.projectId || state.searchId
                          }`,
                          '_blank',
                          'noopener, noreferrer'
                        );
                      }}
                      disabled={
                        !canDownload || !profilerAccessStore.canDownloadProfilerSearchResults
                      }
                      tooltipText={
                        profilerAccessStore.canDownloadProfilerSearchResults
                          ? 'Download search'
                          : 'Download feature is disabled. Please contact us: support@transact.digital'
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            {currentSearchType !== UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler && (
              <div className="results-header">
                <div className="cols">
                  <div className="col">
                    {currentSearchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
                      ? 'Fund name'
                      : currentSearchType ===
                        UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']
                      ? 'Portfolio name'
                      : currentSearchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Corporates
                      ? 'Invest profile name'
                      : 'Entry name'}
                  </div>
                  <div className="col">Industry focus</div>
                  <div className="col">Geo focus</div>
                  <div className="col right-align">Enterprise value (€M)</div>
                  <div className="col right-align">Equity value (€M)</div>
                </div>
                <div className="actions default-search-buttons">
                  <div className="total-num">
                    {!state.isInitialLoading && !state.isLoading
                      ? `${state.results.length} results`
                      : ' '}
                  </div>
                  <div className="buttons">
                    <IconButton
                      icon={ICON_BUTTON_ICONS.FLOPPY_2_FILL}
                      type={ICON_BUTTON_TYPES.BLUE}
                      filled={fillSaveSearchIcon}
                      onClick={() => {
                        if (canSaveSearch && !state.isSavingSearch) {
                          state.toggleSaveProjectPopup(true);
                        }
                      }}
                      disabled={disableSaveSearch || state.isSavingSearch}
                      tooltipText={state.projectId ? 'Search saved as project' : 'Save search'}
                    />
                    <IconButton
                      icon={ICON_BUTTON_ICONS.CHAT_RIGHT_DOTS}
                      type={ICON_BUTTON_TYPES.BLUE}
                      tooltipText={
                        !state.projectId
                          ? 'Save search result to initiate mass outreach'
                          : 'Mass outreach'
                      }
                      disabled={disableMassOutreach}
                      onClick={() => state.toggleMassOutreachPopup(true)}
                    />
                    <IconButton
                      icon={ICON_BUTTON_ICONS.DOWNLOAD}
                      type={ICON_BUTTON_TYPES.BLUE}
                      disabled={!canDownload || !authStore.userInfo.isCognitoExportSearch}
                      onClick={() => {
                        if (authStore.userInfo.isCognitoExportSearch) {
                          eventDownloadSearch();
                          window.open(
                            `${process.env.REACT_APP_API_BASEURL}/public/downloadSearch?searchId=${state.searchId}`,
                            '_blank',
                            'noopener, noreferrer'
                          );
                        }
                      }}
                      tooltipText={
                        authStore.userInfo.isCognitoExportSearch
                          ? 'Download search'
                          : 'Download feature is disabled. Please contact us: support@transact.digital'
                      }
                    />
                    <IconButton
                      id="sort-btn-desktop"
                      icon={
                        state.selectedSort !== 'relevance'
                          ? ICON_BUTTON_ICONS.OCTI_LIST_ORDERED
                          : ICON_BUTTON_ICONS.OCTI_LIST_UNORDERED
                      }
                      type={ICON_BUTTON_TYPES.BLUE}
                      tooltipText={
                        !isLoading && hasResults
                          ? state.selectedSort === 'relevance'
                            ? 'Sort'
                            : agentSearchSortOptions.find((o) => o.value === state.selectedSort)
                                ?.name || 'Sort'
                          : 'Sort is unavailable at this moment'
                      }
                      disabled={isLoading || !hasResults}
                      onClick={state.toggleSort}
                      filled={state.isSortToggled || state.selectedSort !== 'relevance'}
                    />
                    {state.isSortToggled && (
                      <div className="sort-menu-wrap">
                        {state.sortOptions.map((option) => {
                          return (
                            <IconButton
                              key={option.value}
                              hoverType={ICON_BUTTON_TYPES.BLUE}
                              withBorder={false}
                              innerText={option.shortName || option.name}
                              onClick={() =>
                                state.selectSortOption(option.value) & state.toggleSort()
                              }
                              disabled={state.selectedSort === option.value}
                              filled={state.selectedSort === option.value}
                              {...(option.icon ? { iconOnRight: true, icon: option.icon } : {})}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}

            {isLoading && <GeneralLoader />}

            {hasError && (
              <GeneralError
                errorMessage={state.resultsError}
                actionMessage="You may want to:"
                {...(state.failedReqParams
                  ? {
                      actionButtontext: 'Try again',
                      onActionButtonClick: () => state.getResults(...state.failedReqParams)
                    }
                  : { withHomePageButton: true })}
                withLogoutButton={
                  currentSearchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler
                }
              />
            )}

            {showNoResults && <NoResults />}

            {showResults &&
              state.sortedResults
                .slice((state.page - 1) * pageSize, state.page * pageSize)
                .map((entry) =>
                  state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler ? (
                    <CompanyResult
                      key={entry.id}
                      company={entry}
                      displayDetails={state.displayProfilerCompanyDetailsDetails}
                      excludeResult={state.onExcludeResult}
                      isInExcludeAction={!!state.excludingResults[entry.id]}
                    />
                  ) : state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds ? (
                    <FundResult
                      key={entry.id}
                      entry={entry}
                      displayMessagePopup={state.displayMessagePopup}
                      displayDetailsPopup={state.displayDetailsPopup}
                      projectId={state.projectId}
                      searchId={state.searchId}
                      excludeResult={state.onExcludeResult}
                      isInExcludeAction={!!state.excludingResults[entry.id]}
                    />
                  ) : state.searchType[0] ===
                    UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'] ? (
                    <PortfolioResult
                      key={entry.id}
                      entry={entry}
                      displayMessagePopup={state.displayMessagePopup}
                      displayDetailsPopup={state.displayDetailsPopup}
                      projectId={state.projectId}
                      searchId={state.searchId}
                      excludeResult={state.onExcludeResult}
                      isInExcludeAction={!!state.excludingResults[entry.id]}
                    />
                  ) : (
                    <InvestProfileResult
                      key={entry.id}
                      entry={entry}
                      displayMessagePopup={state.displayMessagePopup}
                      displayDetailsPopup={state.displayDetailsPopup}
                      projectId={state.projectId}
                      searchId={state.searchId}
                      excludeResult={state.onExcludeResult}
                      isInExcludeAction={!!state.excludingResults[entry.id]}
                    />
                  )
                )}

            {state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler &&
              showResults &&
              profilerAccessStore.isProfilerDemo &&
              state.fullResultsCount > pageSize && (
                <div className="profiler-demo-limited-results-row">
                  <div className="icon-wrap">
                    <img src={ICON_BUTTON_ICONS.EXCLAMATAION_CIRCLE} />
                  </div>
                  <div className="content">
                    <div className="title">Limited results shown in demo mode</div>
                    <div className="text">
                      You&apos;re viewing {pageSize} of {state.fullResultsCount} results. Contact{' '}
                      <a href="mailto:support@transact.digital">support@transact.digital</a> for a
                      subscription to see the full list.
                    </div>
                  </div>
                </div>
              )}

            {showResults && !!excludedResults.length && (
              <div className="excluded-results-wrap">
                <div className="excluded-results-box">
                  <div
                    className={`excluded-results-header${
                      state.isExcludedExpanded ? ' expanded' : ''
                    }`}>
                    <div className="excluded-results-header-text">
                      Excluded results ({excludedResults.length})
                    </div>
                    <IconButton
                      type={ICON_BUTTON_TYPES.DEFAULT}
                      icon={
                        state.isExcludedExpanded
                          ? ICON_BUTTON_ICONS.CHEVRON_UP
                          : ICON_BUTTON_ICONS.CHEVRON_DOWN
                      }
                      innerText={state.isExcludedExpanded ? 'Collapse' : 'Expand  '}
                      iconOnRight
                      withBorder={false}
                      onClick={state.toggleExcludedExpanded}
                      hoverType={ICON_BUTTON_TYPES.BLUE}
                    />
                  </div>
                  {state.isExcludedExpanded && (
                    <div>
                      {excludedResults.map((entry) =>
                        state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler ? (
                          <CompanyResult
                            key={entry.id}
                            company={entry}
                            displayDetails={state.displayProfilerCompanyDetailsDetails}
                            excludeResult={state.onExcludeResult}
                            isInExcludeAction={!!state.excludingResults[entry.id]}
                          />
                        ) : state.searchType[0] === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds ? (
                          <FundResult
                            key={entry.id}
                            entry={entry}
                            displayMessagePopup={state.displayMessagePopup}
                            displayDetailsPopup={state.displayDetailsPopup}
                            projectId={state.projectId}
                            excludeResult={state.onExcludeResult}
                            isInExcludeAction={!!state.excludingResults[entry.id]}
                          />
                        ) : state.searchType[0] ===
                          UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'] ? (
                          <PortfolioResult
                            key={entry.id}
                            entry={entry}
                            displayMessagePopup={state.displayMessagePopup}
                            displayDetailsPopup={state.displayDetailsPopup}
                            projectId={state.projectId}
                            excludeResult={state.onExcludeResult}
                            isInExcludeAction={!!state.excludingResults[entry.id]}
                          />
                        ) : (
                          <InvestProfileResult
                            key={entry.id}
                            entry={entry}
                            displayMessagePopup={state.displayMessagePopup}
                            displayDetailsPopup={state.displayDetailsPopup}
                            projectId={state.projectId}
                            excludeResult={state.onExcludeResult}
                            isInExcludeAction={!!state.excludingResults[entry.id]}
                          />
                        )
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            <Pagination
              show={showPaging}
              onPageChange={(selected) => {
                const urlParams = new URLSearchParams(location.search);
                const searchCategory = decodeURIComponent(urlParams.get('searchCategory'));
                const fieldsParams = Object.entries(state.currentSearch)
                  .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
                  .join('&');
                navigate(
                  paths.SEARCH_RESULTS +
                    `?searchCategory=${encodeURIComponent(searchCategory)}&page=${selected + 1}&${
                      state.searchId ? `searchId=${state.searchId}&` : ''
                    }${fieldsParams}`
                );
                setTimeout(() => {
                  if (utilsStore.windowWidth < 1201) {
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    return;
                  }

                  window.scrollTo({ top: 24 + 23 - 16, behavior: 'smooth' });
                }, 100);
              }}
              page={state.page - 1}
              pages={state.pages}
              desktopInitialPageRange={3}
              desktopInitialPageMargin={3}
              desktopRangeStep={300}
            />
          </div>
        </div>
      )}
    </StaticPageLayout>
  );
});

export default SearchResults;
