import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import {
  PROJECT_TYPE_TABS,
  encodeProjectsParams,
  PROJECT_LIST_SORT_OPTIONS,
  PROJECT_LIST_SORT,
  projectTypePageSize,
  PROJECT_LIST_PROFILE_FILTER_OPTIONS
} from '../constants';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';
import MultiSelect from '../../../components/inputs/MultiSelect';
import { FIELDS_TYPES } from '../../../utils/constants/fields';
import ProjectListEntry from './ProjectListEntry';
import { useEffect, useRef } from 'react';
import Pagination from '../../../components/navigation/Pagination';
import { UI_OPTIONS } from '../../../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../../../utils/constants/uiOptionKeys';
import ProfilerProjectListEntry from '../../ProfilerProjects/components/ProfilerProjectListEntry';
import GeneralError from '../../../components/errors/GeneralError';
import { OPTION_KEYS } from '../../../utils/constants/optionKeys';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import IconButton, {
  ICON_BUTTON_ICONS,
  ICON_BUTTON_TYPES
} from '../../../components/buttons/IconButton';
// import DeletePopup from './DeletePopup';

const ProjectsList = observer(({ listType = PROJECT_TYPE_TABS.SAVED, params = {} }) => {
  const { projectStore, profilerProjectStore, utilsStore, profilerAccessStore } = useStore();
  const { navigate } = useHistory();
  const tabsRef = useRef(null);

  const state = useLocalObservable(() => ({
    params: params,
    setParams: (newParams) => (state.params = newParams),
    listType: listType,
    setListType: (newListType) => (state.listType = newListType),
    showMobileFilters: false,
    toggleMobileFilters: () => (state.showMobileFilters = !state.showMobileFilters),
    showSortOptions: false,
    toggleSortOptions: () => (state.showSortOptions = !state.showSortOptions),
    get isActive() {
      return state.listType === PROJECT_TYPE_TABS.SAVED;
    },
    get isArchived() {
      return state.listType === PROJECT_TYPE_TABS.ARCHIVED;
    },
    get sort() {
      return state.params.projectSort || PROJECT_LIST_SORT.DATE_DESC;
    },
    get profileFilter() {
      return state.params.projectProfileFilter;
    },
    get profilerFilterOptions() {
      const profileFilterOptions = PROJECT_LIST_PROFILE_FILTER_OPTIONS.slice();
      if (!profilerAccessStore.canUseProfilerSearch) {
        const foundProfilerOptionIndex = profileFilterOptions.findIndex(
          ({ value }) => value === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler
        );
        if (foundProfilerOptionIndex !== -1) {
          profileFilterOptions.splice(foundProfilerOptionIndex);
        }
      }

      return profileFilterOptions;
    },
    get searchReasonFilter() {
      return state.params.projectSearchReasonFilter;
    },
    get listState() {
      let projects = (
        state.isActive
          ? projectStore.projects.concat(profilerProjectStore.projects)
          : state.isArchived
          ? projectStore.archivedProjects.concat(profilerProjectStore.archivedProjects)
          : []
      ).slice();

      const hasItems = !!projects.length;

      const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

      if (state.sort === PROJECT_LIST_SORT.DATE_ASC) {
        projects.sort((a, b) => a.dateSaved - b.dateSaved);
      } else if (state.sort === PROJECT_LIST_SORT.DATE_DESC) {
        projects.sort((a, b) => b.dateSaved - a.dateSaved);
      } else if (state.sort === PROJECT_LIST_SORT.NAME_ASC) {
        projects.sort((a, b) => collator.compare(a.name, b.name));
      } else if (state.sort === PROJECT_LIST_SORT.NAME_DESC) {
        projects.sort((a, b) => collator.compare(b.name, a.name));
      } else if (state.sort === PROJECT_LIST_SORT.FEEDBACK) {
        projects.sort((a, b) => {
          // Determine feedback status
          const hasFeedbackA =
            a.searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler
              ? profilerProjectStore.hasProjectNewFeedback(a)
              : projectStore.hasProjectNewFeedback(a);
          const hasFeedbackB =
            b.searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler
              ? profilerProjectStore.hasProjectNewFeedback(b)
              : projectStore.hasProjectNewFeedback(b);

          // Extract the largest dateResolved for sharedUsers
          const maxDateResolvedA = a.sharedUsers
            ? Math.max(...a.sharedUsers.map((user) => user.dateResolved || 0), 0)
            : 0;
          const maxDateResolvedB = b.sharedUsers
            ? Math.max(...b.sharedUsers.map((user) => user.dateResolved || 0), 0)
            : 0;

          // Extract the largest dateReceived for sharedUsers
          const maxDateReceivedA = a.sharedUsers
            ? Math.max(...a.sharedUsers.map((user) => user.dateReceived || 0), 0)
            : 0;
          const maxDateReceivedB = b.sharedUsers
            ? Math.max(...b.sharedUsers.map((user) => user.dateReceived || 0), 0)
            : 0;

          // Priority 1: Projects with feedback sorted by maxDateResolved
          if (hasFeedbackA && !hasFeedbackB) return -1;
          if (!hasFeedbackA && hasFeedbackB) return 1;
          if (hasFeedbackA && hasFeedbackB) return maxDateResolvedB - maxDateResolvedA;

          // Priority 2: Projects with RESOLVED sharedUsers sorted by maxDateResolved
          const hasResolvedSharedUserA =
            a.sharedUsers && a.sharedUsers.some((user) => user.status === 'RESOLVED');
          const hasResolvedSharedUserB =
            b.sharedUsers && b.sharedUsers.some((user) => user.status === 'RESOLVED');

          if (hasResolvedSharedUserA && !hasResolvedSharedUserB) return -1;
          if (!hasResolvedSharedUserA && hasResolvedSharedUserB) return 1;
          if (hasResolvedSharedUserA && hasResolvedSharedUserB)
            return maxDateResolvedB - maxDateResolvedA;

          // Priority 3: Projects with PENDING sharedUsers sorted by maxDateReceived
          const hasPendingSharedUserA =
            a.sharedUsers && a.sharedUsers.some((user) => user.status === 'PENDING');
          const hasPendingSharedUserB =
            b.sharedUsers && b.sharedUsers.some((user) => user.status === 'PENDING');

          if (hasPendingSharedUserA && !hasPendingSharedUserB) return -1;
          if (!hasPendingSharedUserA && hasPendingSharedUserB) return 1;
          if (hasPendingSharedUserA && hasPendingSharedUserB)
            return maxDateReceivedB - maxDateReceivedA;

          // Priority 4: All other projects sorted by dateSaved descending
          return b.dateSaved - a.dateSaved;
        });
      }

      if (state.profileFilter) {
        projects = projects.filter((p) => p.searchType === state.profileFilter);
      }

      if (state.searchReasonFilter) {
        projects = projects.filter((p) => +p.searchReason === +state.searchReasonFilter);
      }

      return {
        list: projects,
        showList: !!projects.length,
        showNoItems: !hasItems,
        showNoItemsByFilters: hasItems && !projects.length
      };
    },
    get showPaging() {
      return state.listState.showList && state.listState.list.length > projectTypePageSize;
    },
    get projectTypePages() {
      return Math.ceil(state.listState.list.length / projectTypePageSize) || 1;
    },
    get filtersCount() {
      return [state.profileFilter, state.searchReasonFilter].filter((val) => !!val).length;
    },
    get layout() {
      return utilsStore.windowWidth < 1201 ? 'mobile' : 'desktop';
    },
    tabHeight: 35,
    setTabHeight: (height) => (state.tabHeight = height + 1),
    get headerTop() {
      return (utilsStore.windowWidth < 741 ? 52 : 80) + state.tabHeight + 'px';
    }
  }));

  useEffect(() => {
    state.setParams(params);
  }, [state, params]);

  useEffect(() => {
    state.setListType(listType);
  }, [state, listType]);

  useEffect(() => {
    const element = tabsRef.current;

    if (!element) {
      return;
    }

    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        if (entry.contentRect) {
          state.setTabHeight(entry.contentRect.height);
        }
      }
    });

    observer.observe(element);

    return () => {
      observer.disconnect();
    };
  }, [state]);

  // const state = useLocalObservable(() => ({
  //   isDeletePopupOpen: false,
  //   toggleDeletePopup: () => {
  //     state.isDeletePopupOpen = !state.isDeletePopupOpen;
  //     utilsStore.lockScroll(state.isDeletePopupOpen);
  //   }
  // }));

  // useEffect(() => {
  //   return () => {
  //     if (state.isDeletePopupOpen) {
  //       state.toggleDeletePopup();
  //     }
  //   };
  // }, [state]);

  const onSortChange = (value) => {
    if (state.params.projectSort === value) {
      return;
    }

    state.toggleSortOptions();
    const newParams = { ...state.params, projectSort: value, projectTypePage: 1 };
    navigate(paths.PROJECTS + '?params=' + encodeProjectsParams(newParams));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onProfileFilterChange = (_, [value]) => {
    if (state.params.projectProfileFilter === value) {
      return;
    }

    const newParams = { ...state.params, projectProfileFilter: value || null, projectTypePage: 1 };
    navigate(paths.PROJECTS + '?params=' + encodeProjectsParams(newParams));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onSearchReasonFilterChange = (_, [value]) => {
    if (state.params.projectSearchReasonFilter === value) {
      return;
    }

    const newParams = {
      ...state.params,
      projectSearchReasonFilter: value || null,
      projectTypePage: 1
    };
    navigate(paths.PROJECTS + '?params=' + encodeProjectsParams(newParams));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const projectTypeTabs = [
    {
      name:
        'Saved projects (' +
        projectStore.projects.concat(profilerProjectStore.projects).length +
        ')',
      onClick: () => {
        if (state.params.projectTypeTab === PROJECT_TYPE_TABS.SAVED) {
          return;
        }

        const newParams = {
          ...state.params,
          projectTypeTab: PROJECT_TYPE_TABS.SAVED,
          projectTypePage: 1,
          projectSort: PROJECT_LIST_SORT.DATE_DESC,
          projectProfileFilter: null,
          projectSearchReasonFilter: null
        };
        navigate(paths.PROJECTS + '?params=' + encodeProjectsParams(newParams));
        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      isActive: state.params.projectTypeTab === PROJECT_TYPE_TABS.SAVED
    },
    {
      name:
        'Archived projects (' +
        projectStore.archivedProjects.concat(profilerProjectStore.archivedProjects).length +
        ')',
      onClick: () => {
        if (state.params.projectTypeTab === PROJECT_TYPE_TABS.ARCHIVED) {
          return;
        }

        const newParams = {
          ...state.params,
          projectTypeTab: PROJECT_TYPE_TABS.ARCHIVED,
          projectTypePage: 1,
          projectSort: PROJECT_LIST_SORT.DATE_DESC,
          projectProfileFilter: null,
          projectSearchReasonFilter: null
        };
        navigate(paths.PROJECTS + '?params=' + encodeProjectsParams(newParams));

        window.scrollTo({ top: 0, behavior: 'smooth' });
      },
      isActive: state.params.projectTypeTab === PROJECT_TYPE_TABS.ARCHIVED
    }
  ];

  const { list, showList, showNoItems, showNoItemsByFilters } = state.listState;

  useEffect(() => {
    const onGlobalClick = (event) => {
      if (!state.showSortOptions) {
        return;
      }

      const isClickInside =
        event.target.closest('.projects-sort-menu-wrap') ||
        event.target.closest('#projects-sort-button');
      if (isClickInside) {
        return;
      }

      state.toggleSortOptions();
    };

    document.addEventListener('click', onGlobalClick, { capture: true });

    return () => {
      document.removeEventListener('click', onGlobalClick, { capture: true });
    };
  }, [state]);

  return (
    <>
      {/* {state.isDeletePopupOpen && (
        <DeletePopup archivedProjects={list} toggle={state.toggleDeletePopup} />
      )} */}
      <div className="tabs at-projects-listing" ref={tabsRef}>
        {projectTypeTabs.map((tab) => {
          return (
            <div key={tab.name} onClick={tab.onClick} className={`tab ${tab.isActive && 'active'}`}>
              {tab.name}
            </div>
          );
        })}
      </div>
      <div className="table-responsive">
        <div className="projects-list-partial-errors">
          {(projectStore.isLoadingProjects || profilerProjectStore.isLoadingProjects) && (
            <GeneralLoader />
          )}
          {!!projectStore.loadProjectsError && !projectStore.allProjects.length && (
            <GeneralError
              errorMessage={projectStore.loadProjectsError}
              actionMessage="You may want to:"
              actionButtontext="Try again"
              onActionButtonClick={projectStore.loadProjects}
            />
          )}
          {!!profilerProjectStore.loadProjectsError && !profilerProjectStore.allProjects.length && (
            <GeneralError
              errorMessage={profilerProjectStore.loadProjectsError}
              actionMessage="You may want to:"
              actionButtontext="Try again"
              onActionButtonClick={profilerProjectStore.loadProjects}
              withLogoutButton
            />
          )}
        </div>
        <div className="projects-list-header" style={{ top: state.headerTop }}>
          <div className="results-graining-wrap">
            <div className="graining-row-title desktop-only">Filter table by</div>
            <div className="graining-row">
              <div className="filters-wrap desktop-only">
                <MultiSelect
                  field={{
                    NAME: 'profileFilter',
                    ID: 'profile-filter',
                    TYPE: FIELDS_TYPES.TYPE_SELECT,
                    PLACEHOLDER: 'Profile'
                  }}
                  value={state.profileFilter ? [state.profileFilter] : []}
                  values={state.profilerFilterOptions}
                  setFieldValue={onProfileFilterChange}
                  withSingleClear
                />
                <MultiSelect
                  field={{
                    NAME: 'searchReasonFilter',
                    ID: 'searchReason-filter',
                    TYPE: FIELDS_TYPES.TYPE_SELECT,
                    PLACEHOLDER: 'Search reason',
                    VALUES_KEY: OPTION_KEYS.SEARCH_REASONS
                  }}
                  value={state.searchReasonFilter ? [state.searchReasonFilter] : []}
                  setFieldValue={onSearchReasonFilterChange}
                  withSingleClear
                />
              </div>
              <div className="mobile-filters-button mobile-only">
                <IconButton
                  innerText={`Filters${state.filtersCount ? ` (${state.filtersCount})` : ''}`}
                  type={
                    state.showMobileFilters ? ICON_BUTTON_TYPES.BLUE : ICON_BUTTON_TYPES.DEFAULT
                  }
                  withBorder={!!state.filtersCount}
                  onClick={state.toggleMobileFilters}
                  filled={state.showMobileFilters}
                  icon={
                    state.showMobileFilters
                      ? ICON_BUTTON_ICONS.CHEVRON_UP
                      : ICON_BUTTON_ICONS.CHEVRON_DOWN
                  }
                  iconOnRight
                />
              </div>
              <div className="sort-wrap">
                <IconButton
                  id="projects-sort-button"
                  innerText={
                    PROJECT_LIST_SORT_OPTIONS.find((o) => o.value === state.sort)?.name || 'Sort by'
                  }
                  icon={
                    state.showSortOptions
                      ? ICON_BUTTON_ICONS.CHEVRON_UP
                      : ICON_BUTTON_ICONS.CHEVRON_DOWN
                  }
                  withBorder={false}
                  iconOnRight
                  type={ICON_BUTTON_TYPES.DEFAULT}
                  hoverType={ICON_BUTTON_TYPES.BLUE}
                  filled={state.showSortOptions}
                  onClick={state.toggleSortOptions}
                />
                {state.showSortOptions && (
                  <div className="projects-sort-menu-wrap">
                    {PROJECT_LIST_SORT_OPTIONS.map((option) => {
                      return (
                        <IconButton
                          key={option.value}
                          hoverType={ICON_BUTTON_TYPES.BLUE}
                          withBorder={false}
                          innerText={option.shortName || option.name}
                          onClick={() => onSortChange(option.value)}
                          disabled={state.sort === option.value}
                          filled={state.sort === option.value}
                          {...(option.icon ? { iconOnRight: true, icon: option.icon } : {})}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
            {state.showMobileFilters && (
              <div className="mobile-filters mobile-only">
                <MultiSelect
                  field={{
                    NAME: 'profileFilter',
                    ID: 'profile-filter-mobile',
                    TYPE: FIELDS_TYPES.TYPE_SELECT,
                    PLACEHOLDER: 'Profile'
                  }}
                  value={state.profileFilter ? [state.profileFilter] : []}
                  values={state.profilerFilterOptions}
                  setFieldValue={onProfileFilterChange}
                  withSingleClear
                />
                <MultiSelect
                  field={{
                    NAME: 'searchReasonFilter',
                    ID: 'searchReason-filter-mobile',
                    TYPE: FIELDS_TYPES.TYPE_SELECT,
                    PLACEHOLDER: 'Search reason',
                    VALUES_KEY: OPTION_KEYS.SEARCH_REASONS
                  }}
                  value={state.searchReasonFilter ? [state.searchReasonFilter] : []}
                  setFieldValue={onSearchReasonFilterChange}
                  withSingleClear
                />
              </div>
            )}
          </div>
          <div className="table-head">
            <div className="item">Project name</div>
            <div className="item">Profile</div>
            <div className="item">Search reason</div>
            <div className="item">Industries</div>
            <div className="item">Product portfolio</div>
            <div className="item">Results</div>
            <div className="item date">Date</div>
            <div className="item actions">Actions</div>
          </div>
        </div>
        {showNoItems && <div className="no-data">No items for this tab</div>}
        {showNoItemsByFilters && (
          <div className="no-data">No items for this tab based on filter criteria</div>
        )}
        {showList &&
          list
            .slice(
              (state.params.projectTypePage - 1) * projectTypePageSize,
              state.params.projectTypePage * projectTypePageSize
            )
            .map((p, index) => {
              return p.searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler ? (
                <ProfilerProjectListEntry
                  key={p.id}
                  project={p}
                  params={state.params}
                  index={index}
                  isFromAgentView
                />
              ) : (
                <ProjectListEntry key={p.id} project={p} params={state.params} index={index} />
              );
            })}
      </div>
      {/* MMV2-229 Deactivate deletion for projects */}
      {/* {isArchived && list.length > 0 && (
        <div className="delete-text" onClick={state.toggleDeletePopup}>
          Delete Projects
        </div>
      )} */}
      <Pagination
        show={state.showPaging}
        onPageChange={(selected) => {
          navigate(
            paths.PROJECTS +
              '?params=' +
              encodeProjectsParams({ ...state.params, projectTypePage: selected + 1 })
          );
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        page={state.params.projectTypePage - 1}
        pages={state.projectTypePages}
      />
    </>
  );
});

export default ProjectsList;
