import { SEARCH_CATEGORIES } from '../../../../utils/constants/searchCategories';
import { SEARCH_FIELDS } from '../../../../utils/constants/fields';
import MultiSelect from '../../../../components/inputs/MultiSelect';
import Input from '../../../../components/inputs/Input';
import RangeInput from '../../../../components/inputs/RangeInput';
import { UI_OPTIONS } from '../../../../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../../../../utils/constants/uiOptionKeys';

export const getFundsInvestmentCriteriaSearchFields = (
  state,
  uiFormState,
  canUseProfilerSearch,
  canUseMMSearch
) => {
  const disableInputsByLoader = state.isLoading || state.isInitialLoading;

  const searchTypeValues = Object.entries(UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]).map(
    ([name, value]) => ({
      name: value === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler ? 'Corporates' : name,
      value
    })
  );
  if (!canUseProfilerSearch) {
    const foundProfilerOptionIndex = searchTypeValues.findIndex(
      ({ value }) => value === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler
    );
    if (foundProfilerOptionIndex !== -1) {
      searchTypeValues.splice(foundProfilerOptionIndex, 1);
    }
  }
  if (!canUseMMSearch) {
    const foundFundsOptionIndex = searchTypeValues.findIndex(
      ({ value }) => value === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
    );
    if (foundFundsOptionIndex !== -1) {
      searchTypeValues.splice(foundFundsOptionIndex, 1);
    }
    const foundPortfoliosOptionIndex = searchTypeValues.findIndex(
      ({ value }) => value === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']
    );
    if (foundPortfoliosOptionIndex !== -1) {
      searchTypeValues.splice(foundPortfoliosOptionIndex, 1);
    }
    const foundInvestProfilesOptionIndex = searchTypeValues.findIndex(
      ({ value }) => value === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Corporates
    );
    if (foundInvestProfilesOptionIndex !== -1) {
      searchTypeValues.splice(foundInvestProfilesOptionIndex, 1);
    }
  }

  return {
    [SEARCH_FIELDS.SEARCH_TYPE.ID]: (
      <MultiSelect
        field={SEARCH_FIELDS.SEARCH_TYPE}
        value={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.SEARCH_TYPE.NAME]}
        // values={Object.entries(UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE])
        //   .map(([name, value]) => ({
        //     name,
        //     value
        //   }))
        //   .filter(({ value }) => value !== UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler)}
        values={searchTypeValues}
        setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
        disabled={disableInputsByLoader}
        inputWrap={{ enable: true, className: 'col' }}
        wrapClassname="form-group"
        hideRequiredLabelSymbol
      />
    ),
    [SEARCH_CATEGORIES.GENERAL]: {
      [SEARCH_FIELDS.INDUSTRY.ID]: (
        <MultiSelect
          field={SEARCH_FIELDS.INDUSTRY}
          value={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.INDUSTRY.NAME]}
          setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
          disabled={disableInputsByLoader}
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [SEARCH_FIELDS.HEADQUARTER.ID]: (
        <MultiSelect
          field={SEARCH_FIELDS.HEADQUARTER}
          value={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.HEADQUARTER.NAME]}
          setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
          disabled={disableInputsByLoader}
          withSingleClear
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [SEARCH_FIELDS.GEOGRAPHICAL_FOCUS.ID]: (
        <MultiSelect
          field={SEARCH_FIELDS.GEOGRAPHICAL_FOCUS}
          value={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.GEOGRAPHICAL_FOCUS.NAME]}
          setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
          disabled={disableInputsByLoader}
          inputWrap={{ enable: true, className: 'col' }}
        />
      )
    },
    [SEARCH_CATEGORIES.FINANCIALS]: {
      [SEARCH_FIELDS.REVENUE.ID]: (
        <Input
          field={SEARCH_FIELDS.REVENUE}
          value={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.REVENUE.NAME]}
          setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
          disabled={disableInputsByLoader}
          inputWrap={{ enable: true, className: 'col' }}
          onAfterValueChange={uiFormState.onRevenueChange}
        />
      ),
      [SEARCH_FIELDS.EBITDA.ID]: (
        <Input
          field={SEARCH_FIELDS.EBITDA}
          value={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.EBITDA.NAME]}
          setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
          disabled={disableInputsByLoader || uiFormState.isEBITDAFieldDisabled}
          inputWrap={{ enable: true, className: 'col' }}
          onFocus={uiFormState.onEBITDAFocus}
          onBlur={uiFormState.onEBITDABlur}
          onAfterValueChange={uiFormState.onEBITDAChange}
        />
      ),
      [SEARCH_FIELDS.EBITDA_MARGIN.ID]: (
        <Input
          field={SEARCH_FIELDS.EBITDA_MARGIN}
          value={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.EBITDA_MARGIN.NAME]}
          setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
          disabled={disableInputsByLoader || uiFormState.isEBITDAMarginFieldDisabled}
          inputWrap={{ enable: true, className: 'col' }}
          onFocus={uiFormState.onEBITDAMarginFocus}
          onBlur={uiFormState.onEBITDAMarginBlur}
          onAfterValueChange={uiFormState.onEBITDAMarginChange}
        />
      )
    },
    [SEARCH_CATEGORIES.TRANSACTION]: {
      [SEARCH_FIELDS.SITUATION.ID]: (
        <MultiSelect
          field={SEARCH_FIELDS.SITUATION}
          value={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.SITUATION.NAME]}
          setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
          disabled={disableInputsByLoader}
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [SEARCH_FIELDS.EQUITY_STAKE.ID]: (
        <MultiSelect
          field={SEARCH_FIELDS.EQUITY_STAKE}
          value={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.EQUITY_STAKE.NAME]}
          setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
          disabled={disableInputsByLoader}
          inputWrap={{ enable: true, className: 'col' }}
        />
      )
    },
    [SEARCH_CATEGORIES.VALUATION]: {
      [SEARCH_FIELDS.ENTERPRISE_MIN.ID + SEARCH_FIELDS.ENTERPRISE_MAX.ID]: (
        <RangeInput
          fieldMin={SEARCH_FIELDS.ENTERPRISE_MIN}
          fieldMax={SEARCH_FIELDS.ENTERPRISE_MAX}
          valueMin={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.ENTERPRISE_MIN.NAME]}
          valueMax={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.ENTERPRISE_MAX.NAME]}
          disabled={disableInputsByLoader}
          setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
          messages={state.fundsInvestmentCriteriaValidationFields.messages}
          showOnSubmitErrorState={state.onSubmitErrorState}
          wrapClass={'col'}
        />
      )
    }
  };
};
