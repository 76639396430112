import { observer } from 'mobx-react';
import {
  PORTFOLIO_SEARCH_CATEGORY_FIELDS,
  SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA
} from '../../../../utils/constants/fields';
import MultiSelect from '../../../../components/inputs/MultiSelect';
import { useStore } from '../../../../store/store';

const PortfoliosInvestmentCriteriaDesktopFilters = observer(
  ({
    searchTypeField,
    fields,
    onSearch,
    isSearchDisabled,
    categoryFields,
    setCategoryFieldValue,
    categoryOptions,
    isLoading
  }) => {
    const { utilsStore } = useStore();
    if (utilsStore.windowWidth < 1201) {
      return null;
    }

    return (
      <div className="desktop-filters">
        <div className="fade-top" />
        <div className="fade-bottom" />
        <div className="scrollable-items">
          {searchTypeField}
          <MultiSelect
            field={PORTFOLIO_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE}
            value={categoryFields[PORTFOLIO_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME]}
            setFieldValue={setCategoryFieldValue}
            values={categoryOptions}
            inputWrap={{ enable: true, className: 'col' }}
            disabled={isLoading}
          />
          {fields[SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.INDUSTRY.ID]}
          {fields[SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.HEADQUARTER.ID]}
          {fields[SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.GEOGRAPHICAL_FOCUS.ID]}
          {fields[SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.REVENUE.ID]}
        </div>
        <div id="desktop-filters-search-btn-wrap">
          <button
            className="btn btn-primary disable-fade-primary"
            onClick={(event) => onSearch(event) & window.scrollTo({ top: 0 })}
            disabled={isSearchDisabled}>
            Search
          </button>
        </div>
      </div>
    );
  }
);

export default PortfoliosInvestmentCriteriaDesktopFilters;
