import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { formatDateDDMMYYYY, mapFieldsToState } from '../../../utils/utils';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';
import {
  PROFILER_SEARCH_FIELDS,
  PROFILER_PROJECT_FIELDS,
  getErrorFields,
  trimStateFields
} from '../../../utils/constants/fields';
import { encodeProjectsParams, SELECTED_PROJECT_TABS } from '../constants';
import ProfilerArchivePopup from './ProfilerArchivePopup';
import Input from '../../../components/inputs/Input';
import { useEffect } from 'react';
import IconButton, {
  ICON_BUTTON_TYPES,
  ICON_BUTTON_ICONS
} from '../../../components/buttons/IconButton';
import { UI_OPTIONS } from '../../../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../../../utils/constants/uiOptionKeys';
import { HEADER_MESSAGE_TYPES } from '../../../utils/constants/header';
import ProfilerDemoReExecutePopup from './ProfilerDemoReExecutePopup';
import * as Portal from '@radix-ui/react-portal';
import MultiSelect from '../../../components/inputs/MultiSelect';
import { runInAction } from 'mobx';

const ProfilerProjectListEntry = observer(
  ({ project = {}, params = {}, index, isFromAgentView = false }) => {
    const { profilerProjectStore, utilsStore, profilerAccessStore, projectStore } = useStore();
    const isArchivingProject =
      profilerProjectStore.isArchivingProject || projectStore.isArchivingProject;
    const isUpdatingProject =
      profilerProjectStore.isUpdatingProject || projectStore.isUpdatingProject;
    const { navigate } = useHistory();
    const state = useLocalObservable(() => ({
      isRendered: true,
      project: project,
      setProject: (project) => {
        state.project = project;
      },
      isActionsMenuOpen: false,
      toggleActionsMenu: () => {
        state.isActionsMenuOpen = !state.isActionsMenuOpen;
      },
      isMobileActionsMenuOpen: false,
      toggleMobileActionsMenu: () => {
        state.isMobileActionsMenuOpen = !state.isMobileActionsMenuOpen;
        utilsStore.lockScroll(state.isMobileActionsMenuOpen);
      },
      isArchivePopupOpen: false,
      toggleArchivePopup: (isOpen = false) => {
        state.isArchivePopupOpen = isOpen;
        utilsStore.lockScroll(isOpen);
      },
      isDemoRexecutePopupOpen: false,
      toggleDemoReexecutePopup: (isOpen = false) => {
        state.isDemoRexecutePopupOpen = isOpen;
        utilsStore.lockScroll(isOpen);
      },
      isUpdateProjectPopupOpen: false,
      toggleUpdateProjectPopup: (isOpen = false) => {
        state.isUpdateProjectPopupOpen = isOpen;
        utilsStore.lockScroll(isOpen);
        if (isOpen) {
          state.fields[PROFILER_PROJECT_FIELDS.NAME.NAME] =
            state.project[PROFILER_PROJECT_FIELDS.NAME.NAME];
          state.fields[PROFILER_PROJECT_FIELDS.SEARCH_REASON.NAME] =
            state.project[PROFILER_PROJECT_FIELDS.SEARCH_REASON.NAME];
          state.setUpdateProjectError();
        }
      },
      updateProjectError: '',
      setUpdateProjectError: (errorMessage = '') => {
        state.updateProjectError = errorMessage;
      },
      fields: mapFieldsToState(PROFILER_PROJECT_FIELDS),
      setFieldValue: (field = {}, value) => {
        state.fields[field.NAME] = value;
      },
      onSubmitErrorState: false,
      setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
      get validationFields() {
        return getErrorFields(
          [PROFILER_PROJECT_FIELDS.NAME, PROFILER_PROJECT_FIELDS.SEARCH_REASON],
          state.fields
        );
      },
      get isSaveUpdateProjectDisabled() {
        return (
          profilerProjectStore.isUpdatingProject ||
          projectStore.isUpdatingProject ||
          state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
          (state.onSubmitErrorState && state.validationFields.invalidFields.length) ||
          (state.fields[PROFILER_PROJECT_FIELDS.NAME.NAME] ===
            state.project[PROFILER_PROJECT_FIELDS.NAME.NAME] &&
            state.fields[PROFILER_PROJECT_FIELDS.SEARCH_REASON.NAME][0] ===
              state.project[PROFILER_PROJECT_FIELDS.SEARCH_REASON.NAME]?.[0])
        );
      }
    }));

    useEffect(() => {
      return () => {
        runInAction(() => {
          state.isRendered = false;
        });
        if (state.isArchivePopupOpen) {
          state.toggleArchivePopup(false);
        }
        if (state.isDemoRexecutePopupOpen) {
          state.toggleDemoReexecutePopup(false);
        }
        if (state.isUpdateProjectPopupOpen) {
          state.toggleUpdateProjectPopup(false);
        }
        if (state.isMobileActionsMenuOpen) {
          state.toggleMobileActionsMenu(false);
        }
      };
    }, [state]);

    useEffect(() => {
      state.setProject(project);
    }, [state, project]);

    useEffect(() => {
      if (utilsStore.windowWidth > 1200 && state.isMobileActionsMenuOpen) {
        state.toggleMobileActionsMenu();
      }
    }, [state, utilsStore.windowWidth]);

    useEffect(() => {
      const onGlobalClick = (event) => {
        if (!state.isActionsMenuOpen) {
          return;
        }

        const isClickInside =
          event.target.closest('.project-entry-action-menu') ||
          event.target.closest('#project-entry-bring-action-menu' + state.project.id);
        if (isClickInside) {
          return;
        }

        state.toggleActionsMenu();
      };

      document.addEventListener('click', onGlobalClick, { capture: true });

      return () => {
        document.removeEventListener('click', onGlobalClick, { capture: true });
      };
    }, [state]);

    const onViewDetailsClick = () => {
      navigate(
        (isFromAgentView ? paths.PROJECTS : paths.PROFILER_PROJECTS) +
          '?params=' +
          encodeProjectsParams({
            ...params,
            selectedProjectId: state.project.id,
            selectedProjectTab: SELECTED_PROJECT_TABS.RESULTS,
            selectedProjectTabPage: 1
          })
      );
    };

    const reExecuteSearch = () => {
      if (
        state.project.searchCategory ===
        UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company criteria']
      ) {
        const initial = mapFieldsToState(PROFILER_SEARCH_FIELDS);
        const searchFields = {};
        Object.entries(initial).forEach(([fieldName, initialValue]) => {
          searchFields[fieldName] = state.project?.fields?.[fieldName] || initialValue;
        });
        searchFields[PROFILER_SEARCH_FIELDS.SEARCH_TYPE.NAME] = [
          UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler
        ];
        const fieldsParams = Object.entries(searchFields)
          .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
          .join('&');

        navigate(
          paths.SEARCH_RESULTS +
            `?searchCategory=${encodeURIComponent(
              state.project.searchCategory
            )}&page=1&${fieldsParams}&isReExecute=true`
        );
      } else {
        utilsStore.setHeaderMessage(
          `[Re-execute] Search category '${state.project.searchCategory}' is not supported for project of type '${state.project.searchType}'.`,
          HEADER_MESSAGE_TYPES.WARNING
        );
      }
    };

    const onReExecuteSearchClick = () => {
      if (profilerAccessStore.isProfilerDemo) {
        state.toggleDemoReexecutePopup(true);
        return;
      }

      reExecuteSearch();
    };

    const onArchiveClick = () => {
      if (state.isActionsMenuOpen) {
        state.toggleActionsMenu();
      }
      if (state.isMobileActionsMenuOpen) {
        state.toggleMobileActionsMenu();
      }
      state.toggleArchivePopup(true);
    };

    const onSaveProjectUpdateClick = () => {
      trimStateFields(state.fields);
      if (state.validationFields.invalidFields.length) {
        if (!state.onSubmitErrorState) {
          state.setOnSubmitErrorState(true);
        }
        return;
      }

      state.setUpdateProjectError();

      profilerProjectStore.updateProject(
        state.project.id,
        state.fields[PROFILER_PROJECT_FIELDS.NAME.NAME],
        state.fields[PROFILER_PROJECT_FIELDS.SEARCH_REASON.NAME][0],
        () => {
          if (state.isRendered) {
            if (state.isUpdateProjectPopupOpen) {
              state.toggleUpdateProjectPopup(false);
            }
          }
        },
        (errorMessage = '') => {
          if (state.isRendered) {
            state.setUpdateProjectError(errorMessage);
          }
        }
      );
    };

    const hasNewFeedback = profilerProjectStore.hasProjectNewFeedback(state.project);

    return (
      <div className="row" style={index === 0 ? { marginTop: '8px' } : {}}>
        {state.isArchivePopupOpen && (
          <ProfilerArchivePopup
            project={state.project}
            toggle={() => state.toggleArchivePopup(false)}
          />
        )}

        {state.isDemoRexecutePopupOpen && (
          <ProfilerDemoReExecutePopup
            toggle={() => state.toggleDemoReexecutePopup(false)}
            onReExecute={reExecuteSearch}
          />
        )}

        {state.isMobileActionsMenuOpen && (
          <Portal.Root>
            <div className="project-entry-mobile-actions-popup-closer" />
            <div className="project-entry-mobile-actions-popup">
              <div className="header">
                <div className="title">Actions</div>
                <IconButton
                  id="close-project-entry-mobile-actions-popup"
                  withFill={false}
                  withBorder={false}
                  icon={ICON_BUTTON_ICONS.X_LG}
                  onClick={state.toggleMobileActionsMenu}
                />
              </div>
              <div className="actions-menu-mobile">
                <IconButton
                  type={ICON_BUTTON_TYPES.BLUE}
                  filled
                  innerText="Edit project"
                  onClick={() => {
                    state.toggleMobileActionsMenu();
                    state.toggleUpdateProjectPopup(true);
                  }}
                />
                <IconButton
                  type={ICON_BUTTON_TYPES.BLUE}
                  innerText={
                    (state.project.status === 'ACTIVE' ? `Archive` : `Unarchive`) + ' project'
                  }
                  onClick={onArchiveClick}
                />
              </div>
            </div>
          </Portal.Root>
        )}

        {state.isUpdateProjectPopupOpen && (
          <Portal.Root>
            <div className="project-update-popup-closer"></div>
            <div className="project-update-popup-wrapper">
              <div className="project-update-popup">
                <div className="header">
                  <div className="title">
                    Edit {state.project[PROFILER_PROJECT_FIELDS.NAME.NAME]}
                  </div>
                  <IconButton
                    id="close-project-update-popup"
                    withFill={false}
                    withBorder={false}
                    icon={ICON_BUTTON_ICONS.X_LG}
                    onClick={() => state.toggleUpdateProjectPopup(false)}
                  />
                </div>
                <div className="update-fields">
                  <Input
                    field={PROFILER_PROJECT_FIELDS.NAME}
                    value={state.fields[PROFILER_PROJECT_FIELDS.NAME.NAME]}
                    setFieldValue={state.setFieldValue}
                    disabled={isUpdatingProject}
                    messages={state.validationFields.messages}
                    showOnSubmitErrorState
                    hideRequiredLabelSymbol
                  />
                  <MultiSelect
                    field={PROFILER_PROJECT_FIELDS.SEARCH_REASON}
                    value={state.fields[PROFILER_PROJECT_FIELDS.SEARCH_REASON.NAME]}
                    setFieldValue={state.setFieldValue}
                    disabled={isUpdatingProject}
                    hideRequiredLabelSymbol
                  />
                  {!!state.updateProjectError && (
                    <div className="errors">{state.updateProjectError}</div>
                  )}
                </div>
                <div className="update-action-fields">
                  <IconButton
                    innerText="Cancel"
                    onClick={() => state.toggleUpdateProjectPopup(false)}
                  />
                  <IconButton
                    type={ICON_BUTTON_TYPES.BLUE}
                    innerText="Save"
                    onClick={onSaveProjectUpdateClick}
                    disabled={state.isSaveUpdateProjectDisabled}
                    filled
                  />
                </div>
              </div>
            </div>
          </Portal.Root>
        )}

        <div className="item project-name">
          <div className="label">Project name</div>
          <div className="value">{state.project[PROFILER_PROJECT_FIELDS.NAME.NAME]}</div>
        </div>

        <div className="item">
          <div className="label">Profile</div>
          <div className="value">Corporate</div>
        </div>

        <div className="item">
          <div className="label">Search reason</div>
          <div className="value">
            {utilsStore.getOptionName(state.project, PROFILER_PROJECT_FIELDS.SEARCH_REASON)}
          </div>
        </div>

        <div className="item">
          <div className="label">Industries</div>
          <div className="value">{state.project.fields[PROFILER_SEARCH_FIELDS.INDUSTRY.NAME]}</div>
        </div>

        <div className="item profiler-product">
          <div className="label">Product portfolio</div>
          <div className="value">
            {state.project.fields[PROFILER_SEARCH_FIELDS.PRODUCT_PORTFOLIO.NAME]}
          </div>
        </div>

        <div className="item">
          <div className="label">Results</div>
          <div className="value">{state.project.resultsCount}</div>
        </div>

        <div className="item">
          <div className="label">Date</div>
          <div className="value">{formatDateDDMMYYYY(state.project.dateSaved)}</div>
        </div>

        <div className="actions desktop-only">
          <IconButton
            type={ICON_BUTTON_TYPES.BLUE}
            icon={ICON_BUTTON_ICONS.VIEW_DETAILS_CUSTOM1}
            tooltipText={!state.isActionsMenuOpen ? 'View details' : null}
            onClick={onViewDetailsClick}
          />
          <IconButton
            type={ICON_BUTTON_TYPES.BLUE}
            icon={ICON_BUTTON_ICONS.ARROW_COUNTERCLOCKWISE}
            tooltipText={!state.isActionsMenuOpen ? 'Re-execute' : null}
            onClick={onReExecuteSearchClick}
          />
          <IconButton
            id={'project-entry-bring-action-menu' + state.project.id}
            type={ICON_BUTTON_TYPES.BLUE}
            filled={state.isActionsMenuOpen}
            onClick={state.toggleActionsMenu}
            icon={ICON_BUTTON_ICONS.THREE_DOTS_VERTICAL}
            tooltipClassName="project-entry-action-menu"
            forceOpenTooltip={state.isActionsMenuOpen}
            disabled={isArchivingProject || isUpdatingProject}
            tooltipClickable
            tooltipContent={
              state.isActionsMenuOpen ? (
                <>
                  <IconButton
                    type={ICON_BUTTON_TYPES.BLUE}
                    withBorder={false}
                    innerText="Edit project"
                    onClick={() => {
                      state.toggleActionsMenu();
                      state.toggleUpdateProjectPopup(true);
                    }}
                  />
                  <IconButton
                    type={ICON_BUTTON_TYPES.BLUE}
                    withBorder={false}
                    innerText={
                      (state.project.status === 'ACTIVE' ? `Archive` : `Unarchive`) + ' project'
                    }
                    onClick={onArchiveClick}
                  />
                </>
              ) : null
            }
          />
        </div>
        <div className="actions-mobile mobile-only">
          <div className="actions-stretch">
            <IconButton
              type={ICON_BUTTON_TYPES.BLUE}
              innerText="View details"
              onClick={onViewDetailsClick}
              filled
            />
            <IconButton onClick={onReExecuteSearchClick} innerText="Re-execute" />
          </div>
          <IconButton
            wrapClassName="project-entry-bring-mobile-action-menu"
            type={ICON_BUTTON_TYPES.BLUE}
            filled={state.isMobileActionsMenuOpen}
            onClick={state.toggleMobileActionsMenu}
            icon={ICON_BUTTON_ICONS.THREE_DOTS_VERTICAL}
            disabled={isArchivingProject || isUpdatingProject}
          />
        </div>
        {hasNewFeedback && <div className="project-notif-dot" />}
      </div>
    );
  }
);

export default ProfilerProjectListEntry;
