import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { paths } from '../../../utils/constants/routes';
import SplashScreen from './components/SplashScreen';
import Reroute from '../../../components/navigation/Reroute';
import { API_ENDPOINTS } from '../../../api/endpoints';
import StaticPageLayout from '../../../components/layouts/StaticPageLayout';
import { useEffect } from 'react';
import Input from '../../../components/inputs/Input';
import {
  COMPANY_PROFILE_FIELDS,
  getErrorFields,
  compareFields
} from '../../../utils/constants/fields';
import { runInAction } from 'mobx';
import { mapFieldsToState } from '../../../utils/utils';
import MultiSelect from '../../../components/inputs/MultiSelect';
import { mapData } from '../../../api/dataMappers';
import { eventCompleteCompanyProfile, eventCreateCompanyProfilePageView } from '../../../ga4/ga4';
import { HEADER_MESSAGE_TYPES } from '../../../utils/constants/header';

const REDIRECT_TO_HOME_TIMEOUT_MS = 5_000; // 5 seconds

const CreateCompanyProfile = observer(() => {
  const { authStore, makeRequest, utilsStore } = useStore();

  const state = useLocalObservable(() => ({
    isRendered: true,
    setIsRendered: (value = false) => (state.isRendered = value),
    isRerouteTimeoutActive: false,
    setIsRerouteTimeoutActive: (value = false) => (state.isRerouteTimeoutActive = value),
    reroute: false,
    setReroute: (value = false) => (state.reroute = value),
    fields: mapFieldsToState(COMPANY_PROFILE_FIELDS),
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    onSubmitErrorState: false,
    setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
    restoreFields: (e) => {
      e?.preventDefault?.();
      const initial = mapFieldsToState(COMPANY_PROFILE_FIELDS);
      Object.entries(state.fields).forEach(([fieldName]) => {
        state.fields[fieldName] = authStore.userProfileCompany?.[fieldName] || initial[fieldName];
      });
      state.setOnSubmitErrorState(false);
    },
    get validationFields() {
      return getErrorFields(Object.values(COMPANY_PROFILE_FIELDS), state.fields, [
        COMPANY_PROFILE_FIELDS.CONTACT_EMAIL,
        COMPANY_PROFILE_FIELDS.PHONE_NUMBER
      ]);
    },
    get isRestoreDisabled() {
      const hasDiff = compareFields(state.fields, authStore.userProfileCompany);
      return !hasDiff || state.isCreating || authStore.hasUserProfileCompany;
    },
    get isSaveDisabled() {
      return (
        state.isRestoreDisabled ||
        state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
        (state.onSubmitErrorState && state.validationFields.invalidFields.length)
      );
    },
    isSplashScreenVisible: true,
    setIsSplashScreenVisible: (value = false) => (state.isSplashScreenVisible = value),
    isCreating: false,
    progressBarStep: 1, // 1, 2
    createCompanyProfile: (e) => {
      e?.preventDefault?.();

      if (state.validationFields.invalidFields.length) {
        if (!state.onSubmitErrorState) {
          state.setOnSubmitErrorState(true);
        }
        return;
      }

      state.isCreating = true;
      const analyticsFormData = state.getAnalyticsFormData();
      makeRequest({
        endpoint: API_ENDPOINTS.EDIT_PROFILE_COMPANY,
        body: mapData(state.fields, COMPANY_PROFILE_FIELDS, true),
        onSuccess: (response) => {
          authStore.userProfileCompany = mapData(response, COMPANY_PROFILE_FIELDS);
          authStore.hasUserProfileCompany = true;
          state.progressBarStep = 2;
          eventCompleteCompanyProfile(
            analyticsFormData[COMPANY_PROFILE_FIELDS.COMPANY_TYPE.NAME],
            analyticsFormData[COMPANY_PROFILE_FIELDS.COUNTRY.NAME],
            analyticsFormData[COMPANY_PROFILE_FIELDS.TAGLINE.NAME]
          );
        },
        onError: (errorMessage) => {
          utilsStore.setHeaderMessage(
            errorMessage || 'Failed to update company profile.',
            HEADER_MESSAGE_TYPES.ERROR
          );
        },
        onFinally: () => {
          state.isCreating = false;
        }
      });
    },
    getAnalyticsFormData: () => {
      const data = mapData(state.fields, COMPANY_PROFILE_FIELDS, true);
      return Object.fromEntries(
        Object.values(COMPANY_PROFILE_FIELDS).map((fieldConfig) => {
          const fieldName = fieldConfig.NAME;
          const fieldValue = fieldConfig.VALUES_KEY
            ? utilsStore.getOptionName(data[fieldName], fieldConfig)
            : data[fieldName];
          return [fieldName, fieldValue];
        })
      );
    }
  }));

  useEffect(() => {
    if (authStore.userProfileCompany) {
      const initial = mapFieldsToState(COMPANY_PROFILE_FIELDS);
      runInAction(() => {
        Object.entries(authStore.userProfileCompany).forEach(
          ([key, value]) => (state.fields[key] = value || initial[key])
        );
      });
    }
  }, [authStore.userProfileCompany]);

  useEffect(() => {
    state.setIsRendered(true);
    return () => {
      state.setIsRendered(false);
    };
  }, []);

  useEffect(() => {
    if (authStore.hasUserProfileCompany && !state.isRerouteTimeoutActive) {
      state.setIsRerouteTimeoutActive(true);
      setTimeout(() => {
        if (state.isRendered) {
          state.setReroute(true);
        }
      }, REDIRECT_TO_HOME_TIMEOUT_MS);
    }
  }, [state, authStore.hasUserProfileCompany]);

  useEffect(() => {
    if (!authStore.hasUserProfileCompany) {
      eventCreateCompanyProfilePageView();
    }
  }, []);

  const disableAllFields = state.isCreating || authStore.hasUserProfileCompany;

  if (state.reroute) {
    return <Reroute path={paths.HOME_FULL} />;
  }

  return (
    <StaticPageLayout page="company-profile" hideMobileFooter={true}>
      {state.isSplashScreenVisible && !authStore.hasUserProfileCompany && (
        <SplashScreen onHide={() => state.setIsSplashScreenVisible()} />
      )}
      {utilsStore.headerMessage.message == '' && (
        <div className="progress-container">
          <div className="bar-container">
            <div className="bar blue"></div>
            <div
              className={`bar ${
                state.progressBarStep == 2 || authStore.hasUserProfileCompany ? 'blue' : 'gray'
              }`}></div>
          </div>
          {state.progressBarStep == 1 && (
            <div className="icon-text">
              <img src="/icons/loading-black.svg" alt="complete" />
              <div className="text">Company profile</div>
            </div>
          )}
          {state.progressBarStep == 2 && (
            <div className="icon-text">
              <img src="/icons/check-circle-blue.svg" alt="complete" />
              <div className="text">Company profile completed</div>
            </div>
          )}
        </div>
      )}
      <form>
        <div className="form-header create-company-info">
          <div className="container">
            <div className="info">
              <div className="title">My company</div>
              <div className="subtitle">Manage your company data</div>
            </div>
            <div className="actions">
              <button
                className="btn btn-short btn-transparent disable-fade-transparent"
                disabled={state.isRestoreDisabled}
                onClick={state.restoreFields}
                type="reset">
                Restore
              </button>
              <button
                className="btn btn-short btn-primary disable-fade-primary"
                disabled={state.isSaveDisabled}
                onClick={state.createCompanyProfile}
                type="submit">
                {authStore.hasUserProfileCompany
                  ? 'Saved'
                  : state.isCreating
                  ? 'Loading...'
                  : 'Save'}
              </button>
            </div>
          </div>
        </div>
        <div className="form-body form-row-layout">
          <div className="row">
            <div className="col">
              <Input
                field={COMPANY_PROFILE_FIELDS.COMPANY_NAME}
                value={state.fields[COMPANY_PROFILE_FIELDS.COMPANY_NAME.NAME]}
                setFieldValue={state.setFieldValue}
                disabled={disableAllFields}
                messages={state.validationFields.messages}
                showOnSubmitErrorState={true}
              />
            </div>
            <div className="col">
              <MultiSelect
                field={COMPANY_PROFILE_FIELDS.COMPANY_TYPE}
                value={state.fields[COMPANY_PROFILE_FIELDS.COMPANY_TYPE.NAME]}
                setFieldValue={state.setFieldValue}
                disabled
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <MultiSelect
                field={COMPANY_PROFILE_FIELDS.COUNTRY}
                value={state.fields[COMPANY_PROFILE_FIELDS.COUNTRY.NAME]}
                setFieldValue={state.setFieldValue}
                disabled={disableAllFields}
              />
            </div>
            <div className="col">
              <Input
                field={COMPANY_PROFILE_FIELDS.CITY}
                value={state.fields[COMPANY_PROFILE_FIELDS.CITY.NAME]}
                setFieldValue={state.setFieldValue}
                disabled={disableAllFields}
                messages={state.validationFields.messages}
                showOnSubmitErrorState={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Input
                field={COMPANY_PROFILE_FIELDS.PHONE_NUMBER}
                value={state.fields[COMPANY_PROFILE_FIELDS.PHONE_NUMBER.NAME]}
                setFieldValue={state.setFieldValue}
                disabled={disableAllFields}
                showOnSubmitErrorState={state.onSubmitErrorState}
                messages={state.validationFields.messages}
              />
            </div>
            <div className="col">
              <Input
                field={COMPANY_PROFILE_FIELDS.CONTACT_EMAIL}
                value={state.fields[COMPANY_PROFILE_FIELDS.CONTACT_EMAIL.NAME]}
                setFieldValue={state.setFieldValue}
                showOnSubmitErrorState={state.onSubmitErrorState}
                messages={state.validationFields.messages}
                disabled={disableAllFields}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Input
                field={COMPANY_PROFILE_FIELDS.TAGLINE}
                value={state.fields[COMPANY_PROFILE_FIELDS.TAGLINE.NAME]}
                setFieldValue={state.setFieldValue}
                disabled={disableAllFields}
                messages={state.validationFields.messages}
                showOnSubmitErrorState={true}
              />
            </div>
            <div className="col" />
          </div>
        </div>
        <div className="form-footer">
          <button
            className="btn btn-col-2 btn-transparent disable-fade-transparent"
            disabled={state.isRestoreDisabled}
            onClick={state.restoreFields}
            type="reset">
            Restore
          </button>
          <button
            className="btn btn-col-2 btn-primary disable-fade-primary"
            disabled={state.isSaveDisabled}
            onClick={state.createCompanyProfile}
            type="submit">
            {authStore.hasUserProfileCompany ? 'Saved' : state.isCreating ? 'Loading...' : 'Save'}
          </button>
        </div>
      </form>
    </StaticPageLayout>
  );
});

export default CreateCompanyProfile;
