import { SEARCH_FIELDS } from '../../../../../utils/constants/fields';
import { SEARCH_CATEGORIES } from '../../../../../utils/constants/searchCategories';
import Radio from '../../../../../components/inputs/Radio';
import MultiSelect from '../../../../../components/inputs/MultiSelect';
import RangeInput from '../../../../../components/inputs/RangeInput';
import Input from '../../../../../components/inputs/Input';
import { UI_OPTIONS } from '../../../../../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../../../../../utils/constants/uiOptionKeys';

export const getFundsInvestmentCriteriaSearchFields = (state, uiFormState, isFromInvestorView) => {
  let searchTypeMobileOptions = Object.entries(UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]);
  if (state.disableProfilerSearchSelectionMobile) {
    const foundProfilerOptionIndex = searchTypeMobileOptions.findIndex(
      ([name, value]) => value === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler
    );
    if (foundProfilerOptionIndex !== -1) {
      searchTypeMobileOptions.splice(foundProfilerOptionIndex, 1);
    }
  }
  if (isFromInvestorView) {
    const foundFundsOptionIndex = searchTypeMobileOptions.findIndex(
      ([name, value]) => value === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
    );
    if (foundFundsOptionIndex !== -1) {
      searchTypeMobileOptions.splice(foundFundsOptionIndex, 1);
    }
    const foundPortfoliosOptionIndex = searchTypeMobileOptions.findIndex(
      ([name, value]) => value === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']
    );
    if (foundPortfoliosOptionIndex !== -1) {
      searchTypeMobileOptions.splice(foundPortfoliosOptionIndex, 1);
    }
  }
  searchTypeMobileOptions = searchTypeMobileOptions.map(([name, value]) => ({
    name: value === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler ? 'Corporates' : name,
    value
  }));

  return {
    [SEARCH_FIELDS.SEARCH_TYPE.ID]: (
      <Radio
        field={SEARCH_FIELDS.SEARCH_TYPE}
        value={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.SEARCH_TYPE.NAME]}
        setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
        // inputWrap={{ enable: true, className: 'col' }}
      />
    ),
    [SEARCH_FIELDS.SEARCH_TYPE_MOBILE.ID]: (
      <MultiSelect
        field={SEARCH_FIELDS.SEARCH_TYPE_MOBILE}
        value={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.SEARCH_TYPE_MOBILE.NAME]}
        setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
        values={searchTypeMobileOptions}
        // inputWrap={{ enable: true, className: 'col' }}
      />
    ),
    [SEARCH_CATEGORIES.GENERAL]: {
      [SEARCH_FIELDS.INDUSTRY.ID]: (
        <MultiSelect
          field={SEARCH_FIELDS.INDUSTRY}
          value={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.INDUSTRY.NAME]}
          setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [SEARCH_FIELDS.HEADQUARTER.ID]: (
        <MultiSelect
          field={SEARCH_FIELDS.HEADQUARTER}
          value={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.HEADQUARTER.NAME]}
          setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
          withSingleClear
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [SEARCH_FIELDS.GEOGRAPHICAL_FOCUS.ID]: (
        <MultiSelect
          field={SEARCH_FIELDS.GEOGRAPHICAL_FOCUS}
          value={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.GEOGRAPHICAL_FOCUS.NAME]}
          setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
          inputWrap={{ enable: true, className: 'col' }}
        />
      )
    },
    [SEARCH_CATEGORIES.FINANCIALS]: {
      [SEARCH_FIELDS.REVENUE.ID]: (
        <Input
          field={SEARCH_FIELDS.REVENUE}
          value={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.REVENUE.NAME]}
          setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
          inputWrap={{ enable: true, className: 'col' }}
          onAfterValueChange={uiFormState.onRevenueChange}
        />
      ),
      [SEARCH_FIELDS.EBITDA.ID]: (
        <Input
          field={SEARCH_FIELDS.EBITDA}
          value={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.EBITDA.NAME]}
          setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
          inputWrap={{ enable: true, className: 'col' }}
          onFocus={uiFormState.onEBITDAFocus}
          onBlur={uiFormState.onEBITDABlur}
          disabled={uiFormState.isEBITDAFieldDisabled}
          onAfterValueChange={uiFormState.onEBITDAChange}
        />
      ),
      [SEARCH_FIELDS.EBITDA_MARGIN.ID]: (
        <Input
          field={SEARCH_FIELDS.EBITDA_MARGIN}
          value={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.EBITDA_MARGIN.NAME]}
          setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
          inputWrap={{ enable: true, className: 'col' }}
          onFocus={uiFormState.onEBITDAMarginFocus}
          onBlur={uiFormState.onEBITDAMarginBlur}
          disabled={uiFormState.isEBITDAMarginFieldDisabled}
          onAfterValueChange={uiFormState.onEBITDAMarginChange}
        />
      )
    },
    [SEARCH_CATEGORIES.TRANSACTION]: {
      [SEARCH_FIELDS.SITUATION.ID]: (
        <MultiSelect
          field={SEARCH_FIELDS.SITUATION}
          value={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.SITUATION.NAME]}
          setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [SEARCH_FIELDS.EQUITY_STAKE.ID]: (
        <MultiSelect
          field={SEARCH_FIELDS.EQUITY_STAKE}
          value={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.EQUITY_STAKE.NAME]}
          setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
          inputWrap={{ enable: true, className: 'col' }}
        />
      )
    },
    [SEARCH_CATEGORIES.VALUATION]: {
      [SEARCH_FIELDS.ENTERPRISE_MIN.ID + SEARCH_FIELDS.ENTERPRISE_MAX.ID]: (
        <RangeInput
          fieldMin={SEARCH_FIELDS.ENTERPRISE_MIN}
          fieldMax={SEARCH_FIELDS.ENTERPRISE_MAX}
          valueMin={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.ENTERPRISE_MIN.NAME]}
          valueMax={state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.ENTERPRISE_MAX.NAME]}
          setFieldValue={state.setFundsInvestmentCriteriaFieldValue}
          messages={state.fundsInvestmentCriteriaValidationFields.messages}
          showOnSubmitErrorState={state.onSubmitErrorState}
          wrapClass={'col'}
        />
      )
    }
  };
};
