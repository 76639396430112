import { observer } from 'mobx-react';
import { SEARCH_CATEGORIES } from '../../../../utils/constants/searchCategories';
import { FUND_SEARCH_CATEGORY_FIELDS, SEARCH_FIELDS } from '../../../../utils/constants/fields';
import Radio from '../../../../components/inputs/Radio';
import { useStore } from '../../../../store/store';

const FundsInvestmentCriteriaSearchFormDesktop = observer(
  ({
    fields,
    isSearchDisabled,
    onSearch,
    tabs,
    categoryFields,
    setCategoryFieldValue,
    categoryOptions
  }) => {
    const { utilsStore } = useStore();
    if (utilsStore.windowWidth < 1201) {
      return null;
    }

    return (
      <form onSubmit={onSearch} className="desktop">
        <div className="fields-area">
          {/* <div className="search-type-selector">{fields[SEARCH_FIELDS.SEARCH_TYPE.ID]}</div> */}
          {tabs}
          <div className="row category-group">
            <Radio
              field={FUND_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY}
              value={categoryFields[FUND_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME]}
              setFieldValue={setCategoryFieldValue}
              values={categoryOptions}
            />
          </div>
          <div className="flip-horizontal-fields-area">
            <div className="row">
              {fields[SEARCH_CATEGORIES.GENERAL][SEARCH_FIELDS.INDUSTRY.ID]}
              {fields[SEARCH_CATEGORIES.GENERAL][SEARCH_FIELDS.HEADQUARTER.ID]}
              {fields[SEARCH_CATEGORIES.GENERAL][SEARCH_FIELDS.GEOGRAPHICAL_FOCUS.ID]}
            </div>
            <div className="row">
              {fields[SEARCH_CATEGORIES.TRANSACTION][SEARCH_FIELDS.SITUATION.ID]}
              {fields[SEARCH_CATEGORIES.TRANSACTION][SEARCH_FIELDS.EQUITY_STAKE.ID]}
              <div className="col" />
            </div>
            <div className="row">
              {fields[SEARCH_CATEGORIES.FINANCIALS][SEARCH_FIELDS.REVENUE.ID]}
              {fields[SEARCH_CATEGORIES.FINANCIALS][SEARCH_FIELDS.EBITDA.ID]}
              {fields[SEARCH_CATEGORIES.FINANCIALS][SEARCH_FIELDS.EBITDA_MARGIN.ID]}
            </div>
            <div className="row">
              {
                fields[SEARCH_CATEGORIES.VALUATION][
                  SEARCH_FIELDS.ENTERPRISE_MIN.ID + SEARCH_FIELDS.ENTERPRISE_MAX.ID
                ]
              }
              <div className="col" />
              <div className="col search-btn-wrap">
                <button
                  type="submit"
                  className="btn btn-primary disable-fade-primary"
                  disabled={isSearchDisabled}>
                  Search
                </button>
              </div>
            </div>
            <div className="fields-area-mandatory-row">*mandatory fields</div>
          </div>
        </div>
        {/* <div className="separator"></div> */}

        {/* <div className="submit-area">
        <button
          type="submit"
          className="btn btn-primary disable-fade-primary"
          disabled={isSearchDisabled}>
          Search
        </button>
      </div> */}
      </form>
    );
  }
);

export default FundsInvestmentCriteriaSearchFormDesktop;
