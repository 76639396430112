import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { selectedProjectTabPageSize, encodeProjectsParams } from '../constants';
import Pagination from '../../../components/navigation/Pagination';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';
import ProfilerCommentsTabEntry from './ProfilerCommentsTabEntry';

const ProfilerCommentsTabView = observer(({ project = {}, params = {} }) => {
  const { navigate } = useHistory();

  const list = useMemo(() => {
    return project.teamComments.slice().sort((a, b) => b.date - a.date);
  }, [project, project.teamComments, project.teamComments.length]);

  const showPaging = list.length > selectedProjectTabPageSize;
  const pages = Math.ceil(list.length / selectedProjectTabPageSize) || 1;
  const page = (params.projectTypePage || 1) - 1;

  return (
    <div className="comment-table-responsive">
      {list.length === 0 ? (
        <div className="no-data">No items for this tab</div>
      ) : (
        <>
          <div className="header at-projects-listing">
            <div className="item name-item">Team member name</div>
            <div className="item comment-date">Comment date</div>
            <div className="item comment-item">Feedback comment</div>
          </div>

          {list
            .slice(
              (params.selectedProjectTabPage - 1) * selectedProjectTabPageSize,
              params.selectedProjectTabPage * selectedProjectTabPageSize
            )
            .map((comment, index) => (
              <ProfilerCommentsTabEntry
                key={comment.userId + comment.date}
                comment={comment}
                index={index}
              />
            ))}

          <Pagination
            show={showPaging}
            onPageChange={(selected) => {
              navigate(
                paths.PROFILER_PROJECTS +
                  '?params=' +
                  encodeProjectsParams({ ...params, selectedProjectTabPage: selected + 1 })
              );
              setTimeout(() => {
                const el = document.getElementById('project-view-tab-1');
                const header = document.querySelector('header');
                if (el) {
                  const headerHeight = header?.clientHeight || 0;
                  window.scrollTo({ top: el.offsetTop - headerHeight, behavior: 'smooth' });
                }
              }, 100);
            }}
            page={page}
            pages={pages}
          />
        </>
      )}
    </div>
  );
});

export default ProfilerCommentsTabView;
