import React, { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import StaticPageLayout from '../../../components/layouts/StaticPageLayout';
import useHistory from '../../../hooks/useHistory';
import { encodeProjectsParams } from '../../Projects/constants';
import { decodeSharedProjectsParams, SHARED_PROJECT_TYPE_TABS } from './constants';
import { paths } from '../../../utils/constants/routes';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import GeneralError from '../../../components/errors/GeneralError';
import SharedProjectsList from './SharedProjectsList';
import RequestTypeButton from './RequestTypeButton';
import FeedbackRequestView from './FeedbackRequestView';
import { UI_OPTIONS } from '../../../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../../../utils/constants/uiOptionKeys';
import ProfilerFeedbackRequestView from '../../ProfilerTeam/desktop/ProfilerFeedbackRequestView';
import IconButton, {
  ICON_BUTTON_ICONS,
  ICON_BUTTON_TYPES
} from '../../../components/buttons/IconButton';

const TeamDesktop = observer(() => {
  const { location, navigate } = useHistory();
  const { projectStore, profilerProjectStore, authStore, utilsStore } = useStore();
  const state = useLocalObservable(() => ({
    isRendered: true,
    setIsRendered: (isRendered = false) => (state.isRendered = isRendered),
    params: {},
    setParams: (params = {}) => {
      state.params = params;
    },
    get projectList() {
      if (state.params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.PENDING) {
        return projectStore.pendingSharedProjects
          .concat(profilerProjectStore.pendingSharedProjects)
          .slice()
          .sort(
            (a, b) =>
              b.sharedUsers.find((u) => u.id === authStore.currentUserId).dateReceived -
              a.sharedUsers.find((u) => u.id === authStore.currentUserId).dateReceived
          );
      } else {
        return projectStore.resolvedSharedProjects
          .concat(profilerProjectStore.resolvedSharedProjects)
          .slice()
          .sort(
            (a, b) =>
              b.sharedUsers.find((u) => u.id === authStore.currentUserId).dateResolved -
              a.sharedUsers.find((u) => u.id === authStore.currentUserId).dateResolved
          );
      }
    },
    get selectedProject() {
      return state.projectList.find((project) => project.id === state.params.selectedProjectId);
    },
    get showProjectsError() {
      return projectStore.loadProjectsError && !projectStore.allProjects.length;
    },
    get showProfilerProjectsError() {
      return profilerProjectStore.loadProjectsError && !profilerProjectStore.allProjects.length;
    },
    get layout() {
      return utilsStore.windowWidth < 1201 ? 'mobile' : 'desktop';
    },
    showDesktopError: false,
    toggleDesktopError: () => (state.showDesktopError = !state.showDesktopError)
  }));

  useEffect(() => {
    return () => {
      state.setIsRendered();
    };
  }, [state]);

  useEffect(() => {
    authStore.loadCompanyUsers();
  }, [authStore]);

  useEffect(() => {
    if (projectStore.isLoadingProjectsData || profilerProjectStore.isLoadingProjectsData) {
      return;
    }
    let [urlParams, params, shouldNavigate] = [null, {}, false];
    try {
      urlParams = new URLSearchParams(location.search);
      params = decodeSharedProjectsParams(urlParams.get('params'));
    } catch (ex) {
      params = { projectTypeTab: SHARED_PROJECT_TYPE_TABS.PENDING };
      shouldNavigate = true;
    }
    if (typeof params !== 'object' || params === null || params === undefined) {
      params = {};
    }

    if (!Object.values(SHARED_PROJECT_TYPE_TABS).includes(params.projectTypeTab)) {
      params.projectTypeTab = SHARED_PROJECT_TYPE_TABS.PENDING;
      shouldNavigate = true;
    }

    if (shouldNavigate) {
      navigate(paths.TEAM + '?params=' + encodeProjectsParams(params));
    }

    state.setParams(params);
  }, [
    state,
    projectStore,
    profilerProjectStore,
    projectStore.allProjects,
    profilerProjectStore.allProjects,
    projectStore.isLoadingProjectsData,
    profilerProjectStore.isLoadingProjectsData,
    location.search
  ]);

  const showError = state.showProjectsError && state.showProfilerProjectsError;

  const showProjectList =
    !showError &&
    Object.values(SHARED_PROJECT_TYPE_TABS).includes(state.params.projectTypeTab) &&
    !state.params.selectedProjectId;
  const showFeedbackRequest = !showError && state.params.selectedProjectId && state.selectedProject;

  const scrollMobileTabIntoView = (tabId = '') => {
    const tabDOM = document.getElementById(tabId);
    if (tabDOM) {
      setTimeout(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(() => {
          tabDOM.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }, 500);
      }, 100);
    }
  };

  const mobileTabs = [
    {
      id: 'team_mobile_open_requests_tab',
      name: `Open requests (${
        projectStore.pendingSharedProjects.length +
        profilerProjectStore.pendingSharedProjects.length
      })`,
      onClick: () => {
        if (state.params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.PENDING) {
          return;
        }
        navigate(
          paths.TEAM +
            '?params=' +
            encodeProjectsParams({ projectTypeTab: SHARED_PROJECT_TYPE_TABS.PENDING })
        );
        scrollMobileTabIntoView('team_mobile_open_requests_tab');
      },
      isActive: state.params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.PENDING
    },
    {
      id: 'team_mobile_resolved_requests_tab',
      name: `Resolved requests (${
        projectStore.resolvedSharedProjects.length +
        profilerProjectStore.resolvedSharedProjects.length
      })`,
      onClick: () => {
        if (state.params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.RESOLVED) {
          return;
        }
        navigate(
          paths.TEAM +
            '?params=' +
            encodeProjectsParams({ projectTypeTab: SHARED_PROJECT_TYPE_TABS.RESOLVED })
        );
        scrollMobileTabIntoView('team_mobile_resolved_requests_tab');
      },
      isActive: state.params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.RESOLVED
    }
  ];

  const hideMobileFooter = state.layout === 'mobile';

  if (showError) {
    return (
      <StaticPageLayout
        page="team-feedback"
        hideMobileFooter={hideMobileFooter}
        hideMobileFooter1200={hideMobileFooter}>
        <div className="team-projects-listing-error-group">
          <GeneralError
            errorMessage={projectStore.loadProjectsError}
            actionMessage="You may want to:"
            withHomePageButton={true}
            actionButtontext="Try again"
            onActionButtonClick={projectStore.loadProjects}
          />
          <GeneralError
            errorMessage={profilerProjectStore.loadProjectsError}
            actionMessage="You may want to:"
            withHomePageButton={true}
            actionButtontext="Try again"
            onActionButtonClick={profilerProjectStore.loadProjects}
            withLogoutButton
          />
        </div>
      </StaticPageLayout>
    );
  }

  if (
    projectStore.initialLoadProjects ||
    profilerProjectStore.initialLoadProjects ||
    ((projectStore.isLoadingProjects || profilerProjectStore.isLoadingProjects) &&
      !projectStore.allProjects.length &&
      !profilerProjectStore.allProjects.length)
  ) {
    return (
      <StaticPageLayout
        page="team-feedback"
        hideMobileFooter={hideMobileFooter}
        hideMobileFooter1200={hideMobileFooter}>
        <GeneralLoader />
      </StaticPageLayout>
    );
  }

  if (state.layout === 'mobile') {
    return (
      <StaticPageLayout page="team-feedback" hideMobileFooter={true} hideMobileFooter1200={true}>
        {showProjectList && (
          <>
            <div className="mobile-header-merge1">
              <div className="title">Team feedback</div>
            </div>
            <div className="projects-list-partial-errors">
              {(projectStore.isLoadingProjects || profilerProjectStore.isLoadingProjects) && (
                <GeneralLoader />
              )}
              {!!projectStore.loadProjectsError && !projectStore.allProjects.length && (
                <GeneralError
                  errorMessage={projectStore.loadProjectsError}
                  actionMessage="You may want to:"
                  actionButtontext="Try again"
                  onActionButtonClick={projectStore.loadProjects}
                />
              )}
              {!!profilerProjectStore.loadProjectsError &&
                !profilerProjectStore.allProjects.length && (
                  <GeneralError
                    errorMessage={profilerProjectStore.loadProjectsError}
                    actionMessage="You may want to:"
                    actionButtontext="Try again"
                    onActionButtonClick={profilerProjectStore.loadProjects}
                    withLogoutButton
                  />
                )}
            </div>
            <div className="mobile-header-merge2">
              <div className="tabs-scrollable-wrap">
                <div className="tabs-wrap">
                  {mobileTabs.map((tab) => {
                    return (
                      <div
                        key={tab.id}
                        id={tab.id}
                        className={`noselect tab ${tab.isActive ? 'active' : ''}`}
                        onClick={tab.onClick}>
                        {tab.name}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <SharedProjectsList projectList={state.projectList} params={state.params} />
          </>
        )}
        {showFeedbackRequest &&
          (state.selectedProject.searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler ? (
            <ProfilerFeedbackRequestView
              project={state.selectedProject}
              params={state.params}
              isFromAgentView
            />
          ) : (
            <FeedbackRequestView project={state.selectedProject} params={state.params} />
          ))}
      </StaticPageLayout>
    );
  }

  return (
    <StaticPageLayout page="team-feedback">
      {showProjectList && (
        <>
          <div className="layout-wrap">
            <div className="sections-wrap">
              <div className="title-merge">
                <div className="text">Team feedback</div>
                {(state.showProjectsError || state.showProfilerProjectsError) && (
                  <IconButton
                    icon={ICON_BUTTON_ICONS.INFO_CIRCLE}
                    type={ICON_BUTTON_TYPES.RED}
                    onClick={state.toggleDesktopError}
                    filled={state.showDesktopError}
                  />
                )}
                {(state.showProjectsError || state.showProfilerProjectsError) &&
                  state.showDesktopError && (
                    <div className="desktop-error">
                      <div className="errors-group">
                        {state.showProjectsError && (
                          <GeneralError
                            errorMessage={projectStore.loadProjectsError}
                            actionMessage="You may want to:"
                            withHomePageButton={true}
                            actionButtontext="Try again"
                            onActionButtonClick={() =>
                              projectStore.loadProjects() & state.toggleDesktopError()
                            }
                          />
                        )}
                        {state.showProfilerProjectsError && (
                          <GeneralError
                            errorMessage={profilerProjectStore.loadProjectsError}
                            actionMessage="You may want to:"
                            withHomePageButton={true}
                            actionButtontext="Try again"
                            onActionButtonClick={() =>
                              profilerProjectStore.loadProjects() & state.toggleDesktopError()
                            }
                            withLogoutButton
                          />
                        )}
                      </div>
                    </div>
                  )}
              </div>
              <div className="scrollable-body">
                {Object.values(SHARED_PROJECT_TYPE_TABS).map((type) => (
                  <RequestTypeButton key={type} type={type} params={state.params} />
                ))}
              </div>
            </div>
            <div className="right-container">
              <SharedProjectsList projectList={state.projectList} params={state.params} />
            </div>
          </div>
        </>
      )}
      {showFeedbackRequest &&
        (state.selectedProject.searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler ? (
          <ProfilerFeedbackRequestView
            project={state.selectedProject}
            params={state.params}
            isFromAgentView
          />
        ) : (
          <FeedbackRequestView project={state.selectedProject} params={state.params} />
        ))}
    </StaticPageLayout>
  );
});

export default TeamDesktop;
