import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { formatDateDDMMYYYY, mapFieldsToState } from '../../../utils/utils';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';
import {
  SEARCH_FIELDS,
  PROJECT_FIELDS,
  getErrorFields,
  trimStateFields,
  SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA,
  FUND_FIELDS
} from '../../../utils/constants/fields';
import { encodeProjectsParams, SELECTED_PROJECT_TABS } from '../constants';
import ArchivePopup from './ArchivePopup';
import Input from '../../../components/inputs/Input';
import { useEffect } from 'react';
import IconButton, {
  ICON_BUTTON_TYPES,
  ICON_BUTTON_ICONS
} from '../../../components/buttons/IconButton';
import { UI_OPTIONS } from '../../../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../../../utils/constants/uiOptionKeys';
import { HEADER_MESSAGE_TYPES } from '../../../utils/constants/header';
import { runInAction } from 'mobx';
import * as Portal from '@radix-ui/react-portal';
import MultiSelect from '../../../components/inputs/MultiSelect';

const SEARCH_TYPES = {
  funds: 'Fund',
  portfolios: 'Portfolio company',
  investprofiles: 'Invest profile'
};

const ProjectListEntry = observer(({ project = {}, params = {}, index }) => {
  const { projectStore, utilsStore, profilerProjectStore } = useStore();
  const isArchivingProject =
    profilerProjectStore.isArchivingProject || projectStore.isArchivingProject;
  const isUpdatingProject =
    profilerProjectStore.isUpdatingProject || projectStore.isUpdatingProject;
  const { navigate } = useHistory();
  const state = useLocalObservable(() => ({
    isRendered: true,
    project: project,
    setProject: (project) => {
      state.project = project;
    },
    isActionsMenuOpen: false,
    toggleActionsMenu: () => {
      state.isActionsMenuOpen = !state.isActionsMenuOpen;
    },
    isMobileActionsMenuOpen: false,
    toggleMobileActionsMenu: () => {
      state.isMobileActionsMenuOpen = !state.isMobileActionsMenuOpen;
      utilsStore.lockScroll(state.isMobileActionsMenuOpen);
    },
    isArchivePopupOpen: false,
    toggleArchivePopup: (isOpen = false) => {
      state.isArchivePopupOpen = isOpen;
      utilsStore.lockScroll(isOpen);
    },
    isUpdateProjectPopupOpen: false,
    toggleUpdateProjectPopup: (isOpen = false) => {
      state.isUpdateProjectPopupOpen = isOpen;
      utilsStore.lockScroll(isOpen);
      if (isOpen) {
        state.fields[PROJECT_FIELDS.NAME.NAME] = state.project[PROJECT_FIELDS.NAME.NAME];
        state.fields[PROJECT_FIELDS.SEARCH_REASON.NAME] =
          state.project[PROJECT_FIELDS.SEARCH_REASON.NAME];
        state.setUpdateProjectError();
      }
    },
    updateProjectError: '',
    setUpdateProjectError: (errorMessage = '') => {
      state.updateProjectError = errorMessage;
    },
    fields: mapFieldsToState(PROJECT_FIELDS),
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    onSubmitErrorState: false,
    setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
    get validationFields() {
      return getErrorFields([PROJECT_FIELDS.NAME, PROJECT_FIELDS.SEARCH_REASON], state.fields);
    },
    get isSaveUpdateProjectDisabled() {
      return (
        profilerProjectStore.isUpdatingProject ||
        projectStore.isUpdatingProject ||
        state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
        (state.onSubmitErrorState && state.validationFields.invalidFields.length) ||
        (state.fields[PROJECT_FIELDS.NAME.NAME] === state.project[PROJECT_FIELDS.NAME.NAME] &&
          state.fields[PROJECT_FIELDS.SEARCH_REASON.NAME][0] ===
            state.project[PROJECT_FIELDS.SEARCH_REASON.NAME]?.[0])
      );
    }
  }));

  useEffect(() => {
    return () => {
      runInAction(() => {
        state.isRendered = false;
      });
      if (state.isArchivePopupOpen) {
        state.toggleArchivePopup(false);
      }
      if (state.isUpdateProjectPopupOpen) {
        state.toggleUpdateProjectPopup(false);
      }
      if (state.isMobileActionsMenuOpen) {
        state.toggleMobileActionsMenu(false);
      }
    };
  }, [state]);

  useEffect(() => {
    state.setProject(project);
  }, [state, project]);

  useEffect(() => {
    if (utilsStore.windowWidth > 1200 && state.isMobileActionsMenuOpen) {
      state.toggleMobileActionsMenu();
    }
  }, [state, utilsStore.windowWidth]);

  useEffect(() => {
    const onGlobalClick = (event) => {
      if (!state.isActionsMenuOpen) {
        return;
      }

      const isClickInside =
        event.target.closest('.project-entry-action-menu') ||
        event.target.closest('#project-entry-bring-action-menu' + state.project.id);
      if (isClickInside) {
        return;
      }

      state.toggleActionsMenu();
    };

    document.addEventListener('click', onGlobalClick, { capture: true });

    return () => {
      document.removeEventListener('click', onGlobalClick, { capture: true });
    };
  }, [state]);

  const onViewDetailsClick = () => {
    navigate(
      paths.PROJECTS +
        '?params=' +
        encodeProjectsParams({
          ...params,
          selectedProjectId: state.project.id,
          selectedProjectTab: SELECTED_PROJECT_TABS.RESULTS,
          selectedProjectTabPage: 1
        })
    );
  };

  const onReExecuteSearchClick = () => {
    if (state.project.searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds) {
      if (
        state.project.searchCategory ===
        UI_OPTIONS[UI_OPTION_KEYS.FUND_SEARCH_CATEGORY]['Investment criteria']
      ) {
        const initial = mapFieldsToState(SEARCH_FIELDS);
        const searchFields = {};
        Object.entries(initial).forEach(([fieldName, initialValue]) => {
          searchFields[fieldName] = state.project?.fields?.[fieldName] || initialValue;
        });
        const fieldsParams = Object.entries(searchFields)
          .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
          .join('&');

        navigate(
          paths.SEARCH_RESULTS +
            `?searchCategory=${encodeURIComponent(
              state.project.searchCategory
            )}&page=1&${fieldsParams}`
        );
      } else {
        utilsStore.setHeaderMessage(
          `[Re-execute] Search category '${state.project.searchCategory}' is not supported for project of type '${project.searchType}'.`,
          HEADER_MESSAGE_TYPES.WARNING
        );
      }
    } else if (
      state.project.searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']
    ) {
      if (
        state.project.searchCategory ===
        UI_OPTIONS[UI_OPTION_KEYS.PORTFOLIO_SEARCH_CATEGORY]['Investment criteria']
      ) {
        const initial = mapFieldsToState(SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA);
        const searchFields = {};
        Object.entries(initial).forEach(([fieldName, initialValue]) => {
          searchFields[fieldName] = state.project?.fields?.[fieldName] || initialValue;
        });
        const fieldsParams = Object.entries(searchFields)
          .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
          .join('&');

        navigate(
          paths.SEARCH_RESULTS +
            `?searchCategory=${encodeURIComponent(
              state.project.searchCategory
            )}&page=1&${fieldsParams}`
        );
      } else {
        utilsStore.setHeaderMessage(
          `[Re-execute] Search category '${state.project.searchCategory}' is not supported for project of type '${project.searchType}'.`,
          HEADER_MESSAGE_TYPES.WARNING
        );
      }
    } else {
      utilsStore.setHeaderMessage(
        `[Re-execute] Project of type '${state.project.searchType}' is not supported.`,
        HEADER_MESSAGE_TYPES.WARNING
      );
    }
  };

  const onArchiveClick = () => {
    state.toggleArchivePopup(true);
  };

  const onSaveProjectUpdateClick = () => {
    trimStateFields(state.fields);
    if (state.validationFields.invalidFields.length) {
      if (!state.onSubmitErrorState) {
        state.setOnSubmitErrorState(true);
      }
      return;
    }

    state.setUpdateProjectError();

    projectStore.updateProject(
      state.project.id,
      state.fields[PROJECT_FIELDS.NAME.NAME],
      state.fields[PROJECT_FIELDS.SEARCH_REASON.NAME][0],
      () => {
        if (state.isRendered) {
          if (state.isUpdateProjectPopupOpen) {
            state.toggleUpdateProjectPopup(false);
          }
        }
      },
      (errorMessage = '') => {
        if (state.isRendered) {
          state.setUpdateProjectError(errorMessage);
        }
      }
    );
  };

  const hasNewFeedback = projectStore.hasProjectNewFeedback(state.project);

  return (
    <div className="row" style={index === 0 ? { marginTop: '8px' } : {}}>
      {state.isArchivePopupOpen && (
        <ArchivePopup project={state.project} toggle={() => state.toggleArchivePopup(false)} />
      )}

      {state.isMobileActionsMenuOpen && (
        <Portal.Root>
          <div className="project-entry-mobile-actions-popup-closer" />
          <div className="project-entry-mobile-actions-popup">
            <div className="header">
              <div className="title">Actions</div>
              <IconButton
                id="close-project-entry-mobile-actions-popup"
                withFill={false}
                withBorder={false}
                icon={ICON_BUTTON_ICONS.X_LG}
                onClick={state.toggleMobileActionsMenu}
              />
            </div>
            <div className="actions-menu-mobile">
              <IconButton
                type={ICON_BUTTON_TYPES.BLUE}
                filled
                innerText="Edit project"
                onClick={() => {
                  state.toggleMobileActionsMenu();
                  state.toggleUpdateProjectPopup(true);
                }}
              />
              <IconButton
                type={ICON_BUTTON_TYPES.BLUE}
                innerText={
                  (state.project.status === 'ACTIVE' ? `Archive` : `Unarchive`) + ' project'
                }
                onClick={onArchiveClick}
              />
            </div>
          </div>
        </Portal.Root>
      )}

      {state.isUpdateProjectPopupOpen && (
        <Portal.Root>
          <div className="project-update-popup-closer"></div>
          <div className="project-update-popup-wrapper">
            <div className="project-update-popup">
              <div className="header">
                <div className="title">Edit {state.project[PROJECT_FIELDS.NAME.NAME]}</div>
                <IconButton
                  id="close-project-update-popup"
                  withFill={false}
                  withBorder={false}
                  icon={ICON_BUTTON_ICONS.X_LG}
                  onClick={() => state.toggleUpdateProjectPopup(false)}
                />
              </div>
              <div className="update-fields">
                <Input
                  field={PROJECT_FIELDS.NAME}
                  value={state.fields[PROJECT_FIELDS.NAME.NAME]}
                  setFieldValue={state.setFieldValue}
                  disabled={isUpdatingProject}
                  messages={state.validationFields.messages}
                  showOnSubmitErrorState
                  hideRequiredLabelSymbol
                />
                <MultiSelect
                  field={PROJECT_FIELDS.SEARCH_REASON}
                  value={state.fields[PROJECT_FIELDS.SEARCH_REASON.NAME]}
                  setFieldValue={state.setFieldValue}
                  disabled={isUpdatingProject}
                  hideRequiredLabelSymbol
                />
                {!!state.updateProjectError && (
                  <div className="errors">{state.updateProjectError}</div>
                )}
              </div>
              <div className="update-action-fields">
                <IconButton
                  innerText="Cancel"
                  onClick={() => state.toggleUpdateProjectPopup(false)}
                />
                <IconButton
                  type={ICON_BUTTON_TYPES.BLUE}
                  innerText="Save"
                  onClick={onSaveProjectUpdateClick}
                  disabled={state.isSaveUpdateProjectDisabled}
                  filled
                />
              </div>
            </div>
          </div>
        </Portal.Root>
      )}

      <div className="item project-name">
        <div className="label">Project name</div>
        <div className="value">{state.project[PROJECT_FIELDS.NAME.NAME]}</div>
      </div>

      <div className="item">
        <div className="label">Profile</div>
        <div className="value">{SEARCH_TYPES[state.project.searchType]}</div>
      </div>

      <div className="item">
        <div className="label">Search reason</div>
        <div className="value">
          {utilsStore.getOptionName(state.project, PROJECT_FIELDS.SEARCH_REASON)}
        </div>
      </div>

      <div className="item">
        <div className="label">Industries</div>
        <div className="value">
          {utilsStore
            .shortenList(utilsStore.getOptionName(state.project.fields, FUND_FIELDS.INDUSTRIES))
            .join(', ')}
        </div>
      </div>

      <div className="item">
        <div className="label">Product portfolio</div>
        <div className="value">-</div>
      </div>

      <div className="item">
        <div className="label">Results</div>
        <div className="value">{state.project.resultsCount}</div>
      </div>

      <div className="item date">
        <div className="label">Date</div>
        <div className="value">{formatDateDDMMYYYY(state.project.dateSaved)}</div>
      </div>

      <div className="actions desktop-only">
        <IconButton
          type={ICON_BUTTON_TYPES.BLUE}
          icon={ICON_BUTTON_ICONS.VIEW_DETAILS_CUSTOM1}
          tooltipText="View details"
          onClick={onViewDetailsClick}
        />
        <IconButton
          type={ICON_BUTTON_TYPES.BLUE}
          icon={ICON_BUTTON_ICONS.ARROW_COUNTERCLOCKWISE}
          tooltipText="Re-execute"
          onClick={onReExecuteSearchClick}
        />
        <IconButton
          id={'project-entry-bring-action-menu' + state.project.id}
          type={ICON_BUTTON_TYPES.BLUE}
          filled={state.isActionsMenuOpen}
          onClick={state.toggleActionsMenu}
          icon={ICON_BUTTON_ICONS.THREE_DOTS_VERTICAL}
          tooltipClassName="project-entry-action-menu"
          forceOpenTooltip={state.isActionsMenuOpen}
          disabled={isArchivingProject || isUpdatingProject}
          tooltipClickable
          tooltipContent={
            state.isActionsMenuOpen ? (
              <>
                <IconButton
                  type={ICON_BUTTON_TYPES.BLUE}
                  withBorder={false}
                  innerText="Edit project"
                  onClick={() => {
                    state.toggleActionsMenu();
                    state.toggleUpdateProjectPopup(true);
                  }}
                />
                <IconButton
                  type={ICON_BUTTON_TYPES.BLUE}
                  withBorder={false}
                  innerText={
                    (state.project.status === 'ACTIVE' ? `Archive` : `Unarchive`) + ' project'
                  }
                  onClick={onArchiveClick}
                />
              </>
            ) : null
          }
        />
      </div>
      <div className="actions-mobile mobile-only">
        <div className="actions-stretch">
          <IconButton
            type={ICON_BUTTON_TYPES.BLUE}
            innerText="View details"
            onClick={onViewDetailsClick}
            filled
          />
          <IconButton onClick={onReExecuteSearchClick} innerText="Re-execute" />
        </div>
        <IconButton
          wrapClassName="project-entry-bring-mobile-action-menu"
          type={ICON_BUTTON_TYPES.BLUE}
          filled={state.isMobileActionsMenuOpen}
          onClick={state.toggleMobileActionsMenu}
          icon={ICON_BUTTON_ICONS.THREE_DOTS_VERTICAL}
          disabled={isArchivingProject || isUpdatingProject}
        />
      </div>
      {hasNewFeedback && <div className="project-notif-dot" />}
    </div>
  );
});

export default ProjectListEntry;
