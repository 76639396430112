export const PROFILER_FINANCIAL_STATEMENTS = {
  EBIT: {
    item_key_code: 3109,
    name: 'EBIT' // currently returned as 'Total Operating Income'
  },
  EBT: {
    item_key_code: 7272,
    name: 'EBT' // currently returned as 'result from ordinary operations before taxes'
  },
  numberOfEmployees: {
    item_key_code: 5036,
    name: 'Number of employees'
  }
};
