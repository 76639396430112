import { observer } from 'mobx-react';
import { PROFILER_SEARCH_CATEGORIES } from '../../../../utils/constants/searchCategories';
import { Fragment } from 'react';
import { useStore } from '../../../../store/store';
import MultiSelect from '../../../../components/inputs/MultiSelect';
import { PROFILER_SEARCH_CATEGORY_FIELDS } from '../../../../utils/constants/fields';

const ProfilerCompanyCriteriaDesktopFilters = observer(
  ({
    searchTypeField,
    fields,
    fieldsCount,
    searchSectionsToggle,
    toggleSearchSection,
    onSearch,
    isSearchDisabled,
    categoryFields,
    setCategoryFieldValue,
    categoryOptions,
    isLoading,
    isProfilerCompanyCriteriaSearchFieldsDisabled
  }) => {
    const { utilsStore, profilerAccessStore } = useStore();

    if (utilsStore.windowWidth < 1201) {
      return null;
    }

    return (
      <div className="desktop-filters">
        <div className="fade-top" />
        <div className="fade-bottom" />
        <div className="scrollable-items">
          {searchTypeField}
          <MultiSelect
            field={PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE}
            value={categoryFields[PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME]}
            setFieldValue={setCategoryFieldValue}
            values={categoryOptions}
            inputWrap={{ enable: true, className: 'col' }}
            disabled={isLoading || isProfilerCompanyCriteriaSearchFieldsDisabled}
          />
          {Object.values(PROFILER_SEARCH_CATEGORIES).map((section, idx) => {
            const { filled, total } = fieldsCount[section];
            return (
              <div
                className={`filter-category${idx === 0 ? ' no-border' : ''}`}
                key={section}
                id={`desktop-filter-category-${section}`}>
                <button onClick={() => toggleSearchSection(section)}>
                  <span>
                    {section} {filled}/{total}
                  </span>
                  <img
                    src="/icons/chevron-down.svg"
                    className={`${searchSectionsToggle[section] ? 'expanded' : 'collapsed'}`}
                  />
                </button>
                {searchSectionsToggle[section] && (
                  <div className="col-wrapper">
                    {Object.values(fields[section]).map((field, idx) => (
                      <Fragment key={idx}>{field}</Fragment>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
          {profilerAccessStore.isProfilerDemo && (
            <div
              className={`profiler-demo-filter-info-message${
                profilerAccessStore.demoSearchExceededLimit ? ' exceeded' : ''
              }`}>
              <div className="text">
                {profilerAccessStore.demoSearchExceededLimit ? (
                  <>
                    You&apos;ve used all {profilerAccessStore.demoSearchMaxCount} demo searches.
                    Contact <a href="mailto:support@transact.digital">support@transact.digital</a>{' '}
                    for an upgrade to continue.
                  </>
                ) : (
                  <>
                    {profilerAccessStore.demoSearchRemainingCount} out of{' '}
                    {profilerAccessStore.demoSearchMaxCount} searches remaining.
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        <div id="desktop-filters-search-btn-wrap">
          <button
            className="btn btn-primary disable-fade-primary"
            onClick={(event) => onSearch(event) & window.scrollTo({ top: 0 })}
            disabled={isSearchDisabled}>
            Search
          </button>
        </div>
      </div>
    );
  }
);

export default ProfilerCompanyCriteriaDesktopFilters;
