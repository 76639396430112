import { observer } from 'mobx-react';
import {
  PORTFOLIO_SEARCH_CATEGORY_FIELDS,
  SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA
} from '../../../../utils/constants/fields';
import MultiSelect from '../../../../components/inputs/MultiSelect';
import { useStore } from '../../../../store/store';

const PortfoliosInvestmentCriteriaSearchFormMobile = observer(
  ({
    searchTypeInput,
    fields,
    isSearchDisabled,
    onSearch,
    categoryFields,
    setCategoryFieldValue,
    categoryOptions
  }) => {
    const { utilsStore } = useStore();
    if (utilsStore.windowWidth > 1200) {
      return null;
    }

    return (
      <form className="mobile" onSubmit={onSearch}>
        <div className="mobile-search-form-title">Select search type of preference</div>
        {searchTypeInput}
        <MultiSelect
          field={PORTFOLIO_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE}
          value={categoryFields[PORTFOLIO_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME]}
          setFieldValue={setCategoryFieldValue}
          values={categoryOptions}
        />
        {fields[SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.INDUSTRY.ID]}
        {fields[SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.HEADQUARTER.ID]}
        {fields[SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.GEOGRAPHICAL_FOCUS.ID]}
        {fields[SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.REVENUE.ID]}
        <button type="submit" className="btn btn-primary" disabled={isSearchDisabled}>
          Search
        </button>
      </form>
    );
  }
);

export default PortfoliosInvestmentCriteriaSearchFormMobile;
