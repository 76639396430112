import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';

const HorizontalGrabScroll = observer(({ className = '', children }) => {
  const scrollRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (!isDragging) return;
      e.preventDefault();
      const x = e.pageX;
      const deltaX = x - startX;
      scrollRef.current.scrollLeft = scrollLeft - deltaX;
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      document.body.style.cursor = ''; // Reset cursor when dragging stops
    };

    if (isDragging) {
      document.body.style.cursor = 'grabbing'; // Set grabbing cursor globally
    }

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
      document.body.style.cursor = ''; // Ensure cursor resets on cleanup
    };
  }, [isDragging, startX, scrollLeft]);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX);
    setScrollLeft(scrollRef.current.scrollLeft);
    document.body.style.cursor = 'grabbing'; // Set grabbing cursor on mouse down
  };

  const classNames = ['horizontal-grab-scroll-container'];

  if (className) {
    classNames.push(className);
  }

  if (isDragging) {
    classNames.push('dragging');
  }

  return (
    <div ref={scrollRef} className={classNames.join(' ')} onMouseDown={handleMouseDown}>
      {children}
    </div>
  );
});

export default HorizontalGrabScroll;
