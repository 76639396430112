import { observer, useLocalObservable } from 'mobx-react';
import { Tooltip } from 'react-tooltip';
import { useRef, useEffect } from 'react';
import { isPhoneOrTablet } from '../../utils/utils';

export const ICON_BUTTON_ICONS = {
  PERSON: '/icons/clean/person.svg',
  CHAT_SQUARE_TEXT: '/icons/clean/chat-square-text.svg',
  CHAT_RIGHT_DOTS: '/icons/clean/chat-right-dots.svg',
  CHAT_LEFT_QUOTE: '/icons/clean/chat-left-quote.svg',
  SHARE: '/icons/clean/share.svg',
  HOME: '/icons/clean/house.svg',
  TRASH: '/icons/clean/trash.svg',
  DOWNLOAD: '/icons/clean/download.svg',
  ARROW_COUNTERCLOCKWISE: '/icons/clean/arrow-counterclockwise.svg',
  ARROW_REPEAT: '/icons/clean/arrow-repeat.svg',
  ENVELOPE_CHECK: '/icons/clean/envelope-check.svg',
  CHAT_RIGHT_TEXT: '/icons/clean/chat-right-text.svg',
  X_LG: '/icons/clean/x-lg.svg',
  HAND_THUMBS_UP: '/icons/clean/hand-thumbs-up.svg',
  HAND_THUMBS_DOWN: '/icons/clean/hand-thumbs-down.svg',
  QUESTION_LG: '/icons/clean/question-lg.svg',
  FLOPPY_2_FILL: '/icons/clean/floppy2-fill.svg',
  PAPERCLIP: '/icons/clean/paperclip.svg',
  ARCHIVE: '/icons/clean/archive.svg',
  CHEVRON_UP: '/icons/clean/chevron-up.svg',
  CHEVRON_DOWN: '/icons/clean/chevron-down.svg',
  CHEVRON_RIGHT: '/icons/clean/chevron-right.svg',
  PEN: '/icons/clean/pen.svg',
  CASH_STACK: '/icons/clean/cash-stack.svg',
  ARROW_RIGHT_SQUARE: '/icons/clean/arrow-right-square.svg',
  FILE_EARNMARK_PDF: '/icons/clean/file-earmark-pdf.svg',
  PORTFOLIO_COMPANY: '/icons/clean/portfolioCo.svg',
  INVEST_PROFILE: '/icons/clean/Corporate-building.svg',
  THREE_DOTS: '/icons/clean/three-dots.svg',
  THREE_DOTS_VERTICAL: '/icons/clean/three-dots-vertical.svg',
  INFO_CIRCLE: '/icons/clean/info-circle.svg',
  ENVELOPE_DASH: '/icons/clean/envelope-dash.svg',
  CHECK_CIRCLE: '/icons/clean/check-circle.svg',
  CHECK_LG: '/icons/clean/check-lg.svg',
  GEO_ALT: '/icons/clean/geo-alt.svg',
  TELEPHONE: '/icons/clean/telephone.svg',
  ENVELOPE: '/icons/clean/envelope.svg',
  PEOPLE: '/icons/clean/people.svg',
  STAR: '/icons/clean/star.svg',
  STAR_FILL: '/icons/clean/star-fill.svg',
  HOURGLASS: '/icons/clean/hourglass.svg',
  SQUARE_CHECK: '/icons/clean/square-check.svg',
  FILTER: '/icons/clean/filter.svg',
  FILTER_CIRCLE: '/icons/clean/filter-circle.svg',
  FILTER_CIRCLE_FILL: '/icons/clean/filter-circle-fill.svg',
  FILTER_RIGHT: '/icons/clean/filter-right.svg',
  SEND: '/icons/clean/send.svg',
  ARROW_DOWN: '/icons/clean/arrow-down.svg',
  ARROW_LEFT: '/icons/clean/arrow-left.svg',
  SEARCH: '/icons/clean/search.svg',
  WATCHLIST: '/icons/clean/watchlist.svg',
  ARROW_LEFT_RIGHT: '/icons/clean/arrow-left-right.svg',
  CLOUD_SLASH: '/icons/clean/cloud-slash.svg',
  CLOUD_CHECK: '/icons/clean/cloud-check.svg',
  ARROWS_FULLSCREEN: '/icons/clean/arrows-fullscreen.svg',
  FUNNEL: '/icons/clean/funnel.svg',
  FUNNEL_FILL: '/icons/clean/funnel-fill.svg',
  DIAGRAM_PROJECT_1: '/icons/clean/diagram-project-1.svg',
  SORT_ALPHA_DOWN: '/icons/clean/sort-alpha-down.svg',
  SORT_ALPHA_DOWN_ALT: '/icons/clean/sort-alpha-down-alt.svg',
  SORT_ALPHA_UP: '/icons/clean/sort-alpha-up.svg',
  SORT_ALPHA_UP_ALT: '/icons/clean/sort-alpha-up-alt.svg',
  SORT_NUMERIC_DOWN: '/icons/clean/sort-numeric-down.svg',
  SORT_NUMERIC_DOWN_ALT: '/icons/clean/sort-numeric-down-alt.svg',
  SORT_NUMERIC_UP: '/icons/clean/sort-numeric-up.svg',
  SORT_NUMERIC_UP_ALT: '/icons/clean/sort-numeric-up-alt.svg',
  FILE_EARMARK_ARROW_DOWN: '/icons/clean/file-earmark-arrow-down.svg',
  FILE_EARMARK_ARROW_DOWN_FILL: '/icons/clean/file-earmark-arrow-down-fill.svg',
  LINKEDIN: '/icons/clean/linkedin.svg',
  VIEW_DETAILS_CUSTOM1: '/icons/clean/view-details-custom1.svg',
  APP_INDICATOR: '/icons/clean/app-indicator.svg',
  EXCLAMATAION_TRIANGLE_FILL: '/icons/clean/exclamation-triangle-fill.svg',
  ARROW_ANGLE_EXPAND: '/icons/clean/arrows-angle-expand.svg',
  GLOBE2: '/icons/clean/globe2.svg',
  FILE_EARMARK_TEXT: '/icons/clean/file-earmark-text.svg',
  EXCLAMATAION_CIRCLE: '/icons/clean/exclamation-circle.svg',
  // FONT AWESOME
  FA_FILE_LINES_SOLID: '/icons/clean/fa-file-lines-solid.svg',
  // OCTICONS
  OCTI_LIST_UNORDERED: '/icons/clean/octicons-list-unordered.svg',
  OCTI_LIST_ORDERED: '/icons/clean/octicons-list-ordered.svg'
};

export const ICON_BUTTON_TYPES = {
  DEFAULT: 'default', // BLACK
  BLUE: 'blue',
  RED: 'red',
  YELLOW: 'yellow',
  GREEN: 'green'
};

const getIconButtonId = (() => {
  let id = 0;
  return () => {
    id++;
    return 'icon-button' + id;
  };
})();

const IconButton = observer(
  ({
    id = null,
    icon = '',
    type = '',
    hoverType = '',
    withFill = true,
    filled = false,
    withBorder = true,
    disabled = false,
    onClick = () => {},
    innerText = '',
    outerText = '',
    tooltipText = '',
    tooltipContent = null,
    tooltipClassName = '',
    tooltipClickable = false,
    forceOpenTooltip = false,
    tooltipPosition = 'bottom-end',
    wrapClassName = '',
    iconOnRight = false,
    isPDF = false,
    onPDF = () => {},
    notificationDot = false,
    errorNotificationDot = false,
    onButtonRef = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {}
  }) => {
    const btnRef = useRef(null);
    const pdfRef = useRef(null);
    const state = useLocalObservable(() => ({
      buttonId: getIconButtonId()
    }));

    useEffect(() => {
      if (btnRef.current) {
        onButtonRef(btnRef.current);
      }
    }, []);

    const click = (event) => {
      if (isPDF) {
        if (!disabled) {
          pdfRef?.current?.click?.();
        }
        return;
      }
      if (!disabled && typeof onClick === 'function') {
        onClick(event);
      }
    };

    let buttonType = ICON_BUTTON_TYPES.DEFAULT;
    if (Object.values(ICON_BUTTON_TYPES).includes(type)) {
      buttonType = type;
    }
    let buttonHoverType = '';
    if (Object.values(ICON_BUTTON_TYPES).includes(hoverType)) {
      buttonHoverType = hoverType;
    }

    const classNames = ['noselect', 'new-icon-button-wrap', buttonType];
    if (wrapClassName) {
      classNames.push(wrapClassName);
    }
    if (buttonHoverType) {
      classNames.push('hover-type-' + buttonHoverType);
    }
    if (disabled) {
      classNames.push('disabled');
    }
    if (withBorder) {
      classNames.push('bordered');
    }
    if (withFill) {
      classNames.push('with-fill');
      if (filled) {
        classNames.push('filled');
      }
    }
    if (iconOnRight) {
      classNames.push('icon-right');
    }
    if (!isPhoneOrTablet) {
      classNames.push('no-touch-hover');
    }

    return (
      <>
        <div
          onClick={click}
          className={classNames.join(' ')}
          id={id || state.buttonId}
          ref={btnRef}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}>
          <div className="new-icon-button">
            {icon && (
              <div className="img-wrap">
                <img src={icon} />
              </div>
            )}
            {innerText && <div className="inner-text">{innerText}</div>}
            {notificationDot && !errorNotificationDot && <div className="notif-dot" />}
            {errorNotificationDot && !notificationDot && <div className="notif-dot error-dot" />}
            {notificationDot && errorNotificationDot && <div className="semi-notif-error-dot" />}
          </div>
          {outerText && <div className="outer-text">{outerText}</div>}
          {isPDF && (
            <input
              style={{ display: 'none' }}
              type="file"
              name="upload"
              accept=".pdf"
              ref={pdfRef}
              onChange={(event) => {
                if (typeof onPDF === 'function' && event?.target?.files?.[0]) {
                  onPDF(event.target.files[0]);
                }
              }}
            />
          )}
        </div>
        {(!!tooltipText || !!tooltipContent) && (
          <Tooltip
            anchorSelect={`#${id || state.buttonId}`}
            multiline={true}
            variant="light"
            opacity={1}
            noArrow
            place={tooltipPosition}
            className={`icon-button-tooltip tooltip-type-${buttonType}${
              tooltipClassName ? ` ${tooltipClassName}` : ''
            }`}
            clickable={tooltipClickable}
            {...(forceOpenTooltip ? { isOpen: true } : {})}
            {...(tooltipText ? { content: tooltipText } : {})}
            {...(tooltipContent ? { children: tooltipContent } : {})}
          />
        )}
      </>
    );
  }
);

export default IconButton;
