import { useRef, useCallback, useMemo } from 'react';

const CONFIG = {
  particle: {
    size: 3,
    tailLength: 3,
    baseSpeed: 0.0075,
    color: '#348cf9',
    density: 25 // 1 particle per 25 units
  },
  node: {
    width: 14,
    height: 14,
    borderRadius: 2,
    svgSize: 8,
    maxNameWidth: 70, // 14 * 5
    badge: {
      height: 7,
      padding: 1,
      color: '#348CF9',
      fontSize: 4
    },
    generalPartnerBadge: {
      text: 'GP',
      height: 7,
      padding: 1,
      color: '#7B7C7C',
      fontSize: 4
    },
    label: {
      lineHeight: 8,
      offsetFromNode: 10
    }
  },
  link: {
    arrowMargin: 8,
    iconSize: 10,
    defaultWidth: 0.5,
    highlightWidth: 3
  },
  colors: {
    highlight: 'orange',
    target: '#21db4b',
    hover: '#348cf9',
    default: 'black'
  }
};

const createVectorUtils = () => ({
  getLength: (start, end) => Math.sqrt((end.x - start.x) ** 2 + (end.y - start.y) ** 2),
  getAngle: (start, end) => Math.atan2(end.y - start.y, end.x - start.x)
});

class ParticleSystem {
  constructor() {
    this.particles = new Map();
  }

  initialize(linkId, length, start, end) {
    const previousData = this.particles.get(linkId);
    const coordinatesChanged =
      previousData &&
      (previousData.start.x !== start.x ||
        previousData.start.y !== start.y ||
        previousData.end.x !== end.x ||
        previousData.end.y !== end.y);

    if (!this.particles.has(linkId) || coordinatesChanged) {
      const particleCount = Math.max(Math.round(length / CONFIG.particle.density), 1);
      const adjustedSpeed = CONFIG.particle.baseSpeed / particleCount;

      this.particles.set(linkId, {
        particles: Array.from({ length: particleCount }, (_, i) => ({
          t: i / particleCount,
          speed: adjustedSpeed
        })),
        start: { x: start.x, y: start.y },
        end: { x: end.x, y: end.y }
      });
    }
  }

  update(linkId) {
    const data = this.particles.get(linkId);
    if (!data) return;

    data.particles.forEach((particle) => {
      particle.t = (particle.t - particle.speed + 1) % 1;
    });
  }
}

const drawFunctions = {
  particles: (ctx, particles, start, end) => {
    if (!particles?.length) return;

    const angle = Math.atan2(end.y - start.y, end.x - start.x);
    const { size, tailLength, color } = CONFIG.particle;

    ctx.save();
    ctx.strokeStyle = color;
    ctx.fillStyle = color;
    ctx.lineWidth = 1;

    particles.forEach((particle) => {
      const x = end.x + (start.x - end.x) * particle.t;
      const y = end.y + (start.y - end.y) * particle.t;

      ctx.save();
      ctx.translate(x, y);
      ctx.rotate(angle);

      ctx.beginPath();
      ctx.moveTo(-size - tailLength, 0);
      ctx.lineTo(-size, 0);
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(-size, -size / 2);
      ctx.lineTo(0, 0);
      ctx.lineTo(-size, size / 2);
      ctx.closePath();
      ctx.fill();

      ctx.restore();
    });

    ctx.restore();
  },

  link: (ctx, link, isHighlighted, vectorUtils) => {
    const { source: start, target: end } = link;
    const angle = vectorUtils.getAngle(start, end);
    const length = vectorUtils.getLength(start, end);

    ctx.beginPath();
    ctx.moveTo(start.x, start.y);
    ctx.lineTo(end.x, end.y);
    ctx.strokeStyle = isHighlighted ? 'rgba(150, 150, 150, 0.5)' : CONFIG.colors.default;
    ctx.lineWidth = isHighlighted ? CONFIG.link.highlightWidth : CONFIG.link.defaultWidth;
    ctx.stroke();

    if (!isHighlighted) {
      const arrowLength = 5;
      const arrowX = start.x + CONFIG.link.arrowMargin * Math.cos(angle);
      const arrowY = start.y + CONFIG.link.arrowMargin * Math.sin(angle);

      ctx.beginPath();
      ctx.moveTo(arrowX, arrowY);
      ctx.lineTo(
        arrowX + arrowLength * Math.cos(angle - Math.PI / 6),
        arrowY + arrowLength * Math.sin(angle - Math.PI / 6)
      );
      ctx.lineTo(
        arrowX + arrowLength * Math.cos(angle + Math.PI / 6),
        arrowY + arrowLength * Math.sin(angle + Math.PI / 6)
      );
      ctx.closePath();
      ctx.fillStyle = CONFIG.colors.default;
      ctx.fill();
    }

    return { angle, length };
  },

  // linkLabel: (ctx, link, state, angle, start, end, isHighlighted) => {
  //   const { share } = link;
  //   const midX = start.x + (end.x - start.x) / 2;
  //   const midY = start.y + (end.y - start.y) / 2;
  //   const verticalOffset = -6;
  //   const flipHorizontal = end.x < start.x;

  //   ctx.save();
  //   ctx.translate(midX, midY);
  //   ctx.rotate(angle);
  //   if (flipHorizontal) ctx.scale(-1, -1);

  //   const icon = state.bitmapImages.linkShare;
  //   const iconOffset = flipHorizontal
  //     ? -CONFIG.link.iconSize / 2 - 3 - 8
  //     : -CONFIG.link.iconSize / 8 - 5;

  //   if (icon) {
  //     ctx.drawImage(
  //       icon,
  //       iconOffset,
  //       verticalOffset - CONFIG.link.iconSize / 2,
  //       CONFIG.link.iconSize,
  //       CONFIG.link.iconSize
  //     );
  //   }

  //   const textOffset = flipHorizontal ? 3 - 8 : 5;
  //   ctx.font = `${isHighlighted ? 6.3 : 6}px Inter`;
  //   ctx.fillStyle = isHighlighted ? CONFIG.colors.highlight : CONFIG.colors.default;
  //   ctx.textAlign = 'left';
  //   ctx.textBaseline = 'middle';
  //   ctx.fillText(share || 'N/A', textOffset, verticalOffset + 1);

  //   ctx.restore();
  // },

  linkLabel: (ctx, link, state, angle, start, end, isHighlighted) => {
    const { share } = link;
    const midX = start.x + (end.x - start.x) / 2;
    const midY = start.y + (end.y - start.y) / 2;
    const verticalOffset = -6;
    const flipHorizontal = end.x < start.x;

    ctx.save();
    ctx.translate(midX, midY);
    ctx.rotate(angle);
    if (flipHorizontal) {
      ctx.scale(-1, -1);
    }

    const text = share ? `${share}%` : 'N/A';

    ctx.font = `${isHighlighted ? 6.3 : 6}px Inter`;
    ctx.fillStyle = isHighlighted ? CONFIG.colors.highlight : CONFIG.colors.default;
    ctx.textAlign = 'left';
    ctx.textBaseline = 'middle';

    const textWidth = ctx.measureText(text).width;
    // const textOffset = flipHorizontal ? -(textWidth / 2) : -(textWidth / 2);
    const textOffset = -(textWidth / 2);

    ctx.fillText(text, textOffset, verticalOffset + 1);

    ctx.restore();
  },

  node: (() => {
    const createNodePath = (ctx, x, y) => {
      const { width, height, borderRadius } = CONFIG.node;
      ctx.beginPath();
      ctx.moveTo(x - width / 2 + borderRadius, y - height / 2);
      ctx.lineTo(x + width / 2 - borderRadius, y - height / 2);
      ctx.quadraticCurveTo(
        x + width / 2,
        y - height / 2,
        x + width / 2,
        y - height / 2 + borderRadius
      );
      ctx.lineTo(x + width / 2, y + height / 2 - borderRadius);
      ctx.quadraticCurveTo(
        x + width / 2,
        y + height / 2,
        x + width / 2 - borderRadius,
        y + height / 2
      );
      ctx.lineTo(x - width / 2 + borderRadius, y + height / 2);
      ctx.quadraticCurveTo(
        x - width / 2,
        y + height / 2,
        x - width / 2,
        y + height / 2 - borderRadius
      );
      ctx.lineTo(x - width / 2, y - height / 2 + borderRadius);
      ctx.quadraticCurveTo(
        x - width / 2,
        y - height / 2,
        x - width / 2 + borderRadius,
        y - height / 2
      );
      ctx.closePath();
    };

    return (ctx, node, state, targetMapGP) => {
      const { x, y, member_name, beneficial_ownership, type, id } = node;

      ctx.fillStyle = 'white';
      ctx.strokeStyle =
        state.highlightNodes.has(id) && node.target
          ? CONFIG.colors.target
          : id === state.hoverNode?.id
          ? CONFIG.colors.hover
          : state.highlightNodes.has(id)
          ? CONFIG.colors.highlight
          : CONFIG.colors.default;

      ctx.lineWidth =
        id === state.hoverNode?.id || (state.highlightNodes.has(id) && node.target) ? 1 : 0.5;

      createNodePath(ctx, x, y);
      ctx.fill();
      ctx.stroke();

      const svgImg =
        state.bitmapImages[type] ||
        state.bitmapImages?.entity ||
        state.loadedLegendIcons[type] ||
        state.loadedLegendIcons?.entity;

      if (svgImg) {
        ctx.drawImage(
          svgImg,
          x - CONFIG.node.svgSize / 2,
          y - CONFIG.node.svgSize / 2,
          CONFIG.node.svgSize,
          CONFIG.node.svgSize
        );
      }

      if (beneficial_ownership) {
        drawFunctions.beneficialBadge(ctx, x, y, beneficial_ownership);
      } else if (targetMapGP.has(node.id)) {
        drawFunctions.generalPartnershipBadge(ctx, x, y);
      }

      drawFunctions.nodeLabel(ctx, x, y, member_name);
    };
  })(),

  beneficialBadge: (ctx, x, y, text) => {
    const { badge } = CONFIG.node;
    ctx.font = `${badge.fontSize}px Inter`;
    const textWidth = ctx.measureText(text).width;
    const badgeWidth = Math.max(textWidth + 2 * badge.padding, badge.height);
    const badgeX = x - CONFIG.node.width / 2;
    const badgeY = y - CONFIG.node.height / 2;
    const borderRadius = badge.height / 2;

    ctx.fillStyle = badge.color;
    ctx.beginPath();
    ctx.moveTo(badgeX - badgeWidth / 2 + borderRadius, badgeY - badge.height + badge.height / 2);
    ctx.lineTo(badgeX + badgeWidth / 2 - borderRadius, badgeY - badge.height + badge.height / 2);
    ctx.quadraticCurveTo(
      badgeX + badgeWidth / 2,
      badgeY - badge.height + badge.height / 2,
      badgeX + badgeWidth / 2,
      badgeY - badge.height + badge.height / 2 + borderRadius
    );
    ctx.lineTo(badgeX + badgeWidth / 2, badgeY + badge.height / 2 - borderRadius);
    ctx.quadraticCurveTo(
      badgeX + badgeWidth / 2,
      badgeY + badge.height / 2,
      badgeX + badgeWidth / 2 - borderRadius,
      badgeY + badge.height / 2
    );
    ctx.lineTo(badgeX - badgeWidth / 2 + borderRadius, badgeY + badge.height / 2);
    ctx.quadraticCurveTo(
      badgeX - badgeWidth / 2,
      badgeY + badge.height / 2,
      badgeX - badgeWidth / 2,
      badgeY + badge.height / 2 - borderRadius
    );
    ctx.lineTo(badgeX - badgeWidth / 2, badgeY - badge.height + badge.height / 2 + borderRadius);
    ctx.quadraticCurveTo(
      badgeX - badgeWidth / 2,
      badgeY - badge.height + badge.height / 2,
      badgeX - badgeWidth / 2 + borderRadius,
      badgeY - badge.height + badge.height / 2
    );
    ctx.closePath();
    ctx.fill();

    ctx.fillStyle = 'white';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(text, badgeX, badgeY - badge.height / 2 + badge.height / 2);
  },

  generalPartnershipBadge: (ctx, x, y) => {
    const { generalPartnerBadge: badge } = CONFIG.node;
    ctx.font = `${badge.fontSize}px Inter`;
    const textWidth = ctx.measureText(badge.text).width;
    const badgeWidth = Math.max(textWidth + 2 * badge.padding, badge.height);
    const badgeX = x - CONFIG.node.width / 2;
    const badgeY = y - CONFIG.node.height / 2;
    const borderRadius = badge.height / 2;

    ctx.fillStyle = badge.color;
    ctx.beginPath();
    ctx.moveTo(badgeX - badgeWidth / 2 + borderRadius, badgeY - badge.height + badge.height / 2);
    ctx.lineTo(badgeX + badgeWidth / 2 - borderRadius, badgeY - badge.height + badge.height / 2);
    ctx.quadraticCurveTo(
      badgeX + badgeWidth / 2,
      badgeY - badge.height + badge.height / 2,
      badgeX + badgeWidth / 2,
      badgeY - badge.height + badge.height / 2 + borderRadius
    );
    ctx.lineTo(badgeX + badgeWidth / 2, badgeY + badge.height / 2 - borderRadius);
    ctx.quadraticCurveTo(
      badgeX + badgeWidth / 2,
      badgeY + badge.height / 2,
      badgeX + badgeWidth / 2 - borderRadius,
      badgeY + badge.height / 2
    );
    ctx.lineTo(badgeX - badgeWidth / 2 + borderRadius, badgeY + badge.height / 2);
    ctx.quadraticCurveTo(
      badgeX - badgeWidth / 2,
      badgeY + badge.height / 2,
      badgeX - badgeWidth / 2,
      badgeY + badge.height / 2 - borderRadius
    );
    ctx.lineTo(badgeX - badgeWidth / 2, badgeY - badge.height + badge.height / 2 + borderRadius);
    ctx.quadraticCurveTo(
      badgeX - badgeWidth / 2,
      badgeY - badge.height + badge.height / 2,
      badgeX - badgeWidth / 2 + borderRadius,
      badgeY - badge.height + badge.height / 2
    );
    ctx.closePath();
    ctx.fill();

    ctx.fillStyle = 'white';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillText(badge.text, badgeX, badgeY - badge.height / 2 + badge.height / 2);
  },

  nodeLabel: (ctx, x, y, text) => {
    ctx.font = '600 6px Inter';
    ctx.textAlign = 'center';

    const words = text.split(' ');
    const lines = [];
    let currentLine = '';

    for (const word of words) {
      const testLine = currentLine + (currentLine ? ' ' : '') + word;
      if (ctx.measureText(testLine).width > CONFIG.node.maxNameWidth) {
        lines.push(currentLine);
        currentLine = word;
      } else {
        currentLine = testLine;
      }
    }
    lines.push(currentLine);

    const textY =
      y -
      CONFIG.node.height / 2 -
      (lines.length - 1) * CONFIG.node.label.lineHeight -
      CONFIG.node.label.offsetFromNode;

    ctx.fillStyle = 'black';
    lines.forEach((line, i) => {
      ctx.fillText(line, x, textY + i * CONFIG.node.label.lineHeight);
    });
  }
};

const useCanvasObjects = (state) => {
  const vectorUtils = useMemo(() => createVectorUtils(), []);

  const particleSystem = useRef(new ParticleSystem());

  const targetMapGP = useMemo(() => {
    const map = new Map();
    state.popupShareholderStructureData?.mini?.links?.forEach((link) => {
      if (link.detailed_relationship_type_code === 8629) {
        map.set(link.target?.id || link.target, true);
      }
    });
    return map;
  }, [state.popupShareholderStructureData?.mini?.links]);

  const linkCanvasObject = useCallback(
    (link, ctx) => {
      const isHighlighted = state.highlightLinks.has(link.id);
      const { angle, length } = drawFunctions.link(ctx, link, isHighlighted, vectorUtils);

      if (isHighlighted) {
        particleSystem.current.initialize(link.id, length, link.source, link.target);
        particleSystem.current.update(link.id);
        const particleData = particleSystem.current.particles.get(link.id);
        drawFunctions.particles(ctx, particleData.particles, link.target, link.source);
      }

      drawFunctions.linkLabel(ctx, link, state, angle, link.source, link.target, isHighlighted);
    },
    [state, vectorUtils]
  );

  const nodeCanvasObject = useCallback(
    (node, ctx) => {
      drawFunctions.node(ctx, node, state, targetMapGP);
    },
    [state, targetMapGP]
  );

  return { linkCanvasObject, nodeCanvasObject };
};

export default useCanvasObjects;
