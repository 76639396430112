import { Link } from 'react-router-dom';
import { paths } from '../../../utils/constants/routes';
import { useStore } from '../../../store/store';
import { observer } from 'mobx-react';

const NoResults = observer(() => {
  const { utilsStore } = useStore();
  const isMobile = utilsStore.windowWidth < 1201;

  return (
    <div className="no-results">
      <h5>There are no companies in your watchlist</h5>

      <div className="text-help">To add a company in your watchlist:</div>
      <ol>
        <li>
          Execute a search from the <Link to={paths.PROFILER}>profiler</Link>
        </li>
        <li>
          Check on &quot;Details&quot; of any of the company results.
          <img
            src={
              isMobile
                ? '/images/company-details-example-watchlist-tutorial-mobile.svg'
                : '/images/company-details-example-watchlist-tutorial.svg'
            }
          />
        </li>
        <li>
          Click the &quot;watchlist&quot; icon on the top right side of the page.
          <img
            src={
              isMobile
                ? '/images/add-to-watchlist-example-watchlist-tutorial-mobile.svg'
                : '/images/add-to-watchlist-example-watchlist-tutorial.svg'
            }
          />
        </li>
      </ol>
    </div>
  );
});

export default NoResults;
