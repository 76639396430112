import { Store } from '../store'; // eslint-disable-line no-unused-vars
import { makeAutoObservable } from 'mobx';

class ProfilerAccessStore {
  /**
   * @param {Store} root
   */
  constructor(root) {
    makeAutoObservable(this);
    this.root = root;
  }

  get userInfo() {
    return this.root.authStore.userInfo;
  }

  get isProfilerViewer() {
    return !!this.userInfo?.isCognitoProfilerViewer;
  }

  get isProfilerDemo() {
    return !!this.userInfo?.isCognitoProfilerDemo;
  }

  get isProfilerLite() {
    return !!this.userInfo?.isCognitoProfilerLite;
  }

  get isProfilerFull() {
    return !!this.userInfo?.isCognitoProfilerFull;
  }

  get hasAnyProfilerAccess() {
    return (
      this.isProfilerViewer || this.isProfilerDemo || this.isProfilerLite || this.isProfilerFull
    );
  }

  get canUseProfilerSearch() {
    return this.isProfilerLite || this.isProfilerDemo || this.isProfilerFull;
  }

  canViewProfilerDetailsExtendedData = (project) => {
    return (
      this.isProfilerFull ||
      this.root.authStore.companyUsers.findIndex(
        (u) => u.id === project.ownerId && u.isCognitoProfilerFull
      ) !== -1
    );
  };

  get canExportProfilerOnepager() {
    return this.isProfilerLite || this.isProfilerDemo || this.isProfilerFull;
  }

  get canExportProfilerFinancials() {
    return this.isProfilerFull;
  }

  get canExportProfilerFullProfiler() {
    return this.isProfilerFull;
  }

  get canViewProfilerWatchlist() {
    return this.isProfilerDemo || this.isProfilerLite || this.isProfilerFull;
  }

  get canDownloadProfilerSearchResults() {
    return this.isProfilerLite || this.isProfilerDemo || this.isProfilerFull;
  }

  get allowedRequestFeedbackUsersList() {
    return this.root.authStore.companyUsers.filter(
      (u) =>
        u.id !== this.root.authStore.currentUserId &&
        (!!u.isCognitoProfilerViewer ||
          !!u.isCognitoProfilerDemo ||
          !!u.isCognitoProfilerLite ||
          !!u.isCognitoProfilerFull)
    );
  }

  get demoSearchCount() {
    return this.root.authStore.userInfo?.profilerSearchExecutedCount || 0;
  }

  get demoSearchMaxCount() {
    return this.root.authStore.userInfo?.profilerSearchMaxCount || 5;
  }

  get demoSearchRemainingCount() {
    return this.demoSearchMaxCount - this.demoSearchCount;
  }

  get demoSearchExceededLimit() {
    return this.demoSearchRemainingCount <= 0;
  }
}

export default ProfilerAccessStore;
