import { observer } from 'mobx-react';
import {
  FIELDS_TYPES,
  PROFILER_SEARCH_CATEGORY_FIELDS,
  SEARCH_FIELDS
} from '../../../../utils/constants/fields';
import MultiSelect from '../../../../components/inputs/MultiSelect';
import Input from '../../../../components/inputs/Input';
import { useStore } from '../../../../store/store';
import { ICON_BUTTON_ICONS } from '../../../../components/buttons/IconButton';

const ProfilerCompanyNameSearchFormMobile = observer(
  ({ fields, onSearch, categoryFields, setCategoryFieldValue, categoryOptions }) => {
    const { utilsStore, profilerAccessStore } = useStore();
    if (utilsStore.windowWidth > 1200) {
      return null;
    }

    return (
      <form className="mobile" onSubmit={onSearch}>
        {profilerAccessStore.isProfilerDemo && (
          <div className="profiler-demo-info-message">
            <div className="img-wrap">
              <img src={ICON_BUTTON_ICONS.EXCLAMATAION_CIRCLE} />
            </div>
            <div className="text">
              You have {profilerAccessStore.demoSearchRemainingCount} out of{' '}
              {profilerAccessStore.demoSearchMaxCount} demo searches remaining - upgrade to Full
              access to unlock unlimited searches.
            </div>
          </div>
        )}
        <div className="mobile-search-form-title">Select search type of preference</div>
        {fields[SEARCH_FIELDS.SEARCH_TYPE_MOBILE.ID]}
        <MultiSelect
          field={PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE}
          value={categoryFields[PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME]}
          setFieldValue={setCategoryFieldValue}
          values={categoryOptions}
        />
        <Input
          field={{
            NAME: 'companyName',
            ID: 'profiler_company_name',
            LABEL: 'Company name and legal form',
            PLACEHOLDER: 'Type a company name here',
            REQUIRED: true,
            TYPE: FIELDS_TYPES.TYPE_TEXT
          }}
          disabled
        />
        <div
          className="mobile-fields-mandatory-row"
          style={{ margin: '12px 0 24px 0', padding: 0 }}>
          *mandatory fields
        </div>
        <button type="submit" className="btn btn-primary" disabled={true}>
          Search company info
        </button>
      </form>
    );
  }
);

export default ProfilerCompanyNameSearchFormMobile;
