import { observer } from 'mobx-react';
import { useStore } from '../../../store/store';
import IconButton, {
  ICON_BUTTON_TYPES,
  ICON_BUTTON_ICONS
} from '../../../components/buttons/IconButton';
import { SELECTED_PROJECT_TABS } from '../constants';
import { formatNumber } from '../../../utils/utils';

const ProfilerSearchResultsTabEntry = observer(
  ({
    entry = {},
    project = {},
    forTab = SELECTED_PROJECT_TABS.RESULTS,
    excludeResult = () => {},
    isExcludingResult = false,
    displayDetails = () => {},
    index
  }) => {
    const { utilsStore, authStore } = useStore();

    const usersUp = [
      ...new Set(
        project.feedbacks
          ?.filter((fb) => fb.resultId === entry.id && fb.feedback === 'up')
          .map((fb) => {
            const foundUser = authStore.companyUsers.find((u) => u.id === fb.userId);
            if (foundUser) {
              return (
                foundUser.firstName +
                ' ' +
                foundUser.lastName +
                (foundUser.jobPosition ? ` • ${foundUser.jobPosition}` : '')
              );
            }

            return 'unknown';
          })
      )
    ];
    const usersQuestion = [
      ...new Set(
        project.feedbacks
          ?.filter((fb) => fb.resultId === entry.id && fb.feedback === 'question')
          .map((fb) => {
            const foundUser = authStore.companyUsers.find((u) => u.id === fb.userId);
            if (foundUser) {
              return (
                foundUser.firstName +
                ' ' +
                foundUser.lastName +
                (foundUser.jobPosition ? ` • ${foundUser.jobPosition}` : '')
              );
            }

            return 'unknown';
          })
      )
    ];
    const usersDown = [
      ...new Set(
        project.feedbacks
          ?.filter((fb) => fb.resultId === entry.id && fb.feedback === 'down')
          .map((fb) => {
            const foundUser = authStore.companyUsers.find((u) => u.id === fb.userId);
            if (foundUser) {
              return (
                foundUser.firstName +
                ' ' +
                foundUser.lastName +
                (foundUser.jobPosition ? ` • ${foundUser.jobPosition}` : '')
              );
            }

            return 'unknown';
          })
      )
    ];

    return (
      <div
        className="row profiler-search-results-tab-entry-row"
        style={index === 0 ? { marginTop: '8px' } : {}}>
        <div className="item feedback-item mobile-only">
          <IconButton
            type={ICON_BUTTON_TYPES.GREEN}
            icon={ICON_BUTTON_ICONS.HAND_THUMBS_UP}
            innerText={
              (project?.feedbacks?.filter?.(
                (fb) => fb.resultId === entry.id && fb.feedback === 'up'
              )?.length || 0) + ''
            }
            tooltipClassName="popup-feedback popup-feedback-up"
            tooltipClickable
            tooltipContent={
              usersUp.length ? (
                <div className="ppfp-scrollable-body">
                  {usersUp.map((username, idx) => {
                    return (
                      <div className="ppfp-item" key={idx}>
                        {username}
                      </div>
                    );
                  })}
                </div>
              ) : null
            }
            wrapClassName="feedback"
          />
          <IconButton
            type={ICON_BUTTON_TYPES.YELLOW}
            icon={ICON_BUTTON_ICONS.QUESTION_LG}
            innerText={
              (project?.feedbacks?.filter?.(
                (fb) => fb.resultId === entry.id && fb.feedback === 'question'
              )?.length || 0) + ''
            }
            tooltipClassName="popup-feedback popup-feedback-question"
            tooltipClickable
            tooltipContent={
              usersQuestion.length ? (
                <div className="ppfp-scrollable-body">
                  {usersQuestion.map((username, idx) => {
                    return (
                      <div className="ppfp-item" key={idx}>
                        {username}
                      </div>
                    );
                  })}
                </div>
              ) : null
            }
            wrapClassName="feedback"
          />
          <IconButton
            type={ICON_BUTTON_TYPES.RED}
            icon={ICON_BUTTON_ICONS.HAND_THUMBS_DOWN}
            innerText={
              (project?.feedbacks?.filter?.(
                (fb) => fb.resultId === entry.id && fb.feedback === 'down'
              )?.length || 0) + ''
            }
            tooltipClassName="popup-feedback popup-feedback-down"
            tooltipClickable
            tooltipContent={
              usersDown.length ? (
                <div className="ppfp-scrollable-body">
                  {usersDown.map((username, idx) => {
                    return (
                      <div className="ppfp-item" key={idx}>
                        {username}
                      </div>
                    );
                  })}
                </div>
              ) : null
            }
            wrapClassName="feedback"
          />
        </div>
        <div className="item profiler-company-name-item">
          <div className="label">Company name</div>
          <div className="value">{entry.name || 'N/A'}</div>
        </div>
        <div className="item">
          <div className="label">Company HQ</div>
          <div className="value">{entry.headquarters || 'N/A'}</div>
        </div>
        <div className="item">
          <div className="label">Industry</div>
          <div className="value">
            {entry.industries && entry.industries.length
              ? utilsStore.shortenList(entry.industries, 3).join(', ')
              : 'N/A'}
          </div>
        </div>
        <div className="item">
          <div className="label">Value chain</div>
          <div className="value">
            {entry.valueChain && entry.valueChain.length
              ? utilsStore.shortenList(entry.valueChain, 3).join(', ')
              : 'N/A'}
          </div>
        </div>
        <div className="item">
          <div className="label">Products</div>
          <div className="value">
            {entry.products && entry.products.length
              ? utilsStore.shortenList(entry.products, 3).join(', ')
              : 'N/A'}
          </div>
        </div>
        <div className="item profiler-revenue-item">
          <div className="label">Revenue (€M)</div>
          <div className="value right-align-desktop">
            {entry.revenue ? (
              <>
                {entry.revenue}
                <span className="year">
                  &lsquo;
                  {entry.revenueYear?.toString?.()?.slice?.(-2) || 'n/a'}
                </span>
              </>
            ) : (
              'N/A'
            )}
          </div>
        </div>
        <div className="item profiler-employees-item">
          <div className="label">Employees</div>
          <div className="value right-align-desktop">
            {!isNaN(entry.employees) ? (
              <>
                {formatNumber((+entry.employees).toFixed(2))}
                <span className="year">
                  &lsquo;
                  {entry.employeesYear?.toString?.()?.slice?.(-2) || 'n/a'}
                </span>
              </>
            ) : (
              'N/A'
            )}
          </div>
        </div>

        <div className="item feedback-item desktop-only">
          <IconButton
            type={ICON_BUTTON_TYPES.GREEN}
            icon={ICON_BUTTON_ICONS.HAND_THUMBS_UP}
            innerText={
              (project?.feedbacks?.filter?.(
                (fb) => fb.resultId === entry.id && fb.feedback === 'up'
              )?.length || 0) + ''
            }
            tooltipClassName="popup-feedback popup-feedback-up"
            tooltipClickable
            tooltipContent={
              usersUp.length ? (
                <div className="ppfp-scrollable-body">
                  {usersUp.map((username, idx) => {
                    return (
                      <div className="ppfp-item" key={idx}>
                        {username}
                      </div>
                    );
                  })}
                </div>
              ) : null
            }
            wrapClassName="feedback force-default-cursor"
          />
          <IconButton
            type={ICON_BUTTON_TYPES.YELLOW}
            icon={ICON_BUTTON_ICONS.QUESTION_LG}
            innerText={
              (project?.feedbacks?.filter?.(
                (fb) => fb.resultId === entry.id && fb.feedback === 'question'
              )?.length || 0) + ''
            }
            tooltipClassName="popup-feedback popup-feedback-question"
            tooltipClickable
            tooltipContent={
              usersQuestion.length ? (
                <div className="ppfp-scrollable-body">
                  {usersQuestion.map((username, idx) => {
                    return (
                      <div className="ppfp-item" key={idx}>
                        {username}
                      </div>
                    );
                  })}
                </div>
              ) : null
            }
            wrapClassName="feedback force-default-cursor"
          />
          <IconButton
            type={ICON_BUTTON_TYPES.RED}
            icon={ICON_BUTTON_ICONS.HAND_THUMBS_DOWN}
            innerText={
              (project?.feedbacks?.filter?.(
                (fb) => fb.resultId === entry.id && fb.feedback === 'down'
              )?.length || 0) + ''
            }
            tooltipClassName="popup-feedback popup-feedback-down"
            tooltipClickable
            tooltipContent={
              usersDown.length ? (
                <div className="ppfp-scrollable-body">
                  {usersDown.map((username, idx) => {
                    return (
                      <div className="ppfp-item" key={idx}>
                        {username}
                      </div>
                    );
                  })}
                </div>
              ) : null
            }
            wrapClassName="feedback force-default-cursor"
          />
        </div>
        <div className="actions desktop-only">
          <IconButton
            icon={ICON_BUTTON_ICONS.FA_FILE_LINES_SOLID}
            type={ICON_BUTTON_TYPES.BLUE}
            onClick={() => displayDetails(entry)}
            tooltipText="View details"
          />
          {forTab === SELECTED_PROJECT_TABS.RESULTS && (
            <IconButton
              wrapClassName="project-results-exclude-button"
              type={ICON_BUTTON_TYPES.RED}
              icon={ICON_BUTTON_ICONS.X_LG}
              tooltipText="Exclude result"
              disabled={isExcludingResult}
              onClick={() => excludeResult(project.id, entry.id, true)}
            />
          )}
          {forTab === SELECTED_PROJECT_TABS.EXCLUDED && (
            <IconButton
              wrapClassName="project-results-exclude-button"
              type={ICON_BUTTON_TYPES.BLUE}
              icon={ICON_BUTTON_ICONS.ARROW_REPEAT}
              tooltipText="Restore result"
              disabled={isExcludingResult}
              onClick={() => excludeResult(project.id, entry.id, false)}
            />
          )}
        </div>
        <div className="actions-mobile mobile-only">
          <IconButton
            innerText="View details"
            onClick={() => displayDetails(entry)}
            type={ICON_BUTTON_TYPES.BLUE}
            filled
            wrapClassName="view-details-search-results"
          />
          {forTab === SELECTED_PROJECT_TABS.RESULTS && (
            <IconButton
              wrapClassName="project-results-exclude-button"
              type={ICON_BUTTON_TYPES.RED}
              icon={ICON_BUTTON_ICONS.X_LG}
              tooltipText="Exclude result"
              disabled={isExcludingResult}
              onClick={() => excludeResult(project.id, entry.id, true)}
            />
          )}
          {forTab === SELECTED_PROJECT_TABS.EXCLUDED && (
            <IconButton
              wrapClassName="project-results-exclude-button"
              type={ICON_BUTTON_TYPES.BLUE}
              icon={ICON_BUTTON_ICONS.ARROW_REPEAT}
              tooltipText="Restore result"
              disabled={isExcludingResult}
              onClick={() => excludeResult(project.id, entry.id, false)}
            />
          )}
        </div>
      </div>
    );
  }
);

export default ProfilerSearchResultsTabEntry;
