import { observer, useLocalObservable } from 'mobx-react';
import IconButton, {
  ICON_BUTTON_TYPES,
  ICON_BUTTON_ICONS
} from '../../../../components/buttons/IconButton';
import { DETAILS_POUP_TABS } from '../constants/tabs';
import { runInAction } from 'mobx';
import { useEffect, useRef, useLayoutEffect, Fragment } from 'react';
import { ForceGraph2D } from 'react-force-graph';
import * as d3 from 'd3';
import Pagination from '../../../../components/navigation/Pagination';
import { useStore } from '../../../../store/store';
import { getCompanyNewsDisplayTime } from '../../../Outreach/desktop/constants';
import { API_ENDPOINTS } from '../../../../api/endpoints';
import useHistory from '../../../../hooks/useHistory';
// import { ThreeDots } from 'react-loader-spinner';
import {
  formatNumber,
  isElementVisibleOnScreenPartially,
  mapFieldsToState
} from '../../../../utils/utils';
import pubSub from '../../../../utils/pubsub';
import GeneralError from '../../../../components/errors/GeneralError';
import GeneralLoader from '../../../../components/loaders/GeneralLoader';
import {
  COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS,
  getErrorFields,
  trimStateFields
} from '../../../../utils/constants/fields';
import Input from '../../../../components/inputs/Input';
import CheckBox from '../../../../components/inputs/CheckBox';
import useCanvasObjects from './useCanvasObjects';
import { HEADER_MESSAGE_TYPES } from '../../../../utils/constants/header';
import { PROFILER_FINANCIAL_STATEMENTS } from '../../../../utils/constants/profilerFinancials';
import HorizontalGrabScroll from '../../../../components/containers/HorizontalGrabScroll';
import { PROFILER_JOB_TITLES } from '../../../../utils/constants/profilerContacts';
import { Tooltip } from 'react-tooltip';

// const performanceIcons = {
//   group1: {
//     up: '/icons/performance/group1-green-up.svg',
//     down: '/icons/performance/group1-red-down.svg',
//     right: '/icons/performance/group1-yellow-right.svg'
//   },
//   group2: {
//     up: '/icons/performance/group2-black-up.svg',
//     down: '/icons/performance/group2-black-down.svg',
//     right: '/icons/performance/group2-black-right.svg'
//   },
//   group3: {
//     up: '/icons/performance/group3-red-up.svg',
//     down: '/icons/performance/group3-green-down.svg',
//     right: '/icons/performance/group3-black-right.svg'
//   }
// };

const legendsIcons = {
  target: '/icons/clean/crosshair.svg',
  company: '/icons/clean/bank.svg',
  person: '/icons/clean/person.svg',
  entity: '/icons/clean/people.svg',
  share: '/icons/clean/pie-chart.svg',
  beneficial: '/icons/clean/percent.svg',
  linkShare: '/icons/shareholder-share-label-icon.svg'
};

const newsPageSize = 8;
const similerCompaniesPageSize = 8;

const CompanyDetails = observer(
  ({
    company = {},
    displayDetails = () => {},
    isFromWatchList = false,
    style = {},
    prevCompanyName = '',
    project,
    isFromFeedbackProject = false
  }) => {
    const generalFinancialsValuesRef = useRef(null);
    // const financialsMobileFirstRowRef = useRef(null);
    const {
      makeRequest,
      utilsStore,
      profilerProjectStore,
      profilerAccessStore,
      getToken,
      authStore
    } = useStore();
    const { navigate, location } = useHistory();
    const state = useLocalObservable(() => ({
      rendered: true,
      unrender: () => (state.rendered = false),
      observableCompany: company,
      setObservableCompany: (company = {}) => {
        runInAction(() => {
          state.observableCompany = company;
        });
      },
      // tab: isFromWatchList ? DETAILS_POUP_TABS.UPDATES : DETAILS_POUP_TABS.GENERAL,
      // tabId: isFromWatchList
      //   ? 'profiler_search_results_updates_tab'
      //   : 'profiler_search_results_details_tab',
      tab: DETAILS_POUP_TABS.GENERAL,
      tabId: 'profiler_search_results_details_tab',
      setTab: (tab = DETAILS_POUP_TABS.GENERAL, tabId = '') => {
        if (state.tab !== tab) {
          state.tab = tab;
          state.tabId = tabId;
          state.scrollTabIntoView(tabId);
        }
      },
      scrollTabIntoView: (tabId = '', force = false) => {
        const tabDOM = document.getElementById(tabId);
        if (tabDOM) {
          setTimeout(() => {
            tabDOM.scrollIntoView({
              ...(force ? {} : { behavior: 'smooth' }),
              block: 'nearest',
              inline: 'center'
            });
          }, 0);
        }
      },
      generalFinancialsCardScrolled: false,
      setGeneralFinancialsCardScrolled: (value = false) => {
        if (state.generalFinancialsCardScrolled !== value) {
          state.generalFinancialsCardScrolled = value;
        }
      },
      generalFinancialsValuesScrollable: false,
      setGeneralFinancialsValuesScrollable: (value = false) => {
        state.generalFinancialsValuesScrollable = value;
      },
      // desktopFinancialsScrolled: false,
      // setDesktopFinancialsScrolled: (value = false) => {
      //   if (state.desktopFinancialsScrolled !== value) {
      //     state.desktopFinancialsScrolled = value;
      //   }
      // },
      // financialsValuesScrollable: false,
      // setFinancialsValuesScrollable: (value = false) => {
      //   state.financialsValuesScrollable = value;
      // },
      newsPage: 1,
      setNewsPage: (page = 1) => {
        state.newsPage = page;
      },
      get newsPages() {
        return Math.ceil((state.observableCompany.news || []).length / newsPageSize);
      },
      get showNewsPages() {
        return state.newsPages > 1;
      },
      now: Date.now(),
      setDateNow: (ms) => (state.now = ms),
      updateTabVisited: false,
      flagUpdateTabVisited: () => {
        state.updateTabVisited = true;
      },
      isAddRemoveWatchlistPopupOpen: false,
      setIsAddRemoveWatchlistPopupOpen: (open = false) => {
        state.isAddRemoveWatchlistPopupOpen = open;
        utilsStore.lockScroll(open);
      },
      componentVariables: {
        lastScrollY: 0,
        isScrollingToShareholderStructure: false
      },
      companyLogo: null,
      loadCompanyLogo: () => {
        makeRequest({
          endpoint: API_ENDPOINTS.GET_COMPANY_LOGO,
          body: { companyDomain: state.observableCompany.website },
          onSuccess: (response) => {
            state.companyLogo = response?.src || '/images/company-logo-placeholder.svg';
          }
        });
      },
      newsLogos: {},
      loadNewsLogos: () => {
        if (!state.observableCompany.news?.length) {
          state.newsLogos = {};
          return;
        }

        makeRequest({
          endpoint: API_ENDPOINTS.GET_COMPANY_LOGO,
          body: {
            companyDomains: [...new Set(state.observableCompany.news.map((obj) => obj.domain))]
          },
          onSuccess: (response) => {
            state.newsLogos = Object.fromEntries(
              (response || []).map((obj) => [obj.domain, obj.src])
            );
          }
        });
      },
      openedSimilarCompany: null,
      savedScrollYOnSimilarCompany: 0,
      onSimilarCompanyDetailsClick: (company = {}) => {
        state.savedScrollYOnSimilarCompany = window.scrollY;
        state.openedSimilarCompany = company;
      },
      onSimilarCompanyClose: () => {
        state.openedSimilarCompany = null;
      },
      similarCompanies: [],
      similarCompaniesPage: 1,
      setSimilarCompaniesPage: (page = 1) => {
        state.similarCompaniesPage = page;
      },
      similarCompaniesPages: 1,
      isLoadingSimilarCompanies: true,
      loadSimilarCompaniesError: null,
      isSimilarCompaniesLoadSuspend: false,
      loadSimilarCompanies: () => {
        if (state.isSimilarCompaniesLoadSuspend) {
          return;
        }

        state.isSimilarCompaniesLoadSuspend = true;
        state.isLoadingSimilarCompanies = true;
        state.loadSimilarCompaniesError = null;
        makeRequest({
          endpoint: API_ENDPOINTS.GET_SIMILAR_COMPANIES,
          body: { companyId: state.observableCompany.id },
          onSuccess: (response) => {
            if (!state.rendered) {
              return;
            }

            const similarCompanies = response || [];
            state.similarCompanies = similarCompanies;
            state.similarCompaniesPages =
              Math.ceil(similarCompanies.length / similerCompaniesPageSize) || 1;
          },
          onError: (errorMessage) => {
            if (!state.rendered) {
              return;
            }

            state.loadSimilarCompaniesError = errorMessage || 'Failed to load similar companies.';
          },
          onFinally: () => {
            if (!state.rendered) {
              return;
            }

            state.isLoadingSimilarCompanies = false;
            state.isSimilarCompaniesLoadSuspend = false;
          }
        });
      },
      isFinancialsDataEmpty: false,
      financials: null,
      ultimateShareholders: null,
      shareholdersStructure: null,
      topManagement: null,
      keyContacts: null,
      isLoadingDetails: true,
      loadDetailsError: null,
      isDetailsLoadSuspend: false,
      loadDetails: () => {
        if (
          state.isDetailsLoadSuspend ||
          !profilerAccessStore.canViewProfilerDetailsExtendedData(project)
        ) {
          return;
        }

        state.isDetailsLoadSuspend = true;
        state.isLoadingDetails = true;
        state.loadDetailsError = null;
        makeRequest({
          endpoint: API_ENDPOINTS.GET_PROFILER_DETAILS,
          body: {
            companyId: state.observableCompany.id,
            ...(isFromFeedbackProject ? { sharedProjectId: project.id } : {})
          },
          onSuccess: (response) => {
            if (!state.rendered) {
              return;
            }

            state.financials = { details: response.details, general: response.general };
            state.financialYears = response.details.years;
            response.details.groups.forEach((group) => {
              // state.selectedFinancialLevels[group.id] = group.levels[group.levels.length - 1];
              state.collapsedFinancialDetails[group.id] = false;
              state.toggledFinancialFilters[group.id] = false;
            });
            state.topManagement = response.topManagement;
            state.keyContacts = response.keyContacts;
            state.isFinancialsDataEmpty = response.isFinancialsDataEmpty;
            state.shareholdersStructure = response.shareholdersStructure;
            state.generateShareholderStructureData();
          },
          onError: (errorMessage) => {
            if (!state.rendered) {
              return;
            }

            state.loadDetailsError = errorMessage || 'Failed to load company details.';
          },
          onFinally: () => {
            if (!state.rendered) {
              return;
            }

            state.isLoadingDetails = false;
            state.isDetailsLoadSuspend = false;
          }
        });
      },
      selectedFinancialLevels: {},
      collapsedFinancialDetails: {},
      financialYears: [],
      toggledFinancialFilters: {},
      setFinancialLevel: (id, level) => {
        state.selectedFinancialLevels[id] = level;
        state.toggleFinancialFilter(id);
      },
      toggleFinancialDetails: (id) => {
        state.collapsedFinancialDetails[id] = !state.collapsedFinancialDetails[id];
        if (state.collapsedFinancialDetails[id]) {
          return;
        }

        setTimeout(() => {
          const rightFinancialContentDOM = document.getElementById(`right-financial-content-${id}`);
          if (rightFinancialContentDOM && rightFinancialContentDOM.scrollWidth) {
            rightFinancialContentDOM.scrollTo({
              left: rightFinancialContentDOM.scrollWidth,
              behavior: 'smooth'
            });
          }
        }, 10);
      },
      toggleFinancialFilter: (id) => {
        state.toggledFinancialFilters[id] = !state.toggledFinancialFilters[id];
      },
      get filteredFinancialGroups() {
        const groups = [];
        state.financials?.details?.groups?.forEach?.((group) => {
          const groupCopy = { ...group };
          groups.push(groupCopy);
          groupCopy.items = group.items.filter(
            (item) =>
              item.level <=
              (state.selectedFinancialLevels?.[group.id] ||
                group.levels[group.levels.length - 1] ||
                1)
          );
        });
        return groups;
      },
      financialTablesFirstRowsHeights: {},
      setFinancialTableFirstRowHeight: (groupId, height) => {
        if (state.financialTablesFirstRowsHeights[groupId] !== height) {
          state.financialTablesFirstRowsHeights[groupId] = height;
        }
      },
      financialTablesRowsHeights: {},
      setFinancialTableRowHeight: (groupId, itemName, height) => {
        if (state.financialTablesRowsHeights[groupId]?.[itemName] !== height) {
          if (!state.financialTablesRowsHeights[groupId]) {
            state.financialTablesRowsHeights[groupId] = {};
          }
          state.financialTablesRowsHeights[groupId][itemName] = height;
        }
      },
      popupShareholderStructureAppliedFilters: {
        ...mapFieldsToState(COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS),
        [COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.SHAREHOLDER_SHARE_PERCENT.NAME]: '5'
      },
      popupShareholderStructureFilters: {
        ...mapFieldsToState(COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS),
        [COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.SHAREHOLDER_SHARE_PERCENT.NAME]: '5'
      },
      setPopupShareholderStructureFiltersValue: (field = {}, value) => {
        state.popupShareholderStructureFilters[field.NAME] = value;
      },
      onSubmitErrorStatePopupShareholderStructure: false,
      setOnSubmitErrorStatePopupShareholderStructure: (value = false) =>
        (state.onSubmitErrorStatePopupShareholderStructure = value),
      get validationFieldsPopupShareholderStructure() {
        return getErrorFields(
          Object.values(COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS),
          state.popupShareholderStructureFilters
        );
      },
      get isPopupShareholderStructureFilterDisabled() {
        const hasDiff =
          state.popupShareholderStructureFilters[
            COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.SHAREHOLDER_SHARE_PERCENT.NAME
          ] !==
            state.popupShareholderStructureAppliedFilters[
              COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.SHAREHOLDER_SHARE_PERCENT.NAME
            ] ||
          state.popupShareholderStructureFilters[
            COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.ENTITIES_UNKNOWN_VALUE.NAME
          ] !==
            state.popupShareholderStructureAppliedFilters[
              COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.ENTITIES_UNKNOWN_VALUE.NAME
            ];

        return (
          state.validationFieldsPopupShareholderStructure.invalidFields.filter((f) => !f.isOnSubmit)
            .length ||
          (state.setOnSubmitErrorStatePopupShareholderStructure &&
            state.validationFieldsPopupShareholderStructure.invalidFields.length) ||
          !hasDiff
        );
      },
      onPopupShareholderStructureFilter: () => {
        trimStateFields(state.popupShareholderStructureFilters);
        if (state.validationFieldsPopupShareholderStructure.invalidFields.length) {
          if (!state.onSubmitErrorStatePopupShareholderStructure) {
            state.setOnSubmitErrorStatePopupShareholderStructure(true);
          }
          return;
        }

        state.popupShareholderStructureAppliedFilters = JSON.parse(
          JSON.stringify(state.popupShareholderStructureFilters)
        );

        if (!state.toggledShareholderPopup) {
          state.toggleMiniShareholderStructureFilters();
        }

        state.generateShareholderStructureData();

        setTimeout(() => {
          miniGraphRef?.current?.zoomToFit?.(
            100,
            (state.popupShareholderStructureData?.mini?.nodes?.length || 0) <= 3 ? 50 : 24
          );
          popupGraphRef?.current?.zoomToFit?.(
            100,
            (state.popupShareholderStructureData?.mini?.nodes?.length || 0) <= 3 ? 112 : 56
          );
        }, 100);
      },
      onPopupShareholderStructureClearFilter: () => {
        state.popupShareholderStructureAppliedFilters = {
          ...mapFieldsToState(COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS),
          [COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.SHAREHOLDER_SHARE_PERCENT.NAME]: '5'
        };
        state.popupShareholderStructureFilters = {
          ...mapFieldsToState(COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS),
          [COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.SHAREHOLDER_SHARE_PERCENT.NAME]: '5'
        };

        if (!state.toggledShareholderPopup) {
          state.toggleMiniShareholderStructureFilters();
        }

        state.generateShareholderStructureData();

        setTimeout(() => {
          miniGraphRef?.current?.zoomToFit?.(
            100,
            (state.popupShareholderStructureData?.mini?.nodes?.length || 0) <= 3 ? 50 : 24
          );
          popupGraphRef?.current?.zoomToFit?.(
            100,
            (state.popupShareholderStructureData?.mini?.nodes?.length || 0) <= 3 ? 112 : 56
          );
        }, 100);
      },
      toggledMiniShareholderStructureFilters: false,
      toggleMiniShareholderStructureFilters: () => {
        state.toggledMiniShareholderStructureFilters =
          !state.toggledMiniShareholderStructureFilters;
        state.popupShareholderStructureFilters = JSON.parse(
          JSON.stringify(state.popupShareholderStructureAppliedFilters)
        );
      },
      shareholderMiniEngineStopExecuted: false,
      toggledShareholderPopup: false,
      toggleShareholderPopup: () => {
        state.toggledShareholderPopup = !state.toggledShareholderPopup;
        utilsStore.lockScroll(state.toggledShareholderPopup);
        if (state.toggledShareholderPopup) {
          setTimeout(() => {
            popupGraphRef?.current?.zoomToFit?.(
              100,
              (state.popupShareholderStructureData?.mini?.nodes?.length || 0) <= 3 ? 112 : 56
            );
          }, 200);
        }
      },
      highlightNodes: new Set(),
      highlightLinks: new Set(),
      hoverNode: null,
      setHoverNode: (node) => {
        state.hoverNode = node || null;
      },
      updateHighlight: () => {
        state.highlightNodes = new Set(state.highlightNodes);
        state.highlightLinks = new Set(state.highlightLinks);
      },
      handleNodeHover: (node) => {
        state.highlightNodes.clear();
        state.highlightLinks.clear();
        if (node) {
          state.highlightNodes.add(node);
          node.neighbors.forEach((neighbor) => state.highlightNodes.add(neighbor));
          node.links.forEach((link) => state.highlightLinks.add(link.id));

          const findTargetNodePath = (currentNode, visitedNodes = new Set()) => {
            if (visitedNodes.has(currentNode.id)) return false;
            visitedNodes.add(currentNode.id);

            if (currentNode.target === true) {
              state.highlightNodes.add(currentNode.id);
              return true;
            }

            for (let link of currentNode.links) {
              const sourceNode = state.popupShareholderStructureData.mini.nodes.find(
                (n) => n.id === link.source
              );
              if (findTargetNodePath(sourceNode, visitedNodes)) {
                state.highlightNodes.add(currentNode.id);
                state.highlightLinks.add(link.id);
                return true;
              }
            }
            return false;
          };

          findTargetNodePath(node);
        }

        state.setHoverNode(node);
        state.updateHighlight();
      },
      handleLinkHover: (link) => {
        state.highlightNodes.clear();
        state.highlightLinks.clear();

        if (link) {
          state.highlightLinks.add(link.id);
          state.highlightNodes.add(link.source.id);
          state.highlightNodes.add(link.target.id);
        }

        state.updateHighlight();
      },
      disableRestoreGraph: true,
      popupShareholderStructureData: null,
      generateShareholderStructureData: (params) => {
        state.setMiniStructureHeight(0);
        const withCenter = params?.withCenter;
        if (withCenter) {
          runInAction(() => {
            state.disableRestoreGraph = true;
          });
        }

        try {
          const structure = JSON.parse(JSON.stringify(state.shareholdersStructure));

          const rootNodeIndex = structure.nodes.findIndex((node) => node.target);
          const rootNode = structure.nodes.splice(rootNodeIndex, 1)[0];
          structure.nodes.unshift(rootNode);

          const percentageFilterValue = parseFloat(
            state.popupShareholderStructureAppliedFilters[
              COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.SHAREHOLDER_SHARE_PERCENT.NAME
            ]
          );
          const includeLinksWithNaNShares =
            state.popupShareholderStructureAppliedFilters[
              COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.ENTITIES_UNKNOWN_VALUE.NAME
            ];

          let excludedNodes = [];

          structure.links = structure.links.filter((link) => {
            const linkShare = parseFloat(link.share);
            if (!isNaN(percentageFilterValue)) {
              if (isNaN(linkShare)) {
                return includeLinksWithNaNShares || link.detailed_relationship_type_code === 8629;
              }
              return linkShare >= percentageFilterValue;
            }
            return true;
          });

          const nodesWithLinks = structure.nodes.filter((node) => {
            return (
              node.id === rootNode.id ||
              structure.links.some((link) => link.source === node.id || link.target === node.id)
            );
          });

          excludedNodes = structure.nodes.filter((node) => !nodesWithLinks.includes(node));
          structure.nodes = nodesWithLinks;

          const reachableNodes = new Set();
          const markReachableNodes = (nodeId) => {
            if (reachableNodes.has(nodeId)) return;
            reachableNodes.add(nodeId);
            structure.links.forEach((link) => {
              if (link.source === nodeId) {
                markReachableNodes(link.target);
              }
              if (link.target === nodeId) {
                markReachableNodes(link.source);
              }
            });
          };

          markReachableNodes(rootNode.id);

          const nodesBeforeReachableFilter = new Set(structure.nodes.map((node) => node.id));
          structure.nodes = structure.nodes.filter((node) => reachableNodes.has(node.id));

          structure.nodes.forEach((node) => {
            if (!reachableNodes.has(node.id) && !nodesBeforeReachableFilter.has(node.id)) {
              excludedNodes.push(node);
            }
          });

          structure.links = structure.links.filter(
            (link) => reachableNodes.has(link.source) && reachableNodes.has(link.target)
          );

          const aggregatedOwnership = excludedNodes
            .map((node) => {
              const ownership = parseFloat(node.beneficial_ownership);
              return isNaN(ownership) ? 0 : ownership;
            })
            .reduce((acc, ownership) => acc + ownership, 0);

          if (aggregatedOwnership > 0) {
            const aggregatedNode = {
              id: Math.floor(Math.random() * 1e12) | 0,
              member_name: 'Aggregated filtered ultimate shareholders',
              beneficial_ownership: formatNumber(aggregatedOwnership.toFixed(2)),
              width: 100,
              height: 100
            };

            structure.nodes.push(aggregatedNode);

            structure.links.push({
              source: rootNode.id,
              target: aggregatedNode.id,
              share: null,
              value: 'N/A',
              beneficial_ownership: formatNumber(aggregatedOwnership.toFixed(2)),
              is_beneficiary: true
            });
          }

          structure.nodes.forEach((node) => {
            node.width = 100;
            node.height = 100;
            node.neighbors = [];
            node.links = [];
          });

          structure.links.forEach((link) => {
            const sourceNode = structure.nodes.find((node) => node.id === link.source);
            const targetNode = structure.nodes.find((node) => node.id === link.target);

            if (sourceNode && targetNode) {
              sourceNode.neighbors.push(targetNode.id);
              targetNode.neighbors.push(sourceNode.id);
              sourceNode.links.push({ id: link.id, source: link.source, target: link.target });
              targetNode.links.push({ id: link.id, source: link.source, target: link.target });
            }
          });

          // const assignNames = (node) => {
          //   const { id, children } = node;
          //   const foundNode = structure.nodes.find((n) => n.id === id);
          //   if (foundNode) {
          //     node.name = foundNode.member_name;
          //   }
          //   children.forEach(assignNames);
          // };

          const collectData = (structure, rootId) => {
            const nodeMap = new Map();
            const links = structure.links;

            const queue = [{ nodeId: rootId, parentId: null, level: 0 }];
            while (queue.length > 0) {
              const { nodeId, parentId, level } = queue.shift();

              if (!nodeMap.has(nodeId)) {
                nodeMap.set(nodeId, { levels: [], parents: new Map(), children: [] });
              }

              const nodeData = nodeMap.get(nodeId);
              if (!nodeData.levels.includes(level)) {
                nodeData.levels.push(level);
              }
              if (parentId !== null && !nodeData.parents.has(parentId)) {
                nodeData.parents.set(parentId, level);
              }

              const childrenIds = links
                .filter((l) => l.source === nodeId || l.target === nodeId)
                .map((l) => (l.source === nodeId ? l.target : l.source));

              for (const childId of childrenIds) {
                if (!nodeMap.has(childId) || Math.min(...nodeMap.get(childId).levels) > level + 1) {
                  queue.push({ nodeId: childId, parentId: nodeId, level: level + 1 });
                }
              }
            }

            return nodeMap;
          };

          const adjustNodes = (nodeMap) => {
            const finalHierarchyMap = new Map();

            const sortedNodes = [...nodeMap.entries()].sort(
              ([, aData], [, bData]) => Math.min(...aData.levels) - Math.min(...bData.levels)
            );

            for (const [nodeId, data] of sortedNodes) {
              const minLevel = Math.min(...data.levels);
              const validParent = [...data.parents.entries()].reduce((best, [parentId, level]) => {
                if (level === minLevel) return parentId;
                return best;
              }, null);

              if (!finalHierarchyMap.has(nodeId)) {
                finalHierarchyMap.set(nodeId, { id: nodeId, children: [], level: minLevel });
              }

              const node = finalHierarchyMap.get(nodeId);
              node.level = minLevel;

              if (validParent !== null) {
                if (!finalHierarchyMap.has(validParent)) {
                  finalHierarchyMap.set(validParent, {
                    id: validParent,
                    children: [],
                    level: minLevel - 1
                  });
                }
                const parent = finalHierarchyMap.get(validParent);
                parent.children.push(node);

                for (const [parentId, parentLevel] of data.parents.entries()) {
                  if (parentId !== validParent && finalHierarchyMap.has(parentId)) {
                    const higherParent = finalHierarchyMap.get(parentId);
                    higherParent.children = higherParent.children.filter((c) => c.id !== nodeId);
                  }
                }
              }
            }

            const rootNode = [...finalHierarchyMap.values()].find((node) => node.level === 0);
            return rootNode || null;
          };

          const buildHierarchy = (structure, rootId) => {
            const nodeDataMap = collectData(structure, rootId);

            const rootNode = adjustNodes(nodeDataMap);

            // assignNames(rootNode);
            return rootNode;
          };

          let hierarchyRoot = buildHierarchy(structure, rootNode.id);

          // console.log('hierarchy built', JSON.parse(JSON.stringify(hierarchyRoot)));

          let lowestLevelsMap = {};

          const getLowestLevels = (node) => {
            lowestLevelsMap[node.id.toString()] = [
              ...(lowestLevelsMap[node.id.toString()] || []),
              node.level
            ];
            if (node.children?.length) {
              node.children.forEach((child) => getLowestLevels(child));
            }
          };

          getLowestLevels(hierarchyRoot);

          // console.log('lowestLevelsMap before', JSON.parse(JSON.stringify(lowestLevelsMap)));

          lowestLevelsMap = Object.entries(lowestLevelsMap).reduce((acc, [nodeId, nodeLevels]) => {
            acc[nodeId.toString()] = Math.min(...nodeLevels);
            return acc;
          }, {});

          // console.log('lowestLevelsMap', lowestLevelsMap);

          const removeHigherLevelDuplicates = (root) => {
            const lowestLevelsMap = new Map();

            const findLowestLevels = (node, level = 0) => {
              if (!node) return;

              const nodeId = node.id.toString();
              if (!lowestLevelsMap.has(nodeId) || level < lowestLevelsMap.get(nodeId)) {
                lowestLevelsMap.set(nodeId, level);
              }

              if (node.children && node.children.length > 0) {
                node.children.forEach((child) => findLowestLevels(child, level + 1));
              }
            };

            const removeDuplicates = (node, level = 0) => {
              if (!node) return null;

              const nodeId = node.id.toString();
              const lowestLevel = lowestLevelsMap.get(nodeId);

              if (level > lowestLevel) {
                return null;
              }

              if (node.children && node.children.length > 0) {
                node.children = node.children
                  .map((child) => removeDuplicates(child, level + 1))
                  .filter((child) => child !== null);
              }

              return node;
            };

            findLowestLevels(root);
            return removeDuplicates(root);
          };

          hierarchyRoot = removeHigherLevelDuplicates(hierarchyRoot);

          const reorderLevel = (levelNodes, links) => {
            if (!levelNodes || levelNodes.length <= 1) return;

            const connectivityMap = new Map();
            levelNodes.forEach((node) => {
              const connections = links
                .filter((link) => link.source === node.id || link.target === node.id)
                .map((link) => (link.source === node.id ? link.target : link.source))
                .filter((connectedId) => levelNodes.some((n) => n.id === connectedId));
              connectivityMap.set(node.id, connections);
            });

            const nodesWithLevelConnections = new Set();
            connectivityMap.forEach((connections, nodeId) => {
              if (connections.some((connId) => connectivityMap.get(connId)?.includes(nodeId))) {
                nodesWithLevelConnections.add(nodeId);
              }
            });

            const interconnectedNodes = levelNodes.filter((node) =>
              nodesWithLevelConnections.has(node.id)
            );
            const otherConnectedNodes = levelNodes.filter(
              (node) =>
                !nodesWithLevelConnections.has(node.id) && connectivityMap.get(node.id).length > 0
            );
            const unconnectedNodes = levelNodes.filter(
              (node) => connectivityMap.get(node.id).length === 0
            );

            const arrangeInterconnectedNodes = (nodes) => {
              const arranged = [...nodes];
              let changed;
              const maxIterations = 1000; // Prevents infinite loops
              let iterationCount = 0;

              do {
                changed = false;
                iterationCount++;
                for (let i = 0; i < arranged.length - 1; i++) {
                  for (let j = i + 1; j < arranged.length; j++) {
                    const connections1 = connectivityMap.get(arranged[i].id);
                    const connections2 = connectivityMap.get(arranged[j].id);

                    if (connections1.includes(arranged[j].id)) {
                      const betweenNodes = arranged.slice(i + 1, j);
                      const shouldMove = betweenNodes.some(
                        (node) => !connections1.includes(node.id) && !connections2.includes(node.id)
                      );

                      if (shouldMove) {
                        betweenNodes.forEach((node) => {
                          if (!connections1.includes(node.id) && !connections2.includes(node.id)) {
                            arranged.splice(arranged.indexOf(node), 1);
                            arranged.push(node);
                            changed = true;
                          }
                        });
                      }
                    }
                  }
                }
              } while (changed && iterationCount < maxIterations);

              return arranged;
            };

            const arrangeMultiConnectedNodes = (arrangedNodes) => {
              const result = [...arrangedNodes];
              const multiConnected = result.filter(
                (node) => connectivityMap.get(node.id).length > 1
              );

              [...multiConnected].forEach((node) => {
                const connections = connectivityMap.get(node.id);
                let connectedPositions = connections
                  .map((id) => result.findIndex((n) => n.id === id))
                  .filter((pos) => pos !== -1);

                if (connectedPositions.length >= 2) {
                  let currentPos = result.indexOf(node);
                  result.splice(currentPos, 1);
                  connectedPositions = connections
                    .map((id) => result.findIndex((n) => n.id === id))
                    .filter((pos) => pos !== -1);
                  const indexToInsert = Math.min(...connectedPositions) + 1;
                  result.splice(indexToInsert, 0, node);
                }
              });

              return result;
            };

            const finalArrangement = [
              ...unconnectedNodes,
              ...otherConnectedNodes,
              ...arrangeMultiConnectedNodes(arrangeInterconnectedNodes(interconnectedNodes))
            ];

            levelNodes.splice(0, levelNodes.length, ...finalArrangement);

            const processed = new Set();
            levelNodes.forEach((node) => {
              if (node.children?.length > 0 && !processed.has(node.id)) {
                processed.add(node.id);
                reorderLevel(node.children, links);
              }
            });
          };

          const reorderTree = (root, links) => {
            if (!root) return;

            const processed = new Set();
            const processLevel = (node) => {
              if (node.children?.length > 0 && !processed.has(node.id)) {
                processed.add(node.id);
                reorderLevel(node.children, links);
                node.children.forEach(processLevel);
              }
            };

            processLevel(root);
          };

          reorderTree(hierarchyRoot, structure.links);

          // const copyTree = JSON.parse(JSON.stringify(hierarchyRoot));
          // assignNames(copyTree);
          // console.log('hierarchyRoot', copyTree);
          // console.log('links', JSON.parse(JSON.stringify(structure.links)));

          const treeLayout = d3.tree().nodeSize([100, 100]);

          const root = d3.hierarchy(hierarchyRoot);

          treeLayout(root);

          root.descendants().forEach((node) => {
            const n = structure.nodes.find((n) => n.id === node.data.id);
            if (n) {
              n.x = node.x;
              n.y = -node.y;
            }
          });

          // console.log('structure', JSON.parse(JSON.stringify(structure)));

          const ultimateShareholders = structure.nodes.filter(
            (n) => n.beneficial_ownership && !isNaN(n.beneficial_ownership)
          );

          const jsonCopy = JSON.stringify(structure);

          runInAction(() => {
            state.popupShareholderStructureData = {
              mini: JSON.parse(jsonCopy),
              popup: JSON.parse(jsonCopy)
            };

            state.ultimateShareholders = JSON.parse(JSON.stringify(ultimateShareholders));
          });

          if (withCenter) {
            setTimeout(() => {
              miniGraphRef?.current?.zoomToFit?.(
                100,
                (state.popupShareholderStructureData?.mini?.nodes?.length || 0) <= 3 ? 50 : 24
              );
              popupGraphRef?.current?.zoomToFit?.(
                100,
                (state.popupShareholderStructureData?.mini?.nodes?.length || 0) <= 3 ? 112 : 56
              );
              setTimeout(() => {
                runInAction(() => {
                  state.disableRestoreGraph = false;
                });
              }, 1000);
            }, 100);
          }
        } catch (error) {
          console.error('Error in get shareholderStructureData:', error);
          runInAction(() => {
            state.popupShareholderStructureData = null;
          });
        }
      },
      popupStructureHeight: 0,
      popupStructureWidth: 0,
      setPopupStructureHeight: (height = 0) => {
        if (state.popupStructureHeight === height) {
          return;
        }

        state.popupStructureHeight = height;
      },
      setPopupStructureWidth: (width = 0) => {
        if (state.popupStructureWidth === width) {
          return;
        }

        state.popupStructureWidth = width;
      },
      miniStructureHeight: 0,
      miniStructureWidth: 0,
      setMiniStructureHeight: (height = 0) => {
        if (state.miniStructureHeight === height) {
          return;
        }

        state.miniStructureHeight = height;
      },
      setMiniStructureWidth: (width = 0) => {
        if (state.miniStructureWidth === width) {
          return;
        }

        state.miniStructureWidth = width;
      },
      loadedLegendIcons: {},
      preloadLegendIcons: async () => {
        const loadedImages = {};
        const bitmapImages = {};

        const loadPromises = Object.entries(legendsIcons).map(([key, iconSrc]) => {
          return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
              loadedImages[key] = img;
              const canvas = document.createElement('canvas');
              canvas.width = 48;
              canvas.height = 48;
              const ctx = canvas.getContext('2d');
              ctx.drawImage(img, 0, 0, 48, 48);
              createImageBitmap(canvas).then((bitmap) => {
                bitmapImages[key] = bitmap;
                resolve();
              });
            };
            img.src = iconSrc;
          });
        });

        await Promise.all(loadPromises);

        runInAction(() => {
          state.loadedLegendIcons = loadedImages;
          state.bitmapImages = bitmapImages;
        });
      },
      get layout() {
        return utilsStore.windowWidth < 1201 ? 'mobile' : 'desktop';
      },
      bitmapImages: {},
      isDownloadingCompanyOnepager: false,
      setIsDownloadingCompanyOnepager: (value = false) => {
        state.isDownloadingCompanyOnepager = value;
      },
      isSuccessDownloadCompanyOnepager: false,
      setSuccessDownloadCompanyOnepager: () => {
        state.isSuccessDownloadCompanyOnepager = true;
      },
      isDownloadingCompanyFinancials: false,
      setIsDownloadingCompanyFinancials: (value = false) => {
        state.isDownloadingCompanyFinancials = value;
      },
      isSuccessDownloadCompanyFinancials: false,
      setSuccessDownloadCompanyFinanials: () => {
        state.isSuccessDownloadCompanyFinancials = true;
      },
      isDownloadingCompanyFullProfile: false,
      setIsDownloadingCompanyFullProfile: (value = false) => {
        state.isDownloadingCompanyFullProfile = value;
      },
      isSuccessDownloadCompanyFullProfile: false,
      setSuccessDownloadCompanyFullProfile: () => {
        state.isSuccessDownloadCompanyFullProfile = true;
      },
      get companyDownloadsSuccess() {
        const downloadStates = [
          state.isSuccessDownloadCompanyOnepager,
          state.isSuccessDownloadCompanyFinancials,
          state.isSuccessDownloadCompanyFullProfile
        ];
        const maxDownloads = downloadStates.length;
        const done = downloadStates.filter((isDownloaded) => isDownloaded).length;
        return { done, maxDownloads, allDone: done >= maxDownloads };
      },
      toggledFinancialDownload: false,
      toggleFinancialDownload: () => {
        state.toggledFinancialDownload = !state.toggledFinancialDownload;
      }
    }));

    const { linkCanvasObject, nodeCanvasObject } = useCanvasObjects(state);

    const miniGraphRef = useRef();
    const popupGraphRef = useRef();
    useEffect(() => {
      state.preloadLegendIcons();
    }, [state]);

    useEffect(() => {
      authStore.loadCompanyUsers();
    }, [authStore]);

    useEffect(() => {
      const overTreeWrapperDOM = document.querySelector('.over-treeWrapper');
      if (overTreeWrapperDOM?.offsetHeight) {
        state.setMiniStructureHeight(Math.ceil(overTreeWrapperDOM.offsetHeight));
      }

      if (overTreeWrapperDOM?.offsetWidth) {
        state.setMiniStructureWidth(Math.ceil(overTreeWrapperDOM.offsetWidth));
      }
    }, [state.popupShareholderStructureData?.mini, state.tab]);

    useEffect(() => {
      state.setObservableCompany(company);
      // state.loadCompanyLogo();
      state.loadDetails();

      return () => {
        if (state.toggledShareholderPopup) {
          state.toggleShareholderPopup();
        }
      };
    }, [state, company]);

    useEffect(() => {
      const onGlobalClick = (event) => {
        const foundOpenedFilter = Object.entries(state.toggledFinancialFilters).find(
          ([_, isToggled]) => isToggled
        );
        if (!foundOpenedFilter) {
          return;
        }

        const isClickInside = event.target.closest('.lvl-filter-popup');
        if (isClickInside) {
          return;
        }

        const groupId = foundOpenedFilter[0];
        state.toggleFinancialFilter(groupId);
      };

      document.addEventListener('click', onGlobalClick, { capture: true });

      return () => {
        document.removeEventListener('click', onGlobalClick, { capture: true });
      };
    }, [state]);

    useEffect(() => {
      const onGlobalClick = (event) => {
        if (!state.toggledFinancialDownload) {
          return;
        }

        const isClickInside =
          event.target.closest('.financials-download-popup') || event.target.closest('#psrf-btn');
        if (isClickInside) {
          return;
        }

        if (['download-financials-btn', 'download-onepager-btn'].includes(event.target.id)) {
          return;
        }

        state.toggleFinancialDownload();
      };

      document.addEventListener('click', onGlobalClick, { capture: true });

      return () => {
        document.removeEventListener('click', onGlobalClick, { capture: true });
      };
    }, [state]);

    useEffect(() => {
      // TO DO: Uncomment when news are decided and done (check if the data is changed)
      // state.loadNewsLogos();
    }, [state.observableCompany.news]);

    // useEffect(() => {
    //   window.scrollTo({ top: 0 });
    // }, []);

    useEffect(() => {
      const interval = setInterval(() => state.setDateNow(Date.now()), 60_000);
      return () => {
        state.unrender();
        clearInterval(interval);
        if (state.isAddRemoveWatchlistPopupOpen) {
          state.setIsAddRemoveWatchlistPopupOpen();
        }
      };
    }, [state]);

    useEffect(() => {
      if (!state.openedSimilarCompany) {
        setTimeout(() => {
          state.scrollTabIntoView(state.tabId, true);
          setTimeout(() => {
            window.scrollTo({ top: state.savedScrollYOnSimilarCompany });
          }, 0);
        }, 0);
      }
    }, [state, state.openedSimilarCompany]);

    useEffect(() => {
      if (!isFromWatchList) {
        return;
      }

      const subscription = (payload) => {
        if (
          payload.event === 'remove-watchlist-company' &&
          payload.companyId === company.id &&
          !state.openedSimilarCompany
        ) {
          navigate(-1);
        }
      };

      pubSub.subscribe(subscription);

      return () => {
        pubSub.unsubscribe(subscription);
      };
    }, [state, isFromWatchList, company.id]);

    useEffect(() => {
      if (state.tab !== DETAILS_POUP_TABS.SIMILAR_COMPANIES) {
        return;
      }

      if (!state.similarCompanies.length && !state.loadSimilarCompaniesError) {
        state.loadSimilarCompanies();
      }
    }, [state, state.tab]);

    useEffect(() => {
      const closeDetails = () => {
        if (state.openedSimilarCompany) {
          return;
        }

        displayDetails(null);
      };
      navigate(location.pathname + location.search);
      addEventListener('popstate', closeDetails);
      return () => {
        removeEventListener('popstate', closeDetails);
      };
    }, [state]);

    useEffect(() => {
      return () => {
        if (state.updateTabVisited) {
          profilerProjectStore.updateWatchlistCompanyNewsSeenDate(company.id);
        }
      };
    }, [state, profilerProjectStore, company.id]);

    useEffect(() => {
      if (state.tab === DETAILS_POUP_TABS.GENERAL) {
        const cardFinancialsDOM = document.querySelector('.card.financials');
        if (
          state.generalFinancialsCardScrolled ||
          !cardFinancialsDOM ||
          !isElementVisibleOnScreenPartially(cardFinancialsDOM, 3)
        ) {
          return;
        }

        setTimeout(() => {
          const financialValuesDDOM = document.querySelector('.general-financials-values');
          if (financialValuesDDOM && financialValuesDDOM.scrollWidth) {
            state.setGeneralFinancialsCardScrolled(true);
            financialValuesDDOM.scrollTo({
              left: financialValuesDDOM.scrollWidth,
              behavior: 'smooth'
            });
          }
        }, 500);
      } else {
        state.setGeneralFinancialsCardScrolled(false);
      }

      if (state.tab === DETAILS_POUP_TABS.FINANCIALS) {
        let desktopFinancialsDOM = [
          ...document.querySelectorAll('.financials-table > .right-content')
        ];
        if (!desktopFinancialsDOM.length) {
          return;
        }

        setTimeout(() => {
          desktopFinancialsDOM = [
            ...document.querySelectorAll('.financials-table > .right-content')
          ];

          if (desktopFinancialsDOM.length === 1 && desktopFinancialsDOM[0].scrollWidth) {
            desktopFinancialsDOM[0].scrollTo({
              left: desktopFinancialsDOM[0].scrollWidth,
              behavior: 'smooth'
            });
          } else if (desktopFinancialsDOM.length) {
            const tablesToInstaScroll = desktopFinancialsDOM.slice(1);
            tablesToInstaScroll.reverse();
            tablesToInstaScroll.forEach((rightContent) => {
              if (rightContent.scrollWidth) {
                rightContent.scrollTo({ left: rightContent.scrollWidth });
              }
            });

            if (desktopFinancialsDOM[0].scrollWidth) {
              desktopFinancialsDOM[0].scrollTo({
                left: desktopFinancialsDOM[0].scrollWidth,
                behavior: 'smooth'
              });
            }
          }
        }, 500);

        if (state.layout === 'desktop') {
          // let desktopFinancialsDOM = document.querySelector('.financials-table > .right-content');
          // if (state.desktopFinancialsScrolled || !desktopFinancialsDOM) {
          //   return;
          // }
          // setTimeout(() => {
          //   desktopFinancialsDOM = document.querySelector('.financials-table > .right-content');
          //   if (desktopFinancialsDOM && desktopFinancialsDOM.scrollWidth) {
          //     state.setDesktopFinancialsScrolled(true);
          //     desktopFinancialsDOM.scrollTo({
          //       left: desktopFinancialsDOM.scrollWidth,
          //       behavior: 'smooth'
          //     });
          //   }
          // }, 500);
        } else {
          // let mobileFinancialRows = [
          //   ...document.querySelectorAll(
          //     '.financials-tab-content > .card-mobile > .values-scroll-wrap > .values-scroll'
          //   )
          // ];
          // if (mobileFinancialRows.length) {
          //   mobileFinancialRows = [
          //     ...document.querySelectorAll(
          //       '.financials-tab-content > .card-mobile > .values-scroll-wrap > .values-scroll'
          //     )
          //   ];
          //   setTimeout(() => {
          //     mobileFinancialRows.slice(1).forEach((el) => {
          //       el.scrollTo({ left: el.scrollWidth });
          //     });
          //     mobileFinancialRows[0].scrollTo({
          //       left: mobileFinancialRows[0].scrollWidth,
          //       behavior: 'smooth'
          //     });
          //   }, 500);
          // }
        }
      } else {
        // if (state.layout === 'desktop') {
        //   state.setDesktopFinancialsScrolled(false);
        // }
      }
    }, [state, state.tab, state.isLoadingDetails]);

    useEffect(() => {
      if (state.tab === DETAILS_POUP_TABS.UPDATES) {
        state.flagUpdateTabVisited(true);
      }
    }, [state, state.tab]);

    useEffect(() => {
      const onScroll = () => {
        if (state.openedSimilarCompany) {
          return;
        }

        const st = window.pageYOffset || document.documentElement.scrollTop;
        const cardFinancialsDOM = document.querySelector('.card.financials');
        if (
          cardFinancialsDOM &&
          !state.generalFinancialsCardScrolled &&
          isElementVisibleOnScreenPartially(cardFinancialsDOM, 3)
        ) {
          setTimeout(() => {
            const financialValuesDDOM = document.querySelector('.general-financials-values');
            if (financialValuesDDOM && financialValuesDDOM.scrollWidth) {
              state.setGeneralFinancialsCardScrolled(true);
              financialValuesDDOM.scrollTo({
                left: financialValuesDDOM.scrollWidth,
                behavior: 'smooth'
              });
            }
          }, 500);
        }

        // This is applicable only if we start showing the shareholder structure for mobile devices again
        // if (state.tab === DETAILS_POUP_TABS.SHAREHOLDER_STRUCTURE && state.layout === 'mobile') {
        //   const treeWrapperDOM = document.querySelector('.treeWrapper');
        //   if (
        //     treeWrapperDOM &&
        //     treeWrapperDOM.getBoundingClientRect().top <= window.innerHeight &&
        //     !state.componentVariables.isScrollingToShareholderStructure &&
        //     state.componentVariables.lastScrollY &&
        //     st > state.componentVariables.lastScrollY
        //   ) {
        //     runInAction(() => {
        //       state.componentVariables.isScrollingToShareholderStructure = true;
        //     });
        //     window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' });
        //     setTimeout(() => {
        //       runInAction(() => {
        //         state.componentVariables.isScrollingToShareholderStructure = false;
        //       });
        //     }, 500);
        //   }
        // }

        runInAction(() => {
          state.componentVariables.lastScrollY = st <= 0 ? 0 : st;
        });
      };

      window.addEventListener('scroll', onScroll);

      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }, [state]);

    useEffect(() => {
      if (state.tab !== DETAILS_POUP_TABS.GENERAL || !generalFinancialsValuesRef.current) {
        state.setGeneralFinancialsValuesScrollable(false);
        return;
      }

      const resizeObserver = new ResizeObserver(([entry]) => {
        state.setGeneralFinancialsValuesScrollable(
          entry.target.scrollWidth > entry.target.clientWidth
        );
      });

      resizeObserver.observe(generalFinancialsValuesRef.current);
      return () => {
        resizeObserver.disconnect();
      };
    }, [state, state.tab, generalFinancialsValuesRef.current, state.isLoadingDetails]);

    // useEffect(() => {
    //   if (state.tab !== DETAILS_POUP_TABS.FINANCIALS || !financialsMobileFirstRowRef.current) {
    //     state.setFinancialsValuesScrollable(false);
    //     return;
    //   }

    //   const resizeObserver = new ResizeObserver(([entry]) => {
    //     state.setFinancialsValuesScrollable(entry.target.scrollWidth > entry.target.clientWidth);
    //   });

    //   resizeObserver.observe(financialsMobileFirstRowRef.current);
    //   return () => {
    //     resizeObserver.disconnect();
    //   };
    // }, [state, state.tab, financialsMobileFirstRowRef.current]);

    useLayoutEffect(() => {
      if (state.tab === DETAILS_POUP_TABS.SHAREHOLDER_STRUCTURE && state.shareholdersStructure) {
        runInAction(() => {
          state.shareholderMiniEngineStopExecuted = false;
        });
      }
    }, [state, state.tab, state.shareholdersStructure]);

    const tabs = [
      {
        id: 'profiler_search_results_details_tab',
        name: 'General information',
        isActive: state.tab === DETAILS_POUP_TABS.GENERAL,
        onClick: () => {
          state.setTab(DETAILS_POUP_TABS.GENERAL, 'profiler_search_results_details_tab');
        }
      },
      {
        id: 'profiler_search_results_product_tab',
        name: 'Product portfolio',
        isActive: state.tab === DETAILS_POUP_TABS.PRODUCT_PORTFOLIO,
        onClick: () => {
          state.setTab(DETAILS_POUP_TABS.PRODUCT_PORTFOLIO, 'profiler_search_results_product_tab');
        }
      },
      {
        id: 'profiler_search_results_financials_tab',
        name: 'Financials',
        isActive: state.tab === DETAILS_POUP_TABS.FINANCIALS,
        onClick: () => {
          if (profilerAccessStore.canViewProfilerDetailsExtendedData(project)) {
            state.setTab(DETAILS_POUP_TABS.FINANCIALS, 'profiler_search_results_financials_tab');
          }
        },
        isDisabled: !profilerAccessStore.canViewProfilerDetailsExtendedData(project),
        tooltipContent: !profilerAccessStore.canViewProfilerDetailsExtendedData(project) ? (
          <div className="cd-tab-tooltip-no-access">
            Need full platform access? Contact{' '}
            <a href="mailto:support@transact.digital">support@transact.digital</a> for assistance.
          </div>
        ) : null
      },
      {
        id: 'profiler_search_results_shareholder_tab',
        name: 'Shareholder structure',
        isActive: state.tab === DETAILS_POUP_TABS.SHAREHOLDER_STRUCTURE,
        onClick: () => {
          if (profilerAccessStore.canViewProfilerDetailsExtendedData(project)) {
            state.setTab(
              DETAILS_POUP_TABS.SHAREHOLDER_STRUCTURE,
              'profiler_search_results_shareholder_tab'
            );
          }
        },
        isDisabled: !profilerAccessStore.canViewProfilerDetailsExtendedData(project),
        tooltipContent: !profilerAccessStore.canViewProfilerDetailsExtendedData(project) ? (
          <div className="cd-tab-tooltip-no-access">
            Need full platform access? Contact{' '}
            <a href="mailto:support@transact.digital">support@transact.digital</a> for assistance.
          </div>
        ) : null
      },
      {
        id: 'profiler_search_results_key_contacts_tab',
        name: 'Key contacts',
        isActive: state.tab === DETAILS_POUP_TABS.KEY_CONTACTS,
        isDisabled: !profilerAccessStore.canViewProfilerDetailsExtendedData(project),
        onClick: () => {
          if (profilerAccessStore.canViewProfilerDetailsExtendedData(project)) {
            state.setTab(
              DETAILS_POUP_TABS.KEY_CONTACTS,
              'profiler_search_results_key_contacts_tab'
            );
          }
        },
        tooltipContent: !profilerAccessStore.canViewProfilerDetailsExtendedData(project) ? (
          <div className="cd-tab-tooltip-no-access">
            Need full platform access? Contact{' '}
            <a href="mailto:support@transact.digital">support@transact.digital</a> for assistance.
          </div>
        ) : null
      },
      {
        id: 'profiler_search_results_similar_companies_tab',
        name: 'Comparable companies',
        isActive: state.tab === DETAILS_POUP_TABS.SIMILAR_COMPANIES,
        isDisabled: true,
        onClick: () => {
          // state.setTab(
          //   DETAILS_POUP_TABS.SIMILAR_COMPANIES,
          //   'profiler_search_results_similar_companies_tab'
          // );
        }
      }
    ];

    if (isFromWatchList) {
      tabs.push({
        id: 'profiler_search_results_updates_tab',
        name: 'News & updates',
        isActive: state.tab === DETAILS_POUP_TABS.UPDATES,
        isDisabled: true,
        onClick: () => {
          // state.setTab(DETAILS_POUP_TABS.UPDATES, 'profiler_search_results_updates_tab');
        }
      });
    }

    // const isDownloadsToggleFilled = state.companyDownloadsSuccess.allDone;
    // const downloadsToggleTooltipText = state.toggledFinancialDownload
    //   ? ''
    //   : state.companyDownloadsSuccess.done
    //   ? `Downloads (${state.companyDownloadsSuccess.done}/${state.companyDownloadsSuccess.maxDownloads})`
    //   : 'Downloads';

    const isInWatchlist =
      profilerProjectStore.watchlist.findIndex((c) => c.id === company.id) !== -1;

    const watchlistButtonTooltipText = profilerProjectStore.isAddingToProfilerWatchlist
      ? ''
      : !profilerAccessStore.canViewProfilerWatchlist
      ? 'Requires Profiler Lite or Profiler Full access. Contact support@transact.digital for assistance.'
      : isInWatchlist
      ? 'Remove from watchlist'
      : 'Add to watchlist';
    const companyLinkedInButtonTooltipText = 'LinkedIn';

    const onWatchlistButtonClick = () => {
      if (isInWatchlist) {
        state.setIsAddRemoveWatchlistPopupOpen(true);
      } else {
        profilerProjectStore.addToWatchlist(company);
      }
    };

    const onDownloadFinancialsClick = async (type = '') => {
      if (!type) {
        return;
      }

      // state.toggleFinancialDownload();

      if (type === 'financials') {
        if (
          state.isDownloadingCompanyFinancials ||
          !profilerAccessStore.canExportProfilerFinancials
        ) {
          return;
        }

        state.setIsDownloadingCompanyFinancials(true);

        const token = await getToken();
        if (!token) {
          console.error('No authorization token');
          state.setIsDownloadingCompanyFinancials(false);
          return;
        }

        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_BASEURL}/export-profiler-financials?companyId=${company.id}`,
            {
              headers: {
                Authorization: token,
                'Content-Type': 'application/json'
              }
            }
          );

          if (!response.ok) {
            utilsStore.setHeaderMessage(
              'Failed to download financials.',
              HEADER_MESSAGE_TYPES.ERROR
            );
            console.log('Download financials response is not ok');
            return;
          }

          // response.headers.forEach((value, key) => {
          //   console.log(`${key}: ${value}`);
          // });

          const contentDisposition = response.headers.get('Content-Disposition');
          const contentType = response.headers.get('Content-Type');
          let filename = 'transact-digital_financials.xlsx';
          let type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

          if (contentType) {
            type = contentType;
          }

          if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename="([^"]+)"/);
            if (filenameMatch && filenameMatch[1]) {
              filename = decodeURIComponent(filenameMatch[1]);
            }
          }

          const arrayBuffer = await response.arrayBuffer();

          const blob = new Blob([arrayBuffer], { type });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.id = 'download-financials-btn';
            link.href = blobUrl;
            link.target = '_blank';
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(blobUrl);
          }
          state.setSuccessDownloadCompanyFinanials();
        } catch (error) {
          console.error(error);
          utilsStore.setHeaderMessage('Failed to download financials.', HEADER_MESSAGE_TYPES.ERROR);
        } finally {
          state.setIsDownloadingCompanyFinancials(false);
        }
      }

      if (type === 'onepager') {
        if (state.isDownloadingCompanyOnepager || !profilerAccessStore.canExportProfilerOnepager) {
          return;
        }

        state.setIsDownloadingCompanyOnepager(true);

        const token = await getToken();
        if (!token) {
          console.error('No authorization token');
          state.setIsDownloadingCompanyOnepager(false);
          return;
        }

        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_BASEURL}/export-profiler-slides?companyId=${company.id}&type=onepager`,
            {
              headers: {
                Authorization: token,
                'Content-Type': 'application/json'
              }
            }
          );

          if (!response.ok) {
            utilsStore.setHeaderMessage('Failed to download onepager.', HEADER_MESSAGE_TYPES.ERROR);
            console.log('Download onepager response is not ok');
            return;
          }

          // response.headers.forEach((value, key) => {
          //   console.log(`${key}: ${value}`);
          // });

          const contentDisposition = response.headers.get('Content-Disposition');
          const contentType = response.headers.get('Content-Type');
          let filename = 'transact-digital_profiler-onepager.pptx';
          let type = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';

          if (contentType) {
            type = contentType;
          }

          if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename="([^"]+)"/);
            if (filenameMatch && filenameMatch[1]) {
              filename = decodeURIComponent(filenameMatch[1]);
            }
          }

          const arrayBuffer = await response.arrayBuffer();

          const blob = new Blob([arrayBuffer], { type });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.id = 'download-onepager-btn';
            link.href = blobUrl;
            link.target = '_blank';
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(blobUrl);
          }
          state.setSuccessDownloadCompanyOnepager();
        } catch (error) {
          console.error(error);
          utilsStore.setHeaderMessage('Failed to download onepager.', HEADER_MESSAGE_TYPES.ERROR);
        } finally {
          state.setIsDownloadingCompanyOnepager(false);
        }
      }

      if (type === 'full-profile') {
        if (
          state.isDownloadingCompanyFullProfile ||
          !profilerAccessStore.canExportProfilerFullProfiler
        ) {
          return;
        }

        state.setIsDownloadingCompanyFullProfile(true);

        const token = await getToken();
        if (!token) {
          console.error('No authorization token');
          state.setIsDownloadingCompanyFullProfile(false);
          return;
        }

        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_BASEURL}/export-profiler-slides?companyId=${company.id}&type=extended`,
            {
              headers: {
                Authorization: token,
                'Content-Type': 'application/json'
              }
            }
          );

          if (!response.ok) {
            utilsStore.setHeaderMessage(
              'Failed to download full profile.',
              HEADER_MESSAGE_TYPES.ERROR
            );
            console.log('Download full profile response is not ok');
            return;
          }

          // response.headers.forEach((value, key) => {
          //   console.log(`${key}: ${value}`);
          // });

          const contentDisposition = response.headers.get('Content-Disposition');
          const contentType = response.headers.get('Content-Type');
          let filename = 'transact-digital_profiler-extended.pptx';
          let type = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';

          if (contentType) {
            type = contentType;
          }

          if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename="([^"]+)"/);
            if (filenameMatch && filenameMatch[1]) {
              filename = decodeURIComponent(filenameMatch[1]);
            }
          }

          const arrayBuffer = await response.arrayBuffer();

          const blob = new Blob([arrayBuffer], { type });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.id = 'download-onepager-btn';
            link.href = blobUrl;
            link.target = '_blank';
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(blobUrl);
          }
          state.setSuccessDownloadCompanyFullProfile();
        } catch (error) {
          console.error(error);
          utilsStore.setHeaderMessage(
            'Failed to download full profile.',
            HEADER_MESSAGE_TYPES.ERROR
          );
        } finally {
          state.setIsDownloadingCompanyFullProfile(false);
        }
      }
    };

    const showSimilarCompaniesError = !!state.loadSimilarCompaniesError;
    const showSimilarCompaniesLoader = state.isLoadingSimilarCompanies;
    const showSimilarCompaniesResults =
      !showSimilarCompaniesError && !showSimilarCompaniesLoader && !!state.similarCompanies.length;
    const showSimilarCompaniesNoResults =
      !showSimilarCompaniesError && !showSimilarCompaniesLoader && !state.similarCompanies.length;
    const showSimilarCompaniesPagination = state.similarCompaniesPages > 1;

    if (state.openedSimilarCompany) {
      return (
        <CompanyDetails
          key={state.openedSimilarCompany.id}
          company={state.openedSimilarCompany}
          displayDetails={state.onSimilarCompanyClose}
          style={style}
          prevCompanyName={company.name}
          project={project}
          isFromFeedbackProject={isFromFeedbackProject}
        />
      );
    }

    const mobileHeaderActionButtons = (
      <div className="action-btns">
        {!!company.website && (
          <IconButton
            id="website-btn"
            type={ICON_BUTTON_TYPES.BLUE}
            icon={ICON_BUTTON_ICONS.GLOBE2}
            tooltipText={`Visit ${company.website}`}
            onClick={() => window.open('https://' + company.website, '_blank')}
          />
        )}
        <IconButton
          id="linkedin-btn"
          type={ICON_BUTTON_TYPES.BLUE}
          icon={ICON_BUTTON_ICONS.LINKEDIN}
          tooltipText={companyLinkedInButtonTooltipText}
          onClick={() =>
            window.open(
              `https://www.linkedin.com/search/results/companies/?keywords=${encodeURIComponent(
                company.name
              )}`,
              '_blank'
            )
          }
        />
        <IconButton
          id="psrd-btn"
          type={ICON_BUTTON_TYPES.BLUE}
          icon={ICON_BUTTON_ICONS.WATCHLIST}
          disabled={
            profilerProjectStore.isAddingToProfilerWatchlist ||
            !profilerAccessStore.canViewProfilerWatchlist
          }
          tooltipText={watchlistButtonTooltipText}
          filled={isInWatchlist}
          onClick={onWatchlistButtonClick}
        />
        <IconButton
          id="psrf-btn"
          type={ICON_BUTTON_TYPES.BLUE}
          icon={
            state.toggledFinancialDownload
              ? ICON_BUTTON_ICONS.CHEVRON_UP
              : ICON_BUTTON_ICONS.CHEVRON_DOWN
          }
          iconOnRight
          innerText="Download as"
          onClick={state.toggleFinancialDownload}
          filled={state.toggledFinancialDownload}
        />
        {state.toggledFinancialDownload && (
          <div className="financials-download-popup">
            <div className="buttons-wrap">
              <IconButton
                type={ICON_BUTTON_TYPES.BLUE}
                innerText={'Download onepager as PPTX'}
                withBorder={false}
                onClick={() => onDownloadFinancialsClick('onepager')}
                disabled={
                  !profilerAccessStore.canExportProfilerOnepager ||
                  state.isDownloadingCompanyOnepager
                }
                tooltipText={
                  profilerAccessStore.canExportProfilerOnepager
                    ? state.isDownloadingCompanyOnepager
                      ? 'Downloading. Please wait.'
                      : state.isSuccessDownloadCompanyOnepager
                      ? 'Downloaded successfully'
                      : ''
                    : 'Requires Profiler Lite or Profiler Full access. Contact support@transact.digital for assistance.'
                }
                icon={
                  state.isDownloadingCompanyOnepager
                    ? ICON_BUTTON_ICONS.FILE_EARMARK_ARROW_DOWN
                    : state.isSuccessDownloadCompanyOnepager
                    ? ICON_BUTTON_ICONS.FILE_EARMARK_ARROW_DOWN_FILL
                    : null
                }
                iconOnRight
              />
              <IconButton
                type={ICON_BUTTON_TYPES.BLUE}
                innerText={'Download financials as XLSX'}
                withBorder={false}
                onClick={() => onDownloadFinancialsClick('financials')}
                disabled={
                  !profilerAccessStore.canExportProfilerFinancials ||
                  state.isDownloadingCompanyFinancials ||
                  state.isFinancialsDataEmpty ||
                  state.isLoadingDetails
                }
                tooltipText={
                  profilerAccessStore.canExportProfilerFinancials
                    ? state.isFinancialsDataEmpty
                      ? 'No financials data available for export.'
                      : state.isDownloadingCompanyFinancials
                      ? 'Downloading. Please wait.'
                      : state.isSuccessDownloadCompanyFinancials
                      ? 'Downloaded successfully'
                      : ''
                    : 'Requires Profiler Full access. Contact support@transact.digital for assistance.'
                }
                icon={
                  state.isDownloadingCompanyFinancials
                    ? ICON_BUTTON_ICONS.FILE_EARMARK_ARROW_DOWN
                    : state.isSuccessDownloadCompanyFinancials
                    ? ICON_BUTTON_ICONS.FILE_EARMARK_ARROW_DOWN_FILL
                    : null
                }
                iconOnRight
              />
              <IconButton
                type={ICON_BUTTON_TYPES.BLUE}
                innerText={'Download full profile as PPTX'}
                withBorder={false}
                onClick={() => onDownloadFinancialsClick('full-profile')}
                disabled={
                  !profilerAccessStore.canExportProfilerFullProfiler ||
                  state.isDownloadingCompanyFullProfile
                }
                tooltipText={
                  profilerAccessStore.canExportProfilerFullProfiler
                    ? state.isDownloadingCompanyFullProfile
                      ? 'Downloading. Please wait.'
                      : state.isSuccessDownloadCompanyFullProfile
                      ? 'Downloaded successfully'
                      : ''
                    : 'Requires Profiler Full access. Contact support@transact.digital for assistance.'
                }
                icon={
                  state.isDownloadingCompanyFullProfile
                    ? ICON_BUTTON_ICONS.FILE_EARMARK_ARROW_DOWN
                    : state.isSuccessDownloadCompanyFullProfile
                    ? ICON_BUTTON_ICONS.FILE_EARMARK_ARROW_DOWN_FILL
                    : null
                }
                iconOnRight
              />
            </div>
          </div>
        )}
      </div>
    );

    return (
      <>
        <div
          className={`shareholder-structure-watchlist-popup-closer ${
            state.toggledShareholderPopup ? 'visible' : ''
          }`}
          onClick={() => state.setIsAddRemoveWatchlistPopupOpen()}
        />
        <div
          className={`shareholder-structure-watchlist-popup ${
            state.toggledShareholderPopup ? 'visible' : ''
          }`}>
          <div className="sswp-header">
            <div className="sswp-title">Shareholder structure</div>
            <IconButton
              id="sswp-close"
              icon={ICON_BUTTON_ICONS.X_LG}
              withBorder={false}
              withFill={false}
              onClick={state.toggleShareholderPopup}
            />
          </div>
          <IconButton
            id="restore-graph-btn-popup"
            icon={ICON_BUTTON_ICONS.ARROW_COUNTERCLOCKWISE}
            type={ICON_BUTTON_TYPES.DEFAULT}
            hoverType={ICON_BUTTON_TYPES.BLUE}
            withBorder={false}
            onClick={() => state.generateShareholderStructureData({ withCenter: true })}
            innerText="Reset nodes position"
            disabled={state.disableRestoreGraph}
          />
          <div className="content-container">
            <div className="left-container">
              <div className="legend">
                <div className="legend-title">Legend</div>
                <div className="legend-row">
                  <div className="icon-wrap">
                    <img src={legendsIcons.target} />
                  </div>
                  <div>Target</div>
                </div>
                <div className="legend-row">
                  <div className="icon-wrap">
                    <img src={legendsIcons.company} />
                  </div>
                  <div>Organization</div>
                </div>
                <div className="legend-row">
                  <div className="icon-wrap">
                    <img src={legendsIcons.person} />
                  </div>
                  <div>Individual</div>
                </div>
                <div className="legend-row">
                  <div className="icon-wrap">
                    <img src={legendsIcons.entity} />
                  </div>
                  <div>Entity</div>
                </div>
                <div className="legend-row">
                  <div className="icon-wrap">
                    <img src={legendsIcons.share} />
                  </div>
                  <div>Share % of target</div>
                </div>
                <div className="legend-row">
                  <div className="icon-wrap beneficial">
                    <img src={legendsIcons.beneficial} />
                  </div>
                  <div>
                    Beneficial ownership
                    <br />% of target
                  </div>
                </div>
              </div>
              <div className="filters-wrap">
                <div className="filters-title">Filters</div>
                <Input
                  field={COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.SHAREHOLDER_SHARE_PERCENT}
                  value={
                    state.popupShareholderStructureFilters[
                      COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.SHAREHOLDER_SHARE_PERCENT.NAME
                    ]
                  }
                  setFieldValue={state.setPopupShareholderStructureFiltersValue}
                  showOnSubmitErrorState={state.onSubmitErrorStatePopupShareholderStructure}
                  messages={state.validationFieldsPopupShareholderStructure.messages}
                />
                <CheckBox
                  field={COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.ENTITIES_UNKNOWN_VALUE}
                  value={
                    state.popupShareholderStructureFilters[
                      COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.ENTITIES_UNKNOWN_VALUE.NAME
                    ]
                  }
                  setFieldValue={state.setPopupShareholderStructureFiltersValue}
                  showOnSubmitErrorState={state.onSubmitErrorStatePopupShareholderStructure}
                  messages={state.validationFieldsPopupShareholderStructure.messages}
                />
                <div className="filter-actions">
                  <IconButton
                    innerText="Clear filter"
                    onClick={state.onPopupShareholderStructureClearFilter}
                    disabled={
                      ['5'].includes(
                        state.popupShareholderStructureAppliedFilters[
                          COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.SHAREHOLDER_SHARE_PERCENT.NAME
                        ]
                      ) &&
                      state.popupShareholderStructureAppliedFilters[
                        COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.ENTITIES_UNKNOWN_VALUE.NAME
                      ] === false
                    }
                  />
                  <IconButton
                    innerText="Apply"
                    onClick={state.onPopupShareholderStructureFilter}
                    type={ICON_BUTTON_TYPES.BLUE}
                    disabled={state.isPopupShareholderStructureFilterDisabled}
                    filled
                  />
                </div>
              </div>
            </div>

            <div
              className="treeWrapper"
              ref={(el) => {
                if (el?.offsetHeight) {
                  state.setPopupStructureHeight(Math.ceil(el.offsetHeight));
                }

                if (el?.offsetWidth) {
                  state.setPopupStructureWidth(Math.ceil(el.offsetWidth));
                }
              }}>
              {!!state.popupShareholderStructureData?.popup && (
                <>
                  <ForceGraph2D
                    ref={popupGraphRef}
                    width={state.popupStructureWidth}
                    height={state.popupStructureHeight}
                    graphData={state.popupShareholderStructureData.popup}
                    linkDistance={null}
                    charge={null}
                    nodeRelSize={8}
                    onEngineTick={() => {}}
                    cooldownTime={0}
                    onNodeHover={state.handleNodeHover}
                    onLinkHover={state.handleLinkHover}
                    autoPauseRedraw={false}
                    linkCanvasObjectMode={() => 'replace'}
                    linkCanvasObject={linkCanvasObject}
                    nodeCanvasObject={nodeCanvasObject}
                    onNodeDrag={(node) => {
                      runInAction(() => {
                        // Freeze all other nodes in their current position during drag
                        state.popupShareholderStructureData.popup.nodes.forEach((n) => {
                          if (n.id !== node.id) {
                            n.fx = n.x; // Lock position
                            n.fy = n.y; // Lock position
                          }
                        });
                      });
                    }}
                    onNodeDragEnd={(node) => {
                      runInAction(() => {
                        // Lock the dragged node's position to its final coordinates
                        node.fx = node.x; // Set fixed position in x
                        node.fy = node.y; // Set fixed position in y

                        const foundNode = state.popupShareholderStructureData.mini.nodes.find(
                          (n) => n.id === node.id
                        );
                        if (foundNode) {
                          foundNode.fx = node.x;
                          foundNode.fy = node.y;
                          foundNode.x = node.x;
                          foundNode.y = node.y;
                        }
                      });
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        {state.isAddRemoveWatchlistPopupOpen && (
          <>
            <div
              className="add-remove-watchlist-popup-closer"
              onClick={() => state.setIsAddRemoveWatchlistPopupOpen()}
            />
            <div className="add-remove-watchlist-popup">
              <div className="title">{`Are you sure you want to remove ${company.name} from watchlist?`}</div>
              <div className="buttons-container">
                <IconButton
                  innerText="Cancel"
                  onClick={() => state.setIsAddRemoveWatchlistPopupOpen()}
                />
                <IconButton
                  innerText="Remove from watchlist"
                  type={ICON_BUTTON_TYPES.RED}
                  filled
                  onClick={() => {
                    state.setIsAddRemoveWatchlistPopupOpen();
                    profilerProjectStore.addToWatchlist(company);
                  }}
                />
              </div>
            </div>
          </>
        )}
        <div className="company-details" style={style}>
          <button onClick={() => navigate(-1)} className="backlink">
            {isFromWatchList
              ? 'Back to watchlist'
              : prevCompanyName
              ? `Back to ${prevCompanyName}`
              : 'Back to search results'}
          </button>

          {state.layout === 'mobile' ? (
            <div className="company-details-header-mobile">
              <div className="header-row">
                {/* {!!state.companyLogo && <img className="logo" src={state.companyLogo} />}
                {!state.companyLogo && (
                  <div className="loader-wrap">
                    <ThreeDots width={76} height={12} color="#348CF9" />
                  </div>
                )} */}
                <img
                  className="company-logo"
                  src={`https://img.logo.dev/${company.website}?token=pk_QESC1Ml-Td-hf5ME9GKBUA`}
                />
                <div className="company-name">{company.name}</div>
                <div className="company-hq">{company.headquarters}</div>
              </div>
              {utilsStore.windowWidth < 800 && (
                <>
                  <div className="header-row industries">
                    <div className="industries-title">Industries</div>
                    {!company.industries?.length && <div className="no-industries">N/A</div>}
                    {!!company.industries?.length && (
                      <>
                        <HorizontalGrabScroll className="industries-scrollable-container">
                          {company.industries.map((industry, idx) => {
                            return (
                              <div className="industry noselect" key={idx}>
                                {industry}
                              </div>
                            );
                          })}
                        </HorizontalGrabScroll>
                        <div className="fade-left" />
                        <div className="fade-right" />
                      </>
                    )}
                  </div>
                  <div className="header-row">{mobileHeaderActionButtons}</div>
                </>
              )}
              {utilsStore.windowWidth >= 800 && (
                <div className="header-row merged-row">
                  <div className="merged-industries-title">Industries</div>
                  <div className="wrapper">
                    <div className="industries-wrapper">
                      {!company.industries?.length && <div className="no-industries">N/A</div>}
                      {!!company.industries?.length && (
                        <>
                          <HorizontalGrabScroll className="industries-scrollable-container">
                            {company.industries.map((industry, idx) => {
                              return (
                                <div className="industry noselect" key={idx}>
                                  {industry}
                                </div>
                              );
                            })}
                          </HorizontalGrabScroll>
                          <div className="fade-left" />
                          <div className="fade-right" />
                        </>
                      )}
                    </div>

                    {mobileHeaderActionButtons}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="company-details-header">
              {/* {!!state.companyLogo && <img className="logo" src={state.companyLogo} />}
                {!state.companyLogo && (
                  <div className="loader-wrap">
                    <ThreeDots width={150} height={24} color="#348CF9" />
                  </div>
                )} */}
              <img
                className="logo"
                src={`https://img.logo.dev/${company.website}?token=pk_QESC1Ml-Td-hf5ME9GKBUA`}
              />

              <div
                className="info-col company-name"
                style={company.industries?.length ? { maxWidth: '300px' } : {}}>
                <div className="bold-text">{company.name || 'N/A'}</div>
                <div className="normal-text">{company.headquarters || 'N/A'}</div>
              </div>
              <div className="separator" />
              <div className="info-col industries">
                <div className="bold-text">Industries</div>
                {!company.industries?.length && <div className="normal-text">N/A</div>}
                {!!company.industries?.length && (
                  <>
                    <HorizontalGrabScroll className="industries-scrollable-container">
                      {company.industries.map((industry, idx) => {
                        return (
                          <div className="industry noselect" key={idx}>
                            {industry}
                          </div>
                        );
                      })}
                    </HorizontalGrabScroll>
                    <div className="fade-left" />
                    <div className="fade-right" />
                  </>
                )}
              </div>
              <div className="separator" />
              <div className="action-btns">
                <div className="top-actions">
                  {!!company.website && (
                    <IconButton
                      id="website-btn"
                      type={ICON_BUTTON_TYPES.BLUE}
                      icon={ICON_BUTTON_ICONS.GLOBE2}
                      tooltipText={`Visit ${company.website}`}
                      onClick={() => window.open('https://' + company.website, '_blank')}
                      tooltipPosition="top-end"
                    />
                  )}
                  <IconButton
                    id="linkedin-btn"
                    type={ICON_BUTTON_TYPES.BLUE}
                    icon={ICON_BUTTON_ICONS.LINKEDIN}
                    tooltipText={companyLinkedInButtonTooltipText}
                    onClick={() =>
                      window.open(
                        `https://www.linkedin.com/search/results/companies/?keywords=${encodeURIComponent(
                          company.name
                        )}`,
                        '_blank'
                      )
                    }
                    tooltipPosition="top-end"
                  />
                  <IconButton
                    id="psrd-btn"
                    type={ICON_BUTTON_TYPES.BLUE}
                    icon={ICON_BUTTON_ICONS.WATCHLIST}
                    disabled={
                      profilerProjectStore.isAddingToProfilerWatchlist ||
                      !profilerAccessStore.canViewProfilerWatchlist
                    }
                    tooltipText={watchlistButtonTooltipText}
                    filled={isInWatchlist}
                    onClick={onWatchlistButtonClick}
                    tooltipPosition="top-end"
                  />
                </div>
                <div className="bottom-actions">
                  <IconButton
                    id="psrf-btn"
                    type={ICON_BUTTON_TYPES.BLUE}
                    icon={
                      state.toggledFinancialDownload
                        ? ICON_BUTTON_ICONS.CHEVRON_UP
                        : ICON_BUTTON_ICONS.CHEVRON_DOWN
                    }
                    iconOnRight
                    innerText="Download as"
                    onClick={state.toggleFinancialDownload}
                    filled={state.toggledFinancialDownload}
                  />
                </div>

                {state.toggledFinancialDownload && (
                  <div className="financials-download-popup">
                    <div className="buttons-wrap">
                      <IconButton
                        type={ICON_BUTTON_TYPES.BLUE}
                        innerText={'Download onepager as PPTX'}
                        withBorder={false}
                        onClick={() => onDownloadFinancialsClick('onepager')}
                        disabled={
                          !profilerAccessStore.canExportProfilerOnepager ||
                          state.isDownloadingCompanyOnepager
                        }
                        tooltipText={
                          profilerAccessStore.canExportProfilerOnepager
                            ? state.isDownloadingCompanyOnepager
                              ? 'Downloading. Please wait.'
                              : state.isSuccessDownloadCompanyOnepager
                              ? 'Downloaded successfully'
                              : ''
                            : 'Requires Profiler Lite or Profiler Full access. Contact support@transact.digital for assistance.'
                        }
                        icon={
                          state.isDownloadingCompanyOnepager
                            ? ICON_BUTTON_ICONS.FILE_EARMARK_ARROW_DOWN
                            : state.isSuccessDownloadCompanyOnepager
                            ? ICON_BUTTON_ICONS.FILE_EARMARK_ARROW_DOWN_FILL
                            : null
                        }
                        iconOnRight
                      />
                      <IconButton
                        type={ICON_BUTTON_TYPES.BLUE}
                        innerText={'Download financials as XLSX'}
                        withBorder={false}
                        onClick={() => onDownloadFinancialsClick('financials')}
                        disabled={
                          !profilerAccessStore.canExportProfilerFinancials ||
                          state.isDownloadingCompanyFinancials ||
                          state.isFinancialsDataEmpty ||
                          state.isLoadingDetails
                        }
                        tooltipText={
                          profilerAccessStore.canExportProfilerFinancials
                            ? state.isFinancialsDataEmpty
                              ? 'No financials data available for export.'
                              : state.isDownloadingCompanyFinancials
                              ? 'Downloading. Please wait.'
                              : state.isSuccessDownloadCompanyFinancials
                              ? 'Downloaded successfully'
                              : ''
                            : 'Requires Profiler Full access. Contact support@transact.digital for assistance.'
                        }
                        icon={
                          state.isDownloadingCompanyFinancials
                            ? ICON_BUTTON_ICONS.FILE_EARMARK_ARROW_DOWN
                            : state.isSuccessDownloadCompanyFinancials
                            ? ICON_BUTTON_ICONS.FILE_EARMARK_ARROW_DOWN_FILL
                            : null
                        }
                        iconOnRight
                      />
                      <IconButton
                        type={ICON_BUTTON_TYPES.BLUE}
                        innerText={'Download full profile as PPTX'}
                        withBorder={false}
                        onClick={() => onDownloadFinancialsClick('full-profile')}
                        disabled={
                          !profilerAccessStore.canExportProfilerFullProfiler ||
                          state.isDownloadingCompanyFullProfile
                        }
                        tooltipText={
                          profilerAccessStore.canExportProfilerFullProfiler
                            ? state.isDownloadingCompanyFullProfile
                              ? 'Downloading. Please wait.'
                              : state.isSuccessDownloadCompanyFullProfile
                              ? 'Downloaded successfully'
                              : ''
                            : 'Requires Profiler Full access. Contact support@transact.digital for assistance.'
                        }
                        icon={
                          state.isDownloadingCompanyFullProfile
                            ? ICON_BUTTON_ICONS.FILE_EARMARK_ARROW_DOWN
                            : state.isSuccessDownloadCompanyFullProfile
                            ? ICON_BUTTON_ICONS.FILE_EARMARK_ARROW_DOWN_FILL
                            : null
                        }
                        iconOnRight
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="tabs-scrollable-wrap">
            <div className="tabs-wrap">
              {tabs.map((tab) => {
                return (
                  <div
                    key={tab.id}
                    id={tab.id}
                    className={`noselect tab${tab.isActive ? ' active' : ''}${
                      tab.isDisabled ? ' disabled' : ''
                    }`}
                    onClick={tab.onClick}>
                    {tab.name}
                  </div>
                );
              })}
            </div>
          </div>

          {tabs
            .filter((tab) => !!tab.tooltipContent)
            .map((tab) => {
              return (
                <Tooltip
                  key={tab.id}
                  anchorSelect={`#${tab.id}`}
                  multiline={true}
                  variant="light"
                  place="bottom-center"
                  opacity={1}
                  noArrow
                  {...{
                    children: tab.tooltipContent
                  }}
                  clickable
                />
              );
            })}

          {state.tab === DETAILS_POUP_TABS.GENERAL && (
            <div className="general-tab-cards">
              <div className="card">
                <div className="title">Summary</div>
                <div className="card-row">
                  <div className="property">Description</div>
                  <div className="value description">{company.description || 'N/A'}</div>
                </div>
                <div className="card-row">
                  <div className="property">Value chain</div>
                  <div className="value">
                    {company.valueChain && company.valueChain.length
                      ? utilsStore.shortenList(company.valueChain, 3).join(', ')
                      : 'N/A'}
                  </div>
                </div>
                <div className="card-row">
                  <div className="property">Ownership</div>
                  <div className="value">{company.ownership || 'N/A'}</div>
                </div>
                <div className="card-row">
                  <div className="property">HQ location</div>
                  <div className="value">{company.headquarters || 'N/A'}</div>
                </div>
                <div className="card-row">
                  <div className="property">Geo focus</div>
                  <div className="value">{company.geographies.join(', ') || 'N/A'}</div>
                </div>
                {!profilerAccessStore.canViewProfilerDetailsExtendedData(project) && (
                  <div className="card-row">
                    <div className="property">Revenue (€M)</div>
                    <div className="value revenue">
                      {company.revenue ? (
                        <>
                          {company.revenue}
                          <span className="apostrophe-year">
                            &lsquo;
                            {company.revenueYear?.toString?.()?.slice?.(-2) || 'n/a'}
                          </span>
                        </>
                      ) : (
                        'N/A'
                      )}
                    </div>
                  </div>
                )}
                <div
                  className={`card-row${
                    !profilerAccessStore.canViewProfilerDetailsExtendedData(project) ? ' last' : ''
                  }`}>
                  <div
                    className={`property ${
                      company.employeesArray?.length ? 'number-of-employees' : ''
                    }`}>
                    Number of employees
                  </div>
                  {company.employeesArray?.length ? (
                    <table className="employees-array-wrap">
                      <tbody>
                        {company.employeesArray.map((entry, idx) => {
                          return (
                            <tr key={entry.id || idx}>
                              <td>
                                <div className="number">
                                  {formatNumber(entry.value?.toFixed?.(2))}
                                </div>
                                <div className="number-details">
                                  <div>&lsquo;{entry.shortYear}</div>
                                  <div>{entry.reliability || 'n/a'}</div>
                                </div>
                              </td>
                              <td>{entry.information_scope || 'N/A'}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <div className="value">
                      {!isNaN(company.employees)
                        ? formatNumber((+company.employees).toFixed(2))
                        : 'N/A'}
                    </div>
                  )}
                </div>
                {profilerAccessStore.canViewProfilerDetailsExtendedData(project) && (
                  <div className="card-row last">
                    <div className="property">Top management</div>
                    {state.isLoadingDetails && (
                      <GeneralLoader wrapClassName="top-management-loader" />
                    )}
                    {!state.isLoadingDetails && !state.topManagement?.length && (
                      <div className="value">N/A</div>
                    )}
                    {!state.isLoadingDetails && !!state.topManagement?.length && (
                      <table className="top-management-wrap">
                        <tbody>
                          {state.topManagement.map((person, idx) => {
                            return (
                              <tr key={idx}>
                                <td className="person-name">
                                  {person.given_name} {person.family_name}
                                </td>
                                <td className="person-icon">
                                  <IconButton
                                    innerText={
                                      PROFILER_JOB_TITLES[person.mrc_code]?.shortName ||
                                      person.management_responsibility
                                    }
                                    icon={ICON_BUTTON_ICONS.INFO_CIRCLE}
                                    type={ICON_BUTTON_TYPES.BLUE}
                                    tooltipText={person.job_titles?.join?.(', ')}
                                    tooltipPosition="top-center"
                                  />
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                )}
              </div>
              {profilerAccessStore.canViewProfilerDetailsExtendedData(project) && (
                <div className="card financials">
                  <div className="title">Financials</div>
                  {state.isLoadingDetails || state.loadDetailsError ? (
                    <GeneralLoader />
                  ) : (
                    <>
                      {state.layout === 'mobile' && state.generalFinancialsValuesScrollable && (
                        <IconButton
                          id={'company-details-financials-info-scroll-mobile'}
                          type={ICON_BUTTON_TYPES.BLUE}
                          icon={ICON_BUTTON_ICONS.ARROW_LEFT_RIGHT}
                          withBorder={false}
                          tooltipClassName="company-details-financials-info-scroll-mobile-tooltip"
                          tooltipContent={
                            <div>
                              <div className="info-title">Scrollable content</div>
                              <div>
                                <img
                                  src={'/images/company-details-financials-scrollable-info.gif'}
                                />
                              </div>
                            </div>
                          }
                        />
                      )}
                      <div className="general-financials-layout">
                        <div className="general-financials-labels">
                          <div className="labels head-labels">
                            <div className="label head-label">Figures in €M</div>
                          </div>
                          <div className="labels">
                            <div className="label">Revenues</div>
                            <div className="sub-label">Growth in %</div>
                          </div>
                          <div className="labels">
                            <div className="label">Gross profit</div>
                            <div className="sub-label">in % of revenues</div>
                          </div>
                          <div className="labels">
                            <div className="label">EBITDA</div>
                            <div className="sub-label">in % of revenues</div>
                          </div>
                          <div className="labels">
                            <div className="label">{PROFILER_FINANCIAL_STATEMENTS.EBIT.name}</div>
                            <div className="sub-label">in % of revenues</div>
                          </div>
                          <div className="labels">
                            <div className="label">{PROFILER_FINANCIAL_STATEMENTS.EBT.name}</div>
                            <div className="sub-label">in % of revenues</div>
                          </div>
                          <div className="labels">
                            <div className="label">Net income</div>
                            <div className="sub-label">in % of revenues</div>
                          </div>
                          <div className="labels">
                            <div className="label">Total assets</div>
                          </div>
                          <div className="labels last">
                            <div className="label">Cash &amp; Equivalents</div>
                          </div>
                        </div>

                        <div className="general-financials-values" ref={generalFinancialsValuesRef}>
                          <div className="labels-wrap head-labels-wrap">
                            {state.financials?.general?.years?.map?.((year) => {
                              return (
                                <div className="labels head-labels" key={year + 'hl'}>
                                  <div className="label head-label">{year}</div>
                                </div>
                              );
                            })}
                            {!state.financials?.general?.years?.length && (
                              <div className="labels head-labels">
                                <div className="label head-label">N/A</div>
                              </div>
                            )}
                          </div>

                          <div className="labels-wrap">
                            {state.financials?.general?.years?.map?.((year) => {
                              return (
                                <div className="labels" key={year + 'lw-revenues'}>
                                  <div className="label">
                                    {(state.financials?.general?.revenues || []).find(
                                      (obj) => obj.year === year
                                    )?.value || '-'}
                                  </div>
                                  <div className="sub-label">
                                    {(state.financials?.general?.revenues || []).find(
                                      (obj) => obj.year === year
                                    )?.percentage || '-'}
                                  </div>
                                </div>
                              );
                            })}
                            {!state.financials?.general?.revenues?.length && (
                              <div className="labels">
                                <div className="label">N/A</div>
                                <div className="sub-label">&nbsp;</div>
                              </div>
                            )}
                          </div>

                          <div className="labels-wrap">
                            {state.financials?.general?.years?.map?.((year) => {
                              return (
                                <div className="labels" key={year + 'lw-gross-profit'}>
                                  <div className="label">
                                    {(state.financials?.general?.grossProfit || []).find(
                                      (obj) => obj.year === year
                                    )?.value || '-'}
                                  </div>
                                  <div className="sub-label">
                                    {(state.financials?.general?.grossProfit || []).find(
                                      (obj) => obj.year === year
                                    )?.percentage || '-'}
                                  </div>
                                </div>
                              );
                            })}
                            {!state.financials?.general?.grossProfit?.length && (
                              <div className="labels">
                                <div className="label">N/A</div>
                                <div className="sub-label">&nbsp;</div>
                              </div>
                            )}
                          </div>

                          <div className="labels-wrap">
                            {state.financials?.general?.years?.map?.((year) => {
                              return (
                                <div className="labels" key={year + 'lw-ebitda'}>
                                  <div className="label">
                                    {(state.financials?.general?.ebitda || []).find(
                                      (obj) => obj.year === year
                                    )?.value || '-'}
                                  </div>
                                  <div className="sub-label">
                                    {(state.financials?.general?.ebitda || []).find(
                                      (obj) => obj.year === year
                                    )?.percentage || '-'}
                                  </div>
                                </div>
                              );
                            })}
                            {!state.financials?.general?.ebitda?.length && (
                              <div className="labels">
                                <div className="label">N/A</div>
                                <div className="sub-label">&nbsp;</div>
                              </div>
                            )}
                          </div>

                          <div className="labels-wrap">
                            {state.financials?.general?.years?.map?.((year) => {
                              return (
                                <div className="labels" key={year + 'lw-total-operating-income'}>
                                  <div className="label">
                                    {(
                                      state.financials?.general?.[
                                        PROFILER_FINANCIAL_STATEMENTS.EBIT.name
                                      ] || []
                                    ).find((obj) => obj.year === year)?.value || '-'}
                                  </div>
                                  <div className="sub-label">
                                    {(
                                      state.financials?.general?.[
                                        PROFILER_FINANCIAL_STATEMENTS.EBIT.name
                                      ] || []
                                    ).find((obj) => obj.year === year)?.percentage || '-'}
                                  </div>
                                </div>
                              );
                            })}
                            {!state.financials?.general?.years?.length &&
                              !state.financials?.general?.[PROFILER_FINANCIAL_STATEMENTS.EBIT.name]
                                ?.length && (
                                <div className="labels">
                                  <div className="label">N/A</div>
                                  <div className="sub-label">&nbsp;</div>
                                </div>
                              )}
                          </div>

                          <div className="labels-wrap">
                            {state.financials?.general?.years?.map?.((year) => {
                              return (
                                <div
                                  className="labels"
                                  key={year + 'lw-result-from-ordinary-operations-before-taxes'}>
                                  <div className="label">
                                    {(
                                      state.financials?.general?.[
                                        PROFILER_FINANCIAL_STATEMENTS.EBT.name
                                      ] || []
                                    ).find((obj) => obj.year === year)?.value || '-'}
                                  </div>
                                  <div className="sub-label">
                                    {(
                                      state.financials?.general?.[
                                        PROFILER_FINANCIAL_STATEMENTS.EBT.name
                                      ] || []
                                    ).find((obj) => obj.year === year)?.percentage || '-'}
                                  </div>
                                </div>
                              );
                            })}
                            {!state.financials?.general?.years?.length &&
                              !state.financials?.general?.[PROFILER_FINANCIAL_STATEMENTS.EBT.name]
                                ?.length && (
                                <div className="labels">
                                  <div className="label">N/A</div>
                                  <div className="sub-label">&nbsp;</div>
                                </div>
                              )}
                          </div>

                          <div className="labels-wrap">
                            {state.financials?.general?.years?.map?.((year) => {
                              return (
                                <div className="labels" key={year + 'lw-net-income'}>
                                  <div className="label">
                                    {(state.financials?.general?.netIncome || []).find(
                                      (obj) => obj.year === year
                                    )?.value || '-'}
                                  </div>
                                  <div className="sub-label">
                                    {(state.financials?.general?.netIncome || []).find(
                                      (obj) => obj.year === year
                                    )?.percentage || '-'}
                                  </div>
                                </div>
                              );
                            })}
                            {!state.financials?.general?.netIncome?.length && (
                              <div className="labels">
                                <div className="label">N/A</div>
                                <div className="sub-label">&nbsp;</div>
                              </div>
                            )}
                          </div>

                          <div className="labels-wrap">
                            {state.financials?.general?.years?.map?.((year) => {
                              return (
                                <div className="labels" key={year + 'lw-total-assets'}>
                                  <div className="label">
                                    {(state.financials?.general?.totalAssets || []).find(
                                      (obj) => obj.year === year
                                    )?.value || '-'}
                                  </div>
                                </div>
                              );
                            })}
                            {!state.financials?.general?.totalAssets?.length && (
                              <div className="labels">
                                <div className="label">N/A</div>
                              </div>
                            )}
                          </div>

                          <div className="labels-wrap last">
                            {state.financials?.general?.years?.map?.((year) => {
                              return (
                                <div className="labels" key={year + 'lw-cash-equivalents'}>
                                  <div className="label">
                                    {(state.financials?.general?.cashAndEquivalents || []).find(
                                      (obj) => obj.year === year
                                    )?.value || '-'}
                                  </div>
                                </div>
                              );
                            })}
                            {!state.financials?.general?.cashAndEquivalents?.length && (
                              <div className="labels">
                                <div className="label">N/A</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
              <div className="card">
                <div className="title">Product portfolio</div>
                <div className="product-portfolio-value">{company.productPortfolio || 'N/A'}</div>
              </div>
              {profilerAccessStore.canViewProfilerDetailsExtendedData(project) && (
                <div className="card shareholder">
                  <div className="title">Shareholder structure </div>
                  <div className="sub-title">Ultimate owners of {company.name}</div>
                  <div className="shareholder-flat-structure-wrap">
                    {(state.isLoadingDetails || state.loadDetailsError) && <GeneralLoader />}
                    {!!state.ultimateShareholders?.length &&
                      state.ultimateShareholders.map((entry, idx) => {
                        return (
                          <div className="entry" key={entry.id || idx}>
                            <div className="column-entry">{entry.beneficial_ownership} %</div>
                            <div className="column-entry">{entry.member_name}</div>
                            <div className="column-entry">{entry.age}</div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
            </div>
          )}

          {state.tab === DETAILS_POUP_TABS.PRODUCT_PORTFOLIO && (
            <div className="product-portfolio-tab-cards">
              <div className="card">
                <div className="property">Кey end-markets</div>
                <div className="value">
                  {company.businessVerticals?.length ? (
                    <ul className="default">
                      {company.businessVerticals.map((BV) => {
                        return <li key={BV.name}>{`${BV.name}: ${BV.description}`}</li>;
                      })}
                    </ul>
                  ) : (
                    'N/A'
                  )}
                </div>
              </div>
              <div className="card">
                <div className="property">Overview of key products</div>
                <div className="value">
                  {company.keyProducts?.length ? (
                    <ul className="default">
                      {company.keyProducts.map((KP) => {
                        return <li key={KP.name}>{`${KP.name}: ${KP.description}`}</li>;
                      })}
                    </ul>
                  ) : (
                    'N/A'
                  )}
                </div>
              </div>
              <div className="card">
                <div className="property">Key related brands</div>
                <div className="value">
                  {company.keyBrands?.length ? (
                    <ul className="default">
                      {company.keyBrands.map((KB) => {
                        return <li key={KB}>{KB}</li>;
                      })}
                    </ul>
                  ) : (
                    'N/A'
                  )}
                </div>
              </div>
              <div className="card">
                <div className="property">Keywords</div>
                <div className="value">{company.products.join(', ') || 'N/A'}</div>
              </div>
            </div>
          )}

          {(state.tab === DETAILS_POUP_TABS.FINANCIALS ||
            state.tab === DETAILS_POUP_TABS.SHAREHOLDER_STRUCTURE ||
            state.tab === DETAILS_POUP_TABS.KEY_CONTACTS) &&
            state.isLoadingDetails && (
              <div className="financials-tab-content">
                <GeneralLoader />
              </div>
            )}

          {(state.tab === DETAILS_POUP_TABS.FINANCIALS ||
            state.tab === DETAILS_POUP_TABS.SHAREHOLDER_STRUCTURE ||
            state.tab === DETAILS_POUP_TABS.KEY_CONTACTS) &&
            !!state.loadDetailsError && (
              <div className="financials-tab-content">
                <GeneralError
                  errorMessage={state.loadDetailsError}
                  actionMessage="You may want to:"
                  actionButtontext={'Try again'}
                  onActionButtonClick={state.loadDetails}
                />
              </div>
            )}

          {state.tab === DETAILS_POUP_TABS.FINANCIALS &&
            !state.isLoadingDetails &&
            !state.loadDetailsError && (
              <div className="financials-tab-content">
                {state.filteredFinancialGroups.map((group, idx) => {
                  const hasLevels = !!group.levels.length;
                  const isCollapsed = state.collapsedFinancialDetails[group.id];
                  const isFilterToggled = state.toggledFinancialFilters[group.id];
                  const hasSelectedLevel = !!state.selectedFinancialLevels[group.id];
                  const selectedLevel =
                    state.selectedFinancialLevels[group.id] ||
                    group.levels[group.levels.length - 1] ||
                    1;
                  const lastLevel = group.levels[group.levels.length - 1];
                  return (
                    <div key={group.id} className="group-wrap">
                      <div className="group-head">
                        {hasLevels && (
                          <div className="top-row">
                            <IconButton
                              hoverType={ICON_BUTTON_TYPES.BLUE}
                              icon={ICON_BUTTON_ICONS.FILTER}
                              innerText={`Filter by level${
                                hasSelectedLevel ? ' (' + selectedLevel + ')' : ''
                              }`}
                              withBorder={hasSelectedLevel}
                              onClick={() => {
                                if (state.toggledFinancialFilters[group.id]) {
                                  return;
                                }

                                state.toggleFinancialFilter(group.id);
                              }}
                              filled={isFilterToggled}
                            />
                            {isFilterToggled && (
                              <div className="lvl-filter-popup">
                                <div className="lvl-buttons-wrap">
                                  {group.levels.map((lvl) => {
                                    const isSelected = hasSelectedLevel && selectedLevel === lvl;
                                    return (
                                      <IconButton
                                        key={lvl}
                                        type={
                                          isSelected
                                            ? ICON_BUTTON_TYPES.BLUE
                                            : ICON_BUTTON_TYPES.DEFAULT
                                        }
                                        filled={isSelected}
                                        hoverType={ICON_BUTTON_TYPES.BLUE}
                                        innerText={`Up to level ${lvl}`}
                                        withBorder={false}
                                        onClick={() => state.setFinancialLevel(group.id, lvl)}
                                      />
                                    );
                                  })}
                                </div>
                                {hasSelectedLevel && (
                                  <div className="filter-action-buttons-wrap">
                                    <IconButton
                                      disabled={!hasSelectedLevel}
                                      type={ICON_BUTTON_TYPES.RED}
                                      innerText="Clear filter"
                                      onClick={() => state.setFinancialLevel(group.id, null)}
                                    />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        <div className="bottom-row">
                          <div className="group-title">{group.title}</div>
                          <IconButton
                            icon={
                              isCollapsed
                                ? ICON_BUTTON_ICONS.CHEVRON_DOWN
                                : ICON_BUTTON_ICONS.CHEVRON_UP
                            }
                            innerText={isCollapsed ? 'Expand  ' : 'Collapse'}
                            withBorder={false}
                            iconOnRight
                            onClick={() => state.toggleFinancialDetails(group.id)}
                          />
                        </div>
                        {!isCollapsed && state.layout === 'mobile' && (
                          <div className="mobile-scrollable-content-row">
                            <IconButton
                              wrapClassName="company-details-financials-mobile-row-info-scroll-btn"
                              type={ICON_BUTTON_TYPES.BLUE}
                              icon={ICON_BUTTON_ICONS.ARROW_LEFT_RIGHT}
                              withBorder={false}
                              tooltipClassName="company-details-financials-mobile-row-info-scroll-tooltip"
                              tooltipContent={
                                <div>
                                  <div className="info-title">Scrollable content</div>
                                  <div>
                                    <img
                                      src={
                                        '/images/company-details-financials-scrollable-info-mobile.gif'
                                      }
                                    />
                                  </div>
                                </div>
                              }
                            />
                          </div>
                        )}
                      </div>
                      {!isCollapsed && !group.items.length && (
                        <div className="no-financials-items">There is no data found.</div>
                      )}
                      {!isCollapsed && !!group.items.length && (
                        <div className="financials-table">
                          <div className="left-content">
                            <div
                              className="row"
                              style={{
                                height: state.financialTablesFirstRowsHeights[group.id]
                                  ? `${state.financialTablesFirstRowsHeights[group.id]}px`
                                  : 'auto'
                              }}>
                              <div className="row-cell property">
                                <div className="label">Figures in €M</div>
                              </div>
                            </div>
                            {group.items.map((item, idx) => {
                              return (
                                <div
                                  key={item.name}
                                  className={`row lvl${item.level}`}
                                  id={`fprop-${group.id}-${item.name}`}
                                  style={{
                                    ...(item.level === 1 && group.items[idx - 1]?.level === 1
                                      ? { borderTop: 'none' }
                                      : {}),
                                    height: state.financialTablesRowsHeights[group.id]?.[item.name]
                                      ? `${state.financialTablesRowsHeights[group.id][item.name]}px`
                                      : 'auto'
                                  }}
                                  ref={(el) => {
                                    if (el?.offsetHeight) {
                                      state.setFinancialTableRowHeight(
                                        group.id,
                                        item.name,
                                        Math.ceil(el.offsetHeight)
                                      );
                                    }
                                  }}>
                                  <div className="row-cell property">
                                    <div
                                      className="label"
                                      style={
                                        item.level > 1
                                          ? {
                                              paddingLeft: `${
                                                (item.level - 1) *
                                                (state.layout === 'mobile' ? 4 : 16)
                                              }px`
                                            }
                                          : {}
                                      }>
                                      {item.name}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          <div className="right-content" id={`right-financial-content-${group.id}`}>
                            <div
                              className="row"
                              ref={(el) => {
                                if (el?.clientHeight) {
                                  state.setFinancialTableFirstRowHeight(group.id, el.clientHeight);
                                }
                              }}>
                              {state.financialYears.map((year, idx) => {
                                return (
                                  <div className="row-cell" key={year + '' + idx}>
                                    <div className="label">
                                      {year}
                                      {!!group.reliability[year] && (
                                        <div>{group.reliability[year]}</div>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            {group.items.map((item, idx) => {
                              return (
                                <div
                                  key={item.name}
                                  className={`row lvl${item.level}`}
                                  id={`frow-${group.id}-${item.name}`}
                                  style={{
                                    ...(item.level === 1 && group.items[idx - 1]?.level === 1
                                      ? { borderTop: 'none' }
                                      : {}),
                                    height: state.financialTablesRowsHeights[group.id]?.[item.name]
                                      ? `${state.financialTablesRowsHeights[group.id][item.name]}px`
                                      : 'auto'
                                  }}>
                                  {state.financialYears.map((year, idx) => {
                                    const value = item.entries.find(
                                      (ntry) => ntry.year === year
                                    )?.value;
                                    return (
                                      <div className="row-cell" key={year + '' + idx}>
                                        <div className="label">{value || '-'}</div>
                                      </div>
                                    );
                                  })}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            )}

          {state.tab === DETAILS_POUP_TABS.SHAREHOLDER_STRUCTURE &&
            !state.isLoadingDetails &&
            !state.loadDetailsError && (
              <div className="shareholder-structure-tab-cards">
                <div className="card structure-filter-wrap">
                  <IconButton
                    icon={ICON_BUTTON_ICONS.FILTER}
                    hoverType={ICON_BUTTON_TYPES.BLUE}
                    innerText="Filter"
                    withBorder={
                      !(
                        ['5'].includes(
                          state.popupShareholderStructureAppliedFilters[
                            COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.SHAREHOLDER_SHARE_PERCENT.NAME
                          ]
                        ) &&
                        state.popupShareholderStructureAppliedFilters[
                          COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.ENTITIES_UNKNOWN_VALUE.NAME
                        ] === false
                      )
                    }
                    onClick={state.toggleMiniShareholderStructureFilters}
                    filled={state.toggledMiniShareholderStructureFilters}
                    disabled={state.toggledMiniShareholderStructureFilters}
                  />
                  {state.toggledMiniShareholderStructureFilters && (
                    <div className="filters-wrap" id="mini-structure-filters-wrap">
                      <div className="fiter-header">
                        <div className="header-title">Filters</div>
                        <IconButton
                          icon={ICON_BUTTON_ICONS.X_LG}
                          onClick={state.toggleMiniShareholderStructureFilters}
                        />
                      </div>
                      <Input
                        field={COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.SHAREHOLDER_SHARE_PERCENT}
                        value={
                          state.popupShareholderStructureFilters[
                            COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.SHAREHOLDER_SHARE_PERCENT.NAME
                          ]
                        }
                        setFieldValue={state.setPopupShareholderStructureFiltersValue}
                        showOnSubmitErrorState={state.onSubmitErrorStatePopupShareholderStructure}
                        messages={state.validationFieldsPopupShareholderStructure.messages}
                      />
                      <CheckBox
                        field={COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.ENTITIES_UNKNOWN_VALUE}
                        value={
                          state.popupShareholderStructureFilters[
                            COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.ENTITIES_UNKNOWN_VALUE.NAME
                          ]
                        }
                        setFieldValue={state.setPopupShareholderStructureFiltersValue}
                        showOnSubmitErrorState={state.onSubmitErrorStatePopupShareholderStructure}
                        messages={state.validationFieldsPopupShareholderStructure.messages}
                      />
                      <div className="filter-actions">
                        <IconButton
                          innerText="Clear"
                          onClick={state.onPopupShareholderStructureClearFilter}
                          disabled={
                            ['5'].includes(
                              state.popupShareholderStructureAppliedFilters[
                                COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.SHAREHOLDER_SHARE_PERCENT
                                  .NAME
                              ]
                            ) &&
                            state.popupShareholderStructureAppliedFilters[
                              COMPANY_DETAILS_SHAREHOLDER_POPUP_FIELDS.ENTITIES_UNKNOWN_VALUE.NAME
                            ] === false
                          }
                        />
                        <IconButton
                          innerText="Filter"
                          onClick={state.onPopupShareholderStructureFilter}
                          type={ICON_BUTTON_TYPES.BLUE}
                          disabled={state.isPopupShareholderStructureFilterDisabled}
                          filled
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="card">
                  <div className="heading">Ultimate shareholders</div>
                  <div className="shareholder-flat-structure-wrap">
                    <div className="entry entry-heading">
                      <div className="column-entry">Share (%)</div>
                      <div className="column-entry">Name</div>
                      <div className="column-entry">Age</div>
                    </div>
                    {!!state.ultimateShareholders?.length &&
                      state.ultimateShareholders.map((entry, idx) => {
                        return (
                          <div className="entry" key={entry.id || idx}>
                            <div className="column-entry">{entry.beneficial_ownership} %</div>
                            <div className="column-entry">{entry.member_name}</div>
                            <div className="column-entry">{entry.age}</div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                {state.layout !== 'mobile' && (
                  <div className="card">
                    <div className="heading">Shareholders structure</div>
                    <div className="shareholders-structure-wrap">
                      <div className="legend">
                        <div className="legend-row">
                          <div className="icon-wrap">
                            <img src={legendsIcons.target} />
                          </div>
                          <div>Target</div>
                        </div>
                        <div className="legend-row">
                          <div className="icon-wrap">
                            <img src={legendsIcons.company} />
                          </div>
                          <div>Organization</div>
                        </div>
                        <div className="legend-row">
                          <div className="icon-wrap">
                            <img src={legendsIcons.person} />
                          </div>
                          <div>Individual</div>
                        </div>
                        <div className="legend-row">
                          <div className="icon-wrap">
                            <img src={legendsIcons.entity} />
                          </div>
                          <div>Entity</div>
                        </div>
                        <div className="legend-row">
                          <div className="icon-wrap">
                            <img src={legendsIcons.share} />
                          </div>
                          <div>Share % of target</div>
                        </div>
                        <div className="legend-row">
                          <div className="icon-wrap beneficial">
                            <img src={legendsIcons.beneficial} />
                          </div>
                          <div>Beneficial ownership % of target</div>
                        </div>
                      </div>
                      {state.layout === 'desktop' && (
                        <>
                          <div className="mini-controls">
                            <div className="triangle-icon-wrap">
                              <img src={ICON_BUTTON_ICONS.EXCLAMATAION_TRIANGLE_FILL} />
                            </div>
                            <div className="expand-msg">
                              Click the button for full-screen shareholder structure.
                            </div>
                            <div className="mini-structure-action-btns">
                              <IconButton
                                id="cp-expand"
                                icon={ICON_BUTTON_ICONS.ARROW_ANGLE_EXPAND}
                                type={ICON_BUTTON_TYPES.BLUE}
                                onClick={state.toggleShareholderPopup}
                                innerText="Expand"
                                iconOnRight
                                disabled={state.toggledMiniShareholderStructureFilters}
                              />
                            </div>
                          </div>
                          <div className="mini-controls2">
                            <IconButton
                              icon={ICON_BUTTON_ICONS.ARROW_COUNTERCLOCKWISE}
                              type={ICON_BUTTON_TYPES.DEFAULT}
                              hoverType={ICON_BUTTON_TYPES.BLUE}
                              withBorder={false}
                              onClick={() =>
                                state.generateShareholderStructureData({ withCenter: true })
                              }
                              innerText="Reset nodes position"
                              disabled={
                                state.toggledMiniShareholderStructureFilters ||
                                state.disableRestoreGraph
                              }
                            />
                          </div>
                        </>
                      )}
                      {!!state.popupShareholderStructureData && (
                        <div className="over-treeWrapper">
                          <div className="treeWrapper">
                            <ForceGraph2D
                              ref={miniGraphRef}
                              width={state.miniStructureWidth}
                              height={state.miniStructureHeight}
                              graphData={state.popupShareholderStructureData.mini}
                              linkDistance={null}
                              charge={null}
                              nodeRelSize={8}
                              onEngineTick={() => {}}
                              cooldownTime={0}
                              onNodeHover={state.handleNodeHover}
                              onLinkHover={state.handleLinkHover}
                              autoPauseRedraw={false}
                              onEngineStop={() => {
                                if (state.shareholderMiniEngineStopExecuted) {
                                  return;
                                }
                                runInAction(() => {
                                  state.shareholderMiniEngineStopExecuted = true;

                                  setTimeout(() => {
                                    miniGraphRef?.current?.zoomToFit?.(
                                      100,
                                      (state.popupShareholderStructureData?.mini?.nodes?.length ||
                                        0) <= 3
                                        ? 50
                                        : 24
                                    );
                                    setTimeout(() => {
                                      runInAction(() => {
                                        state.disableRestoreGraph = false;
                                      });
                                    }, 1000);
                                  }, 100);
                                });
                              }}
                              linkCanvasObjectMode={() => 'replace'}
                              linkCanvasObject={linkCanvasObject}
                              nodeCanvasObject={nodeCanvasObject}
                              onNodeDrag={(node) => {
                                runInAction(() => {
                                  // Freeze all other nodes in their current position during drag
                                  state.popupShareholderStructureData.mini.nodes.forEach((n) => {
                                    if (n.id !== node.id) {
                                      n.fx = n.x; // Lock position
                                      n.fy = n.y; // Lock position
                                    }
                                  });
                                });
                              }}
                              onNodeDragEnd={(node) => {
                                runInAction(() => {
                                  // Lock the dragged node's position to its final coordinates
                                  node.fx = node.x; // Set fixed position in x
                                  node.fy = node.y; // Set fixed position in y

                                  const foundNode =
                                    state.popupShareholderStructureData.popup.nodes.find(
                                      (n) => n.id === node.id
                                    );

                                  if (foundNode) {
                                    foundNode.fx = node.x;
                                    foundNode.fy = node.y;
                                    foundNode.x = node.x;
                                    foundNode.y = node.y;
                                  }
                                });
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

          {state.tab === DETAILS_POUP_TABS.KEY_CONTACTS &&
            !state.isLoadingDetails &&
            !state.loadDetailsError && (
              <div className="key-contacts-wrap">
                {!state.keyContacts?.length && (
                  <div className="no-key-contacts">There are no key contacts found.</div>
                )}
                {!!state.keyContacts?.length && (
                  <div className="key-contacts-container">
                    {state.keyContacts.map((contact, idx) => {
                      const onLinkedInClick = () =>
                        window.open(
                          `https://www.linkedin.com/search/results/people/?company=${encodeURIComponent(
                            company.name
                          )}&keywords=${encodeURIComponent(
                            `${contact.given_name} ${contact.family_name}`
                          )}`,
                          '_blank'
                        );

                      return (
                        <div className="contact-card" key={idx}>
                          <div className="contact-card-inner-wrap">
                            <div className="contact-info">
                              <div className="name">
                                {contact.given_name} {contact.family_name}
                              </div>
                              <div className="job-title">{contact.job_titles?.join?.(', ')}</div>
                              <div className="association-date">
                                Part of the company since{' '}
                                {contact.association_start_date
                                  ? new Date(contact.association_start_date).getFullYear()
                                  : 'n/a'}
                              </div>
                            </div>
                            <IconButton
                              wrapClassName="linkedin-contact-button"
                              type={ICON_BUTTON_TYPES.BLUE}
                              icon={ICON_BUTTON_ICONS.LINKEDIN}
                              onClick={onLinkedInClick}
                            />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            )}

          {state.tab === DETAILS_POUP_TABS.SIMILAR_COMPANIES && (
            <div className="similar-companies-wrap">
              {showSimilarCompaniesError && (
                <GeneralError
                  errorMessage={state.loadSimilarCompaniesError}
                  actionMessage="You may want to:"
                  actionButtontext={'Try again'}
                  onActionButtonClick={state.loadSimilarCompanies}
                />
              )}
              {showSimilarCompaniesLoader && <GeneralLoader />}
              {showSimilarCompaniesNoResults && (
                <div className="no-results">There are no similar companies found.</div>
              )}
              {showSimilarCompaniesResults && (
                <>
                  <div className="similar-listing-layout">
                    <div className="results">
                      <div className="results-header">
                        <div className="cols">
                          <div className="col company-name">Company name</div>
                          <div className="col">Company HQ</div>
                          <div className="col">Industry</div>
                          <div className="col">Value chain</div>
                          <div className="col">Products</div>
                          <div className="col right-align">Revenue (€M)</div>
                          <div className="col right-align">Employees</div>
                        </div>
                        <div className="actions" />
                      </div>

                      {state.similarCompanies
                        .slice(
                          (state.similarCompaniesPage - 1) * similerCompaniesPageSize,
                          state.similarCompaniesPage * similerCompaniesPageSize
                        )
                        .map((c) => {
                          return (
                            <div key={c.id} className="item">
                              <div className="cols">
                                <div className="col profiler-company-name">
                                  <div className="label">Company name</div>
                                  <div className="value">{c.name || 'N/A'}</div>
                                </div>
                                <div className="col">
                                  <div className="label">Company HQ</div>
                                  <div className="value">{c.headquarters || 'N/A'}</div>
                                </div>
                                <div className="col">
                                  <div className="label">Industry</div>
                                  <div className="value">
                                    {c.industries && c.industries.length
                                      ? utilsStore.shortenList(c.industries, 3).join(', ')
                                      : 'N/A'}
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="label">Value chain</div>
                                  <div className="value">
                                    {c.valueChain && c.valueChain.length
                                      ? utilsStore.shortenList(c.valueChain, 3).join(', ')
                                      : 'N/A'}
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="label">Products</div>
                                  <div className="value">
                                    {c.products && c.products.length
                                      ? utilsStore.shortenList(c.products, 3).join(', ')
                                      : 'N/A'}
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="label">Revenue (€M)</div>
                                  <div className="value right-align-desktop">
                                    {c.revenue ? (
                                      <>
                                        {c.revenue}
                                        <span className="year">
                                          &lsquo;
                                          {c.revenueYear?.toString?.()?.slice?.(-2) || 'N/A'}
                                        </span>
                                      </>
                                    ) : (
                                      'N/A'
                                    )}
                                  </div>
                                </div>
                                <div className="col no-border">
                                  <div className="label">Employees</div>
                                  <div className="value right-align-desktop">
                                    {!isNaN(c.employees) ? (
                                      <>
                                        {formatNumber((+c.employees).toFixed(2))}
                                        <span className="year">
                                          &lsquo;
                                          {c.employeesYear?.toString?.()?.slice?.(-2) || 'N/A'}
                                        </span>
                                      </>
                                    ) : (
                                      'N/A'
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="actions">
                                {' '}
                                <div className="col no-border view-details-btn">
                                  <IconButton
                                    filled
                                    type={ICON_BUTTON_TYPES.BLUE}
                                    onClick={() => state.onSimilarCompanyDetailsClick(c)}
                                    innerText="Details"
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>

                  <Pagination
                    show={showSimilarCompaniesPagination}
                    onPageChange={(selected) => {
                      state.setSimilarCompaniesPage(selected + 1);
                      // window.scrollTo({ top: 0, behavior: 'smooth' });
                      setTimeout(() => {
                        const tabsDOM = document.querySelector('.tabs-wrap');
                        if (tabsDOM) {
                          window.scrollTo({
                            top: tabsDOM.offsetTop - (state.layout === 'mobile' ? 52 : 80),
                            behavior: 'smooth'
                          });
                        }
                      }, 0);
                    }}
                    page={state.similarCompaniesPage - 1}
                    pages={state.similarCompaniesPages}
                  />
                </>
              )}
            </div>
          )}

          {state.tab === DETAILS_POUP_TABS.UPDATES && (
            <div className="product-portfolio-updates-wrap">
              <div className="news-container">
                <div className="news-main-title">Last updates</div>
                {(company.news || [])
                  .slice((state.newsPage - 1) * newsPageSize, state.newsPage * newsPageSize)
                  .map((entry, idx) => {
                    const displayTime = getCompanyNewsDisplayTime(entry.date, state.now);
                    return (
                      <div
                        className="news-card"
                        key={idx}
                        onClick={() =>
                          window.open(
                            company.website.startsWith('http') ? entry.url : 'https://' + entry.url,
                            '_blank'
                          )
                        }>
                        <div className="news-info">
                          <div className="domain-wrap">
                            {/* <img
                              className="domain-logo"
                              src={
                                state.newsLogos[entry.domain] ||
                                '/images/company-logo-placeholder.svg'
                              }
                            /> */}
                            <img
                              className="domain-logo"
                              src={`https://img.logo.dev/${entry.domain}?token=pk_QESC1Ml-Td-hf5ME9GKBUA`}
                            />
                            <div className="domain-text">{entry.domain}</div>
                          </div>
                          <div className="news-title">{entry.title}</div>
                          <div className="news-date">{displayTime}</div>
                        </div>
                        <div className="image-wrap">
                          <img src={entry.imageUrl} />
                        </div>
                      </div>
                    );
                  })}
              </div>
              <Pagination
                show={state.showNewsPages}
                onPageChange={(selected) => {
                  state.setNewsPage(selected + 1);
                  // window.scrollTo({ top: 0, behavior: 'smooth' });
                  setTimeout(() => {
                    const tabsDOM = document.querySelector('.tabs-wrap');
                    if (tabsDOM) {
                      tabsDOM.scrollIntoView({ behavior: 'smooth' });
                    }
                  }, 0);
                }}
                page={state.newsPage - 1}
                pages={state.newsPages}
              />
            </div>
          )}
        </div>
      </>
    );
  }
);

export default CompanyDetails;
