import { ICON_BUTTON_ICONS } from '../../components/buttons/IconButton';
import { UI_OPTION_KEYS } from '../../utils/constants/uiOptionKeys';
import { UI_OPTIONS } from '../../utils/constants/uiOptions';

export const projectTypePageSize = 8;
export const selectedProjectTabPageSize = 8;
export const PROJECT_TYPE_TABS = { SAVED: 'saved', ARCHIVED: 'archived' };
export const PROJECT_LIST_SORT = {
  DATE_DESC: 'DATE_DESC',
  DATE_ASC: 'DATE_ASC',
  NAME_DESC: 'NAME_DESC',
  NAME_ASC: 'NAME_ASC',
  FEEDBACK: 'FEEDBACK'
};
export const PROJECT_LIST_PROFILE_FILTER_OPTIONS = [
  { name: 'Fund', value: UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds },
  { name: 'Portfolio company', value: UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'] },
  { name: 'Corporate', value: UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler }
];
export const PROJECT_LIST_SORT_OPTIONS = [
  {
    name: 'Sort by date (desc) ↓',
    value: PROJECT_LIST_SORT.DATE_DESC,
    shortName: 'Sort by date',
    icon: ICON_BUTTON_ICONS.SORT_NUMERIC_DOWN_ALT
  },
  {
    name: 'Sort by date (asc)   ↑',
    value: PROJECT_LIST_SORT.DATE_ASC,
    shortName: 'Sort by date',
    icon: ICON_BUTTON_ICONS.SORT_NUMERIC_UP_ALT
  },
  {
    name: 'Sort by name (a → z)',
    value: PROJECT_LIST_SORT.NAME_ASC,
    shortName: 'Sort by name',
    icon: ICON_BUTTON_ICONS.SORT_ALPHA_UP_ALT
  },
  {
    name: 'Sort by name (z → a)',
    value: PROJECT_LIST_SORT.NAME_DESC,
    shortName: 'Sort by name',
    icon: ICON_BUTTON_ICONS.SORT_ALPHA_DOWN_ALT
  },
  {
    name: 'Sort by feedback',
    value: PROJECT_LIST_SORT.FEEDBACK,
    shortName: 'By feedback',
    icon: ICON_BUTTON_ICONS.APP_INDICATOR
  }
];
export const SELECTED_PROJECT_TABS = {
  RESULTS: 'results',
  EXCLUDED: 'excluded',
  COMMENTS: 'comments'
};

export const encodeProjectsParams = (params = {}) => encodeURIComponent(JSON.stringify(params));
export const decodeProjectsParams = (paramsString = '') =>
  JSON.parse(decodeURIComponent(paramsString));
