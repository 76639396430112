import { PROFILER_SEARCH_CATEGORIES } from '../../../../utils/constants/searchCategories';
import { PROFILER_SEARCH_FIELDS } from '../../../../utils/constants/fields';
import Input from '../../../../components/inputs/Input';
import RangeInput from '../../../../components/inputs/RangeInput';
import MultiSelect from '../../../../components/inputs/MultiSelect';

export const getProfilerCompanyCriteriaSearchFields = (state) => {
  const disableInputsByLoader = state.isLoading || state.isInitialLoading;
  const disableInputsByDemoExceedLimit = state.isProfilerCompanyCriteriaSearchFieldsDisabled;

  return {
    [PROFILER_SEARCH_CATEGORIES.INDUSTRY]: {
      [PROFILER_SEARCH_FIELDS.INDUSTRY.ID]: (
        <Input
          field={PROFILER_SEARCH_FIELDS.INDUSTRY}
          value={state.profilerCompanyCriteriaFields[PROFILER_SEARCH_FIELDS.INDUSTRY.NAME]}
          setFieldValue={state.setProfilerCompanyCriteriaFieldValue}
          disabled={disableInputsByLoader || disableInputsByDemoExceedLimit}
          messages={state.profilerCompanyCriteriaValidationFields.messages}
          showOnSubmitErrorState={state.onProfilerCompanyCriteriaSubmitErrorState}
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [PROFILER_SEARCH_FIELDS.VALUE_CHAIN.ID]: (
        <Input
          field={PROFILER_SEARCH_FIELDS.VALUE_CHAIN}
          value={state.profilerCompanyCriteriaFields[PROFILER_SEARCH_FIELDS.VALUE_CHAIN.NAME]}
          setFieldValue={state.setProfilerCompanyCriteriaFieldValue}
          disabled={disableInputsByLoader || disableInputsByDemoExceedLimit}
          messages={state.profilerCompanyCriteriaValidationFields.messages}
          showOnSubmitErrorState={state.onProfilerCompanyCriteriaSubmitErrorState}
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [PROFILER_SEARCH_FIELDS.PRODUCT_PORTFOLIO.ID]: (
        <Input
          field={PROFILER_SEARCH_FIELDS.PRODUCT_PORTFOLIO}
          value={state.profilerCompanyCriteriaFields[PROFILER_SEARCH_FIELDS.PRODUCT_PORTFOLIO.NAME]}
          setFieldValue={state.setProfilerCompanyCriteriaFieldValue}
          disabled={disableInputsByLoader || disableInputsByDemoExceedLimit}
          messages={state.profilerCompanyCriteriaValidationFields.messages}
          showOnSubmitErrorState={state.onProfilerCompanyCriteriaSubmitErrorState}
          inputWrap={{ enable: true, className: 'col' }}
        />
      )
    },
    [PROFILER_SEARCH_CATEGORIES.FINANCIALS]: {
      [PROFILER_SEARCH_FIELDS.REVENUE_MIN.ID + PROFILER_SEARCH_FIELDS.REVENUE_MAX.ID]: (
        <RangeInput
          fieldMin={PROFILER_SEARCH_FIELDS.REVENUE_MIN}
          fieldMax={PROFILER_SEARCH_FIELDS.REVENUE_MAX}
          valueMin={state.profilerCompanyCriteriaFields[PROFILER_SEARCH_FIELDS.REVENUE_MIN.NAME]}
          valueMax={state.profilerCompanyCriteriaFields[PROFILER_SEARCH_FIELDS.REVENUE_MAX.NAME]}
          setFieldValue={state.setProfilerCompanyCriteriaFieldValue}
          disabled={disableInputsByLoader || disableInputsByDemoExceedLimit}
          messages={state.profilerCompanyCriteriaValidationFields.messages}
          showOnSubmitErrorState={state.onProfilerCompanyCriteriaSubmitErrorState}
          wrapClass={'col'}
        />
      ),
      [PROFILER_SEARCH_FIELDS.EMPLOYEES_MIN.ID + PROFILER_SEARCH_FIELDS.EMPLOYEES_MAX.ID]: (
        <RangeInput
          fieldMin={PROFILER_SEARCH_FIELDS.EMPLOYEES_MIN}
          fieldMax={PROFILER_SEARCH_FIELDS.EMPLOYEES_MAX}
          valueMin={state.profilerCompanyCriteriaFields[PROFILER_SEARCH_FIELDS.EMPLOYEES_MIN.NAME]}
          valueMax={state.profilerCompanyCriteriaFields[PROFILER_SEARCH_FIELDS.EMPLOYEES_MAX.NAME]}
          setFieldValue={state.setProfilerCompanyCriteriaFieldValue}
          disabled={disableInputsByLoader || disableInputsByDemoExceedLimit}
          messages={state.profilerCompanyCriteriaValidationFields.messages}
          showOnSubmitErrorState={state.onProfilerCompanyCriteriaSubmitErrorState}
          wrapClass={'col'}
        />
      ),
      [PROFILER_SEARCH_FIELDS.HEADQUARTER.ID]: (
        <MultiSelect
          field={PROFILER_SEARCH_FIELDS.HEADQUARTER}
          value={state.profilerCompanyCriteriaFields[PROFILER_SEARCH_FIELDS.HEADQUARTER.NAME]}
          setFieldValue={state.setProfilerCompanyCriteriaFieldValue}
          disabled={disableInputsByLoader || disableInputsByDemoExceedLimit}
          inputWrap={{ enable: true, className: 'col' }}
          withSingleClear
        />
      )
    }
  };
};
