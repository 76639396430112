export const PROFILER_JOB_TITLES = {
  B6H8: {
    code: 'B6H8',
    name: 'Managing Director',
    shortName: 'MD'
  },
  A1CS: {
    code: 'A1CS',
    name: 'Executive Board Member',
    shortName: 'EBM'
  },
  A1G7: {
    code: 'A1G7',
    name: 'Board of Directors',
    shortName: 'BoD'
  },
  A1A7: {
    code: 'A1A7',
    name: 'Chief Executive Officer',
    shortName: 'CEO'
  }
};
