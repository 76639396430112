import { PROFILER_SEARCH_FIELDS } from '../../../../../utils/constants/fields';
import { PROFILER_SEARCH_CATEGORIES } from '../../../../../utils/constants/searchCategories';
import MultiSelect from '../../../../../components/inputs/MultiSelect';
import RangeInput from '../../../../../components/inputs/RangeInput';
import Input from '../../../../../components/inputs/Input';

export const getProfilerCompanyCriteriaSearchFields = (state) => {
  const overlapingProps = {
    setFieldValue: state.setProfilerCompanyCriteriaFieldValue,
    showOnSubmitErrorState: state.onProfilerCompanyCriteriaSubmitErrorState,
    messages: state.profilerCompanyCriteriaValidationFields.messages,
    disabled: state.isProfilerCompanyCriteriaSearchFieldsDisabled
  };

  return {
    [PROFILER_SEARCH_CATEGORIES.INDUSTRY]: {
      [PROFILER_SEARCH_FIELDS.INDUSTRY.ID]: (
        <Input
          field={PROFILER_SEARCH_FIELDS.INDUSTRY}
          value={state.profilerCompanyCriteriaFields[PROFILER_SEARCH_FIELDS.INDUSTRY.NAME]}
          {...overlapingProps}
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [PROFILER_SEARCH_FIELDS.VALUE_CHAIN.ID]: (
        <Input
          field={PROFILER_SEARCH_FIELDS.VALUE_CHAIN}
          value={state.profilerCompanyCriteriaFields[PROFILER_SEARCH_FIELDS.VALUE_CHAIN.NAME]}
          {...overlapingProps}
          inputWrap={{ enable: true, className: 'col' }}
        />
      ),
      [PROFILER_SEARCH_FIELDS.PRODUCT_PORTFOLIO.ID]: (
        <Input
          field={PROFILER_SEARCH_FIELDS.PRODUCT_PORTFOLIO}
          value={state.profilerCompanyCriteriaFields[PROFILER_SEARCH_FIELDS.PRODUCT_PORTFOLIO.NAME]}
          {...overlapingProps}
          inputWrap={{ enable: true, className: 'col' }}
        />
      )
    },
    [PROFILER_SEARCH_CATEGORIES.FINANCIALS]: {
      [PROFILER_SEARCH_FIELDS.REVENUE_MIN.ID + PROFILER_SEARCH_FIELDS.REVENUE_MAX.ID]: (
        <RangeInput
          fieldMin={PROFILER_SEARCH_FIELDS.REVENUE_MIN}
          fieldMax={PROFILER_SEARCH_FIELDS.REVENUE_MAX}
          valueMin={state.profilerCompanyCriteriaFields[PROFILER_SEARCH_FIELDS.REVENUE_MIN.NAME]}
          valueMax={state.profilerCompanyCriteriaFields[PROFILER_SEARCH_FIELDS.REVENUE_MAX.NAME]}
          {...overlapingProps}
          wrapClass={'col'}
        />
      ),
      [PROFILER_SEARCH_FIELDS.EMPLOYEES_MIN.ID + PROFILER_SEARCH_FIELDS.EMPLOYEES_MAX.ID]: (
        <RangeInput
          fieldMin={PROFILER_SEARCH_FIELDS.EMPLOYEES_MIN}
          fieldMax={PROFILER_SEARCH_FIELDS.EMPLOYEES_MAX}
          valueMin={state.profilerCompanyCriteriaFields[PROFILER_SEARCH_FIELDS.EMPLOYEES_MIN.NAME]}
          valueMax={state.profilerCompanyCriteriaFields[PROFILER_SEARCH_FIELDS.EMPLOYEES_MAX.NAME]}
          {...overlapingProps}
          wrapClass={'col'}
        />
      ),
      [PROFILER_SEARCH_FIELDS.HEADQUARTER.ID]: (
        <MultiSelect
          field={PROFILER_SEARCH_FIELDS.HEADQUARTER}
          value={state.profilerCompanyCriteriaFields[PROFILER_SEARCH_FIELDS.HEADQUARTER.NAME]}
          {...overlapingProps}
          withSingleClear
          inputWrap={{ enable: true, className: 'col' }}
        />
      )
    }
  };
};
