const NoResults = () => {
  return (
    <div className="no-results">
      <h5>There are no suggestions based on your search criteria</h5>

      <div className="text-help">Search help</div>
      <ul>
        <li>Review your search criteria and extend search scope</li>
        <li>Some combinations might not lead to matching results</li>
        <li>
          Need further assistance? Contact us at{' '}
          <a href="mailto:support@transact.digital">support@transact.digital</a>
        </li>
      </ul>
    </div>
  );
};

export default NoResults;
