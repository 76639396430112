import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../store/store';
import {
  PORTFOLIO_FIELDS,
  FUND_FIELDS,
  COMPANY_PROFILE_FIELDS
} from '../../utils/constants/fields';
import { PUNCTUATION } from '../../utils/constants/notation';
import { formatPercent, formatNumber, formatNumberRange } from '../../utils/utils';
import * as Portal from '@radix-ui/react-portal';
import DetailsContactTab from './details-components/DetailsContactTab';

const PortfolioCoDetailsPopup = observer(
  ({
    portfolio = {},
    onClose = () => {},
    contact = null,
    withAffilatedFundLoader = false,
    isLoadingAffilatedFund = false
  }) => {
    const { utilsStore, fundsStore } = useStore();
    const state = useLocalObservable(() => ({
      tab: contact ? 'contact' : 'general',
      setTab: (tab = 'general') => (state.tab = tab)
    }));

    const tabs = [
      ...(contact
        ? [
            {
              name: 'Contact',
              onClick: () => state.setTab('contact'),
              isActive: state.tab === 'contact'
            }
          ]
        : []),
      {
        name: 'General information',
        onClick: () => state.setTab('general'),
        isActive: state.tab === 'general'
      },
      {
        name: 'Portfolio information',
        onClick: () => state.setTab('information'),
        isActive: state.tab === 'information'
      }
    ];

    const companyName =
      portfolio.companyName || portfolio.companyProfile?.[COMPANY_PROFILE_FIELDS.COMPANY_NAME.NAME];

    return (
      <Portal.Root>
        <div className="shared-popup">
          <div className="shared-fund-details-popup-body">
            <div className="header">
              <div className="tabs">
                {tabs.map((tab) => {
                  return (
                    <div
                      key={tab.name}
                      className={`tab${tab.isActive ? ' active' : ''}`}
                      onClick={tab.onClick}>
                      {tab.name}
                    </div>
                  );
                })}
              </div>
              <div className="close" onClick={onClose}>
                Close
              </div>
            </div>
            <div className="content">
              {state.tab === 'contact' && <DetailsContactTab contact={contact} />}
              {state.tab === 'general' && (
                <>
                  {!!companyName && (
                    <div className="row">
                      <div className="bullet"></div>
                      <div className="label">Private equity firm</div>
                      <div className="value">{companyName}</div>
                    </div>
                  )}
                  <div className="row">
                    <div className="bullet"></div>
                    <div className="label">{PORTFOLIO_FIELDS.NAME.LABEL}</div>
                    <div className="value">
                      {portfolio[PORTFOLIO_FIELDS.NAME.NAME] || PUNCTUATION.EMPTY_VALUE}
                    </div>
                  </div>
                  <div className="row">
                    <div className="bullet"></div>
                    <div className="label">{PORTFOLIO_FIELDS.AFFILIATED_FUND.LABEL}</div>
                    <div className="value">
                      {fundsStore.allFunds.find(
                        (f) => f.id === portfolio[PORTFOLIO_FIELDS.AFFILIATED_FUND.NAME][0]
                      )?.[FUND_FIELDS.NAME.NAME] ||
                        portfolio.affiliateFundName ||
                        (withAffilatedFundLoader && isLoadingAffilatedFund ? 'Loading...' : '') ||
                        PUNCTUATION.EMPTY_VALUE}
                    </div>
                  </div>
                  <div className="row">
                    <div className="bullet"></div>
                    <div className="label">{PORTFOLIO_FIELDS.ACQUISITION_DATE.LABEL}</div>
                    <div className="value">
                      {portfolio[PORTFOLIO_FIELDS.ACQUISITION_DATE.NAME] || PUNCTUATION.EMPTY_VALUE}
                    </div>
                  </div>
                  <div className="row">
                    <div className="bullet"></div>
                    <div className="label">{PORTFOLIO_FIELDS.REVENUE.LABEL} (€M)</div>
                    <div className="value">
                      {formatNumber(portfolio[PORTFOLIO_FIELDS.REVENUE.NAME])}
                    </div>
                  </div>
                  <div className="row">
                    <div className="bullet"></div>
                    <div className="label">{PORTFOLIO_FIELDS.EBITDA.LABEL} (€M)</div>
                    <div className="value">
                      {formatNumber(portfolio[PORTFOLIO_FIELDS.EBITDA.NAME])}
                    </div>
                  </div>
                  <div className="row">
                    <div className="bullet"></div>
                    <div className="label">{PORTFOLIO_FIELDS.DESCRIPTION.LABEL}</div>
                    <div className="value">{portfolio[PORTFOLIO_FIELDS.DESCRIPTION.NAME]}</div>
                  </div>
                  <div className="row">
                    <div className="bullet"></div>
                    <div className="label">{PORTFOLIO_FIELDS.INDUSTRIES.LABEL}</div>
                    <div className="value">
                      {utilsStore.getOptionName(portfolio, PORTFOLIO_FIELDS.INDUSTRIES).join(', ')}
                    </div>
                  </div>
                  <div className="row">
                    <div className="bullet"></div>
                    <div className="label">{PORTFOLIO_FIELDS.GEOGRAPHIES.LABEL}</div>
                    <div className="value">
                      {utilsStore.getOptionName(portfolio, PORTFOLIO_FIELDS.GEOGRAPHIES).join(', ')}
                    </div>
                  </div>
                  <div className="row">
                    <div className="bullet"></div>
                    <div className="label">{PORTFOLIO_FIELDS.SITUATION.LABEL}</div>
                    <div className="value">
                      {utilsStore.getOptionName(portfolio, PORTFOLIO_FIELDS.SITUATION).join(', ')}
                    </div>
                  </div>
                  <div className="row">
                    <div className="bullet"></div>
                    <div className="label">{PORTFOLIO_FIELDS.EQUITY.LABEL}</div>
                    <div className="value">
                      {utilsStore.getOptionName(portfolio, PORTFOLIO_FIELDS.EQUITY).join(', ')}
                    </div>
                  </div>
                </>
              )}
              {state.tab === 'information' && (
                <>
                  <div className="row">
                    <div className="bullet"></div>
                    <div className="label">{PORTFOLIO_FIELDS.REVENUE_RANGE_MIN.LABEL} (€M)</div>
                    <div className="value">
                      {formatNumberRange(
                        portfolio[PORTFOLIO_FIELDS.REVENUE_RANGE_MIN.NAME],
                        portfolio[PORTFOLIO_FIELDS.REVENUE_RANGE_MAX.NAME]
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="bullet"></div>
                    <div className="label">{PORTFOLIO_FIELDS.EBITDA_RANGE_MIN.LABEL} (€M)</div>
                    <div className="value">
                      {formatNumberRange(
                        portfolio[PORTFOLIO_FIELDS.EBITDA_RANGE_MIN.NAME],
                        portfolio[PORTFOLIO_FIELDS.EBITDA_RANGE_MAX.NAME]
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="bullet"></div>
                    <div className="label">{PORTFOLIO_FIELDS.EBITDA_MARGIN.LABEL}</div>
                    <div className="value">
                      {formatPercent(portfolio[PORTFOLIO_FIELDS.EBITDA_MARGIN.NAME])}
                    </div>
                  </div>
                  <div className="row">
                    <div className="bullet"></div>
                    <div className="label">{PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MIN.LABEL} (€M)</div>
                    <div className="value">
                      {formatNumberRange(
                        portfolio[PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MIN.NAME],
                        portfolio[PORTFOLIO_FIELDS.ENTERPRISE_RANGE_MAX.NAME]
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="bullet"></div>
                    <div className="label">{PORTFOLIO_FIELDS.EQUITY_RANGE_MIN.LABEL} (€M)</div>
                    <div className="value">
                      {formatNumberRange(
                        portfolio[PORTFOLIO_FIELDS.EQUITY_RANGE_MIN.NAME],
                        portfolio[PORTFOLIO_FIELDS.EQUITY_RANGE_MAX.NAME]
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Portal.Root>
    );
  }
);

export default PortfolioCoDetailsPopup;
