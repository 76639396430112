import { observer } from 'mobx-react';
import { SEARCH_CATEGORIES } from '../../../../utils/constants/searchCategories';
import { FUND_SEARCH_CATEGORY_FIELDS, SEARCH_FIELDS } from '../../../../utils/constants/fields';
import MultiSelect from '../../../../components/inputs/MultiSelect';
import { useStore } from '../../../../store/store';

const FundsInvestmentCriteriaSearchFormMobile = observer(
  ({
    onShowSection = null,
    fields,
    fieldsCount,
    isSearchDisabled,
    onSearch,
    erroredSections = {},
    categoryFields,
    setCategoryFieldValue,
    categoryOptions
  }) => {
    const { utilsStore } = useStore();

    const onSetSection = (section) => {
      if (typeof onShowSection === 'function') {
        onShowSection(section);
      }
    };

    if (utilsStore.windowWidth > 1200) {
      return null;
    }

    return (
      <form className="mobile" onSubmit={onSearch}>
        <div className="mobile-search-form-title">Select search type of preference</div>
        {fields[SEARCH_FIELDS.SEARCH_TYPE_MOBILE.ID]}
        <MultiSelect
          field={FUND_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE}
          value={categoryFields[FUND_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME]}
          setFieldValue={setCategoryFieldValue}
          values={categoryOptions}
        />
        {Object.values(SEARCH_CATEGORIES).map((section) => {
          const { filled, total } = fieldsCount[section];
          const btnClassNames = ['btn', 'btn-next'];
          if (erroredSections[section]) {
            btnClassNames.push('error');
          }
          return (
            <button
              className={btnClassNames.join(' ')}
              key={section}
              onClick={() => {
                onSetSection(section);
                window.scrollTo({ top: 0 });
              }}>
              {section} {filled}/{total}
            </button>
          );
        })}
        <button type="submit" className="btn btn-primary" disabled={isSearchDisabled}>
          Search
        </button>
      </form>
    );
  }
);

export default FundsInvestmentCriteriaSearchFormMobile;
