import { observer, useLocalObservable } from 'mobx-react';
import { useMemo, useCallback, useRef, useEffect } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { useStore } from '../../store/store';
import InputWrapper from './components/InputWrapper';
import { FIELDS_TYPES } from '../../utils/constants/fields';
import pubSub from '../../utils/pubsub';
// import { canScrollElement } from '../../utils/utils';

// const variablesManager = {
//   lastKeydownMultiselect: 0
// };

const MultiSelect = observer(
  ({
    field,
    value = [],
    values = [],
    valuesKey = '',
    setFieldValue,
    showOnSubmitErrorState = false,
    messages = [],
    disabled = false,
    hideErrorMessages = false,
    hideRequiredLabelSymbol = false,
    hideLabel = false,
    withSingleClear = false,
    inputWrap = { enable: false, className: '' },
    wrapClassname = ''
  }) => {
    const { utilsStore } = useStore();
    const state = useLocalObservable(() => ({
      rendered: false,
      setRendered: (value = false) => (state.rendered = value),
      dropdownToggled: false,
      setDropdownToggled: (isToggled = false) => (state.dropdownToggled = isToggled)
    }));
    const ref = useRef(null);

    const items = useMemo(() => {
      return (
        Array.isArray(values) && values.length
          ? values
          : Array.isArray(field?.VALUES) && field.VALUES.length
          ? field.VALUES
          : valuesKey || field.VALUES_KEY
          ? utilsStore?.options?.[valuesKey || field.VALUES_KEY] || []
          : []
      ).map((f) => ({
        name: f.name,
        id: f.value
      }));
    }, [utilsStore.options, field, valuesKey, values]);

    const selected = useMemo(() => {
      return items.filter((opt) => value.some((val) => val == opt.id));
    }, [items, value]);

    // const handleKeyPress = useMemo(() => {
    //   if (!state.rendered || field.TYPE === FIELDS_TYPES.TYPE_MULTI_SELECT) {
    //     return () => true;
    //   }

    //   let searchString = '';
    //   let foundName = null;
    //   const parent = document.getElementById(field.ID);
    //   const optionContainer = parent?.querySelector?.('div.optionListContainer > ul');
    //   return (event) => {
    //     if (!ref?.current?.state?.toggleOptionsList || !optionContainer) {
    //       return;
    //     }

    //     event.stopImmediatePropagation();
    //     event.preventDefault();
    //     event?.nativeEvent?.stopImmediatePropagation?.();

    //     if (event.keyCode === 13) {
    //       if (foundName) {
    //         const brr = items.find((i) => i.name === foundName).id;
    //         setFieldValue(field, [items.find((i) => i.name === foundName).id]);
    //       }
    //       ref.current.onBlur();
    //       return;
    //     }

    //     if (!((event.keyCode >= 65 && event.keyCode <= 90) || event.keyCode === 32)) {
    //       return;
    //     }

    //     searchString += event.key;

    //     variablesManager.lastKeydownMultiselect = new Date().getTime();

    //     setTimeout(() => {
    //       if (new Date().getTime() - variablesManager.lastKeydownMultiselect >= 1000) {
    //         searchString = '';
    //       }
    //     }, 1000);

    //     const options = [...optionContainer.querySelectorAll('li')];
    //     const foundOption = options.find((el) =>
    //       el.textContent.toLowerCase().startsWith(searchString.toLowerCase())
    //     );
    //     options.forEach((el) => (el.className = 'option'));
    //     if (foundOption) {
    //       foundName = foundOption.textContent;
    //       foundOption.className = foundOption.className.trim() + ' searched-keyword-multiselect';
    //       if (canScrollElement(optionContainer)) {
    //         optionContainer.scrollTo({ top: foundOption.offsetTop });
    //       }
    //     } else {
    //       foundName = null;
    //       optionContainer.scrollTo({ top: 0 });
    //     }
    //   };
    // }, [field.ID, ref.current, state.rendered, items]);

    // useEffect(() => {
    //   document.addEventListener('keydown', handleKeyPress, { capture: true });
    //   return () => {
    //     document.removeEventListener('keydown', handleKeyPress, { capture: true });
    //   };
    // }, [handleKeyPress]);

    useEffect(() => {
      state.setRendered(true);
      return () => {
        state.setRendered();
      };
    }, [state]);

    useEffect(() => {
      const subscription = (payload) => {
        if (
          payload?.event === 'multiselect-click' &&
          payload?.multiselectId !== field.ID &&
          ref.current.state.toggleOptionsList
        ) {
          // const input = document
          //   .getElementById(field.ID)
          //   .querySelector('div.search-wrapper')
          //   .querySelector('input[type="text"].searchBox');
          // input?.blur?.();
          ref.current.onBlur();
        }
      };
      pubSub.subscribe(subscription);
      return () => {
        pubSub.unsubscribe(subscription);
      };
    }, [field, ref.current]);

    useEffect(() => {
      const stopEvent = (event) => {
        event.stopImmediatePropagation();
        event.preventDefault();
        event?.nativeEvent?.stopImmediatePropagation?.();
      };

      if (
        (field.TYPE === FIELDS_TYPES.TYPE_SELECT || field.TYPE === FIELDS_TYPES.TYPE_RADIO) &&
        ref.current &&
        field.ID
      ) {
        const parent = document.getElementById(field.ID).querySelector('div.search-wrapper');
        const input = parent.querySelector('input[type="text"].searchBox');

        const handleAdvancedToggle = (event) => {
          stopEvent(event);

          if (event?.target?.className === 'icon_cancel closeIcon') {
            setFieldValue(field, []);
            pubSub.publish({ event: 'multiselect-click', multiselectId: field.ID });
            setTimeout(() => {
              input.focus();
            }, 20);
            return;
          }

          if (event?.target?.className === 'multiselect-arrow-hover') {
            if (!ref.current.state.toggleOptionsList) {
              input.focus();
              pubSub.publish({ event: 'multiselect-click', multiselectId: field.ID });
              setTimeout(() => {
                if (!ref.current.state.toggleOptionsList) {
                  ref.current.onFocus();
                }
              }, 20);
            } else {
              input.blur();
              setTimeout(() => {
                if (ref.current.state.toggleOptionsList) {
                  ref.current.onBlur();
                }
              }, 20);
            }
            return;
          }

          if (!ref.current.state.toggleOptionsList) {
            input.focus();
            pubSub.publish({ event: 'multiselect-click', multiselectId: field.ID });
            setTimeout(() => {
              if (!ref.current.state.toggleOptionsList) {
                ref.current.onFocus();
              }
            }, 20);
          }
        };

        if (!parent.querySelector('.multiselect-arrow-hover')) {
          const arrowHover = document.createElement('div');
          arrowHover.className = 'multiselect-arrow-hover';
          parent.appendChild(arrowHover);
          parent.addEventListener('click', stopEvent, { capture: true });
          parent.addEventListener('mousedown', handleAdvancedToggle, { capture: true });
          parent.addEventListener('touchstart', handleAdvancedToggle, { capture: true });
        }

        if (input.getAttribute('readonly') !== 'readonly') {
          input.removeAttribute('disabled');
          // input.setAttribute('readonly', 'readonly');
        }

        if (!parent.getAttribute('parenteventsattached')) {
          parent.addEventListener('mousedown', handleAdvancedToggle, { capture: true });
          parent.addEventListener('touchstart', handleAdvancedToggle, { capture: true });
          parent.setAttribute('parenteventsattached', 'true');
        }

        if (!input.getAttribute('inputeventsattached')) {
          input.addEventListener('click', stopEvent, { capture: true });
          input.addEventListener('focus', stopEvent, { capture: true });
          input.addEventListener('blur', stopEvent, { capture: true });
          input.setAttribute('inputeventsattached', 'true');
        }

        const container = document.getElementById(field.ID);
        if (!container.className.includes('singleCustom')) {
          container.className += ' singleCustom';
        }
      }

      if (field.TYPE !== FIELDS_TYPES.TYPE_MULTI_SELECT || !field.ID || !ref.current) {
        return;
      }

      const input = document
        .getElementById(field.ID)
        .querySelector('div.search-wrapper')
        .querySelector('input[type="text"].searchBox');

      const handleMultiselectArrowHover = (event) => {
        if (ref.current.state.toggleOptionsList) {
          stopEvent(event);
          input.blur();
        }
      };

      const searchWrapper = document.getElementById(field.ID).querySelector('div.search-wrapper');
      if (!searchWrapper.querySelector('.multiselect-arrow-hover')) {
        const arrowHover = document.createElement('div');
        arrowHover.className = 'multiselect-arrow-hover';
        searchWrapper.appendChild(arrowHover);
        arrowHover.addEventListener('click', handleMultiselectArrowHover, { capture: true });
      }

      const parent = document.getElementById(field.ID).querySelector('div.optionListContainer');
      if (parent.querySelector('.multiselect-clear-btn')) {
        return;
      }

      const wrap = document.createElement('div');
      wrap.className = 'multiselect-clear-btn-wrap';
      const emptyEl = document.createElement('span');
      const clearBtn = document.createElement('button');
      clearBtn.textContent = 'Clear all';
      clearBtn.className = 'multiselect-clear-btn';
      wrap.appendChild(emptyEl);
      wrap.appendChild(clearBtn);
      parent.insertBefore(wrap, parent.firstChild);
      clearBtn.addEventListener('click', () => {
        ref.current.resetSelectedValues();
        setFieldValue(field, []);
        setTimeout(() => {
          document.getElementById(field.ID).querySelector('div.search-wrapper').click();
        }, 20);
      });
    }, [field, ref.current, value, disabled]);

    useEffect(() => {
      const container = document.getElementById(field.ID)?.querySelector('div.optionListContainer');

      if (container) {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (mutation.attributeName === 'class') {
              const currentClassName = mutation.target.className;

              if (currentClassName.includes('displayBlock')) {
                state.setDropdownToggled(true);
              }
              if (currentClassName.includes('displayNone')) {
                state.setDropdownToggled(false);
              }
            }
          });
        });

        observer.observe(container, { attributes: true });

        return () => observer.disconnect();
      }
    }, [field, ref.current]);

    const props = useMemo(() => {
      const labelProps = {};

      if (field.LABEL_CLASSNAME) {
        labelProps.className = field.LABEL_CLASSNAME;
      }

      return { labelProps };
    }, [field, value]);

    const updateValue = useCallback(
      (selected) => {
        setFieldValue(
          field,
          selected.map((i) => i.id)
        );
        if (
          (field.TYPE === FIELDS_TYPES.TYPE_SELECT || field.TYPE === FIELDS_TYPES.TYPE_RADIO) &&
          selected?.length
        ) {
          state.setDropdownToggled(false);
        }
      },
      [setFieldValue, field]
    );

    const { validationState, onSubmitErrorMessages } = useMemo(() => {
      let validationState = '';
      const onSubmitErrorMessages = showOnSubmitErrorState
        ? messages.filter((m) => m.field === field.NAME && !m.isValid)
        : [];
      if (showOnSubmitErrorState && onSubmitErrorMessages.length) {
        validationState = 'error';
      }

      return { validationState, onSubmitErrorMessages };
    }, [showOnSubmitErrorState, messages]);

    const withWrap = inputWrap.enable;

    const view = (
      <div
        className={
          (wrapClassname || field.WRAP_CLASSNAME || 'form-group') +
          ' select-form-group' +
          (state.dropdownToggled ? ' focused' : '')
        }
        {...{ 'validation-state': validationState }}>
        {!!field.LABEL && !hideLabel && (
          <label htmlFor={field.ID} {...props.labelProps}>
            {field.LABEL}
            {field.REQUIRED && !hideRequiredLabelSymbol && !field.HIDE_REQUIRED_LABEL_SYMBOL && '*'}
            {!!field.LABEL_DESCRIPTION && <span>{field.LABEL_DESCRIPTION}</span>}
          </label>
        )}
        <div className="input-error-group">
          <div
            className={`input-container icon-select-downarrow multiselect ${
              value?.length > 0 ? 'darker-placeholder' : ''
            } ${!withSingleClear ? 'without-single-select-clear' : ''} ${
              disabled ? 'disabled' : ''
            } ${state.dropdownToggled ? 'dropdownToggled' : 'dropdownUntoggled'}`}>
            <Multiselect
              ref={ref}
              id={field.ID}
              singleSelect={
                field.TYPE === FIELDS_TYPES.TYPE_SELECT || field.TYPE === FIELDS_TYPES.TYPE_RADIO
              }
              options={items}
              hideSelectedList={field.TYPE === FIELDS_TYPES.TYPE_MULTI_SELECT}
              displayValue="name"
              selectedValues={selected}
              showCheckbox={field.TYPE === FIELDS_TYPES.TYPE_MULTI_SELECT}
              placeholder={
                field.TYPE === FIELDS_TYPES.TYPE_MULTI_SELECT
                  ? value?.length
                    ? value.length === 1
                      ? items.find(({ id }) => id === value[0])?.name
                      : `${value.length} selected`
                    : field.PLACEHOLDER
                  : field.PLACEHOLDER
              }
              avoidHighlightFirstOption={true}
              onSelect={updateValue}
              onRemove={updateValue}
              disable={disabled}
              emptyRecordMsg={'No options available'}
              closeIcon="cancel"
            />
          </div>
          {!hideErrorMessages &&
            onSubmitErrorMessages
              .filter(({ msg }) => msg.length)
              .map(({ msg }, idx) => (
                <div
                  key={idx}
                  className={
                    field.ON_SUBMIT_ERROR_STATE_ERROR_MESSAGE_CLASSNAME || 'error-msg-input'
                  }>
                  {msg}
                </div>
              ))}
        </div>
      </div>
    );

    return withWrap ? (
      <InputWrapper className={inputWrap.className || ''}>{view}</InputWrapper>
    ) : (
      view
    );
  }
);

export default MultiSelect;
