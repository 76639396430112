import { API_ENDPOINTS } from './endpoints';
import { REQ_METHODS } from './methods';
import { CONTENT_TYPES } from './contentTypes';

const disableMockGlobal = false;

export const request = async ({ endpoint, body = {}, headers = {}, params = {} }) => {
  if (!Object.values(API_ENDPOINTS).find((e) => endpoint.path.startsWith(e.path))) {
    throw new Error(`${JSON.stringify(endpoint)} is invalid request endpoint!`);
  }

  if (!Object.values(REQ_METHODS).includes(endpoint.method)) {
    throw new Error(`${endpoint.method} is invalid request method!`);
  }

  const controller = new AbortController();
  const { signal } = controller;

  const req = {
    method: endpoint.method,
    headers: { 'Content-Type': endpoint.contentType || CONTENT_TYPES.APPLICATION_JSON, ...headers },
    signal
  };

  const { POST, PUT, PATCH, DELETE } = REQ_METHODS;
  if ([POST, PUT, PATCH, DELETE].includes(endpoint.method)) {
    req.body = JSON.stringify(body);
  }

  const responseInfo = { done: false };

  let request = null;
  if (!disableMockGlobal && typeof endpoint.mockResponseData === 'function') {
    request = new Promise((resolve) => {
      setTimeout(() => {
        const data = endpoint.mockResponseData(body);
        if (endpoint.dataMapper && typeof endpoint.dataMapper === 'function') {
          endpoint.dataMapper(data);
        }
        resolve({ success: true, data });
      }, 200);
    });
  } else {
    const isContentJSON = req?.headers?.['Content-Type'] === CONTENT_TYPES.APPLICATION_JSON;
    request = fetch(
      `${process.env.REACT_APP_API_BASEURL}${endpoint.path}${
        Object.keys(params).length
          ? `?${Object.entries(params)
              .map(([param, value]) => `${param}=${value}`)
              .join('&')}`
          : ''
      }`,
      req
    )
      .then((res) => {
        responseInfo.done = true;
        if (isContentJSON) {
          return res.json();
        }

        return res;
      })
      .then((res) => {
        if (isContentJSON) {
          if (res.success && endpoint.dataMapper && typeof endpoint.dataMapper === 'function') {
            endpoint.dataMapper(res.data);
          }
          return res;
        }

        return res;
      });
  }

  const response = await Promise.race([
    request,
    new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          success: false,
          errorMessage: `Connection timed out (${endpoint.method} '${endpoint.path}')`
        });
        if (!responseInfo.done) {
          controller.abort();
        }
      }, +(process.env.REACT_APP_API_TIMEOUT_MS || 20_000));
    })
  ]);

  return response;
};
