import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../store/store';
import ReactPaginate from 'react-paginate';
import { isPhoneOrTablet } from '../../utils/utils';
import { useEffect, useRef } from 'react';

const Pagination = observer(
  ({
    show = true,
    page = 1,
    pages = 1,
    onPageChange = (page) => {}, // ReactPaginate starts from 0 and ends at length - 1 so when you receive the page add + 1 if you need human readable page
    pageRange = 0, // use this to override existing logic (Pagination's calculations won't apply)
    pageMargin = 0 // use this to override existing logic (Pagination's calculations won't apply)
    // desktopInitialPageRange = 5, // use this to override the calculations' starting point for desktop range
    // desktopInitialPageMargin = 5, // use this to override the calculations' starting point for desktop margin
    // desktopRangeStep = 150 // use this to override the increase range and margin by pixel expansion for desktop
  }) => {
    const { utilsStore } = useStore();
    const state = useLocalObservable(() => ({
      pageRange: 1,
      setPageRange: (range = 1) => (state.pageRange = range),
      pageMargin: 1,
      setPageMargin: (margin = 1) => (state.pageMargin = margin),
      get layout() {
        return utilsStore.windowWidth < 1201 ? 'mobile' : 'desktop';
      }
    }));

    const ref = useRef(null);

    // test solution
    useEffect(() => {
      if (!ref.current) {
        return;
      }

      const resizeObserver = new ResizeObserver(([entry]) => {
        if (utilsStore.windowWidth < 361) {
          state.setPageRange(1);
          state.setPageMargin(1);
          return;
        }

        if (utilsStore.windowWidth < 501) {
          state.setPageRange(2);
          state.setPageMargin(2);
          return;
        }

        if (utilsStore.windowWidth < 680) {
          state.setPageRange(3);
          state.setPageMargin(3);
          return;
        }

        const fullWidth = entry.contentRect.width;
        const prevWidth = state.layout === 'mobile' ? 30 : 62;
        const nextWidth = state.layout === 'mobile' ? 30 + 4 : 62 + 4;
        const breaksWidth = (state.layout === 'mobile' ? 30 : 38) * 2 + 4 * 2;
        const availableWidth = fullWidth - prevWidth - nextWidth - breaksWidth;

        const singleBtnWidth = state.layout === 'mobile' ? 30 + 4 : 38 + 4;
        const maxButtonsCount = Math.floor(availableWidth / singleBtnWidth);
        const actualButtonsCount = maxButtonsCount <= 0 ? 3 : maxButtonsCount;

        const calculatedRange = Math.floor(actualButtonsCount / 3);
        const preActualRange = calculatedRange <= 0 ? 1 : calculatedRange;
        const actualRange =
          preActualRange > 1 ? preActualRange - (preActualRange % 2 === 0 ? 2 : 1) : preActualRange;

        state.setPageRange(actualRange || 1);
        state.setPageMargin(actualRange || 1);
      });

      resizeObserver.observe(ref.current);
      return () => {
        resizeObserver.disconnect();
      };
    }, [state, utilsStore, state.layout, ref, ref.current, page, show]);

    // let [range, margin] = [1, 1];
    // if (utilsStore.windowWidth < 361) {
    //   [range, margin] = [1, 1];
    // } else if (utilsStore.windowWidth < 501) {
    //   [range, margin] = [2, 2];
    // } else if (utilsStore.windowWidth < 601) {
    //   [range, margin] = [3, 3];
    // } else if (utilsStore.windowWidth < 1201) {
    //   const increase = Math.floor((utilsStore.windowWidth - 601) / 80);
    //   if (increase > 0) {
    //     [range, margin] = [3 + increase, 3 + increase];
    //   } else {
    //     [range, margin] = [3, 3];
    //   }
    // } else {
    //   [range, margin] = [desktopInitialPageRange, desktopInitialPageMargin];
    //   const increase = Math.floor((utilsStore.windowWidth - 1201) / desktopRangeStep);
    //   if (increase > 0) {
    //     [range, margin] = [range + increase, margin + increase];
    //   }
    // }

    const containerClasses = ['pagination-container'];
    if (state.layout === 'mobile') {
      containerClasses.push('mobile-virtual-pagination-container-default');

      if (!isPhoneOrTablet) {
        containerClasses.push('with-mobile-hover');
      }
    }

    const containerClassName = containerClasses.join(' ');

    if (!show) {
      return null;
    }

    // <div ref={ref}><ReactPaginate /></div>

    return (
      <div ref={ref} className="pagination-container-wrap">
        <ReactPaginate
          onPageChange={({ selected }) => onPageChange(selected)}
          forcePage={page}
          pageRangeDisplayed={pageRange || state.pageRange}
          marginPagesDisplayed={pageMargin || state.pageMargin}
          // pageRangeDisplayed={pageRange || range}
          // marginPagesDisplayed={pageMargin || margin}
          pageCount={pages}
          renderOnZeroPageCount={null}
          breakLabel="..."
          nextLabel={state.layout === 'mobile' ? '→' : 'Next'}
          previousLabel={state.layout === 'mobile' ? '←' : 'Prev'}
          containerClassName={containerClassName}
          pageClassName="page"
          pageLinkClassName="pageLink"
          nextClassName="next"
          nextLinkClassName="nextLink"
          previousClassName="prev"
          previousLinkClassName="prevLink"
          breakClassName="break"
          breakLinkClassName="breakLink"
        />
      </div>
    );
  }
);

export default Pagination;
