import { observer } from 'mobx-react';
import {
  FUND_SEARCH_CATEGORY_FIELDS,
  PORTFOLIO_SEARCH_CATEGORY_FIELDS,
  PROFILER_SEARCH_CATEGORY_FIELDS,
  SEARCH_FIELDS,
  SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA
} from '../../../../utils/constants/fields';
import { Fragment, useEffect } from 'react';
import useHistory from '../../../../hooks/useHistory';
import { useStore } from '../../../../store/store';
import { UI_OPTIONS } from '../../../../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../../../../utils/constants/uiOptionKeys';
import MultiSelect from '../../../../components/inputs/MultiSelect';

const MobileFilters = observer(
  ({
    state,
    fundsInvestmentCriteriaFields,
    portfoliosInvestmentCriteriaFields,
    profilerCompanyCriteriaFields
  }) => {
    const { utilsStore, profilerAccessStore } = useStore();
    const { navigate, location } = useHistory();

    useEffect(() => {
      const closeFilters = () => {
        state.toggleMobileFilters();
      };
      navigate(location.pathname + location.search);
      addEventListener('popstate', closeFilters);
      return () => {
        removeEventListener('popstate', closeFilters);
      };
    }, [state]);

    useEffect(() => {
      if (utilsStore.windowWidth > 1200) {
        navigate(-1);
      }
    }, [utilsStore.windowWidth]);

    const fundsInvestmentCriteriafieldsSections = Object.entries(fundsInvestmentCriteriaFields)
      .filter(([id]) => id !== SEARCH_FIELDS.SEARCH_TYPE.ID)
      .reduce((arr, curr) => {
        arr.push(...Object.values(curr[1]));
        return arr;
      }, []);

    const profilerCompanyCriteriaFieldsSections = Object.entries(
      profilerCompanyCriteriaFields
    ).reduce((arr, curr) => {
      arr.push(...Object.values(curr[1]));
      return arr;
    }, []);

    const isLoading = state.isLoading || state.initialLoading;
    const searchType = state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.SEARCH_TYPE.NAME][0];

    if (utilsStore.windowWidth > 1200) {
      return null;
    }

    return (
      <div className="mobile-filters-container">
        <button onClick={() => navigate(-1)} className="backlink">
          Search results
        </button>

        <div className="fields">
          {searchType !== UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler &&
            fundsInvestmentCriteriaFields[SEARCH_FIELDS.SEARCH_TYPE.ID]}
          {searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler && (
            <>
              {profilerAccessStore.isProfilerDemo && (
                <div
                  className={`profiler-demo-mobile-filter-info-message${
                    profilerAccessStore.demoSearchExceededLimit ? ' exceeded' : ''
                  }`}>
                  <div className="title">
                    {profilerAccessStore.demoSearchExceededLimit ? (
                      <>
                        You&apos;ve used all {profilerAccessStore.demoSearchMaxCount} demo searches
                      </>
                    ) : (
                      <>
                        You have {profilerAccessStore.demoSearchRemainingCount} out of{' '}
                        {profilerAccessStore.demoSearchMaxCount} searches remaining
                      </>
                    )}
                  </div>
                  <div className="text">
                    {profilerAccessStore.demoSearchExceededLimit ? (
                      <>
                        Contact{' '}
                        <a href="mailto:support@transact.digital">support@transact.digital</a> for
                        an upgrade to continue.
                      </>
                    ) : (
                      <>Upgrade to Full access to unlock unlimited searches.</>
                    )}
                  </div>
                </div>
              )}
              {fundsInvestmentCriteriaFields[SEARCH_FIELDS.SEARCH_TYPE.ID]}
              <MultiSelect
                field={PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE}
                value={
                  state.profilerSearchCategoryFields[
                    PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME
                  ]
                }
                setFieldValue={state.setProfilerSearchCategoryFieldValue}
                values={state.profilerCategories.filter(({ disabled }) => !disabled)}
                inputWrap={{ enable: true, className: 'col' }}
                disabled={isLoading || state.isProfilerCompanyCriteriaSearchFieldsDisabled}
              />
              {state.profilerSearchCategoryFields[
                PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME
              ][0] === UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company criteria'] && (
                <>
                  {profilerCompanyCriteriaFieldsSections.map((field, idx) => (
                    <Fragment key={idx}>{field}</Fragment>
                  ))}
                </>
              )}
            </>
          )}
          {searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds && (
            <>
              <MultiSelect
                field={FUND_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE}
                value={
                  state.fundsSearchCategoryFields[
                    FUND_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME
                  ]
                }
                setFieldValue={state.setFundsSearchCategoryFieldValue}
                values={state.fundsCategories.filter(({ disabled }) => !disabled)}
                inputWrap={{ enable: true, className: 'col' }}
                disabled={isLoading}
              />
              {state.fundsSearchCategoryFields[
                FUND_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME
              ][0] === UI_OPTIONS[UI_OPTION_KEYS.FUND_SEARCH_CATEGORY]['Investment criteria'] && (
                <>
                  {fundsInvestmentCriteriafieldsSections.map((field, idx) => (
                    <Fragment key={idx}>{field}</Fragment>
                  ))}
                </>
              )}
            </>
          )}
          {searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'] && (
            <>
              <MultiSelect
                field={PORTFOLIO_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE}
                value={
                  state.portfoliosSearchCategoryFields[
                    PORTFOLIO_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME
                  ]
                }
                setFieldValue={state.setPortfoliosSearchCategoryFieldValue}
                values={state.portfoliosCategories.filter(({ disabled }) => !disabled)}
                inputWrap={{ enable: true, className: 'col' }}
                disabled={isLoading}
              />
              {state.portfoliosSearchCategoryFields[
                PORTFOLIO_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME
              ][0] ===
                UI_OPTIONS[UI_OPTION_KEYS.PORTFOLIO_SEARCH_CATEGORY]['Investment criteria'] && (
                <>
                  {
                    portfoliosInvestmentCriteriaFields[
                      SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.INDUSTRY.ID
                    ]
                  }
                  {
                    portfoliosInvestmentCriteriaFields[
                      SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.HEADQUARTER.ID
                    ]
                  }
                  {
                    portfoliosInvestmentCriteriaFields[
                      SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.GEOGRAPHICAL_FOCUS.ID
                    ]
                  }
                  {
                    portfoliosInvestmentCriteriaFields[
                      SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.REVENUE.ID
                    ]
                  }
                </>
              )}
            </>
          )}
        </div>

        <div className="form-footer">
          {searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler && (
            <>
              {state.profilerSearchCategoryFields[
                PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME
              ][0] === UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company criteria'] && (
                <button
                  className="btn btn-primary disable-fade-primary"
                  onClick={state.onSearch}
                  disabled={state.isProfilerCompanyCriteriaSearchDisabled}>
                  Show results
                </button>
              )}
            </>
          )}
          {searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds && (
            <>
              {state.fundsSearchCategoryFields[
                FUND_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME
              ][0] === UI_OPTIONS[UI_OPTION_KEYS.FUND_SEARCH_CATEGORY]['Investment criteria'] && (
                <button
                  className="btn btn-primary disable-fade-primary"
                  onClick={state.onSearch}
                  disabled={state.isFundsInvestmentCriteriaSearchDisabled}>
                  Show results
                </button>
              )}
            </>
          )}
          {searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'] && (
            <>
              {state.portfoliosSearchCategoryFields[
                PORTFOLIO_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME
              ][0] ===
                UI_OPTIONS[UI_OPTION_KEYS.PORTFOLIO_SEARCH_CATEGORY]['Investment criteria'] && (
                <button
                  className="btn btn-primary disable-fade-primary"
                  onClick={state.onSearch}
                  disabled={state.isPortfoliosInvestmentCriteriaSearchDisabled}>
                  Show results
                </button>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
);

export default MobileFilters;
