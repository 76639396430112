import { observer } from 'mobx-react';
import { FIELDS_TYPES, PROFILER_SEARCH_CATEGORY_FIELDS } from '../../../../utils/constants/fields';
import Radio from '../../../../components/inputs/Radio';
import Input from '../../../../components/inputs/Input';
import { useStore } from '../../../../store/store';
import { ICON_BUTTON_ICONS } from '../../../../components/buttons/IconButton';

const ProfilerCompanyNameSearchFormDesktop = observer(
  ({ onSearch, tabs, categoryFields, setCategoryFieldValue, categoryOptions }) => {
    const { utilsStore, profilerAccessStore } = useStore();
    if (utilsStore.windowWidth < 1201) {
      return null;
    }

    return (
      <form onSubmit={onSearch} className="desktop">
        <div className="fields-area">
          {tabs}
          <div className="row category-group">
            <Radio
              field={PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY}
              value={categoryFields[PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME]}
              setFieldValue={setCategoryFieldValue}
              values={categoryOptions}
            />
          </div>
          <div className="flip-horizontal-fields-area">
            {profilerAccessStore.isProfilerDemo && (
              <div className="profiler-demo-info-message">
                <div className="img-wrap">
                  <img src={ICON_BUTTON_ICONS.EXCLAMATAION_CIRCLE} />
                </div>
                <div className="text">
                  You have {profilerAccessStore.demoSearchRemainingCount} out of{' '}
                  {profilerAccessStore.demoSearchMaxCount} demo searches remaining - upgrade to Full
                  access to unlock unlimited searches.
                </div>
              </div>
            )}
            <div>
              <Input
                field={{
                  NAME: 'companyName',
                  ID: 'profiler_company_name',
                  LABEL: 'Company name and legal form',
                  PLACEHOLDER: 'Type a company name here',
                  REQUIRED: true,
                  TYPE: FIELDS_TYPES.TYPE_TEXT
                }}
                disabled
                inputWrap={{ enable: true, className: 'fade-in-search-row' }}
              />
            </div>
            <div className="row">
              <div className="col"></div>
              <div className="col"></div>
              <div className="col company-name-search-btn-wrap">
                <button
                  type="submit"
                  className="btn btn-primary disable-fade-primary"
                  disabled={true}>
                  Search company info
                </button>
              </div>
            </div>
            <div className="fields-area-mandatory-row" style={{ marginTop: '164px' }}>
              *mandatory fields
            </div>
          </div>
        </div>
      </form>
    );
  }
);

export default ProfilerCompanyNameSearchFormDesktop;
