import { observer, useLocalObservable } from 'mobx-react';
import { useEffect } from 'react';
import {
  selectedProjectTabPageSize,
  SELECTED_PROJECT_TABS,
  encodeProjectsParams
} from '../constants';
import ProfilerSearchResultsTabEntry from './ProfilerSearchResultsTabEntry';
import Pagination from '../../../components/navigation/Pagination';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';
import { useStore } from '../../../store/store';
import { ICON_BUTTON_ICONS } from '../../../components/buttons/IconButton';

const ProfilerSearchResultsTabView = observer(
  ({
    project = {},
    results = [],
    forTab = SELECTED_PROJECT_TABS.RESULTS,
    params = {},
    excludeResult = () => {},
    isExcludingResult = false,
    displayDetails = () => {},
    isFromAgentView = false,
    fullResultsCount
  }) => {
    const { utilsStore, profilerAccessStore } = useStore();
    const { navigate } = useHistory();

    const state = useLocalObservable(() => ({
      forTab: forTab,
      setForTab: (value = '') => (state.forTab = value),
      get currentResults() {
        return state.forTab === SELECTED_PROJECT_TABS.RESULTS
          ? results.filter((r) => !r.isExcluded)
          : results.filter((r) => r.isExcluded);
      },
      get layout() {
        return utilsStore.windowWidth < 1201 ? 'mobile' : 'desktop';
      }
    }));

    useEffect(() => {
      state.setForTab(forTab);
    }, [state, forTab]);

    const showPagination = state.currentResults.length > selectedProjectTabPageSize;
    const pages = Math.ceil(state.currentResults.length / selectedProjectTabPageSize) || 1;
    const page = (params.selectedProjectTabPage || 1) - 1;

    return (
      <div className="table-responsive">
        {state.currentResults.length === 0 ? (
          <div className="no-data">No items for this tab</div>
        ) : (
          <>
            <div className="header profiler-search-results-tab-view-header at-project-results">
              <div className="item company-name-item ">Company name</div>
              <div className="item">Company HQ</div>
              <div className="item">Industry</div>
              <div className="item">Value chain</div>
              <div className="item">Products</div>
              <div className="item text-align-right-desktop revenue-item">Revenue (€M)</div>
              <div className="item text-align-right-desktop employees-item">Employees</div>
              <div className="item feedback-item-profiler">Feedback results</div>
              <div className="item actions-profiler" />
            </div>

            {state.currentResults
              .slice(
                (params.selectedProjectTabPage - 1) * selectedProjectTabPageSize,
                params.selectedProjectTabPage * selectedProjectTabPageSize
              )
              .map((entry, index) => (
                <ProfilerSearchResultsTabEntry
                  key={entry.id}
                  entry={entry}
                  project={project}
                  forTab={forTab}
                  excludeResult={excludeResult}
                  isExcludingResult={isExcludingResult}
                  displayDetails={displayDetails}
                  index={index}
                />
              ))}

            {profilerAccessStore.isProfilerDemo &&
              fullResultsCount > selectedProjectTabPageSize && (
                <div className="profiler-demo-limited-results-row">
                  <div className="icon-wrap">
                    <img src={ICON_BUTTON_ICONS.EXCLAMATAION_CIRCLE} />
                  </div>
                  <div className="content">
                    <div className="title">Limited results shown in demo mode</div>
                    <div className="text">
                      You&apos;re viewing {selectedProjectTabPageSize} of {fullResultsCount}{' '}
                      results. Contact{' '}
                      <a href="mailto:support@transact.digital">support@transact.digital</a> for a
                      subscription to see the full list.
                    </div>
                  </div>
                </div>
              )}

            <Pagination
              show={showPagination}
              onPageChange={(selected) => {
                navigate(
                  (isFromAgentView ? paths.PROJECTS : paths.PROFILER_PROJECTS) +
                    '?params=' +
                    encodeProjectsParams({ ...params, selectedProjectTabPage: selected + 1 })
                );
                setTimeout(() => {
                  const el = document.getElementById('project-view-scroll-starting-point');
                  const header = document.querySelector('header');
                  if (el) {
                    const headerHeight = header?.clientHeight || 0;
                    window.scrollTo({
                      top: el.offsetTop - headerHeight + (state.layout === 'desktop' ? 16 : 0),
                      behavior: 'smooth'
                    });
                  }
                }, 100);
              }}
              page={page}
              pages={pages}
            />
          </>
        )}
      </div>
    );
  }
);

export default ProfilerSearchResultsTabView;
