import { observer } from 'mobx-react';
import { SEARCH_CATEGORIES } from '../../../../utils/constants/searchCategories';
import { FUND_SEARCH_CATEGORY_FIELDS, SEARCH_FIELDS } from '../../../../utils/constants/fields';
import { Fragment } from 'react';
import MultiSelect from '../../../../components/inputs/MultiSelect';
import { useStore } from '../../../../store/store';

const FundsInvestmentCriteriaDesktopFilters = observer(
  ({
    fields,
    fieldsCount,
    searchSectionsToggle,
    toggleSearchSection,
    onSearch,
    isSearchDisabled,
    categoryFields,
    setCategoryFieldValue,
    categoryOptions,
    isLoading
  }) => {
    const { utilsStore } = useStore();
    if (utilsStore.windowWidth < 1201) {
      return null;
    }

    return (
      <div className="desktop-filters">
        <div className="fade-top" />
        <div className="fade-bottom" />
        <div className="scrollable-items">
          {fields[SEARCH_FIELDS.SEARCH_TYPE.ID]}
          <MultiSelect
            field={FUND_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE}
            value={categoryFields[FUND_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME]}
            setFieldValue={setCategoryFieldValue}
            values={categoryOptions}
            inputWrap={{ enable: true, className: 'col' }}
            disabled={isLoading}
          />
          {Object.values(SEARCH_CATEGORIES).map((section) => {
            const { filled, total } = fieldsCount[section];
            return (
              <div
                className="filter-category"
                key={section}
                id={`desktop-filter-category-${section}`}>
                <button onClick={() => toggleSearchSection(section)}>
                  <span>
                    {section} {filled}/{total}
                  </span>
                  <img
                    src="/icons/chevron-down.svg"
                    className={`${searchSectionsToggle[section] ? 'expanded' : 'collapsed'}`}
                  />
                </button>
                {searchSectionsToggle[section] && (
                  <div className="col-wrapper">
                    {Object.values(fields[section]).map((field, idx) => (
                      <Fragment key={idx}>{field}</Fragment>
                    ))}
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div id="desktop-filters-search-btn-wrap">
          <button
            className="btn btn-primary disable-fade-primary"
            onClick={(event) => onSearch(event) & window.scrollTo({ top: 0 })}
            disabled={isSearchDisabled}>
            Search
          </button>
        </div>
      </div>
    );
  }
);

export default FundsInvestmentCriteriaDesktopFilters;
