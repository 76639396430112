import { SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA } from '../../../../../utils/constants/fields';
import MultiSelect from '../../../../../components/inputs/MultiSelect';
import Input from '../../../../../components/inputs/Input';

export const getPortfoliosInvestmentCriteriaSearchFields = (state) => {
  return {
    [SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.INDUSTRY.ID]: (
      <MultiSelect
        field={SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.INDUSTRY}
        value={
          state.portfoliosInvestmentCriteriaFields[
            SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.INDUSTRY.NAME
          ]
        }
        setFieldValue={state.setPortfoliosInvestmentCriteriaFieldValue}
        inputWrap={{ enable: true, className: 'col' }}
      />
    ),
    [SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.HEADQUARTER.ID]: (
      <MultiSelect
        field={SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.HEADQUARTER}
        value={
          state.portfoliosInvestmentCriteriaFields[
            SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.HEADQUARTER.NAME
          ]
        }
        setFieldValue={state.setPortfoliosInvestmentCriteriaFieldValue}
        withSingleClear
        inputWrap={{ enable: true, className: 'col' }}
      />
    ),
    [SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.GEOGRAPHICAL_FOCUS.ID]: (
      <MultiSelect
        field={SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.GEOGRAPHICAL_FOCUS}
        value={
          state.portfoliosInvestmentCriteriaFields[
            SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.GEOGRAPHICAL_FOCUS.NAME
          ]
        }
        setFieldValue={state.setPortfoliosInvestmentCriteriaFieldValue}
        inputWrap={{ enable: true, className: 'col' }}
      />
    ),

    [SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.REVENUE.ID]: (
      <Input
        field={SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.REVENUE}
        value={
          state.portfoliosInvestmentCriteriaFields[
            SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.REVENUE.NAME
          ]
        }
        setFieldValue={state.setPortfoliosInvestmentCriteriaFieldValue}
        inputWrap={{ enable: true, className: 'col' }}
      />
    )
  };
};
