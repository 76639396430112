import { Store } from '../store'; // eslint-disable-line no-unused-vars
import { makeAutoObservable, runInAction } from 'mobx';
import { API_ENDPOINTS } from '../../api/endpoints';

export const WSS_EVENTS = {
  FUNDS_STORE: {
    INVESTOR_FUND: 'fund',
    INVESTOR_PORTFOLIO: 'portfolio',
    INVESTOR_PROFILE: 'invest-profile'
  },
  PROJECTS_STORE: {
    PROJECT: 'project',
    PROJECT_OWNER_LAST_CHECKED_DATE_UPDATE: 'project-olcd-update'
  },
  PROFILER_PROJECTS_STORE: {
    PROFILER_PROJECT: 'profiler-project',
    ROFILER_PROJECT_OWNER_LAST_CHECKED_DATE_UPDATE: 'profiler-project-olcd-update',
    PROFILER_WATCHLIST_COMPANY: 'company-watchlist',
    PROFILER_WATCHLIST_NEWS_UPDATE: 'company-watchlist-news-update'
  },
  OUTREACH_STORE: {
    OUTREACH: 'outreach',
    OUTREACH_MESSAGE: 'chat',
    OUTREACH_LAST_CHECKED_MESSAGE_UPDATE: 'chat-lcm-update',
    OUTREACH_ARCHIVE_UPDATE: 'chat-archive-update',
    OUTREACH_UNREAD_UPDATE: 'chat-unread-update'
  }
};

export const WSS_ACTIONS = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete'
};

class WebSocketStore {
  /**
   * @param {Store} root
   */
  constructor(root) {
    makeAutoObservable(this);
    this.root = root;
    this.init();
    setInterval(this.init, 5_000);
    this.pingSocket();
    setInterval(this.pingSocket, 120_000); // someone calculate the cost per user per 8 hours a day to ping on every 5 seconds and change it if plausible, otherwise disconnect event will be fired as late as (2 minutes + 119 seconds) and might end up bugging the FE client https://chatgpt.com/share/6762affa-d428-8002-bbb7-b1d14ccea6ca
  }

  initialConect = false;
  initialDisconnect = false;
  reset = () => {
    this._onSocketClose();
    this.initialConect = false;
    this.initialDisconnect = false;
  };

  pingSocket = () => {
    if (this.ws?.readyState === WebSocket.OPEN) {
      this.ws.send(JSON.stringify({ ping: 'pong' }));
    }
  };

  ws = null;
  connected = false;
  isConnecting = false;

  loadReconectedData = () => {
    // user has been disconnected before that
    this.root.authStore.loadCompanyUsers();
    this.root.fundsStore.loadCompanyFunds();
    this.root.fundsStore.loadFunds();
    this.root.projectStore.loadProjects();
    this.root.profilerProjectStore.loadProjects();
    this.root.profilerProjectStore.loadWatchlist();
    this.root.outreachStore.loadOutreaches();
  };

  _onSocketOpen = () => {
    if (!this.connected) {
      this.loadReconectedData();
    }
    this.connected = true;
    this.isConnecting = false;
    setTimeout(() => {
      if (!this.initialConect) {
        runInAction(() => {
          this.initialConect = true;
        });
      }
    }, 100);
  };

  _onSocketMessage = () => {
    try {
      const jsData = JSON.parse(event.data);
      if (jsData?.message !== 'default') {
        console.log('ws event.data = ', jsData);
      }

      if (Array.isArray(jsData)) {
        jsData.forEach((dataEntry) => {
          const dataType = dataEntry?.for;
          if (Object.values(WSS_EVENTS.FUNDS_STORE).includes(dataType)) {
            this.root.fundsStore._wss_onData(dataEntry);
          } else if (Object.values(WSS_EVENTS.PROJECTS_STORE).includes(dataType)) {
            this.root.projectStore._wss_onData(dataEntry);
          } else if (Object.values(WSS_EVENTS.PROFILER_PROJECTS_STORE).includes(dataType)) {
            this.root.profilerProjectStore._wss_onData(dataEntry);
          } else if (Object.values(WSS_EVENTS.OUTREACH_STORE).includes(dataType)) {
            this.root.outreachStore._wss_onData(dataEntry);
          }
        });
      }
    } catch (ex) {
      console.error(ex);
    }
  };

  _initSocket = (wssToken) => {
    const userId = this.root.authStore.user.idToken.payload['cognito:username'];

    const ws = new WebSocket(
      `${process.env.REACT_APP_WSS_URL}?userId=${userId}&wssToken=${wssToken}`
    );

    ws.addEventListener('open', this._onSocketOpen);
    ws.addEventListener('message', this._onSocketMessage);
    ws.addEventListener('close', this._onSocketClose);
    ws.addEventListener('error', this._onSocketClose);

    this.ws = ws;
  };

  init = (wssToken = '') => {
    if (
      !this.root.authStore.user ||
      !this.root.authStore.userInfo ||
      this.isConnecting ||
      this.connected
    ) {
      return;
    }

    this.isConnecting = true;
    if (!wssToken) {
      this.root.makeRequest({
        endpoint: API_ENDPOINTS.GET_WEBSOCKET_TOKEN,
        onSuccess: ({ token: wssToken }) => {
          this._initSocket(wssToken);
        },
        onError: () => {
          this._onSocketClose({});
        }
      });
    } else {
      this._initSocket(wssToken);
    }
  };

  _onSocketClose = (event) => {
    if (this.ws) {
      this.ws.removeEventListener('open', this._onSocketOpen);
      this.ws.removeEventListener('message', this._onSocketMessage);
      this.ws.removeEventListener('close', this._onSocketClose);
      this.ws.removeEventListener('error', this._onSocketClose);
      this.ws.close();
    }
    this.connected = false;
    this.isConnecting = false;
    this.ws = null;

    if (event) {
      setTimeout(() => {
        if (!this.initialDisconnect) {
          runInAction(() => {
            this.initialDisconnect = true;
          });
        }
      }, 100);
    }
  };
}

export default WebSocketStore;
