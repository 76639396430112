import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { useEffect } from 'react';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import GeneralError from '../../../components/errors/GeneralError';
import { formatDateDDMMYYYY, formatNumberRange } from '../../../utils/utils';
import Pagination from '../../../components/navigation/Pagination';
import IconButton, { ICON_BUTTON_TYPES } from '../../../components/buttons/IconButton';
import MultiSelect from '../../../components/inputs/MultiSelect';
import { FIELDS_TYPES, FUND_FIELDS, COMPANY_PROFILE_FIELDS } from '../../../utils/constants/fields';
import { runInAction } from 'mobx';
import FundDetailsPopup from '../../../components/shared/FundDetailsPopup';
import PortfolioCoDetailsPopup from '../../../components/shared/PortfolioCoDetailsPopup';
import InvestProfileDetailsPopup from '../../../components/shared/InvestProfileDetailsPopup';

const InvestVehicles = observer(() => {
  const { adminDashboardStore, utilsStore } = useStore();
  const state = useLocalObservable(() => ({
    selectedEntryId: null,
    setSelectedEntryId: (entryId = '') => {
      state.selectedEntryId = entryId;
      utilsStore.lockScroll(state.selectedEntryId);
    },
    get selectedEntry() {
      return adminDashboardStore.investVehiclesList.find(
        (entry) => entry.id === state.selectedEntryId
      );
    },
    get layout() {
      return utilsStore.windowWidth < 1201 ? 'mobile' : 'desktop';
    },
    get filterTypeValues() {
      // BE decided not to use the same values as in the agent search
      return [
        { name: 'All', value: 'all' },
        { name: 'Fund', value: 'fund' },
        { name: 'Portfolio company', value: 'portfolioCompany' },
        { name: 'Invest profile', value: 'investProfile' }
      ];
    },
    get disableFilterInput() {
      return adminDashboardStore.isLoadingInvestVehicles;
    }
  }));

  useEffect(() => {
    return () => {
      if (state.selectedEntryId) {
        state.setSelectedEntryId(null);
      }
    };
  }, [state]);

  useEffect(() => {
    if (
      !adminDashboardStore.isLoadingInvestVehicles &&
      !adminDashboardStore.investVehiclesList.length &&
      !adminDashboardStore.loadInvestVehiclesError
    ) {
      adminDashboardStore.loadInvestVehiclesList(adminDashboardStore.investVehiclesPage);
    }
  }, [adminDashboardStore]);

  const showLoader = adminDashboardStore.isLoadingInvestVehicles;
  const showError = !!adminDashboardStore.loadInvestVehiclesError;
  const showResults = !!adminDashboardStore.investVehiclesList.length;
  const showPaging = adminDashboardStore.investVehiclesMaxPage > 1;
  const showNoResults =
    !adminDashboardStore.isLoadingInvestVehicles &&
    !adminDashboardStore.loadInvestVehiclesError &&
    !adminDashboardStore.investVehiclesList.length;

  return (
    <>
      {!!state.selectedEntry &&
        (state.selectedEntry.entryType === 'fund' ? (
          <FundDetailsPopup
            contact={state.selectedEntry.contact}
            fund={state.selectedEntry}
            onClose={() => state.setSelectedEntryId(null)}
            withMobile1200
          />
        ) : state.selectedEntry.entryType === 'portfolioCompany' ? (
          <PortfolioCoDetailsPopup
            contact={state.selectedEntry.contact}
            portfolio={state.selectedEntry}
            onClose={() => state.setSelectedEntryId(null)}
            withMobile1200
          />
        ) : (
          <InvestProfileDetailsPopup
            contact={state.selectedEntry.contact}
            profile={state.selectedEntry}
            onClose={() => state.setSelectedEntryId(null)}
            withMobile1200
          />
        ))}
      <div className="filters-wrap-invest-vehicles">
        {/* <IconButton
          type={ICON_BUTTON_TYPES.DEFAULT}
          hoverType={ICON_BUTTON_TYPES.GREEN}
          icon={ICON_BUTTON_ICONS.ARROW_REPEAT}
          withBorder={false}
          innerText="Refresh"
          onClick={() => {
            adminDashboardStore.loadInvestVehiclesList(adminDashboardStore.investVehiclesPage);
          }}
          disabled={state.disableFilterInput}
        /> */}
        <MultiSelect
          field={{
            NAME: 'investVehiclesFilterType',
            ID: 'users_mgmt_filter_company_type',
            PLACEHOLDER: 'Select type',
            TYPE: FIELDS_TYPES.TYPE_SELECT
          }}
          values={state.filterTypeValues}
          value={[adminDashboardStore.investVehiclesFilterType]}
          setFieldValue={(_, value) => {
            runInAction(() => {
              adminDashboardStore.investVehiclesFilterType = value[0];
            });
            adminDashboardStore.loadInvestVehiclesList();
          }}
          disabled={state.disableFilterInput}
          inputWrap={{ enable: true, className: 'filter-type' }}
        />
      </div>
      {showLoader && <GeneralLoader />}
      {showError && (
        <GeneralError
          errorMessage={adminDashboardStore.loadInvestVehiclesError}
          actionMessage="You may want to:"
          actionButtontext="Try again"
          onActionButtonClick={() =>
            adminDashboardStore.loadInvestVehiclesList(adminDashboardStore.investVehiclesPage)
          }
        />
      )}
      {showResults && (
        <div className="results-table-vehicles">
          <div className="table-head desktop-only">
            <div className="head-item">Invest profile name</div>
            <div className="head-item">Invest profile type</div>
            <div className="head-item">Industry focus</div>
            <div className="head-item">Geo focus</div>
            <div className="head-item">Enterprise value (€M)</div>
            <div className="head-item">Equity value (€M)</div>
            <div className="head-item">Date created</div>
            <div className="head-item" />
          </div>
          {adminDashboardStore.investVehiclesList.map((entry) => {
            const displayType =
              {
                fund: 'Fund',
                portfolioCompany: 'Portfolio company',
                investProfile: 'Invest profile'
              }[entry.entryType] || 'N/A';

            const companyName =
              entry.companyName || entry.companyProfile?.[COMPANY_PROFILE_FIELDS.COMPANY_NAME.NAME];

            if (state.layout === 'mobile') {
              return (
                <div key={entry.id} className="entry-item-mobile">
                  <div className="row">
                    <div className="field">Invest profile name</div>
                    <div className="value text">{entry.name || companyName}</div>
                  </div>
                  <div className="row">
                    <div className="field">Invest profile type</div>
                    <div className="value text">{displayType}</div>
                  </div>
                  <div className="row">
                    <div className="field">Industry focus</div>
                    <div className="value text">
                      {utilsStore
                        .shortenList(utilsStore.getOptionName(entry, FUND_FIELDS.INDUSTRIES))
                        .join(', ')}
                    </div>
                  </div>
                  <div className="row">
                    <div className="field">Geo focus</div>
                    <div className="value text">
                      {utilsStore
                        .shortenList(utilsStore.getOptionName(entry, FUND_FIELDS.GEOGRAPHIES))
                        .join(', ')}
                    </div>
                  </div>
                  <div className="row">
                    <div className="field">Enterprise value (€M)</div>
                    <div className="value text">
                      {formatNumberRange(
                        entry[FUND_FIELDS.ENTERPRISE_RANGE_MIN.NAME],
                        entry[FUND_FIELDS.ENTERPRISE_RANGE_MAX.NAME]
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="field">Equity value (€M)</div>
                    <div className="value text">
                      {formatNumberRange(
                        entry[FUND_FIELDS.EQUITY_RANGE_MIN.NAME],
                        entry[FUND_FIELDS.EQUITY_RANGE_MAX.NAME]
                      )}
                    </div>
                  </div>
                  <div className="row last">
                    <div className="field">Date created</div>
                    <div className="value text">
                      {entry.createdAt ? formatDateDDMMYYYY(entry.createdAt) : 'N/A'}
                    </div>
                  </div>
                  <IconButton
                    type={ICON_BUTTON_TYPES.BLUE}
                    filled
                    innerText="View details"
                    wrapClassName="view-details-mobile"
                    onClick={() => state.setSelectedEntryId(entry.id)}
                  />
                </div>
              );
            }

            return (
              <div key={entry.id} className="entry-item">
                <div className="cell text">{entry.name || companyName}</div>
                <div className="cell text">{displayType}</div>
                <div className="cell text">
                  {utilsStore
                    .shortenList(utilsStore.getOptionName(entry, FUND_FIELDS.INDUSTRIES))
                    .join(', ')}
                </div>
                <div className="cell text">
                  {utilsStore
                    .shortenList(utilsStore.getOptionName(entry, FUND_FIELDS.GEOGRAPHIES))
                    .join(', ')}
                </div>
                <div className="cell text">
                  {formatNumberRange(
                    entry[FUND_FIELDS.ENTERPRISE_RANGE_MIN.NAME],
                    entry[FUND_FIELDS.ENTERPRISE_RANGE_MAX.NAME]
                  )}
                </div>
                <div className="cell text">
                  {formatNumberRange(
                    entry[FUND_FIELDS.EQUITY_RANGE_MIN.NAME],
                    entry[FUND_FIELDS.EQUITY_RANGE_MAX.NAME]
                  )}
                </div>
                <div className="cell text">
                  {entry.createdAt ? formatDateDDMMYYYY(entry.createdAt) : 'N/A'}
                </div>
                <div className="cell">
                  <IconButton
                    type={ICON_BUTTON_TYPES.BLUE}
                    filled
                    innerText="View details"
                    onClick={() => state.setSelectedEntryId(entry.id)}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      {showNoResults && <div className="no-results">No results found.</div>}
      <Pagination
        show={showPaging}
        onPageChange={(selected) => {
          adminDashboardStore.loadInvestVehiclesList(selected + 1);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        page={adminDashboardStore.investVehiclesPage - 1}
        pages={adminDashboardStore.investVehiclesMaxPage}
      />
    </>
  );
});

export default InvestVehicles;
