import { observer } from 'mobx-react';
import { useStore } from '../../store/store';
import { useEffect } from 'react';
import MobileAppRefresher from '../loaders/MobileAppRefresher';

const StaticPageLayout = observer(
  ({ children, page, hideMobileFooter, component, disableScrollReverse }) => {
    const { utilsStore } = useStore();

    useEffect(() => {
      utilsStore.setIsMobileFooterHidden(
        utilsStore.isMobileHamburgerOpen ? false : hideMobileFooter || false
      );
      return () => {
        utilsStore.setIsMobileFooterHidden(false);
      };
    }, [hideMobileFooter, utilsStore.isMobileHamburgerOpen]);

    useEffect(() => {
      if (disableScrollReverse) {
        return;
      }

      window.scrollTo({ top: 0 });
    }, []);

    const classNames = ['app-body', 'static-container'];
    if (utilsStore.isMobileHamburgerOpen) {
      classNames.push('hidden');
    }
    classNames.push(`page-${page}`);

    if (component) {
      classNames.push(`component-${component}`);
    }

    return (
      <div className={classNames.join(' ')}>
        <MobileAppRefresher />
        {children}
      </div>
    );
  }
);

export default StaticPageLayout;
