import React from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import SharedProjectEntry from './SharedProjectEntry';
import { SHARED_PROJECT_TYPE_TABS } from './constants';
import { useStore } from '../../../store/store';
import { UI_OPTIONS } from '../../../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../../../utils/constants/uiOptionKeys';
import ProfilerSharedProjectEntry from '../../ProfilerTeam/desktop/ProfilerSharedProjectEntry';

const SharedProjectsList = observer(({ projectList, params }) => {
  const { utilsStore } = useStore();
  const state = useLocalObservable(() => ({
    get layout() {
      return utilsStore.windowWidth < 1201 ? 'mobile' : 'desktop';
    }
  }));

  if (state.layout === 'mobile') {
    if (!projectList.length) {
      return (
        <div className="empty-feedback-mobile">
          <div className="empty-feedback-title">Your feedback starts here!</div>
          <div className="empty-feedback-row">
            Review search outputs of your colleagues on company and invest profiles.
          </div>
          <div className="empty-feedback-row">
            How it works:
            <ul>
              <li>Colleagues can share search results with the team for feedback</li>
              <li>
                Selected team members will receive a request here to leave comments and suggestions
              </li>
            </ul>
          </div>
          <div className="empty-feedback-footer">
            Need help collaborating? Contact us at{' '}
            <a href="mailto:support@transact.digital">support@transact.digital</a>.
          </div>
        </div>
      );
    } else {
      return projectList.map((project) =>
        project.searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler ? (
          <ProfilerSharedProjectEntry
            key={project.id}
            project={project}
            params={params}
            isFromAgentView
          />
        ) : (
          <SharedProjectEntry key={project.id} project={project} params={params} />
        )
      );
    }
  }

  return (
    <div className="table-responsive">
      {projectList.length === 0 ? (
        <div className="empty-feedback-desktop-wrap">
          <div className="empty-feedback-desktop">
            <div className="empty-feedback-title">Your feedback starts here!</div>
            <div className="empty-feedback-row">
              Review search outputs of your colleagues on company and invest profiles.
            </div>
            <div className="empty-feedback-row">
              How it works:
              <ul>
                <li>Colleagues can share search results with the team for feedback</li>
                <li>
                  Selected team members will receive a request here to leave comments and
                  suggestions
                </li>
              </ul>
            </div>
            <div className="empty-feedback-footer">
              Need help collaborating? Contact us at{' '}
              <a href="mailto:support@transact.digital">support@transact.digital</a>.
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="header">
            <div className="item">Sender name</div>
            <div className="item">Project name</div>
            <div className="item">Profile</div>
            <div className="item">Industries</div>
            <div className="item">Results</div>
            <div className="item">
              {params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.PENDING
                ? 'Date received'
                : 'Date resolved'}
            </div>
          </div>
          {projectList.map((project) =>
            project.searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler ? (
              <ProfilerSharedProjectEntry
                key={project.id}
                project={project}
                params={params}
                isFromAgentView
              />
            ) : (
              <SharedProjectEntry key={project.id} project={project} params={params} />
            )
          )}
        </>
      )}
    </div>
  );
});

export default SharedProjectsList;
