import { observer } from 'mobx-react';
import { useStore } from '../../../store/store';
import StaticPageLayout from '../../../components/layouts/StaticPageLayout';
import OnboardingSteps from './investor/OnboardingSteps';
import { paths } from '../../../utils/constants/routes';
import useHistory from '../../../hooks/useHistory';
import { Tooltip } from 'react-tooltip';
// import Funds from './investor/Funds';
// import PortfolioCompanies from './investor/PortfolioCompanies';
// import GeneralError from '../../../components/errors/GeneralError';
// import { useEffect } from 'react';
// import { eventPageviewStartPage } from '../../../ga4/ga4';
// import InvestProfiles from './investor/InvestProfiles';

const InvestorView = observer(() => {
  const { authStore, profilerAccessStore } = useStore();
  const { navigate } = useHistory();

  return (
    <StaticPageLayout page="investor-default">
      <div className="investor-cover">
        <h1>Find new investment opportunities</h1>
      </div>
      <div className="investor-container">
        <div className="onboarding-container">
          <h2
            className={
              !profilerAccessStore.canUseProfilerSearch &&
              !profilerAccessStore.canViewProfilerWatchlist
                ? 'gray-out'
                : ''
            }>
            Deal sourcing
          </h2>
          <div className="cards-container">
            <div
              className={`card${!profilerAccessStore.canUseProfilerSearch ? ' gray-out' : ''}`}
              id="hiv-cp-btn"
              onClick={() => profilerAccessStore.canUseProfilerSearch && navigate(paths.PROFILER)}>
              <div className="heading">
                <div className="icon-onboarding profiler"></div>
              </div>
              <div className="body">
                <h6 className="no-margin-bottom">Company profiler</h6>
              </div>
            </div>
            {!profilerAccessStore.canUseProfilerSearch && (
              <Tooltip
                anchorSelect="#hiv-cp-btn"
                multiline={true}
                variant="light"
                place="bottom-center"
                opacity={1}
                noArrow
                {...{
                  children: (
                    <div className="home-invesstor-profiler-disabled-tooltip">
                      Need access to corporate company profiles? Contact{' '}
                      <a href="mailto:support@transact.digital">support@transact.digital</a> for
                      assistance.
                    </div>
                  )
                }}
                clickable
              />
            )}

            <div
              className={`card${!profilerAccessStore.canUseProfilerSearch ? ' gray-out' : ''}`}
              id="hiv-sp-btn"
              onClick={() =>
                profilerAccessStore.canUseProfilerSearch && navigate(paths.PROFILER_PROJECTS)
              }>
              <div className="heading">
                <div className="icon-onboarding profiler-projects"></div>
              </div>
              <div className="body">
                <h6 className="no-margin-bottom">Saved projects</h6>
              </div>
            </div>
            {!profilerAccessStore.canUseProfilerSearch && (
              <Tooltip
                anchorSelect="#hiv-sp-btn"
                multiline={true}
                variant="light"
                place="bottom-center"
                opacity={1}
                noArrow
                {...{
                  children: (
                    <div className="home-invesstor-profiler-disabled-tooltip">
                      Need access to corporate company profiles? Contact{' '}
                      <a href="mailto:support@transact.digital">support@transact.digital</a> for
                      assistance.
                    </div>
                  )
                }}
                clickable
              />
            )}

            <div
              className={`card${!profilerAccessStore.canViewProfilerWatchlist ? ' gray-out' : ''}`}
              id="hiv-wl-btn"
              onClick={() =>
                profilerAccessStore.canViewProfilerWatchlist && navigate(paths.PROFILER_WATCH_LIST)
              }>
              <div className="heading">
                <div className="icon-onboarding profiler-watch-list"></div>
              </div>
              <div className="body">
                <h6 className="no-margin-bottom">My watchlist</h6>
              </div>
            </div>
            {!profilerAccessStore.canViewProfilerWatchlist && (
              <Tooltip
                anchorSelect="#hiv-wl-btn"
                multiline={true}
                variant="light"
                place="bottom-center"
                opacity={1}
                noArrow
                {...{
                  children: (
                    <div className="home-invesstor-profiler-disabled-tooltip">
                      Need access to corporate company profiles? Contact{' '}
                      <a href="mailto:support@transact.digital">support@transact.digital</a> for
                      assistance.
                    </div>
                  )
                }}
                clickable
              />
            )}
          </div>
        </div>

        <div className="onboarding-container">
          <h2>My investment profiles</h2>
          <div className="cards-container">
            {authStore.isCoraporatesInvestor ? (
              <div className="card" onClick={() => navigate(paths.INVEST_PROFILES)}>
                <div className="heading">
                  <div className="icon-onboarding my-profiles"></div>
                </div>
                <div className="body">
                  <h6 className="no-margin-bottom">My invest profiles</h6>
                </div>
              </div>
            ) : (
              <>
                <div className="card" onClick={() => navigate(paths.FUNDS)}>
                  <div className="heading">
                    <div className="icon-onboarding my-funds"></div>
                  </div>
                  <div className="body">
                    <h6 className="no-margin-bottom">My funds</h6>
                  </div>
                </div>

                <div className="card" onClick={() => navigate(paths.PORTOFLIO_COMPANIES)}>
                  <div className="heading">
                    <div className="icon-onboarding my-portfolios"></div>
                  </div>
                  <div className="body">
                    <h6 className="no-margin-bottom">My portfolio companies</h6>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="onboarding-container">
          <OnboardingSteps />
        </div>
      </div>
    </StaticPageLayout>
  );
});

// const InvestorView = observer(() => {
//   const { fundsStore, authStore } = useStore();

//   useEffect(() => {
//     if (!fundsStore.isLoadingData) {
//       eventPageviewStartPage(fundsStore.funds.length, fundsStore.portfolios.length);
//     }
//   }, [fundsStore.isLoadingData]);

//   const showLoadError =
//     fundsStore.loadDataError &&
//     ((authStore.isCoraporatesInvestor && !fundsStore.allInvestProfiles.length) ||
//       (authStore.isCoraporatesInvestor &&
//         !fundsStore.allFunds.length &&
//         !fundsStore.allPortfolios.length));
//   const showContent = !showLoadError;

//   return (
//     <StaticPageLayout page="investor-default">
//       <div className="investor-cover">
//         <h1>Find new investment opportunities</h1>
//       </div>
//       <div className="investor-container">
//         {showLoadError && (
//           <GeneralError
//             errorMessage={fundsStore.loadDataError}
//             actionMessage={'You may want to:'}
//             actionButtontext={'Try again'}
//             onActionButtonClick={fundsStore.loadFunds}
//           />
//         )}
//         {showContent && (
//           <>
//             {authStore.isCoraporatesInvestor ? (
//               <div className="funds-container">
//                 <InvestProfiles />
//               </div>
//             ) : (
//               <>
//                 <div className="funds-container">
//                   <Funds />
//                 </div>
//                 <div className="funds-container">
//                   <PortfolioCompanies />
//                 </div>
//               </>
//             )}
//           </>
//         )}
//         <div className="onboarding-container">
//           <OnboardingSteps />
//         </div>
//       </div>
//     </StaticPageLayout>
//   );
// });

export default InvestorView;
