import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import {
  FUND_FIELDS,
  PORTFOLIO_FIELDS,
  INVEST_PROFILE_FIELDS
} from '../../../utils/constants/fields';
import { formatDateDDMMYYYY, formatNumberRange } from '../../../utils/utils';
import { useEffect } from 'react';
import FundDetailsPopup from '../../../components/shared/FundDetailsPopup';
import { INV_TYPE_TABS } from '../constants';
import PortfolioCoDetailsPopup from '../../../components/shared/PortfolioCoDetailsPopup';
import InvestProfileDetailsPopup from '../../../components/shared/InvestProfileDetailsPopup';
import IconButton, { ICON_BUTTON_TYPES } from '../../../components/buttons/IconButton';

const AllInvestmentsListEntry = observer(({ entry = {}, type = INV_TYPE_TABS.FUNDS }) => {
  const { utilsStore, authStore, fundsStore } = useStore();
  const state = useLocalObservable(() => ({
    isInfoPopupOpen: false,
    toggleInfoPopup: () => {
      state.isInfoPopupOpen = !state.isInfoPopupOpen;
      utilsStore.lockScroll(state.isInfoPopupOpen);
    }
  }));

  useEffect(() => {
    return () => {
      if (state.isInfoPopupOpen) {
        state.toggleInfoPopup();
      }
    };
  }, [entry.id, state]);

  const fields = {
    [INV_TYPE_TABS.FUNDS]: FUND_FIELDS,
    [INV_TYPE_TABS.PORTFOLIOS]: PORTFOLIO_FIELDS,
    [INV_TYPE_TABS.INVEST_PROFILES]: INVEST_PROFILE_FIELDS
  }[type];

  const owner = authStore.companyUsers.find((u) => u.id === entry.ownerId);
  const updatedAt = entry['updatedAt'] || entry['createdAt'];

  return (
    <>
      {state.isInfoPopupOpen &&
        (type === INV_TYPE_TABS.FUNDS ? (
          <FundDetailsPopup fund={entry} onClose={state.toggleInfoPopup} />
        ) : type === INV_TYPE_TABS.PORTFOLIOS ? (
          <PortfolioCoDetailsPopup
            portfolio={entry}
            onClose={state.toggleInfoPopup}
            withAffilatedFundLoader
            isLoadingAffilatedFund={fundsStore.isLoadingCompanyFunds}
          />
        ) : (
          <InvestProfileDetailsPopup profile={entry} onClose={state.toggleInfoPopup} />
        ))}
      <div className="row">
        <div className="item strong">
          <div className="label">
            {type === INV_TYPE_TABS.FUNDS
              ? 'Fund name'
              : type === INV_TYPE_TABS.PORTFOLIOS
              ? 'Portfolio name'
              : 'Invest name'}
          </div>
          <div className="value fund-name">{entry[fields.NAME.NAME]}</div>
        </div>

        <div className="item">
          <div className="label">Industry focus</div>
          <div className="value">
            {utilsStore
              .shortenList(utilsStore.getOptionName(entry, fields.INDUSTRIES), 3)
              .join(', ')}
          </div>
        </div>

        <div className="item">
          <div className="label">Geo focus</div>
          <div className="value">
            {utilsStore
              .shortenList(utilsStore.getOptionName(entry, fields.GEOGRAPHIES), 3)
              .join(', ')}
          </div>
        </div>

        <div className="item">
          <div className="label">Enterprise value</div>
          <div className="value">
            {formatNumberRange(
              entry[fields.ENTERPRISE_RANGE_MIN.NAME],
              entry[fields.ENTERPRISE_RANGE_MAX.NAME]
            )}
          </div>
        </div>

        <div className="item">
          <div className="label">Equity value</div>
          <div className="value">
            {formatNumberRange(
              entry[fields.EQUITY_RANGE_MIN.NAME],
              entry[fields.EQUITY_RANGE_MAX.NAME]
            )}
          </div>
        </div>

        <div className="item">
          <div className="label">Owned by</div>
          <div className="value">
            {owner
              ? `${owner.firstName} ${owner.lastName}`
              : authStore.isLoadingCompanyUsers
              ? 'Loading...'
              : 'unknown'}
          </div>
        </div>

        <div className="item">
          <div className="label">Latest change</div>
          <div className="value">{formatDateDDMMYYYY(updatedAt)}</div>
        </div>

        <div className="actions">
          <IconButton
            innerText="View Details"
            type={ICON_BUTTON_TYPES.BLUE}
            filled
            onClick={state.toggleInfoPopup}
            wrapClassName="action-button"
          />
        </div>
      </div>
    </>
  );
});

export default AllInvestmentsListEntry;
