import { observer } from 'mobx-react';
import * as Portal from '@radix-ui/react-portal';
import { useStore } from '../../../store/store';
import IconButton, {
  ICON_BUTTON_ICONS,
  ICON_BUTTON_TYPES
} from '../../../components/buttons/IconButton';

const ProfilerDemoReExecutePopup = observer(({ toggle = () => {}, onReExecute = () => {} }) => {
  const { profilerAccessStore } = useStore();

  const onReExecuteClick = () => {
    toggle();
    onReExecute();
  };

  return (
    <Portal.Root>
      <div className="reexecute-profiler-popup-wrap">
        <div className="reexecute-profiler-popup">
          <div className="title">
            Re-execute search{!profilerAccessStore.demoSearchExceededLimit >= 1 ? '?' : ''}
          </div>
          <div className="content">
            {!profilerAccessStore.demoSearchExceededLimit ? (
              <>
                <div className="exclamation-message">
                  <div className="icon-wrap">
                    <img src={ICON_BUTTON_ICONS.EXCLAMATAION_CIRCLE} />
                  </div>
                  <div className="text">
                    You have {profilerAccessStore.demoSearchRemainingCount} out of{' '}
                    {profilerAccessStore.demoSearchMaxCount} searches remaining.
                  </div>
                </div>
                This will count as 1 of your {profilerAccessStore.demoSearchMaxCount} demo searches.
                Once you&apos;ve reached your limit, you&apos;ll need to upgrade to continue. Are
                you sure you want to proceed?
              </>
            ) : (
              <>
                You have used all of your demo searches. Contact{' '}
                <a href="mailto:support@transact.digital">support@transact.digital</a> for an
                upgrade to continue.
              </>
            )}
          </div>
          <div className="actions">
            <IconButton
              onClick={toggle}
              innerText={!profilerAccessStore.demoSearchExceededLimit ? 'Cancel' : 'Close'}
              type={
                !profilerAccessStore.demoSearchExceededLimit
                  ? ICON_BUTTON_TYPES.DEFAULT
                  : ICON_BUTTON_TYPES.BLUE
              }
              filled={profilerAccessStore.demoSearchExceededLimit}
            />
            {!profilerAccessStore.demoSearchExceededLimit && (
              <IconButton
                onClick={onReExecuteClick}
                innerText="Confirm re-execution"
                filled
                type={ICON_BUTTON_TYPES.BLUE}
              />
            )}
          </div>
        </div>
      </div>
    </Portal.Root>
  );
});

export default ProfilerDemoReExecutePopup;
