import { observer, useLocalObservable } from 'mobx-react';
import { Link } from 'react-router-dom';
import Input from '../../../../components/inputs/Input';
import {
  PROJECT_FIELDS,
  getErrorFields,
  trimStateFields
} from '../../../../utils/constants/fields';
import { paths } from '../../../../utils/constants/routes';
import { useRef, useEffect } from 'react';
import { mapFieldsToState } from '../../../../utils/utils';
import GeneralLoader from '../../../../components/loaders/GeneralLoader';
import MultiSelect from '../../../../components/inputs/MultiSelect';

const SaveSearchPopup = observer(({ toggleSaveProjectPopup, onSaveSearch }) => {
  const popupRef = useRef(null);
  const state = useLocalObservable(() => ({
    isRendered: false,
    setIsRendered: (value = false) => (state.isRendered = value),
    fields: mapFieldsToState(PROJECT_FIELDS),
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    onSubmitErrorState: false,
    setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
    get validationFields() {
      return getErrorFields([PROJECT_FIELDS.NAME, PROJECT_FIELDS.SEARCH_REASON], state.fields);
    },
    get isSendDisabled() {
      return (
        state.isSaving ||
        state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
        (state.onSubmitErrorState && state.validationFields.invalidFields.length)
      );
    },
    isSaving: false,
    displaySuccess: false,
    messageError: null,
    onSave: () => {
      trimStateFields(state.fields);
      if (state.validationFields.invalidFields.length) {
        if (!state.onSubmitErrorState) {
          state.setOnSubmitErrorState(true);
        }
        return;
      }

      const searchName = state.fields[PROJECT_FIELDS.NAME.NAME];
      const searchReason = state.fields[PROJECT_FIELDS.SEARCH_REASON.NAME][0];

      state.isSaving = true;
      state.messageError = null;
      onSaveSearch(
        searchName,
        searchReason,
        () => {
          if (!state.isRendered) {
            return;
          }
          state.displaySuccess = true;
        },
        (errorMessage) => {
          if (!state.isRendered) {
            return;
          }
          state.messageError = errorMessage || 'Failed to save project';
        },
        () => {
          if (!state.isRendered) {
            return;
          }
          state.isSaving = false;
        }
      );
    },
    overlayHeight: window.visualViewport.height + 2000,
    updateOverlayHeight: () => (state.overlayHeight = window.visualViewport.height + 2000)
  }));

  useEffect(() => {
    state.setIsRendered(true);
    const onViewportResize = () => {
      state.updateOverlayHeight();
    };
    window.visualViewport.addEventListener('resize', onViewportResize);
    return () => {
      state.setIsRendered(false);
      window.visualViewport.removeEventListener('resize', onViewportResize);
    };
  }, [state]);

  useEffect(() => {
    const clickEvent = (e) => {
      if (
        popupRef?.current &&
        !['save-reach-btn', 'save-reach-btn-mobile'].includes(e.target?.id) &&
        !popupRef.current.contains(e.target)
      ) {
        toggleSaveProjectPopup(false);
      }
    };
    document.addEventListener('click', clickEvent, { capture: true });
    return () => {
      document.removeEventListener('click', clickEvent, { capture: true });
    };
  }, []);

  return (
    <div className="overlay" style={{ height: state.overlayHeight + 'px' }}>
      <div className="container popup-save-search" ref={popupRef}>
        {state.isSaving ? (
          <>
            <div className="header">
              <h5> </h5>
              <div className="close" onClick={() => toggleSaveProjectPopup(null)}>
                Close
              </div>
            </div>
            <GeneralLoader />
          </>
        ) : state.displaySuccess ? (
          <>
            <div className="header message">
              <div className="success-text">
                <img src="/icons/check-circle-blue.svg" alt="Success" />
                <h4>You have successfully saved this search.</h4>
                <p>Review related projects or manage your saved searches.</p>
              </div>
              <div className="close" onClick={() => toggleSaveProjectPopup(null)}>
                Close
              </div>
            </div>

            <div className="success-actions">
              <Link to={paths.PROJECTS} className="btn btn-primary btn-short">
                Go to saved projects
              </Link>
            </div>
          </>
        ) : (
          <>
            <div className="header">
              <h5>You are about to save this search</h5>
              <div className="close" onClick={() => toggleSaveProjectPopup(null)}>
                Close
              </div>
            </div>

            <p>Please type in a project name to save your results.</p>

            <div className="input-row-halves">
              <MultiSelect
                field={PROJECT_FIELDS.SEARCH_REASON}
                value={state.fields[PROJECT_FIELDS.SEARCH_REASON.NAME]}
                setFieldValue={state.setFieldValue}
                disabled={state.isSaving}
                hideLabel
              />
              <Input
                field={PROJECT_FIELDS.NAME}
                value={state.fields[PROJECT_FIELDS.NAME.NAME]}
                setFieldValue={state.setFieldValue}
                disabled={state.isSaving}
                messages={state.validationFields.messages}
                showOnSubmitErrorState={true}
                hideLabel
              />
            </div>

            <div className="actions">
              <button
                className="btn btn-primary btn-short disable-fade-primary"
                disabled={state.isSendDisabled}
                onClick={state.onSave}>
                Save search
              </button>
            </div>

            {!!state.messageError && <div className="errors">{state.messageError}</div>}
          </>
        )}
      </div>
    </div>
  );
});

export default SaveSearchPopup;
