import { observer, useLocalObservable } from 'mobx-react';
import { useEffect } from 'react';
import {
  selectedProjectTabPageSize,
  SELECTED_PROJECT_TABS,
  encodeProjectsParams
} from './constants';
import Pagination from '../../../components/navigation/Pagination';
import useHistory from '../../../hooks/useHistory';
import { paths } from '../../../utils/constants/routes';
import ProfilerFeedbackSearchResultsTabEntry from './ProfilerFeedbackSearchResultsTabEntry';
import { ICON_BUTTON_ICONS } from '../../../components/buttons/IconButton';
import { useStore } from '../../../store/store';

const ProfilerFeedbackSearchResultsTabView = observer(
  ({
    project = {},
    results = [],
    feedbacks = [],
    forTab = SELECTED_PROJECT_TABS.RESULTS,
    params = {},
    displayDetails = () => {},
    onChangeFeedback,
    fullResultsCount
  }) => {
    const { authStore, profilerAccessStore } = useStore();
    const { navigate } = useHistory();

    const state = useLocalObservable(() => ({
      forTab: forTab,
      setForTab: (value = '') => (state.forTab = value),
      get currentResults() {
        return state.forTab === SELECTED_PROJECT_TABS.RESULTS
          ? results.filter((r) => !r.isExcluded)
          : results.filter((r) => r.isExcluded);
      }
    }));

    useEffect(() => {
      state.setForTab(forTab);
    }, [state, forTab]);

    const showPagination = state.currentResults.length > selectedProjectTabPageSize;
    const pages = Math.ceil(state.currentResults.length / selectedProjectTabPageSize) || 1;

    const getEntryFeedbacks = (entryId) => {
      return feedbacks.filter((feedback) => feedback.resultId === entryId);
    };

    const page = (params.selectedProjectTabPage || 1) - 1;

    return (
      <div className="table-responsive">
        {state.currentResults.length === 0 ? (
          <div className="no-data">No items for this tab</div>
        ) : (
          <>
            <div className="header profiler-search-results-tab-view-header">
              <div className="item company-name-item">Company name</div>
              <div className="item">Company HQ</div>
              <div className="item">Industry</div>
              <div className="item">Value chain</div>
              <div className="item">Products</div>
              <div className="item text-align-right-desktop revenue-item">Revenue (€M)</div>
              <div className="item text-align-right-desktop employees-item">Employees</div>
              <div className="item feedback-item-profiler">Feedback</div>
              <div className="item actions-profiler" />
            </div>

            {state.currentResults
              .slice(
                (params.selectedProjectTabPage - 1) * selectedProjectTabPageSize,
                params.selectedProjectTabPage * selectedProjectTabPageSize
              )
              .map((entry) => (
                <ProfilerFeedbackSearchResultsTabEntry
                  key={entry.id}
                  entry={entry}
                  project={project}
                  feedbacks={getEntryFeedbacks(entry.id)}
                  displayDetails={displayDetails}
                  onChangeFeedback={onChangeFeedback}
                  params={params}
                />
              ))}

            {profilerAccessStore.isProfilerDemo &&
              !!authStore.companyUsers.find((u) => u.id === project.ownerId)
                ?.isCognitoProfilerDemo &&
              fullResultsCount > selectedProjectTabPageSize && (
                <div className="profiler-demo-limited-results-row">
                  <div className="icon-wrap">
                    <img src={ICON_BUTTON_ICONS.EXCLAMATAION_CIRCLE} />
                  </div>
                  <div className="content">
                    <div className="title">Limited results shown in demo mode</div>
                    <div className="text">
                      You&apos;re viewing {selectedProjectTabPageSize} of {fullResultsCount}{' '}
                      results. Contact{' '}
                      <a href="mailto:support@transact.digital">support@transact.digital</a> for a
                      subscription to see the full list.
                    </div>
                  </div>
                </div>
              )}

            <Pagination
              show={showPagination}
              onPageChange={(selected) => {
                navigate(
                  paths.PROFILER_TEAM +
                    '?params=' +
                    encodeProjectsParams({ ...params, selectedProjectTabPage: selected + 1 })
                );
                setTimeout(() => {
                  const el = document.getElementById('team-project-view-tab-1');
                  const header = document.querySelector('header');
                  if (el) {
                    const headerHeight = header?.clientHeight || 0;
                    window.scrollTo({ top: el.offsetTop - headerHeight, behavior: 'smooth' });
                  }
                }, 100);
              }}
              page={page}
              pages={pages}
            />
          </>
        )}
      </div>
    );
  }
);

export default ProfilerFeedbackSearchResultsTabView;
