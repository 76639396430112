import { observer } from 'mobx-react';
import {
  PORTFOLIO_SEARCH_CATEGORY_FIELDS,
  SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA
} from '../../../../utils/constants/fields';
import Radio from '../../../../components/inputs/Radio';
import { useStore } from '../../../../store/store';

const PortfoliosInvestmentCriteriaSearchFormDesktop = observer(
  ({
    fields,
    isSearchDisabled,
    onSearch,
    tabs,
    categoryFields,
    setCategoryFieldValue,
    categoryOptions
  }) => {
    const { utilsStore } = useStore();
    if (utilsStore.windowWidth < 1201) {
      return null;
    }

    return (
      <form onSubmit={onSearch} className="desktop">
        <div className="fields-area">
          {/* <div className="search-type-selector">{fields[SEARCH_FIELDS.SEARCH_TYPE.ID]}</div> */}
          {tabs}
          <div className="row category-group">
            <Radio
              field={PORTFOLIO_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY}
              value={categoryFields[PORTFOLIO_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME]}
              setFieldValue={setCategoryFieldValue}
              values={categoryOptions}
            />
          </div>
          <div className="flip-horizontal-fields-area">
            <div className="row">
              {fields[SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.INDUSTRY.ID]}
              {fields[SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.HEADQUARTER.ID]}
              {fields[SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.GEOGRAPHICAL_FOCUS.ID]}
            </div>
            <div className="row">
              {fields[SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.REVENUE.ID]}
              <div className="col" />
              <div className="col search-btn-wrap">
                <button
                  type="submit"
                  className="btn btn-primary disable-fade-primary"
                  disabled={isSearchDisabled}>
                  Search
                </button>
              </div>
            </div>
            <div className="fields-area-mandatory-row" style={{ marginTop: '168px' }}>
              *mandatory fields
            </div>
          </div>
        </div>
        {/* <div className="separator"></div> */}

        {/* <div className="submit-area">
        <button
          type="submit"
          className="btn btn-primary disable-fade-primary"
          disabled={isSearchDisabled}>
          Search
        </button>
      </div> */}
      </form>
    );
  }
);

export default PortfoliosInvestmentCriteriaSearchFormDesktop;
