import { observer } from 'mobx-react';
import {
  PROFILER_SEARCH_CATEGORY_FIELDS,
  PROFILER_SEARCH_FIELDS
} from '../../../../utils/constants/fields';
import Radio from '../../../../components/inputs/Radio';
import { PROFILER_SEARCH_CATEGORIES } from '../../../../utils/constants/searchCategories';
import { useStore } from '../../../../store/store';
import { ICON_BUTTON_ICONS } from '../../../../components/buttons/IconButton';

const ProfilerCompanyCriteriaSearchFormDesktop = observer(
  ({
    fields,
    isSearchDisabled,
    onSearch,
    tabs,
    categoryFields,
    setCategoryFieldValue,
    categoryOptions
  }) => {
    const { utilsStore, profilerAccessStore } = useStore();
    if (utilsStore.windowWidth < 1201) {
      return null;
    }

    return (
      <form onSubmit={onSearch} className="desktop">
        <div className="fields-area">
          {tabs}
          <div className="row category-group">
            <Radio
              field={PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY}
              value={categoryFields[PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME]}
              setFieldValue={setCategoryFieldValue}
              values={categoryOptions}
              disabled={profilerAccessStore.demoSearchExceededLimit}
            />
          </div>
          <div className="flip-horizontal-fields-area">
            {profilerAccessStore.isProfilerDemo && (
              <div
                className={`profiler-demo-info-message${
                  profilerAccessStore.demoSearchExceededLimit ? ' exceeded' : ''
                }`}>
                <div className="img-wrap">
                  <img src={ICON_BUTTON_ICONS.EXCLAMATAION_CIRCLE} />
                </div>
                <div className="text">
                  {profilerAccessStore.demoSearchExceededLimit ? (
                    <>
                      You&apos;ve used all {profilerAccessStore.demoSearchMaxCount} demo searches -
                      upgrade to Full access to continue exploring.
                    </>
                  ) : (
                    <>
                      You have {profilerAccessStore.demoSearchRemainingCount} out of{' '}
                      {profilerAccessStore.demoSearchMaxCount} demo searches remaining - upgrade to
                      Full access to unlock unlimited searches.
                    </>
                  )}
                </div>
              </div>
            )}

            <div className="row">
              {fields[PROFILER_SEARCH_CATEGORIES.INDUSTRY][PROFILER_SEARCH_FIELDS.INDUSTRY.ID]}
              {fields[PROFILER_SEARCH_CATEGORIES.INDUSTRY][PROFILER_SEARCH_FIELDS.VALUE_CHAIN.ID]}
              {
                fields[PROFILER_SEARCH_CATEGORIES.INDUSTRY][
                  PROFILER_SEARCH_FIELDS.PRODUCT_PORTFOLIO.ID
                ]
              }
            </div>
            <div className="row">
              {
                fields[PROFILER_SEARCH_CATEGORIES.FINANCIALS][
                  PROFILER_SEARCH_FIELDS.REVENUE_MIN.ID + PROFILER_SEARCH_FIELDS.REVENUE_MAX.ID
                ]
              }
              {
                fields[PROFILER_SEARCH_CATEGORIES.FINANCIALS][
                  PROFILER_SEARCH_FIELDS.EMPLOYEES_MIN.ID + PROFILER_SEARCH_FIELDS.EMPLOYEES_MAX.ID
                ]
              }
              {fields[PROFILER_SEARCH_CATEGORIES.FINANCIALS][PROFILER_SEARCH_FIELDS.HEADQUARTER.ID]}
            </div>
            <div className="row">
              <div className="col"></div>
              <div className="col"></div>
              <div className="col search-btn-wrap">
                <button
                  type="submit"
                  className="btn btn-primary disable-fade-primary"
                  disabled={isSearchDisabled}>
                  Search
                </button>
              </div>
            </div>
            <div className="fields-area-mandatory-row" style={{ marginTop: '94px' }}>
              *mandatory fields
            </div>
          </div>
        </div>
      </form>
    );
  }
);

export default ProfilerCompanyCriteriaSearchFormDesktop;
