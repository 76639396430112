import { observer, useLocalObservable } from 'mobx-react';
import {
  FUND_FIELDS,
  PORTFOLIO_FIELDS,
  INVEST_PROFILE_FIELDS
} from '../../../utils/constants/fields';
import { useStore } from '../../../store/store';
import { formatNumberRange } from '../../../utils/utils';
import IconButton, {
  ICON_BUTTON_TYPES,
  ICON_BUTTON_ICONS
} from '../../../components/buttons/IconButton';
import { SELECTED_PROJECT_TABS } from '../constants';
import FundDetailsPopup from '../../../components/shared/FundDetailsPopup';
import PortfolioCoDetailsPopup from '../../../components/shared/PortfolioCoDetailsPopup';
import InvestProfileDetailsPopup from '../../../components/shared/InvestProfileDetailsPopup';
import { useEffect } from 'react';
import ExistingOutreachPopup from '../../../components/shared/ExistingOutreachPopup';

const fieldsByType = {
  funds: FUND_FIELDS,
  portfolios: PORTFOLIO_FIELDS,
  investprofiles: INVEST_PROFILE_FIELDS
};

const SearchResultsTabEntry = observer(
  ({
    entry = {},
    project = {},
    type = 'funds',
    forTab = SELECTED_PROJECT_TABS.RESULTS,
    excludeResult = () => {},
    isExcludingResult = false,
    displayMessagePopup,
    setDesktopActionsWidth = () => {},
    index
  }) => {
    const { utilsStore, projectStore, authStore, outreachStore } = useStore();

    const state = useLocalObservable(() => ({
      isDetailsPopupOpen: false,
      toggleDetailsPopup: () => {
        state.isDetailsPopupOpen = !state.isDetailsPopupOpen;
        utilsStore.lockScroll(state.isDetailsPopupOpen);
      },
      existingOutreachPopupOpen: false,
      toggleExistingOutreachPopup: () => {
        state.existingOutreachPopupOpen = !state.existingOutreachPopupOpen;
        utilsStore.lockScroll(state.existingOutreachPopupOpen);
      }
    }));

    useEffect(() => {
      return () => {
        if (state.existingOutreachPopupOpen) {
          state.toggleExistingOutreachPopup();
        }
      };
    }, [state]);

    useEffect(() => {
      return () => {
        if (state.isDetailsPopupOpen) {
          state.toggleDetailsPopup();
        }
      };
    }, [state, entry.id]);

    const closeThePopUp = () => {
      if (state.isDetailsPopupOpen) {
        state.toggleDetailsPopup();
      }
    };

    const isFund = type === 'funds';
    const isPortfolio = type === 'portfolios';
    const isProfile = type === 'investprofiles';

    const fields = fieldsByType[type];

    const isLoadingOutreaches =
      outreachStore.isLoadingOutreaches || outreachStore.initialLoadOutreaches;

    const canSendOutreach = outreachStore.canSendOutreachToEntryId(project.id, entry.id);

    const disabledOutreach =
      !project.id ||
      projectStore.isLoadingProjects ||
      outreachStore.isSendingOutreach ||
      isLoadingOutreaches;

    const outreach = outreachStore.outreaches.find(
      (o) => o.projectId === project.id && o.objectData.id === entry.id
    );

    const usersUp = [
      ...new Set(
        project.feedbacks
          ?.filter((fb) => fb.resultId === entry.id && fb.feedback === 'up')
          .map((fb) => {
            const foundUser = authStore.companyUsers.find((u) => u.id === fb.userId);
            if (foundUser) {
              return (
                foundUser.firstName +
                ' ' +
                foundUser.lastName +
                (foundUser.jobPosition ? ` • ${foundUser.jobPosition}` : '')
              );
            }

            return 'unknown';
          })
      )
    ];
    const usersQuestion = [
      ...new Set(
        project.feedbacks
          ?.filter((fb) => fb.resultId === entry.id && fb.feedback === 'question')
          .map((fb) => {
            const foundUser = authStore.companyUsers.find((u) => u.id === fb.userId);
            if (foundUser) {
              return (
                foundUser.firstName +
                ' ' +
                foundUser.lastName +
                (foundUser.jobPosition ? ` • ${foundUser.jobPosition}` : '')
              );
            }

            return 'unknown';
          })
      )
    ];
    const usersDown = [
      ...new Set(
        project.feedbacks
          ?.filter((fb) => fb.resultId === entry.id && fb.feedback === 'down')
          .map((fb) => {
            const foundUser = authStore.companyUsers.find((u) => u.id === fb.userId);
            if (foundUser) {
              return (
                foundUser.firstName +
                ' ' +
                foundUser.lastName +
                (foundUser.jobPosition ? ` • ${foundUser.jobPosition}` : '')
              );
            }

            return 'unknown';
          })
      )
    ];

    return (
      <div className="row" style={index === 0 ? { marginTop: '8px' } : {}}>
        {state.existingOutreachPopupOpen && (
          <ExistingOutreachPopup onClose={state.toggleExistingOutreachPopup} outreach={outreach} />
        )}
        {state.isDetailsPopupOpen &&
          (isFund ? (
            <FundDetailsPopup fund={entry} onClose={closeThePopUp} />
          ) : isPortfolio ? (
            <PortfolioCoDetailsPopup portfolio={entry} onClose={closeThePopUp} />
          ) : (
            <InvestProfileDetailsPopup profile={entry} onClose={closeThePopUp} />
          ))}
        <div className="item feedback-item mobile-only">
          <IconButton
            type={ICON_BUTTON_TYPES.GREEN}
            icon={ICON_BUTTON_ICONS.HAND_THUMBS_UP}
            innerText={
              (project?.feedbacks?.filter?.(
                (fb) => fb.resultId === entry.id && fb.feedback === 'up'
              )?.length || 0) + ''
            }
            tooltipClassName="popup-feedback popup-feedback-up"
            tooltipClickable
            tooltipContent={
              usersUp.length ? (
                <div className="ppfp-scrollable-body">
                  {usersUp.map((username, idx) => {
                    return (
                      <div className="ppfp-item" key={idx}>
                        {username}
                      </div>
                    );
                  })}
                </div>
              ) : null
            }
            wrapClassName="feedback"
          />
          <IconButton
            type={ICON_BUTTON_TYPES.YELLOW}
            icon={ICON_BUTTON_ICONS.QUESTION_LG}
            innerText={
              (project?.feedbacks?.filter?.(
                (fb) => fb.resultId === entry.id && fb.feedback === 'question'
              )?.length || 0) + ''
            }
            tooltipClassName="popup-feedback popup-feedback-question"
            tooltipClickable
            tooltipContent={
              usersQuestion.length ? (
                <div className="ppfp-scrollable-body">
                  {usersQuestion.map((username, idx) => {
                    return (
                      <div className="ppfp-item" key={idx}>
                        {username}
                      </div>
                    );
                  })}
                </div>
              ) : null
            }
            wrapClassName="feedback"
          />
          <IconButton
            type={ICON_BUTTON_TYPES.RED}
            icon={ICON_BUTTON_ICONS.HAND_THUMBS_DOWN}
            innerText={
              (project?.feedbacks?.filter?.(
                (fb) => fb.resultId === entry.id && fb.feedback === 'down'
              )?.length || 0) + ''
            }
            tooltipClassName="popup-feedback popup-feedback-down"
            tooltipClickable
            tooltipContent={
              usersDown.length ? (
                <div className="ppfp-scrollable-body">
                  {usersDown.map((username, idx) => {
                    return (
                      <div className="ppfp-item" key={idx}>
                        {username}
                      </div>
                    );
                  })}
                </div>
              ) : null
            }
            wrapClassName="feedback"
          />
        </div>
        <div className="item">
          <div className="label">
            {isFund ? 'Fund name' : isPortfolio ? 'Portfolio name' : 'Profile name'}
          </div>
          <div className="value">{entry[fields.NAME.NAME] || entry.companyName}</div>
        </div>
        <div className="item">
          <div className="label">Industry focus</div>
          <div className="value">
            {utilsStore
              .shortenList(utilsStore.getOptionName(entry, fields.INDUSTRIES), 2)
              .join(', ')}
          </div>
        </div>
        <div className="item">
          <div className="label">Geo focus</div>
          <div className="value">
            {utilsStore
              .shortenList(utilsStore.getOptionName(entry, fields.GEOGRAPHIES), 3)
              .join(', ')}
          </div>
        </div>
        <div className="item">
          <div className="label">Enterprise value (€M)</div>
          <div className="value text-align-right-desktop">
            {formatNumberRange(
              entry[fields.ENTERPRISE_RANGE_MIN.NAME],
              entry[fields.ENTERPRISE_RANGE_MAX.NAME]
            )}
          </div>
        </div>
        <div className="item">
          <div className="label">Equity value (€M)</div>
          <div className="value text-align-right-desktop">
            {formatNumberRange(
              entry[fields.EQUITY_RANGE_MIN.NAME],
              entry[fields.EQUITY_RANGE_MAX.NAME]
            )}
          </div>
        </div>

        <div className="item feedback-item desktop-only">
          <IconButton
            type={ICON_BUTTON_TYPES.GREEN}
            icon={ICON_BUTTON_ICONS.HAND_THUMBS_UP}
            innerText={
              (project?.feedbacks?.filter?.(
                (fb) => fb.resultId === entry.id && fb.feedback === 'up'
              )?.length || 0) + ''
            }
            tooltipClassName="popup-feedback popup-feedback-up"
            tooltipClickable
            tooltipContent={
              usersUp.length ? (
                <div className="ppfp-scrollable-body">
                  {usersUp.map((username, idx) => {
                    return (
                      <div className="ppfp-item" key={idx}>
                        {username}
                      </div>
                    );
                  })}
                </div>
              ) : null
            }
            wrapClassName="feedback force-default-cursor"
          />
          <IconButton
            type={ICON_BUTTON_TYPES.YELLOW}
            icon={ICON_BUTTON_ICONS.QUESTION_LG}
            innerText={
              (project?.feedbacks?.filter?.(
                (fb) => fb.resultId === entry.id && fb.feedback === 'question'
              )?.length || 0) + ''
            }
            tooltipClassName="popup-feedback popup-feedback-question"
            tooltipClickable
            tooltipContent={
              usersQuestion.length ? (
                <div className="ppfp-scrollable-body">
                  {usersQuestion.map((username, idx) => {
                    return (
                      <div className="ppfp-item" key={idx}>
                        {username}
                      </div>
                    );
                  })}
                </div>
              ) : null
            }
            wrapClassName="feedback force-default-cursor"
          />
          <IconButton
            type={ICON_BUTTON_TYPES.RED}
            icon={ICON_BUTTON_ICONS.HAND_THUMBS_DOWN}
            innerText={
              (project?.feedbacks?.filter?.(
                (fb) => fb.resultId === entry.id && fb.feedback === 'down'
              )?.length || 0) + ''
            }
            tooltipClassName="popup-feedback popup-feedback-down"
            tooltipClickable
            tooltipContent={
              usersDown.length ? (
                <div className="ppfp-scrollable-body">
                  {usersDown.map((username, idx) => {
                    return (
                      <div className="ppfp-item" key={idx}>
                        {username}
                      </div>
                    );
                  })}
                </div>
              ) : null
            }
            wrapClassName="feedback force-default-cursor"
          />
        </div>
        <div
          className="actions desktop-only"
          ref={(el) => {
            if (el?.clientWidth) {
              setDesktopActionsWidth(el.clientWidth);
            }
          }}>
          <IconButton
            id="project-result-view-details-desktop"
            innerText="View details"
            onClick={state.toggleDetailsPopup}
            type={ICON_BUTTON_TYPES.BLUE}
            filled
          />
          <IconButton
            wrapClassName="project-results-outreach-button"
            type={ICON_BUTTON_TYPES.BLUE}
            icon={
              canSendOutreach ? ICON_BUTTON_ICONS.CHAT_RIGHT_TEXT : ICON_BUTTON_ICONS.ENVELOPE_CHECK
            }
            onClick={() => {
              if (canSendOutreach) {
                displayMessagePopup(entry);
              } else {
                if (outreach) {
                  state.toggleExistingOutreachPopup();
                }
              }
            }}
            disabled={disabledOutreach}
            tooltipText={
              isLoadingOutreaches
                ? 'Loading...'
                : canSendOutreach
                ? 'Outreach'
                : 'Outreach initiated'
            }
            filled={!canSendOutreach}
          />
          {forTab === SELECTED_PROJECT_TABS.RESULTS && (
            <IconButton
              wrapClassName="project-results-exclude-button"
              type={ICON_BUTTON_TYPES.RED}
              icon={ICON_BUTTON_ICONS.X_LG}
              tooltipText="Exclude result"
              disabled={isExcludingResult}
              onClick={() => excludeResult(project.id, entry.id, true)}
            />
          )}
          {forTab === SELECTED_PROJECT_TABS.EXCLUDED && (
            <IconButton
              wrapClassName="project-results-exclude-button"
              type={ICON_BUTTON_TYPES.BLUE}
              icon={ICON_BUTTON_ICONS.ARROW_REPEAT}
              tooltipText="Restore result"
              disabled={isExcludingResult}
              onClick={() => excludeResult(project.id, entry.id, false)}
            />
          )}
        </div>
        <div className="actions-mobile mobile-only">
          <IconButton
            id="project-result-view-details-mobile"
            innerText="View details"
            onClick={state.toggleDetailsPopup}
            type={ICON_BUTTON_TYPES.BLUE}
            filled
            wrapClassName="view-details-search-results"
          />
          <IconButton
            wrapClassName="project-results-outreach-button"
            type={ICON_BUTTON_TYPES.BLUE}
            icon={
              canSendOutreach ? ICON_BUTTON_ICONS.CHAT_RIGHT_TEXT : ICON_BUTTON_ICONS.ENVELOPE_CHECK
            }
            onClick={() => {
              if (canSendOutreach) {
                displayMessagePopup(entry);
              } else {
                if (outreach) {
                  state.toggleExistingOutreachPopup();
                }
              }
            }}
            disabled={disabledOutreach}
            tooltipText={canSendOutreach ? 'Outreach' : 'Outreach initiated'}
            filled={!canSendOutreach}
          />
          {forTab === SELECTED_PROJECT_TABS.RESULTS && (
            <IconButton
              wrapClassName="project-results-exclude-button"
              type={ICON_BUTTON_TYPES.RED}
              icon={ICON_BUTTON_ICONS.X_LG}
              tooltipText="Exclude result"
              disabled={isExcludingResult}
              onClick={() => excludeResult(project.id, entry.id, true)}
            />
          )}
          {forTab === SELECTED_PROJECT_TABS.EXCLUDED && (
            <IconButton
              wrapClassName="project-results-exclude-button"
              type={ICON_BUTTON_TYPES.BLUE}
              icon={ICON_BUTTON_ICONS.ARROW_REPEAT}
              tooltipText="Restore result"
              disabled={isExcludingResult}
              onClick={() => excludeResult(project.id, entry.id, false)}
            />
          )}
        </div>
      </div>
    );
  }
);

export default SearchResultsTabEntry;
