import { observer } from 'mobx-react';
import { PROFILER_SEARCH_CATEGORY_FIELDS, SEARCH_FIELDS } from '../../../../utils/constants/fields';
import MultiSelect from '../../../../components/inputs/MultiSelect';
import { useStore } from '../../../../store/store';
import { ICON_BUTTON_ICONS } from '../../../../components/buttons/IconButton';

const ProfilerCompanyBulkSearchFormMobile = observer(
  ({ fields, onSearch, categoryFields, setCategoryFieldValue, categoryOptions }) => {
    const { utilsStore, profilerAccessStore } = useStore();
    if (utilsStore.windowWidth > 1200) {
      return null;
    }

    return (
      <form className="mobile" onSubmit={onSearch}>
        {profilerAccessStore.isProfilerDemo && (
          <div className="profiler-demo-info-message">
            <div className="img-wrap">
              <img src={ICON_BUTTON_ICONS.EXCLAMATAION_CIRCLE} />
            </div>
            <div className="text">
              You have {profilerAccessStore.demoSearchRemainingCount} out of{' '}
              {profilerAccessStore.demoSearchMaxCount} demo searches remaining - upgrade to Full
              access to unlock unlimited searches.
            </div>
          </div>
        )}
        <div className="mobile-search-form-title">Select search type of preference</div>
        {fields[SEARCH_FIELDS.SEARCH_TYPE_MOBILE.ID]}
        <MultiSelect
          field={PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE}
          value={categoryFields[PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME]}
          setFieldValue={setCategoryFieldValue}
          values={categoryOptions}
        />
        <div className="profiler-download-wrap">
          <div className="download-label">Upload a file before executing the search*</div>
          <div className="download-container">
            <div className="upload-icon"></div>
            <div className="upload-info-row">
              <div>Drag and drop a file or&nbsp;</div>
              <div className="choose-file-link">Choose from your device</div>
            </div>
          </div>
        </div>
        <button type="submit" className="btn btn-primary" disabled={true}>
          Search
        </button>
      </form>
    );
  }
);

export default ProfilerCompanyBulkSearchFormMobile;
