import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../store/store';
import Reroute from '../../components/navigation/Reroute';
import { paths, routes } from '../../utils/constants/routes';
import { useEffect, useMemo } from 'react';
import StaticPageLayout from '../../components/layouts/StaticPageLayout';
import FundsList from './components/FundsList';
import PortfoliosList from './components/PortfoliosList';
import InvestProfilesList from './components/InvestProfilesList';
import { FUNDS_PATH_COMPONENTS, FUNDS_VIEW_MODE } from './constants';
import FundView from './components/FundView';
import PortfolioView from './components/PortfolioView';
import InvestProfileView from './components/InvestProfileView';
import GeneralLoader from '../../components/loaders/GeneralLoader';
import GeneralError from '../../components/errors/GeneralError';
import useHistory from '../../hooks/useHistory';
import { matchPath } from '../../utils/utils';
import * as Portal from '@radix-ui/react-portal';
import IconButton, {
  ICON_BUTTON_ICONS,
  ICON_BUTTON_TYPES
} from '../../components/buttons/IconButton';

const Funds = observer(() => {
  const { fundsStore, authStore, utilsStore } = useStore();
  const { location, params, goBack, navigate } = useHistory();

  const state = useLocalObservable(() => ({
    fundsReviewTab: 'all',
    setFundsReviewTab: (tab = '') => {
      state.fundsReviewTab = tab;
    },
    portfoliosReviewTab: 'all',
    setPortfoliosReviewTab: (tab = '') => {
      state.portfoliosReviewTab = tab;
    },
    isFundsReviewPopupUpen: false,
    toggleFundsReviewPopup: () => {
      state.isFundsReviewPopupUpen = !state.isFundsReviewPopupUpen;
      utilsStore.lockScroll(state.isFundsReviewPopupUpen);
    }
  }));

  const [fundId, editFundEntry, portfolioId, editPortfolioEntry, profileId, editProfileEntry] =
    useMemo(() => {
      const urlParams = new URLSearchParams(location.search);
      const [fundId, portfolioId, profileId] = [
        urlParams.get('fundId'),
        urlParams.get('portfolioId'),
        urlParams.get('profileId')
      ];
      const [editFundEntry, editPortfolioEntry, editProfileEntry] = [
        fundsStore.funds.find((f) => f.id == fundId),
        fundsStore.portfolios.find((f) => f.id == portfolioId),
        fundsStore.investProfiles.find((f) => f.id === profileId)
      ];
      return [fundId, editFundEntry, portfolioId, editPortfolioEntry, profileId, editProfileEntry];
    }, [fundsStore, location.search, fundsStore.isLoading]);

  useEffect(() => {
    if (fundsStore.isLoadingData) {
      return;
    }

    if (fundsStore.shouldUpdateFundsPageVisit) {
      state.toggleFundsReviewPopup();
      fundsStore.updateFundsPageVisit();
    }
  }, [fundsStore, fundsStore.isLoadingData]);

  useEffect(() => {
    return () => {
      if (state.isFundsReviewPopupUpen) {
        state.toggleFundsReviewPopup();
      }
    };
  }, [state]);

  const isFundPath = matchPath([routes.FUNDS_PATH], location);
  const isPortfolioCoPath = matchPath([routes.PORTFOLIO_COMPANIES_PATH], location);
  const isInvestProfilePath = matchPath([routes.INVEST_PROFILES_PATH], location);
  const component = params.component;
  const showFundsList = !component && isFundPath;
  const showPortfoliosList = !component && isPortfolioCoPath;
  const showProfilesList = !component && isInvestProfilePath;
  const showFundView =
    isFundPath && [FUNDS_PATH_COMPONENTS.CREATE, FUNDS_PATH_COMPONENTS.EDIT].includes(component);
  const showPortfolioView =
    isPortfolioCoPath &&
    [FUNDS_PATH_COMPONENTS.CREATE, FUNDS_PATH_COMPONENTS.EDIT].includes(component);
  const showProfileView =
    isInvestProfilePath &&
    [FUNDS_PATH_COMPONENTS.CREATE, FUNDS_PATH_COMPONENTS.EDIT].includes(component);
  const hideMobileFooter = !(showFundsList || showPortfoliosList || showProfilesList);

  const isCorporate = authStore.isCoraporatesInvestor;

  if (
    fundsStore.initialLoad ||
    (fundsStore.isLoading &&
      ((isCorporate && !fundsStore.allInvestProfiles.length) ||
        (!isCorporate && !fundsStore.allFunds.length && !fundsStore.allPortfolios.length)))
  ) {
    return <GeneralLoader />;
  }

  if (
    fundsStore.loadDataError &&
    ((isCorporate && !fundsStore.allInvestProfiles.length) ||
      (!isCorporate && !fundsStore.allFunds.length && !fundsStore.allPortfolios.length))
  ) {
    return (
      <StaticPageLayout
        page="investor-funds-listing"
        hideMobileFooter={hideMobileFooter}
        component={component || 'default'}>
        <GeneralError
          errorMessage={fundsStore.loadDataError}
          actionMessage="You may want to:"
          withHomePageButton={true}
          actionButtontext={'Try again'}
          onActionButtonClick={fundsStore.loadFunds}
        />
      </StaticPageLayout>
    );
  }

  if (isCorporate && !isInvestProfilePath) {
    return <Reroute path={paths.INVEST_PROFILES} />;
  }

  if (!isCorporate && isInvestProfilePath) {
    return <Reroute path={paths.FUNDS} />;
  }

  if (
    showFundView &&
    component === FUNDS_PATH_COMPONENTS.EDIT &&
    (!fundId || !editFundEntry) &&
    !fundsStore.isLoadingData &&
    !fundsStore.loadDataError
  ) {
    return <Reroute path={paths.FUNDS} />;
  }

  if (
    showPortfolioView &&
    component === FUNDS_PATH_COMPONENTS.EDIT &&
    (!portfolioId || !editPortfolioEntry) &&
    !fundsStore.isLoadingData &&
    !fundsStore.loadDataError
  ) {
    return <Reroute path={paths.PORTOFLIO_COMPANIES} />;
  }

  if (
    showProfileView &&
    component === FUNDS_PATH_COMPONENTS.EDIT &&
    (!profileId || !editProfileEntry) &&
    !fundsStore.isLoadingData &&
    !fundsStore.loadDataError
  ) {
    return <Reroute path={paths.INVEST_PROFILES} />;
  }

  return (
    <StaticPageLayout
      page="investor-funds-listing"
      hideMobileFooter={hideMobileFooter}
      component={component || 'default'}>
      {state.isFundsReviewPopupUpen && (
        <Portal.Root>
          <div className="funds-review-popup-closer"></div>
          <div className="funds-review-popup-wrapper">
            <div className="funds-review-popup">
              <div className="header">
                <div className="title">Review your invest profiles</div>
                <IconButton
                  id="close-funds-review-popup"
                  withFill={false}
                  withBorder={false}
                  icon={ICON_BUTTON_ICONS.X_LG}
                  onClick={state.toggleFundsReviewPopup}
                />
              </div>
              <div className="text-content">
                <div className="row">
                  We’ve imported your Funds & Portfolio Companies. Before using them, please review
                  and complete any missing details.
                </div>
                <div className="row">
                  Select an item from the list, review its details, and save your changes.
                </div>
              </div>
              <div className="progress-content">
                <div className="sub-title">Your progress</div>
                <div className="remaining-title">
                  {!!fundsStore.fundsForReview.length &&
                    !!fundsStore.portfoliosForReview.length && (
                      <>
                        {fundsStore.fundsForReview.length} fund and{' '}
                        {fundsStore.portfoliosForReview.length} portfolio companies remaining to
                        complete your onboarding
                      </>
                    )}
                  {!!fundsStore.fundsForReview.length && (
                    <>
                      {fundsStore.fundsForReview.length} funds remaining to complete your onboarding
                    </>
                  )}
                  {!!fundsStore.portfoliosForReview.length && (
                    <>
                      {fundsStore.portfoliosForReview.length} portfolio companies remaining to
                      complete your onboarding
                    </>
                  )}
                </div>
                <div className="bar-wrapper">
                  {(!!fundsStore.reviewedFundsCount || !!fundsStore.reviewedPortfoliosCount) && (
                    <div
                      className="progress-bar"
                      style={{
                        width:
                          Math.floor(
                            (100 /
                              fundsStore.funds
                                .concat(fundsStore.portfolios)
                                .filter((item) => item.isImported).length) *
                              (fundsStore.reviewedFundsCount + fundsStore.reviewedPortfoliosCount)
                          ) + '%'
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="action-fields">
                <IconButton
                  type={ICON_BUTTON_TYPES.BLUE}
                  innerText={
                    fundsStore.reviewedFundsCount || fundsStore.reviewedPortfoliosCount
                      ? 'Continue reviewing'
                      : 'Review now'
                  }
                  onClick={() => {
                    state.toggleFundsReviewPopup();
                    if (fundsStore.fundsForReview.length) {
                      state.setFundsReviewTab('review');
                      if (!matchPath([paths.FUNDS], location)) {
                        navigate(paths.FUNDS);
                      }
                    } else {
                      state.setPortfoliosReviewTab('review');
                      if (!matchPath([paths.PORTOFLIO_COMPANIES], location)) {
                        navigate(paths.PORTOFLIO_COMPANIES);
                      }
                    }
                  }}
                  filled
                />
              </div>
            </div>
          </div>
        </Portal.Root>
      )}

      {(showFundsList || showPortfoliosList || showProfilesList) && (
        <>
          <div className="gray-title-panel">
            <div className="title-section">
              <h4>
                {isCorporate
                  ? 'My invest profiles'
                  : isFundPath
                  ? 'My funds'
                  : 'My portfolio companies'}
              </h4>
              <h6>
                {isCorporate
                  ? 'Create and manage your invest profiles'
                  : isFundPath
                  ? 'Create and manage your fund profiles'
                  : 'Create and manage your portfolio company profiles'}
              </h6>
            </div>
          </div>
          <div className="tabs no-mobile-stretch">
            {isCorporate ? (
              <div
                className={'tab no-cursor investo-profile'}
                onClick={() => goBack(paths.INVEST_PROFILES)}>
                Invest profiles
              </div>
            ) : (
              <>
                <div
                  className={`tab ${showFundsList ? 'active' : ''}`}
                  onClick={() => goBack(paths.FUNDS)}>
                  Funds
                  {!!fundsStore.fundsForReview.length && <div className="review-dot" />}
                </div>
                <div
                  className={`tab ${showPortfoliosList ? 'active' : ''}`}
                  onClick={() => goBack(paths.PORTOFLIO_COMPANIES)}>
                  <div className="desktop-text">Portfolio companies</div>
                  <div className="mobile-text">Portfolio companies</div>
                  {!!fundsStore.portfoliosForReview.length && <div className="review-dot" />}
                </div>
              </>
            )}
          </div>
          {showFundsList && !!fundsStore.fundsForReview.length && (
            <div className="review-tabs">
              <div
                className={`tab ${state.fundsReviewTab === 'all' ? 'active' : ''}`}
                onClick={() => state.setFundsReviewTab('all')}>
                <div className="inner-wrap">All funds</div>
              </div>
              <div
                className={`tab ${state.fundsReviewTab === 'review' ? 'active' : ''}`}
                onClick={() => state.setFundsReviewTab('review')}>
                <div className="inner-wrap">
                  Not reviewed
                  <div className="review-dot" />
                </div>
              </div>
            </div>
          )}
          {showPortfoliosList && !!fundsStore.portfoliosForReview.length && (
            <div className="review-tabs">
              <div
                className={`tab ${state.portfoliosReviewTab === 'all' ? 'active' : ''}`}
                onClick={() => state.setPortfoliosReviewTab('all')}>
                <div className="inner-wrap">All funds</div>
              </div>
              <div
                className={`tab ${state.portfoliosReviewTab === 'review' ? 'active' : ''}`}
                onClick={() => state.setPortfoliosReviewTab('review')}>
                <div className="inner-wrap">
                  Not reviewed
                  <div className="review-dot" />
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {showFundsList && (
        <FundsList
          showOnlyForReview={
            !!fundsStore.fundsForReview.length && state.fundsReviewTab === 'review'
          }
        />
      )}
      {showPortfoliosList && (
        <PortfoliosList
          showOnlyForReview={
            !!fundsStore.portfoliosForReview.length && state.portfoliosReviewTab === 'review'
          }
        />
      )}
      {showProfilesList && <InvestProfilesList />}
      {showFundView && (
        <FundView
          fund={editFundEntry}
          mode={
            FUNDS_PATH_COMPONENTS.CREATE === component
              ? FUNDS_VIEW_MODE.CREATE
              : FUNDS_VIEW_MODE.EDIT
          }
          key={
            (FUNDS_PATH_COMPONENTS.CREATE === component ? 'c-f' : 'e-f') + (editFundEntry?.id || '')
          }
        />
      )}
      {showPortfolioView && (
        <PortfolioView
          portfolio={editPortfolioEntry}
          mode={
            FUNDS_PATH_COMPONENTS.CREATE === component
              ? FUNDS_VIEW_MODE.CREATE
              : FUNDS_VIEW_MODE.EDIT
          }
          key={
            (FUNDS_PATH_COMPONENTS.CREATE === component ? 'c-p' : 'e-p') +
            (editPortfolioEntry?.id || '')
          }
        />
      )}
      {showProfileView && (
        <InvestProfileView
          profile={editProfileEntry}
          mode={
            FUNDS_PATH_COMPONENTS.CREATE === component
              ? FUNDS_VIEW_MODE.CREATE
              : FUNDS_VIEW_MODE.EDIT
          }
          key={
            (FUNDS_PATH_COMPONENTS.CREATE === component ? 'c-p' : 'e-p') +
            (editProfileEntry?.id || '')
          }
        />
      )}
    </StaticPageLayout>
  );
});

export default Funds;
