import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../../store/store';
import { useEffect } from 'react';
import GeneralLoader from '../../../components/loaders/GeneralLoader';
import GeneralError from '../../../components/errors/GeneralError';
import { formatDateDDMMYYYY } from '../../../utils/utils';
import StatusIcon, { STATUS_ICON_TYPES } from './StatusIcon';
import Pagination from '../../../components/navigation/Pagination';
import IconButton, {
  ICON_BUTTON_TYPES,
  ICON_BUTTON_ICONS
} from '../../../components/buttons/IconButton';
import MultiSelect from '../../../components/inputs/MultiSelect';
import { FIELDS_TYPES } from '../../../utils/constants/fields';
import { runInAction } from 'mobx';
import { OPTION_KEYS } from '../../../utils/constants/optionKeys';
import UserInfo from './UserInfo';
import SelectUserGroupsPopup from './SelectUserGroupsPopup';

const UsersManagement = observer(() => {
  const { adminDashboardStore, utilsStore, authStore } = useStore();
  const state = useLocalObservable(() => ({
    manageGroupsUserId: null,
    setManageGroupsUserId: (userId = '') => {
      state.manageGroupsUserId = userId;
      utilsStore.lockScroll(!!state.manageGroupsUserId);
    },
    capturedScrollYPositionPreSelectedUser: 0,
    selectedUserId: null,
    setSelectedUserId: (userId = '', isUnmount = false) => {
      runInAction(() => {
        state.selectedUserId = userId;
        if (userId) {
          state.capturedScrollYPositionPreSelectedUser = window.scrollY;
        } else {
          if (state.capturedScrollYPositionPreSelectedUser && !isUnmount) {
            const position = state.capturedScrollYPositionPreSelectedUser;
            state.capturedScrollYPositionPreSelectedUser = 0;
            setTimeout(() => window.scrollTo({ top: position }), 0);
          }
        }
      });
    },
    filtersToggled: false,
    toggleFilters: (clearChanges = false) => {
      state.filtersToggled = !state.filtersToggled;
      utilsStore.lockScroll(state.filtersToggled);
      if (clearChanges) {
        adminDashboardStore.filtersDisplayUsersManagement = JSON.parse(
          JSON.stringify(adminDashboardStore.filtersUsersManagement)
        );
      }
    },
    get layout() {
      return utilsStore.windowWidth < 1201 ? 'mobile' : 'desktop';
    },
    get selectedUser() {
      return adminDashboardStore.usersManagementList.find((u) => u.id === state.selectedUserId);
    },
    get manageGroupsUser() {
      return adminDashboardStore.usersManagementList.find((u) => u.id === state.manageGroupsUserId);
    },
    get filterCompanyTypeValues() {
      const companyTypes = utilsStore.options?.[OPTION_KEYS.COMPANY_TYPES];
      return [{ name: 'All', value: 'all' }, ...(companyTypes || [])];
    },
    getFiltersCount: (filters = {}) => {
      let count = 0;
      // filters values may change in the future and more complex checks may be needed, leave the check dum style for now
      if (filters.filterCompanyType && filters.filterCompanyType !== 'all') {
        count++;
      }
      if (filters.filterConfirmationStatus) {
        count++;
      }
      if (filters.filterApproveStatus) {
        count++;
      }
      if (filters.filterOnlyMasterGroup) {
        count++;
      }
      return count;
    },
    get displayFiltersCount() {
      return state.getFiltersCount(adminDashboardStore.filtersDisplayUsersManagement);
    },
    get appliedFiltersCount() {
      return state.getFiltersCount(adminDashboardStore.filtersUsersManagement);
    },
    get disableFiltersInput() {
      return adminDashboardStore.isLoadingUsersManagement;
    },
    get disableApplyFiltersBtn() {
      return (
        adminDashboardStore.isLoadingUsersManagement ||
        JSON.stringify(adminDashboardStore.filtersDisplayUsersManagement) ===
          JSON.stringify(adminDashboardStore.filtersUsersManagement)
      );
    },
    get disableClearAllFiltersBtn() {
      return (
        adminDashboardStore.isLoadingUsersManagement ||
        (state.displayFiltersCount === 0 && state.appliedFiltersCount === 0)
      );
    }
  }));

  useEffect(() => {
    return () => {
      if (state.filtersToggled) {
        state.toggleFilters();
      }

      if (state.selectedUserId) {
        state.setSelectedUserId(null, true);
      }

      if (state.manageGroupsUserId) {
        state.setManageGroupsUserId(null);
      }
    };
  }, [state]);

  useEffect(() => {
    if (
      !adminDashboardStore.isLoadingUsersManagement &&
      !adminDashboardStore.usersManagementList.length &&
      !adminDashboardStore.loadUsersManagementError
    ) {
      adminDashboardStore.loadUsersManagementList(adminDashboardStore.usersManagementPage);
    }
  }, [adminDashboardStore]);

  const showLoader = adminDashboardStore.isLoadingUsersManagement;
  const showError = !!adminDashboardStore.loadUsersManagementError;
  const showResults = !!adminDashboardStore.usersManagementList.length;
  const showPaging = adminDashboardStore.usersManagementMaxPage > 1;
  const showNoResults =
    !adminDashboardStore.isLoadingUsersManagement &&
    !adminDashboardStore.loadUsersManagementError &&
    !adminDashboardStore.usersManagementList.length;

  if (state.selectedUser) {
    return <UserInfo user={state.selectedUser} onClose={() => state.setSelectedUserId(null)} />;
  }

  return (
    <>
      {!!state.manageGroupsUser && (
        <SelectUserGroupsPopup
          user={state.manageGroupsUser}
          onClose={() => state.setManageGroupsUserId(null)}
        />
      )}
      <div className="filters-wrap">
        <IconButton
          type={ICON_BUTTON_TYPES.DEFAULT}
          hoverType={ICON_BUTTON_TYPES.GREEN}
          icon={ICON_BUTTON_ICONS.ARROW_REPEAT}
          withBorder={false}
          innerText="Refresh"
          onClick={() => {
            adminDashboardStore.loadUsersManagementList(adminDashboardStore.usersManagementPage);
          }}
          disabled={state.disableFiltersInput}
        />
        <IconButton
          type={ICON_BUTTON_TYPES.DEFAULT}
          hoverType={ICON_BUTTON_TYPES.BLUE}
          withBorder={false}
          filled={state.filtersToggled}
          icon={ICON_BUTTON_ICONS.FILTER}
          innerText={
            state.displayFiltersCount ? `Filters (${state.displayFiltersCount})` : 'Filters'
          }
          onClick={state.toggleFilters}
        />
        {state.filtersToggled && (
          <>
            <div className="filters-closer" onClick={() => state.toggleFilters(true)} />
            <div className="filters-container">
              <div className="filters-title">Filter by:</div>
              <div className="filters-body">
                <MultiSelect
                  field={{
                    NAME: 'filterCompanyType',
                    ID: 'users_mgmt_filter_company_type',
                    LABEL: 'Company type',
                    PLACEHOLDER: 'Select',
                    TYPE: FIELDS_TYPES.TYPE_SELECT
                  }}
                  values={state.filterCompanyTypeValues}
                  value={[adminDashboardStore.filtersDisplayUsersManagement.filterCompanyType]}
                  setFieldValue={(_, value) => {
                    runInAction(() => {
                      adminDashboardStore.filtersDisplayUsersManagement.filterCompanyType =
                        value[0];
                    });
                  }}
                  disabled={state.disableFiltersInput}
                  inputWrap={{ enable: true, className: 'filter-wrap' }}
                />
                <div className="filter-wrap">
                  <div className="checkbox-wrap">
                    <div className="label">Confirmation status (email)</div>
                    <div className="noselect options-layout">
                      <label>
                        <input
                          type={FIELDS_TYPES.TYPE_CHECKBOX}
                          checked={
                            adminDashboardStore.filtersDisplayUsersManagement
                              .filterConfirmationStatus
                          }
                          onChange={() => {
                            runInAction(() => {
                              adminDashboardStore.filtersDisplayUsersManagement.filterConfirmationStatus =
                                !adminDashboardStore.filtersDisplayUsersManagement
                                  .filterConfirmationStatus;
                            });
                          }}
                          disabled={state.disableFiltersInput}
                        />
                        Unconfirmed
                      </label>
                    </div>
                  </div>
                </div>
                <div className="filter-wrap">
                  <div className="checkbox-wrap">
                    <div className="label">Is user approved?</div>
                    <div className="noselect options-layout">
                      <label>
                        <input
                          type={FIELDS_TYPES.TYPE_CHECKBOX}
                          checked={
                            adminDashboardStore.filtersDisplayUsersManagement
                              .filterApproveStatus === 'approved'
                          }
                          onChange={() => {
                            runInAction(() => {
                              adminDashboardStore.filtersDisplayUsersManagement.filterApproveStatus =
                                adminDashboardStore.filtersDisplayUsersManagement
                                  .filterApproveStatus === 'approved'
                                  ? ''
                                  : 'approved';
                            });
                          }}
                          disabled={state.disableFiltersInput}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type={FIELDS_TYPES.TYPE_CHECKBOX}
                          checked={
                            adminDashboardStore.filtersDisplayUsersManagement
                              .filterApproveStatus === 'not-approved'
                          }
                          onChange={() => {
                            runInAction(() => {
                              adminDashboardStore.filtersDisplayUsersManagement.filterApproveStatus =
                                adminDashboardStore.filtersDisplayUsersManagement
                                  .filterApproveStatus === 'not-approved'
                                  ? ''
                                  : 'not-approved';
                            });
                          }}
                          disabled={state.disableFiltersInput}
                        />
                        No
                      </label>
                    </div>
                  </div>
                </div>
                <div className="filter-wrap">
                  <div className="checkbox-wrap">
                    <div className="label">Only user special rights</div>
                    <div className="noselect options-layout">
                      <label>
                        <input
                          type={FIELDS_TYPES.TYPE_CHECKBOX}
                          checked={
                            adminDashboardStore.filtersDisplayUsersManagement.filterOnlyMasterGroup
                          }
                          onChange={() => {
                            runInAction(() => {
                              adminDashboardStore.filtersDisplayUsersManagement.filterOnlyMasterGroup =
                                !adminDashboardStore.filtersDisplayUsersManagement
                                  .filterOnlyMasterGroup;
                            });
                          }}
                          disabled={state.disableFiltersInput}
                        />
                        Master
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filters-actions">
                <IconButton
                  innerText="Clear all filters"
                  type={ICON_BUTTON_TYPES.RED}
                  disabled={state.disableClearAllFiltersBtn}
                  onClick={() => {
                    state.toggleFilters();
                    adminDashboardStore.clearUsersManagementFilters();
                  }}
                  wrapClassName={'clear-btn-override'}
                />
                <IconButton innerText="Cancel" onClick={() => state.toggleFilters(true)} />
                <IconButton
                  type={ICON_BUTTON_TYPES.BLUE}
                  filled
                  innerText="Apply filters"
                  disabled={state.disableApplyFiltersBtn}
                  onClick={() => {
                    state.toggleFilters();
                    adminDashboardStore.loadUsersManagementList();
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
      {showLoader && <GeneralLoader />}
      {showError && (
        <GeneralError
          errorMessage={adminDashboardStore.loadUsersManagementError}
          actionMessage="You may want to:"
          actionButtontext="Try again"
          onActionButtonClick={() =>
            adminDashboardStore.loadUsersManagementList(adminDashboardStore.usersManagementPage)
          }
        />
      )}
      {showResults && (
        <div className="results-table">
          <div className="table-head desktop-only">
            <div className="head-item">Name</div>
            <div className="head-item">Company name</div>
            <div className="head-item">Company type</div>
            <div className="head-item">Email address</div>
            <div className="head-item">Phone Number</div>
            <div className="head-item">Confirmation status</div>
            <div className="head-item">Date created</div>
            <div className="head-item">StatusGroup Approved</div>
            <div className="head-item">StatusGroup Agent</div>
            <div className="head-item">StatusGroup Investor</div>
            <div className="head-item">StatusGroup Master</div>
          </div>
          {adminDashboardStore.usersManagementList.map((user) => {
            const isCurrentUser =
              process.env.REACT_APP_ENVIRONMENT === 'dev' && authStore.currentUserId === user.id;
            if (state.layout === 'mobile') {
              return (
                <div
                  key={user.id}
                  className={`user-item-mobile${isCurrentUser ? ' current-user' : ''}`}>
                  <div className="row">
                    <div className="field">Name</div>
                    <div
                      className="value username"
                      onClick={() => {
                        state.setSelectedUserId(user.id);
                      }}>
                      {user.firstName} {user.lastName}
                    </div>
                  </div>
                  <div className="row">
                    <div className="field">Company name</div>
                    <div className="value text">{user.companyProfileName}</div>
                  </div>
                  <div className="row">
                    <div className="field">Company type</div>
                    <div className="value text">
                      {user.companyType
                        ? utilsStore.options?.[OPTION_KEYS.COMPANY_TYPES]?.find?.(
                            (c) => c.value === user.companyType
                          )?.name || user.companyType
                        : 'N/A'}
                    </div>
                  </div>
                  <div className="row">
                    <div className="field">Email address</div>
                    <div className="value text">{user.email}</div>
                  </div>
                  <div className="row">
                    <div className="field">Phone number</div>
                    <div className="value text">{user.phoneNumber || 'N/A'}</div>
                  </div>
                  <div className="row">
                    <div className="field">Confirmation status</div>
                    <div className="value">
                      {user.isCognitoVerified ? (
                        <div className="chipp green">Confirmed</div>
                      ) : (
                        <div className="chipp red">Unconfirmed</div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="field">Date created</div>
                    <div className="value text">{formatDateDDMMYYYY(user.createdAt)}</div>
                  </div>
                  <div className="row">
                    <div className="field">StatusGroup Approved</div>
                    <div className="value">
                      <StatusIcon
                        type={
                          user.cognitoGroups.includes('approved')
                            ? STATUS_ICON_TYPES.GREEN
                            : STATUS_ICON_TYPES.RED
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="field">StatusGroup Agent</div>
                    <div className="value">
                      <StatusIcon
                        type={
                          user.cognitoGroups.includes('agent')
                            ? STATUS_ICON_TYPES.GREEN
                            : STATUS_ICON_TYPES.RED
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="field">StatusGroup Investor</div>
                    <div className="value">
                      <StatusIcon
                        type={
                          user.cognitoGroups.includes('investor')
                            ? STATUS_ICON_TYPES.GREEN
                            : STATUS_ICON_TYPES.RED
                        }
                      />
                    </div>
                  </div>
                  <div className="row last">
                    <div className="field">StatusGroup Master</div>
                    <div className="value">
                      <StatusIcon
                        type={
                          user.cognitoGroups.includes('master')
                            ? STATUS_ICON_TYPES.GREEN
                            : STATUS_ICON_TYPES.RED
                        }
                      />
                    </div>
                  </div>
                  <IconButton
                    type={ICON_BUTTON_TYPES.BLUE}
                    filled
                    icon={ICON_BUTTON_ICONS.PEOPLE}
                    innerText="Manage groups"
                    onClick={() => {
                      state.setManageGroupsUserId(user.id);
                    }}
                    wrapClassName="manage-groups-mobile"
                  />
                </div>
              );
            }

            return (
              <div key={user.id} className={`user-item${isCurrentUser ? ' current-user' : ''}`}>
                <div className="cell username">
                  <IconButton
                    type={ICON_BUTTON_TYPES.BLUE}
                    icon={ICON_BUTTON_ICONS.PEOPLE}
                    tooltipText="Manage groups"
                    onClick={() => {
                      state.setManageGroupsUserId(user.id);
                    }}
                  />
                  <font
                    className="name"
                    onClick={() => {
                      state.setSelectedUserId(user.id);
                    }}>
                    {user.firstName} {user.lastName}
                  </font>
                </div>
                <div className="cell text">{user.companyProfileName}</div>
                <div className="cell text">
                  {user.companyType
                    ? utilsStore.options?.[OPTION_KEYS.COMPANY_TYPES]?.find?.(
                        (c) => c.value === user.companyType
                      )?.name || user.companyType
                    : 'N/A'}
                </div>
                <div className="cell text">{user.email}</div>
                <div className="cell text">{user.phoneNumber || 'N/A'}</div>
                <div className="cell">
                  {user.isCognitoVerified ? (
                    <div className="chipp green">Confirmed</div>
                  ) : (
                    <div className="chipp red">Unconfirmed</div>
                  )}
                </div>
                <div className="cell text">{formatDateDDMMYYYY(user.createdAt)}</div>
                <div className="cell">
                  <StatusIcon
                    type={
                      user.cognitoGroups.includes('approved')
                        ? STATUS_ICON_TYPES.GREEN
                        : STATUS_ICON_TYPES.RED
                    }
                  />
                </div>
                <div className="cell">
                  <StatusIcon
                    type={
                      user.cognitoGroups.includes('agent')
                        ? STATUS_ICON_TYPES.GREEN
                        : STATUS_ICON_TYPES.RED
                    }
                  />
                </div>
                <div className="cell">
                  <StatusIcon
                    type={
                      user.cognitoGroups.includes('investor')
                        ? STATUS_ICON_TYPES.GREEN
                        : STATUS_ICON_TYPES.RED
                    }
                  />
                </div>
                <div className="cell">
                  <StatusIcon
                    type={
                      user.cognitoGroups.includes('master')
                        ? STATUS_ICON_TYPES.GREEN
                        : STATUS_ICON_TYPES.RED
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}
      {showNoResults && <div className="no-results">No results found.</div>}
      <Pagination
        show={showPaging}
        onPageChange={(selected) => {
          adminDashboardStore.loadUsersManagementList(selected + 1);
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
        page={adminDashboardStore.usersManagementPage - 1}
        pages={adminDashboardStore.usersManagementMaxPage}
      />
    </>
  );
});

export default UsersManagement;
