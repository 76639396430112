import { observer, useLocalObservable } from 'mobx-react';
import {
  FUND_FIELDS,
  PORTFOLIO_FIELDS,
  INVEST_PROFILE_FIELDS
} from '../../../utils/constants/fields';
import { useStore } from '../../../store/store';
import { formatNumberRange } from '../../../utils/utils';
import IconButton, {
  ICON_BUTTON_TYPES,
  ICON_BUTTON_ICONS
} from '../../../components/buttons/IconButton';
import { SHARED_PROJECT_TYPE_TABS } from './constants';
import FundDetailsPopup from '../../../components/shared/FundDetailsPopup';
import PortfolioCoDetailsPopup from '../../../components/shared/PortfolioCoDetailsPopup';
import InvestProfileDetailsPopup from '../../../components/shared/InvestProfileDetailsPopup';
import { useCallback, useEffect } from 'react';

const fieldsByType = {
  funds: FUND_FIELDS,
  portfolios: PORTFOLIO_FIELDS,
  investprofiles: INVEST_PROFILE_FIELDS
};

const FeedbackSearchResultsTabEntry = observer(
  ({ entry = {}, feedbacks = {}, project = {}, type = 'funds', onChangeFeedback, params }) => {
    const { utilsStore, authStore } = useStore();

    const state = useLocalObservable(() => ({
      isDetailsPopupOpen: false,
      toggleDetailsPopup: () => {
        state.isDetailsPopupOpen = !state.isDetailsPopupOpen;
        utilsStore.lockScroll(state.isDetailsPopupOpen);
      },
      entryFeedback: [],
      get layout() {
        return utilsStore.windowWidth < 1201 ? 'mobile' : 'desktop';
      }
    }));

    useEffect(() => {
      return () => {
        if (state.isDetailsPopupOpen) {
          state.toggleDetailsPopup();
        }
      };
    }, [state, entry.id]);

    useEffect(() => {
      state.feedbacks = project.feedbacks.find((project) => project.resultId === entry.id);
    }, [entry, project]);
    const closeThePopUp = () => {
      if (state.isDetailsPopupOpen) {
        state.toggleDetailsPopup();
      }
    };

    const isFund = type === 'funds';
    const isPortfolio = type === 'portfolios';
    const isProfile = type === 'investprofiles';

    const fields = fieldsByType[type];

    const isFeedbackMarked = useCallback(
      (resultId, feedback) => {
        return feedbacks.find((fb) => fb.resultId === resultId && fb.feedback === feedback);
      },
      [feedbacks]
    );

    const readOnly = params.projectTypeTab === SHARED_PROJECT_TYPE_TABS.RESOLVED;

    return (
      <div className="row">
        {state.isDetailsPopupOpen &&
          (isFund ? (
            <FundDetailsPopup fund={entry} onClose={closeThePopUp} />
          ) : isPortfolio ? (
            <PortfolioCoDetailsPopup portfolio={entry} onClose={closeThePopUp} />
          ) : (
            <InvestProfileDetailsPopup profile={entry} onClose={closeThePopUp} />
          ))}
        {state.layout === 'mobile' && (
          <div className="item feedback-item">
            <IconButton
              type={ICON_BUTTON_TYPES.GREEN}
              icon={ICON_BUTTON_ICONS.HAND_THUMBS_UP}
              onClick={() => !readOnly && onChangeFeedback(entry.id, 'up')}
              filled={isFeedbackMarked(entry.id, 'up')}
            />
            <IconButton
              type={ICON_BUTTON_TYPES.YELLOW}
              icon={ICON_BUTTON_ICONS.QUESTION_LG}
              onClick={() => !readOnly && onChangeFeedback(entry.id, 'question')}
              filled={isFeedbackMarked(entry.id, 'question')}
            />
            <IconButton
              type={ICON_BUTTON_TYPES.RED}
              icon={ICON_BUTTON_ICONS.HAND_THUMBS_DOWN}
              onClick={() => !readOnly && onChangeFeedback(entry.id, 'down')}
              filled={isFeedbackMarked(entry.id, 'down')}
            />
          </div>
        )}
        <div className="item">
          <div className="label">
            {isFund ? 'Fund name' : isPortfolio ? 'Portfolio name' : 'Profile name'}
          </div>
          <div className="value">{entry[fields.NAME.NAME] || entry.companyName}</div>
        </div>
        <div className="item">
          <div className="label">Industry focus</div>
          <div className="value">
            {utilsStore
              .shortenList(utilsStore.getOptionName(entry, fields.INDUSTRIES), 2)
              .join(', ')}
          </div>
        </div>
        <div className="item">
          <div className="label">Geo focus</div>
          <div className="value">
            {utilsStore
              .shortenList(utilsStore.getOptionName(entry, fields.GEOGRAPHIES), 3)
              .join(', ')}
          </div>
        </div>
        <div className="item">
          <div className="label">Enterprise value (€M)</div>
          <div className="value text-align-right-desktop">
            {formatNumberRange(
              entry[fields.ENTERPRISE_RANGE_MIN.NAME],
              entry[fields.ENTERPRISE_RANGE_MAX.NAME]
            )}
          </div>
        </div>
        <div className="item">
          <div className="label">Equity value (€M)</div>
          <div className="value text-align-right-desktop">
            {formatNumberRange(
              entry[fields.EQUITY_RANGE_MIN.NAME],
              entry[fields.EQUITY_RANGE_MAX.NAME]
            )}
          </div>
        </div>

        {state.layout === 'desktop' && (
          <div className="item feedback-item">
            <IconButton
              type={ICON_BUTTON_TYPES.GREEN}
              icon={ICON_BUTTON_ICONS.HAND_THUMBS_UP}
              onClick={() => !readOnly && onChangeFeedback(entry.id, 'up')}
              filled={isFeedbackMarked(entry.id, 'up')}
              wrapClassName={readOnly ? 'force-default-cursor' : ''}
            />
            <IconButton
              type={ICON_BUTTON_TYPES.YELLOW}
              icon={ICON_BUTTON_ICONS.QUESTION_LG}
              onClick={() => !readOnly && onChangeFeedback(entry.id, 'question')}
              filled={isFeedbackMarked(entry.id, 'question')}
              wrapClassName={readOnly ? 'force-default-cursor' : ''}
            />
            <IconButton
              type={ICON_BUTTON_TYPES.RED}
              icon={ICON_BUTTON_ICONS.HAND_THUMBS_DOWN}
              onClick={() => !readOnly && onChangeFeedback(entry.id, 'down')}
              filled={isFeedbackMarked(entry.id, 'down')}
              wrapClassName={readOnly ? 'force-default-cursor' : ''}
            />
          </div>
        )}
        <div className="actions">
          <IconButton
            innerText="View details"
            onClick={state.toggleDetailsPopup}
            type={ICON_BUTTON_TYPES.BLUE}
            filled
          />
        </div>
      </div>
    );
  }
);

export default FeedbackSearchResultsTabEntry;
