import { Store } from '../store'; // eslint-disable-line no-unused-vars
import { makeAutoObservable, runInAction } from 'mobx';
import { API_ENDPOINTS } from '../../api/endpoints';
import { mapData, mapCompanyEntry } from '../../api/dataMappers';
import { PROFILER_PROJECT_FIELDS, PROFILER_SEARCH_FIELDS } from '../../utils/constants/fields';
import { ACCOUNT_VERIF_TYPES } from '../../utils/constants/auth';
import { HEADER_MESSAGE_TYPES } from '../../utils/constants/header';
import pubSub from '../../utils/pubsub';
import { WSS_EVENTS, WSS_ACTIONS } from './WebSocketStore';
import { UI_OPTIONS } from '../../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../../utils/constants/uiOptionKeys';

class ProfilerProjectsStore {
  /**
   * @param {Store} root
   */
  constructor(root) {
    makeAutoObservable(this);
    this.root = root;
  }

  reset = () => {
    this.wssQuery = [];
    this.allProjects = [];
    this.loadProjectsError = null;
    this.isLoadingProjects = false;
    this.initialLoadProjects = true;
    this.isRequestingProjectFeedback = false;
    this.isResolvingProjectFeedback = false;
    this.isUpdatingProject = false;
    this.isArchivingProject = false;
    this.isDeletingProjects = false;
    this.watchlist = [];
    this.loadWatchlistError = null;
    this.isLoadingWatchlist = false;
    this.initialLoadWatchlist = true;
    this.isAddingToProfilerWatchlist = false;
    this.removingFromWatchlistEntries = {};
  };

  wssQuery = [];
  allProjects = [];
  loadProjectsError = null;
  isLoadingProjects = false;
  initialLoadProjects = true;
  isRequestingProjectFeedback = false;
  isResolvingProjectFeedback = false;
  isUpdatingProject = false;
  isArchivingProject = false;
  isDeletingProjects = false;
  watchlist = [];
  loadWatchlistError = null;
  isLoadingWatchlist = false;
  initialLoadWatchlist = true;
  isAddingToProfilerWatchlist = false;
  removingFromWatchlistEntries = {};

  _wss_onData = (jsData = {}) => {
    if (
      [
        WSS_EVENTS.PROFILER_PROJECTS_STORE.PROFILER_PROJECT,
        WSS_EVENTS.PROFILER_PROJECTS_STORE.ROFILER_PROJECT_OWNER_LAST_CHECKED_DATE_UPDATE
      ].includes(jsData.for) &&
      (this.isLoadingProjects || this.initialLoadProjects || this.loadProjectsError)
    ) {
      return;
    }

    if (
      [
        WSS_EVENTS.PROFILER_PROJECTS_STORE.PROFILER_WATCHLIST_COMPANY,
        WSS_EVENTS.PROFILER_PROJECTS_STORE.PROFILER_WATCHLIST_NEWS_UPDATE
      ].includes(jsData.for) &&
      (this.isLoadingWatchlist || this.initialLoadWatchlist || this.loadWatchlistError)
    ) {
      return;
    }

    if (jsData.for === WSS_EVENTS.PROFILER_PROJECTS_STORE.PROFILER_PROJECT) {
      if ([WSS_ACTIONS.CREATE, WSS_ACTIONS.UPDATE].includes(jsData?.action)) {
        jsData.data.searchCategory =
          jsData.data.searchCategory ||
          UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company criteria'];
        let fieldsToMap = null;
        if (
          jsData.data.searchCategory ===
          UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company criteria']
        ) {
          fieldsToMap = PROFILER_SEARCH_FIELDS;
        } else {
          fieldsToMap = PROFILER_SEARCH_FIELDS;
        }

        jsData.data = {
          ...jsData.data,
          ...mapData(jsData.data, PROFILER_PROJECT_FIELDS),
          ...(jsData.data.searchFields && fieldsToMap
            ? { fields: mapData(jsData.data.searchFields, fieldsToMap) }
            : {})
        };
      }
      runInAction(() => {
        if (jsData?.action === WSS_ACTIONS.CREATE) {
          this._wss_onCreateProject(jsData);
        } else if (jsData?.action === WSS_ACTIONS.UPDATE) {
          this._wss_onUpdateProject(jsData);
        } else if (jsData?.action === WSS_ACTIONS.DELETE) {
          this._wss_onDeleteProject(jsData);
        }
      });
    } else if (
      jsData.for ===
      WSS_EVENTS.PROFILER_PROJECTS_STORE.ROFILER_PROJECT_OWNER_LAST_CHECKED_DATE_UPDATE
    ) {
      runInAction(() => {
        this._wss_onOwnerLastCheckedDateUpdate(jsData);
      });
    } else if (jsData.for === WSS_EVENTS.PROFILER_PROJECTS_STORE.PROFILER_WATCHLIST_COMPANY) {
      runInAction(() => {
        if (jsData?.action === WSS_ACTIONS.CREATE) {
          this._wss_onWatchlistCompanyCreate(jsData.data);
        } else if (jsData?.action === WSS_ACTIONS.DELETE) {
          this._wss_onWatchlistCompanyDelete(jsData.data);
        }
      });
    } else if (jsData.for === WSS_EVENTS.PROFILER_PROJECTS_STORE.PROFILER_WATCHLIST_NEWS_UPDATE) {
      runInAction(() => {
        this._wss_onWatchlistCompanyNewsSeenDateUpdate(jsData);
      });
    }
  };

  _wss_onCreateProject = (jsData) => {
    const project = jsData.data;

    const foundProject = this.allProjects.find((p) => p.id === project.id);
    if (foundProject) {
      Object.entries(project).forEach(([prop, value]) => {
        foundProject[prop] = value;
      });
    } else {
      this.allProjects.push(project);
    }
  };

  _wss_onUpdateProject = (jsData) => {
    const project = jsData.data;

    const foundProject = this.allProjects.find((p) => p.id === project.id);
    if (foundProject) {
      let shouldUpdateLastCheckedDate = false;
      if (foundProject.ownerId === this.root.authStore.currentUserId) {
        const foundProjectLastResolvedDate =
          foundProject?.sharedUsers?.sort?.((a, b) => b.dateResolved - a.dateResolved)?.[0]
            ?.dateResolved || 0;
        const updatedProjectDataLastResolvedDate =
          project?.sharedUsers?.sort?.((a, b) => b.dateResolved - a.dateResolved)?.[0]
            ?.dateResolved || 0;
        if (
          updatedProjectDataLastResolvedDate > foundProjectLastResolvedDate &&
          updatedProjectDataLastResolvedDate > foundProject.ownerLastCheckedDate
        ) {
          shouldUpdateLastCheckedDate = true;
        }
      }
      Object.entries(project).forEach(([prop, value]) => {
        foundProject[prop] = value;
      });
      console.log('foundProject', foundProject);
      if (shouldUpdateLastCheckedDate) {
        setTimeout(() => {
          pubSub.publish({
            event: 'update-profiler-project-last-checked-date',
            projectId: project.id
          });
        }, 0);
      }
    }
  };

  _wss_onDeleteProject = (jsData) => {
    const projectId = jsData.data?.id;

    const foundProjectIndex = this.allProjects.findIndex((p) => p.id === projectId);
    if (foundProjectIndex !== -1) {
      this.allProjects.splice(foundProjectIndex, 1);
    }
  };

  _wss_onOwnerLastCheckedDateUpdate = (jsData) => {
    const projectId = jsData.data?.projectId;

    const foundProjectIndex = this.allProjects.findIndex((p) => p.id === projectId);
    if (foundProjectIndex !== -1) {
      this.allProjects[foundProjectIndex].ownerLastCheckedDate = jsData.data.date;
    }
  };

  _wss_onWatchlistCompanyCreate = (jsData) => {
    console.log('jsData', jsData);
    const foundCompanyIndex = this.watchlist.findIndex((c) => c.id === jsData.data?.id);
    if (foundCompanyIndex !== -1) {
      return;
    }

    this.watchlist.push(jsData.data);
    this.root.utilsStore.setHeaderMessage(
      `You have successfully added company '${jsData.data.name}' to your watchlist.`
    );
  };

  _wss_onWatchlistCompanyDelete = (jsData) => {
    const companyId = jsData.data?.companyId;
    const foundCompanyIndex = this.watchlist.findIndex((c) => c.id === companyId);
    if (foundCompanyIndex === -1) {
      return;
    }

    const companyName = this.watchlist[foundCompanyIndex].name;
    pubSub.publish({ event: 'remove-watchlist-company', companyId });
    this.watchlist.splice(foundCompanyIndex, 1);
    this.root.utilsStore.setHeaderMessage(
      `You have successfully removed company '${companyName}' from your watchlist.`
    );
  };

  _wss_onWatchlistCompanyNewsSeenDateUpdate = (jsData) => {
    const companyId = jsData.data?.companyId;

    const foundCompanyIndex = this.watchlist.findIndex((c) => c.id === companyId);
    if (foundCompanyIndex !== -1) {
      this.watchlist[foundCompanyIndex].lastSeenNews = jsData.data.timestamp;
    }
  };

  // active
  get projects() {
    const currentUserId = this.root.authStore.currentUserId;

    if (!currentUserId) {
      return [];
    }

    return this.allProjects.filter((p) => p.ownerId === currentUserId && p.status === 'ACTIVE');
  }

  // archived
  get archivedProjects() {
    const currentUserId = this.root.authStore.currentUserId;

    if (!currentUserId) {
      return [];
    }

    return this.allProjects.filter((p) => p.ownerId === currentUserId && p.status === 'ARCHIVED');
  }

  get pendingSharedProjects() {
    const currentUserId = this.root.authStore.currentUserId;

    if (!currentUserId) {
      return [];
    }

    return this.allProjects.filter((p) => {
      const foundUser = p.sharedUsers?.find?.((user) => user.id === currentUserId);
      if (!foundUser) {
        return false;
      }
      return foundUser.status === 'PENDING';
    });
  }

  get resolvedSharedProjects() {
    const currentUserId = this.root.authStore.currentUserId;

    if (!currentUserId) {
      return [];
    }

    return this.allProjects.filter((p) => {
      const foundUser = p.sharedUsers?.find?.((user) => user.id === currentUserId);
      if (!foundUser) {
        return false;
      }
      return foundUser.status === 'RESOLVED';
    });
  }

  get hasNewProjectFeedback() {
    const currentUserId = this.root.authStore.currentUserId;

    return !!this.allProjects.filter((p) => {
      if (p.ownerId !== currentUserId || p.status === 'ARCHIVED') {
        return false;
      }

      return this.hasProjectNewFeedback(p);
    }).length;
  }

  hasProjectNewFeedback = (project) => {
    const ownerLastCheckedDate = project.ownerLastCheckedDate || 0;
    if (
      project.sharedUsers?.findIndex?.(
        (u) => !!u.dateResolved && u.dateResolved > ownerLastCheckedDate
      ) !== -1
    ) {
      return true;
    }

    return false;
  };

  get isLoadingProjectsData() {
    return this.isLoadingProjects || this.initialLoadProjects;
  }

  loadProjects = () => {
    if (
      this.isLoadingProjects ||
      this.root.authStore.userVerificationStatus !== ACCOUNT_VERIF_TYPES.APPROVED ||
      !this.root.profilerAccessStore.hasAnyProfilerAccess
    ) {
      if (!this.root.profilerAccessStore.hasAnyProfilerAccess && this.initialLoadProjects) {
        runInAction(() => {
          this.initialLoadProjects = false;
        });
      }
      return;
    }

    this.isLoadingProjects = true;
    this.loadProjectsError = null;
    this.root.makeRequests({
      requests: [
        { endpoint: API_ENDPOINTS.GET_SELF_COMPANY_USERS },
        { endpoint: API_ENDPOINTS.GET_PROFILER_PROJECTS }
      ],
      onSuccess: (response) => {
        const companyUsers = response[0];
        let projects = response[1].map((p) => {
          const searchCategory =
            p.searchCategory ||
            UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company criteria'];
          let fieldsToMap = null;
          if (
            searchCategory ===
            UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company criteria']
          ) {
            fieldsToMap = PROFILER_SEARCH_FIELDS;
          } else {
            fieldsToMap = PROFILER_SEARCH_FIELDS;
          }

          return {
            ...p,
            ...mapData(p, PROFILER_PROJECT_FIELDS),
            searchCategory,
            ...(fieldsToMap ? { fields: mapData(p.searchFields, fieldsToMap) } : {})
          };
        });

        if (!this.root.profilerAccessStore.canUseProfilerSearch) {
          // the user can only give feedback and previously had profiler search/project access
          // exclude his own projects
          projects = projects.filter((p) => p.ownerId !== this.root.authStore.currentUserId);
        }

        // if the project was shared to the current user from someone that no longer has profiler access -> exlude it
        projects = projects.filter(
          (p) =>
            companyUsers.findIndex(
              (u) =>
                (u.id !== this.root.authStore.currentUserId &&
                  u.id === p.ownerId &&
                  (u.isCognitoProfilerLite ||
                    u.isCognitoProfilerDemo ||
                    u.isCognitoProfilerFull)) ||
                p.ownerId === this.root.authStore.currentUserId
            ) !== -1
        );

        this.allProjects = projects;
      },
      onError: (errorMessage) => {
        this.loadProjectsError =
          '[Profiler] ' + (errorMessage || 'Failed to obtain profiler projects.');
      },
      onFinally: () => {
        this.isLoadingProjects = false;
        if (this.initialLoadProjects) {
          this.initialLoadProjects = false;
        }
      }
    });
  };

  saveProject = (
    searchId = '',
    name = '',
    searchReason,
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.SAVE_PROFILER_PROJECT,
      body: { searchId, name, searchReason },
      onSuccess: (response) => {
        this.root.utilsStore.setHeaderMessage(
          `You have successfully saved project with name '${name}'.`
        );
        onSuccess(response);
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          `Failed to save project with name '${name}'.`,
          HEADER_MESSAGE_TYPES.ERROR
        );
        onError(errorMessage);
      },
      onFinally
    });
  };

  archiveProject = (
    projectId = '',
    archive = true,
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isArchivingProject) {
      return;
    }

    this.isArchivingProject = true;

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.ARCHIVE_PROFILER_PROJECT,
      body: { projectId, archive },
      onSuccess: (response) => {
        this.root.utilsStore.setHeaderMessage(
          `Successfully ${archive ? 'archived' : 'unarchived'} project.`
        );
        onSuccess();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          `Failed to ${archive ? 'archive' : 'unarchive'} project.`,
          HEADER_MESSAGE_TYPES.ERROR
        );
        onError(errorMessage);
      },
      onFinally: () => {
        this.isArchivingProject = false;
        onFinally();
      }
    });
  };

  deleteProjects = (
    projectIds = [],
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isDeletingProjects) {
      return;
    }

    this.isDeletingProjects = true;

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.DELETE_PROFILER_PROJECTS,
      body: { projectIds },
      onSuccess: () => {
        this.root.utilsStore.setHeaderMessage(
          `Successfully deleted ${projectIds.length ? 'projects' : 'project'}.`
        );
        onSuccess();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          `Failed to delete ${projectIds.length ? 'projects' : 'project'}.`,
          HEADER_MESSAGE_TYPES.ERROR
        );
        onError(errorMessage);
      },
      onFinally: () => {
        this.isDeletingProjects = false;
        onFinally();
      }
    });
  };

  requestProjectFeedback = (
    projectId = '',
    askUserIds = [],
    note = '',
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isRequestingProjectFeedback) {
      return;
    }

    this.isRequestingProjectFeedback = true;

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.REQUEST_PROFILER_PROJECT_FEEDBACK,
      body: { projectId, askUserIds, note },
      onSuccess: (response) => {
        this.root.utilsStore.setHeaderMessage('Successfully requested feedback.');
        onSuccess();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          'Failed to request feedback.',
          HEADER_MESSAGE_TYPES.ERROR
        );
        onError(errorMessage);
      },
      onFinally: () => {
        this.isRequestingProjectFeedback = false;
        onFinally();
      }
    });
  };

  resolveProjectFeedback = (
    projectId = '',
    comment = '',
    feedbacks = [],
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isResolvingProjectFeedback) {
      return;
    }

    this.isResolvingProjectFeedback = true;

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.RESOLVE_PROFILER_PROJECT_FEEDBACK,
      body: { projectId, comment, feedbacks },
      onSuccess: (response) => {
        this.root.utilsStore.setHeaderMessage('Successfully resolved feedback.');
        onSuccess();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          'Failed to resolve feedback.',
          HEADER_MESSAGE_TYPES.ERROR
        );
        onError(errorMessage);
      },
      onFinally: () => {
        this.isResolvingProjectFeedback = false;
        onFinally();
      }
    });
  };

  updateProject = (
    projectId = '',
    name = '',
    searchReason = null,
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isUpdatingProject) {
      return;
    }

    this.isUpdatingProject = true;

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.UPDATE_PROFILER_PROJECT,
      body: { projectId, name, searchReason },
      onSuccess: (response) => {
        this.root.utilsStore.setHeaderMessage(`Successfully updated project '${name}'.`);
        onSuccess();
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          errorMessage || `Failed to update project '${name}'.`,
          HEADER_MESSAGE_TYPES.ERROR
        );
        onError(errorMessage || `Failed to update project '${name}'.`);
      },
      onFinally: () => {
        this.isUpdatingProject = false;
        onFinally();
      }
    });
  };

  updateProjectLastCheckedDate = (
    projectId = '',
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    const foundProject = this.allProjects.find((p) => p.id === projectId);
    if (!foundProject) {
      return;
    }

    const ownerLastCheckedDate = foundProject.ownerLastCheckedDate || 0;
    const lastResolvedDate =
      foundProject.sharedUsers
        ?.filter?.((u) => !!u.dateResolved)
        ?.sort?.((a, b) => b.dateResolved - a.dateResolved)?.[0]?.dateResolved || 0;

    if (!lastResolvedDate || ownerLastCheckedDate > lastResolvedDate) {
      return;
    }

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.UPDATE_PROFILER_PROJECT_LAST_CHECKED_DATE,
      body: { projectId, date: lastResolvedDate + 1 },
      onSuccess: (response) => {
        onSuccess(response);
      },
      onError: (errorMessage) => {
        onError(errorMessage);
      },
      onFinally: () => {
        onFinally();
      }
    });
  };

  loadWatchlist = () => {
    if (
      this.isLoadingWatchlist ||
      this.root.authStore.userVerificationStatus !== ACCOUNT_VERIF_TYPES.APPROVED ||
      !this.root.profilerAccessStore.canViewProfilerWatchlist
    ) {
      return;
    }

    this.isLoadingWatchlist = true;
    this.loadWatchlistError = null;
    this.root.makeRequest({
      endpoint: API_ENDPOINTS.GET_PROFILER_WATCHLIST,
      onSuccess: (response) => {
        this.watchlist = response;
      },
      onError: (errorMessage) => {
        this.loadWatchlistError = errorMessage || 'Failed to obtain watchlist.';
      },
      onFinally: () => {
        this.isLoadingWatchlist = false;
        if (this.initialLoadWatchlist) {
          this.initialLoadWatchlist = false;
        }
      }
    });
  };

  addToWatchlist = (
    company = {},
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isAddingToProfilerWatchlist) {
      return;
    }

    const [companyId, companyName] = [company.id, company.name];

    const add = this.watchlist.findIndex((c) => c.id === companyId) === -1;
    runInAction(() => {
      this.isAddingToProfilerWatchlist = true;
      if (!add) {
        this.removingFromWatchlistEntries[companyId] = true;
      }
    });

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.ADD_TO_PROFILER_WATCHLIST,
      body: { companyId, add },
      onSuccess: (response) => {
        if (add) {
          this._wss_onWatchlistCompanyCreate({ data: mapCompanyEntry(response) });
        } else {
          this._wss_onWatchlistCompanyDelete({ data: { companyId } });
        }
        onSuccess(response);
      },
      onError: (errorMessage) => {
        this.root.utilsStore.setHeaderMessage(
          `Failed to ${add ? 'add' : 'remove'} company '${companyName}' ${
            add ? 'to' : 'from'
          } your watchlist.`,
          HEADER_MESSAGE_TYPES.ERROR
        );
        onError(errorMessage);
      },
      onFinally: () => {
        this.isAddingToProfilerWatchlist = false;
        this.removingFromWatchlistEntries[companyId] = false;
        onFinally();
      }
    });
  };

  updateWatchlistCompanyNewsSeenDate = (
    companyId = '',
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    const foundCompany = this.watchlist.find((c) => c.id === companyId);
    if (!foundCompany) {
      return;
    }

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.UPDATE_PROFILER_WATCHLIST_NEWS_CHECKED,
      body: { companyId, timestamp: Date.now() },
      onSuccess: (response) => {
        onSuccess(response);
      },
      onError: (errorMessage) => {
        onError(errorMessage);
      },
      onFinally: () => {
        onFinally();
      }
    });
  };
}

export default ProfilerProjectsStore;
