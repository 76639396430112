export const OPTION_KEYS = {
  PHONE_CODES: 'phoneCodes',
  COMPANY_TYPES: 'companyTypes',
  INDUSTRIES: 'industries',
  PERSONAL_COUNTRIES: 'personalCountries',
  COUNTRIES: 'countries',
  GEOGRAPHIES: 'geographies',
  EQUITY_STAKES: 'equityStakes',
  SITUATIONS: 'situations',
  PROFILER_HEADQUARTER_COUNTRIES: 'profilerHeadquarterCountries',
  SEARCH_REASONS: 'searchReasons'
};
