import { observer, useLocalObservable } from 'mobx-react';
import StaticPageLayout from '../../../components/layouts/StaticPageLayout';
import OnboardingSteps from './agent/OnboardingSteps';
import FundsInvestmentCriteriaSearchFormDesktop from './agent/FundsInvestmentCriteriaSearchFormDesktop';
import FundsInvestmentCriteriaSearchFormMobile from './agent/FundsInvestmentCriteriaSearchFormMobile';
import SearchFormMobileSection from './agent/SearchFormMobileSection';
import {
  FUND_SEARCH_CATEGORY_FIELDS,
  PORTFOLIO_SEARCH_CATEGORY_FIELDS,
  PROFILER_SEARCH_CATEGORY_FIELDS,
  PROFILER_SEARCH_FIELDS,
  SEARCH_FIELDS,
  SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA,
  getErrorFields,
  trimStateFields
} from '../../../utils/constants/fields';
import {
  getFilledSearchCategoriesCount,
  mapFieldsToState,
  formatNumber
} from '../../../utils/utils';
import { paths } from '../../../utils/constants/routes';
import { getFundsInvestmentCriteriaSearchFields } from './agent/constants/fundsInvestmentCriteriaSearchFields';
import useHistory from '../../../hooks/useHistory';
import {
  PROFILER_SEARCH_CATEGORIES,
  SEARCH_CATEGORIES
} from '../../../utils/constants/searchCategories';
import { UI_OPTIONS } from '../../../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../../../utils/constants/uiOptionKeys';
import { useStore } from '../../../store/store';
import { Tooltip } from 'react-tooltip';
import ProfilerCompanyCriteriaSearchFormDesktop from './agent/ProfilerCompanyCriteriaSearchFormDesktop';
import { getProfilerCompanyCriteriaSearchFields } from './agent/constants/profilerCompanyCriteriaSearchFields';
import ProfilerCompanyCriteriaSearchFormMobile from './agent/ProfilerCompanyCriteriaSearchFormMobile';
import ProfilerCompanyNameSearchFormDesktop from './agent/ProfilerCompanyNameSearchFormDesktop';
import ProfilerCompanyNameSearchFormMobile from './agent/ProfilerCompanyNameSearchFormMobile';
import ProfilerCompanyBulkSearchFormDesktop from './agent/ProfilerCompanyBulkSearchFormDesktop';
import ProfilerCompanyBulkSearchFormMobile from './agent/ProfilerCompanyBulkSearchFormMobile';
import { Fragment } from 'react';
import { getPortfoliosInvestmentCriteriaSearchFields } from './agent/constants/portfoliosInvestmentCriteriaSearchFields';
import PortfoliosInvestmentCriteriaSearchFormDesktop from './agent/PortfoliosInvestmentCriteriaSearchFormDesktop';
import PortfoliosInvestmentCriteriaSearchFormMobile from './agent/PortfoliosInvestmentCriteriaSearchFormMobile';

const AgentView = observer(({ isFromInvestorView = false }) => {
  const { profilerAccessStore } = useStore();
  const { navigate } = useHistory();
  const state = useLocalObservable(() => ({
    // funds investment criteria state
    isFundsInvestmentCriteriaMobileFiltersDisplayed: false,
    toggleFundsInvestmentCriteriaMobileFilters: () => {
      state.isFundsInvestmentCriteriaMobileFiltersDisplayed =
        !state.isFundsInvestmentCriteriaMobileFiltersDisplayed;
      const fundsInvestmentCriteriaSavedScrollY = state.fundsInvestmentCriteriaSavedScrollY;
      state.fundsInvestmentCriteriaSavedScrollY = 0;
      setTimeout(() => window.scrollTo({ top: fundsInvestmentCriteriaSavedScrollY }), 0);
    },
    fundsInvestmentCriteriaSavedScrollY: 0,
    fundsInvestmentCriteriaMobileFilterSection: null,
    setFundsInvestmentCriteriaMobileFilterSection: (value) => {
      state.fundsInvestmentCriteriaSavedScrollY = window.scrollY;
      state.fundsInvestmentCriteriaMobileFilterSection = value;
      state.isFundsInvestmentCriteriaMobileFiltersDisplayed = true;
    },
    fundsInvestmentCriteriaFields: {
      ...mapFieldsToState(SEARCH_FIELDS),
      [SEARCH_FIELDS.SEARCH_TYPE.NAME]: [
        isFromInvestorView
          ? UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler
          : UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds
      ]
    },
    setFundsInvestmentCriteriaFieldValue: (field = {}, value) => {
      state.fundsInvestmentCriteriaFields[field.NAME] = value;
    },
    get fundsInvestmentCriteriaFieldsCount() {
      return getFilledSearchCategoriesCount(
        state.fundsInvestmentCriteriaFields,
        SEARCH_CATEGORIES,
        SEARCH_FIELDS
      );
    },
    onFundsInvestmentCriteriaSubmitErrorState: false,
    setFundsInvestmentCriteriaOnSubmitErrorState: (value = false) =>
      (state.onFundsInvestmentCriteriaSubmitErrorState = value),
    get fundsInvestmentCriteriaValidationFields() {
      return getErrorFields(Object.values(SEARCH_FIELDS), state.fundsInvestmentCriteriaFields);
    },
    get isFundsInvestmentCriteriaSearchDisabled() {
      return (
        state.fundsInvestmentCriteriaValidationFields.invalidFields.filter((f) => !f.isOnSubmit)
          .length ||
        (state.onFundsInvestmentCriteriaSubmitErrorState &&
          state.fundsInvestmentCriteriaValidationFields.invalidFields.length)
      );
    },
    fundsInvestmentCriteriaUIFormState: {
      isEBITDAFieldFocused: false,
      isEBITDAMarginFieldFocused: false,
      get hasRevenueValue() {
        return !!state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.REVENUE.NAME]?.length;
      },
      get isEBITDAFieldDisabled() {
        return (
          !state.fundsInvestmentCriteriaUIFormState.hasRevenueValue ||
          state.fundsInvestmentCriteriaUIFormState.isEBITDAMarginFieldFocused
        );
      },
      get isEBITDAMarginFieldDisabled() {
        return (
          !state.fundsInvestmentCriteriaUIFormState.hasRevenueValue ||
          state.fundsInvestmentCriteriaUIFormState.isEBITDAFieldFocused
        );
      },
      onRevenueChange: (revenue) => {
        const hasValue = !!revenue?.length;
        if (hasValue) {
          const EBITDAValue = state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.EBITDA.NAME];
          const EBITDAMarginValue =
            state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.EBITDA_MARGIN.NAME];
          if (EBITDAValue) {
            state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.EBITDA_MARGIN.NAME] = formatNumber(
              ((+EBITDAValue / +revenue) * 100).toFixed(2),
              ''
            );
          } else if (EBITDAMarginValue) {
            state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.EBITDA.NAME] = formatNumber(
              ((+revenue * +EBITDAMarginValue) / 100).toFixed(2),
              ''
            );
          }
        }
      },
      onEBITDAChange: (EBITDA) => {
        const hasValue = !!EBITDA?.length;
        if (hasValue) {
          const revenue = state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.REVENUE.NAME];
          state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.EBITDA_MARGIN.NAME] = formatNumber(
            ((+EBITDA / +revenue) * 100).toFixed(2),
            ''
          );
        }
      },
      onEBITDAFocus: () => {
        state.fundsInvestmentCriteriaUIFormState.isEBITDAFieldFocused = true;
      },
      onEBITDABlur: () => {
        state.fundsInvestmentCriteriaUIFormState.isEBITDAFieldFocused = false;
      },
      onEBITDAMarginChange: (EBITDAMargin) => {
        const hasValue = !!EBITDAMargin?.length;
        if (hasValue) {
          const revenue = state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.REVENUE.NAME];
          state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.EBITDA.NAME] = formatNumber(
            ((+revenue * +EBITDAMargin) / 100).toFixed(2),
            ''
          );
        }
      },
      onEBITDAMarginFocus: () => {
        state.fundsInvestmentCriteriaUIFormState.isEBITDAMarginFieldFocused = true;
      },
      onEBITDAMarginBlur: () => {
        state.fundsInvestmentCriteriaUIFormState.isEBITDAMarginFieldFocused = false;
      }
    },
    // portfolios investment criteria state
    portfoliosInvestmentCriteriaFields: {
      ...mapFieldsToState(SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA),
      [SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA.SEARCH_TYPE.NAME]: [
        UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.']
      ]
    },
    setPortfoliosInvestmentCriteriaFieldValue: (field = {}, value) => {
      state.portfoliosInvestmentCriteriaFields[field.NAME] = value;
    },
    onPortfoliosInvestmentCriteriaSubmitErrorState: false,
    setPortfoliosInvestmentCriteriaOnSubmitErrorState: (value = false) =>
      (state.onPortfoliosInvestmentCriteriaSubmitErrorState = value),
    get portfoliosInvestmentCriteriaValidationFields() {
      return getErrorFields(
        Object.values(SEARCH_FIELDS_PORTFOLIO_INVESTMENT_CRITERIA),
        state.portfoliosInvestmentCriteriaFields
      );
    },
    get isPortfoliosInvestmentCriteriaSearchDisabled() {
      return (
        state.portfoliosInvestmentCriteriaValidationFields.invalidFields.filter(
          (f) => !f.isOnSubmit
        ).length ||
        (state.onPortfoliosInvestmentCriteriaSubmitErrorState &&
          state.portfoliosInvestmentCriteriaValidationFields.invalidFields.length)
      );
    },
    // profiler company criteria state
    isProfilerCompanyCriteriaMobileFiltersDisplayed: false,
    toggleProfilerCompanyCriteriaMobileFilters: () => {
      state.isProfilerCompanyCriteriaMobileFiltersDisplayed =
        !state.isProfilerCompanyCriteriaMobileFiltersDisplayed;
      const profilerCompanyCriteriaSavedScrollY = state.profilerCompanyCriteriaSavedScrollY;
      state.profilerCompanyCriteriaSavedScrollY = 0;
      setTimeout(() => window.scrollTo({ top: profilerCompanyCriteriaSavedScrollY }), 0);
    },
    profilerCompanyCriteriaSavedScrollY: 0,
    profilerCompanyCriteriaMobileFilterSection: null,
    setProfilerCompanyCriteriaMobileFilterSection: (value) => {
      state.profilerCompanyCriteriaSavedScrollY = window.scrollY;
      state.profilerCompanyCriteriaMobileFilterSection = value;
      state.isProfilerCompanyCriteriaMobileFiltersDisplayed = true;
    },
    profilerCompanyCriteriaFields: {
      ...mapFieldsToState(PROFILER_SEARCH_FIELDS),
      [PROFILER_SEARCH_FIELDS.SEARCH_TYPE.NAME]: [UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler]
    },
    setProfilerCompanyCriteriaFieldValue: (field = {}, value) => {
      state.profilerCompanyCriteriaFields[field.NAME] = value;
    },
    get profilerCompanyCriteriaFieldsCount() {
      return getFilledSearchCategoriesCount(
        state.profilerCompanyCriteriaFields,
        PROFILER_SEARCH_CATEGORIES,
        PROFILER_SEARCH_FIELDS
      );
    },
    onProfilerCompanyCriteriaSubmitErrorState: false,
    setProfilerCompanyCriteriaOnSubmitErrorState: (value = false) =>
      (state.onProfilerCompanyCriteriaSubmitErrorState = value),
    get profilerCompanyCriteriaValidationFields() {
      return getErrorFields(
        Object.values(PROFILER_SEARCH_FIELDS),
        state.profilerCompanyCriteriaFields
      );
    },
    get isProfilerCompanyCriteriaSearchDisabled() {
      return (
        state.profilerCompanyCriteriaValidationFields.invalidFields.filter((f) => !f.isOnSubmit)
          .length ||
        (state.onProfilerCompanyCriteriaSubmitErrorState &&
          state.profilerCompanyCriteriaValidationFields.invalidFields.length) ||
        state.isProfilerCompanyCriteriaSearchFieldsDisabled
      );
    },
    get isProfilerCompanyCriteriaSearchFieldsDisabled() {
      return profilerAccessStore.isProfilerDemo && profilerAccessStore.demoSearchExceededLimit;
    },
    // rest
    get fundsCategories() {
      return Object.entries(UI_OPTIONS[UI_OPTION_KEYS.FUND_SEARCH_CATEGORY]).map(
        ([name, value]) => ({
          name,
          value,
          disabled: value !== UI_OPTIONS[UI_OPTION_KEYS.FUND_SEARCH_CATEGORY]['Investment criteria']
        })
      );
    },
    get portfoliosCategories() {
      return Object.entries(UI_OPTIONS[UI_OPTION_KEYS.PORTFOLIO_SEARCH_CATEGORY]).map(
        ([name, value]) => ({
          name,
          value,
          disabled:
            value !== UI_OPTIONS[UI_OPTION_KEYS.PORTFOLIO_SEARCH_CATEGORY]['Investment criteria']
        })
      );
    },
    get profilerCategories() {
      return Object.entries(UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]).map(
        ([name, value]) => ({ name, value })
      );
    },
    fundsSearchCategoryFields: {
      [FUND_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME]: [
        UI_OPTIONS[UI_OPTION_KEYS.FUND_SEARCH_CATEGORY]['Investment criteria']
      ]
    },
    setFundsSearchCategoryFieldValue: (field = {}, value) => {
      state.fundsSearchCategoryFields[field.NAME] = value;
    },
    portfoliosSearchCategoryFields: {
      [PORTFOLIO_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME]: [
        UI_OPTIONS[UI_OPTION_KEYS.PORTFOLIO_SEARCH_CATEGORY]['Investment criteria']
      ]
    },
    setPortfoliosSearchCategoryFieldValue: (field = {}, value) => {
      state.portfoliosSearchCategoryFields[field.NAME] = value;
    },
    profilerSearchCategoryFields: {
      [PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME]: [
        UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company criteria']
      ]
    },
    setProfilerSearchCategoryFieldValue: (field = {}, value) => {
      state.profilerSearchCategoryFields[field.NAME] = value;
    },
    get disableProfilerSearchSelectionMobile() {
      return !profilerAccessStore.canUseProfilerSearch;
    }
  }));

  const fundsInvestmentCriteriaFields = getFundsInvestmentCriteriaSearchFields(
    state,
    state.fundsInvestmentCriteriaUIFormState,
    isFromInvestorView
  );
  const portfoliosInvestmentCriteriaFields = getPortfoliosInvestmentCriteriaSearchFields(state);
  const profilerCompanyCriteriaFields = getProfilerCompanyCriteriaSearchFields(state);

  const setTabValue = (value) =>
    state.setFundsInvestmentCriteriaFieldValue(SEARCH_FIELDS.SEARCH_TYPE, [value]);

  const tabs = [
    ...(!isFromInvestorView
      ? [
          {
            id: 'agent-view-funds-tab',
            name: 'Funds',
            value: UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds,
            isSelected:
              state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.SEARCH_TYPE.NAME][0] ===
              UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds,
            setTabValue: () => setTabValue(UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds)
          },
          {
            id: 'agent-view-portfolios-tab',
            name: 'Portfolio companies',
            value: UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'],
            isSelected:
              state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.SEARCH_TYPE.NAME][0] ===
              UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'],
            setTabValue: () => setTabValue(UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'])
          }
        ]
      : []),
    .../*['dev', 'stg']*/ ([].includes(process.env.REACT_APP_ENVIRONMENT)
      ? [
          {
            id: 'agent-view-corporates-tab',
            name: 'Corporates',
            value: UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Corporates,
            isSelected:
              state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.SEARCH_TYPE.NAME][0] ===
              UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Corporates,
            setTabValue: () => setTabValue(UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Corporates)
          }
        ]
      : []),
    {
      id: 'agent-view-profiler-tab',
      name: 'Corporates',
      value: UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler,
      isSelected:
        state.fundsInvestmentCriteriaFields[SEARCH_FIELDS.SEARCH_TYPE.NAME][0] ===
        UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler,
      setTabValue: () => setTabValue(UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler),
      disabled: !profilerAccessStore.canUseProfilerSearch,
      // tooltipText: !profilerAccessStore.canUseProfilerSearch
      //   ? 'Please contact us for further information: support@transact.digital'
      //   : ''
      tooltipContent: !profilerAccessStore.canUseProfilerSearch ? (
        <div className="tooltip-content-disabled-profiler-corporates-tab">
          Need access to corporate company profiles? Contact{' '}
          <a href="mailto:support@transact.digital">support@transact.digital</a> for assistance.
        </div>
      ) : null
    }
  ];

  const desktopTabs = (
    <Fragment>
      <div className="tabs">
        <div className="tabs-scrollable-container">
          {tabs.map(({ id, name, isSelected, disabled, setTabValue }) => {
            const classNames = ['tab'];
            if (isSelected) {
              classNames.push('selected');
            }
            if (disabled) {
              classNames.push('disabled');
            }
            const className = classNames.join(' ');
            return (
              <div
                id={id}
                className={className}
                key={id}
                onClick={() => {
                  if (isSelected || disabled) {
                    return;
                  }

                  setTabValue();
                }}>
                {name}
              </div>
            );
          })}
        </div>
      </div>
      {tabs
        .filter((t) => t.tooltipContent)
        .map(({ id, tooltipContent }) => {
          return (
            <Tooltip
              key={id}
              anchorSelect={`#${id}`}
              multiline={true}
              variant="light"
              opacity={1}
              noArrow
              place="bottom-center"
              className={`icon-button-tooltip tooltip-type-default`}
              clickable
              {...{ children: tooltipContent }}
            />
          );
        })}
    </Fragment>
  );

  const selectedTabValue = tabs.find(({ isSelected }) => isSelected).value;
  const isFundSearch = selectedTabValue === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Funds;
  const isPortfolioSearch =
    selectedTabValue === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE]['Portfolio Co.'];
  const isCorporatesSearch = selectedTabValue === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Corporates;
  const isProfilerSearch = selectedTabValue === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler;

  const onSearch = (e) => {
    e?.preventDefault?.();

    if (isFundSearch) {
      if (
        state.fundsSearchCategoryFields[FUND_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME][0] ===
        UI_OPTIONS[UI_OPTION_KEYS.FUND_SEARCH_CATEGORY]['Investment criteria']
      ) {
        trimStateFields(state.fundsInvestmentCriteriaFields);
        if (state.fundsInvestmentCriteriaValidationFields.invalidFields.length) {
          if (!state.onFundsInvestmentCriteriaSubmitErrorState) {
            state.setFundsInvestmentCriteriaOnSubmitErrorState(true);
          }
          return;
        }

        const fields = Object.entries(state.fundsInvestmentCriteriaFields)
          .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
          .join('&');

        navigate(
          paths.SEARCH_RESULTS +
            `?${FUND_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME}=${encodeURIComponent(
              state.fundsSearchCategoryFields[FUND_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME][0]
            )}&page=1&${fields}`
        );
      }
    }

    if (isPortfolioSearch) {
      if (
        state.portfoliosSearchCategoryFields[
          PORTFOLIO_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME
        ][0] === UI_OPTIONS[UI_OPTION_KEYS.PORTFOLIO_SEARCH_CATEGORY]['Investment criteria']
      ) {
        trimStateFields(state.portfolioInvestmentCriteriaFields);
        if (state.portfoliosInvestmentCriteriaValidationFields.invalidFields.length) {
          if (!state.onPortfoliosInvestmentCriteriaSubmitErrorState) {
            state.setPortfoliosInvestmentCriteriaOnSubmitErrorState(true);
          }
          return;
        }

        const fields = Object.entries(state.portfoliosInvestmentCriteriaFields)
          .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
          .join('&');

        navigate(
          paths.SEARCH_RESULTS +
            `?${PORTFOLIO_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME}=${encodeURIComponent(
              state.portfoliosSearchCategoryFields[
                PORTFOLIO_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME
              ][0]
            )}&page=1&${fields}`
        );
      }
    }

    if (isProfilerSearch) {
      if (
        state.profilerSearchCategoryFields[
          PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME
        ][0] === UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company criteria']
      ) {
        trimStateFields(state.profilerCompanyCriteriaFields);
        if (state.profilerCompanyCriteriaValidationFields.invalidFields.length) {
          if (!state.onProfilerCompanyCriteriaSubmitErrorState) {
            state.setProfilerCompanyCriteriaOnSubmitErrorState(true);
          }
          return;
        }

        const fields = Object.entries(state.profilerCompanyCriteriaFields)
          .map(([k, v]) => `${[k]}=${encodeURIComponent(JSON.stringify(v))}`)
          .join('&');

        navigate(
          paths.SEARCH_RESULTS +
            `?${PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME}=${encodeURIComponent(
              state.profilerSearchCategoryFields[
                PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME
              ][0]
            )}&page=1&${fields}`
        );
      }
    }
  };

  const title =
    isFundSearch || isPortfolioSearch || isCorporatesSearch
      ? 'Create your list of potential buyers'
      : isProfilerSearch
      ? 'Find relevant companies and insights'
      : 'Unknown search';

  let fundsInvestmentCriteriaErroredSections = Object.fromEntries(
    Object.values(SEARCH_CATEGORIES).map((categoryName) => [categoryName, false])
  );
  if (state.onFundsInvestmentCriteriaSubmitErrorState) {
    fundsInvestmentCriteriaErroredSections = Object.fromEntries(
      Object.values(SEARCH_CATEGORIES).map((categoryName) => {
        const sectionFieldNames = Object.values(SEARCH_FIELDS)
          .filter((fieldConfig) => fieldConfig.SECTION === categoryName)
          .map((fieldConfig) => fieldConfig.NAME);
        const errored = state.fundsInvestmentCriteriaValidationFields.invalidFields.some(
          ({ name }) => sectionFieldNames.includes(name)
        );
        return [categoryName, errored];
      })
    );
  }

  let profilerCompanyCriteriaErroredSections = Object.fromEntries(
    Object.values(PROFILER_SEARCH_CATEGORIES).map((categoryName) => [categoryName, false])
  );
  if (state.onProfilerCompanyCriteriaSubmitErrorState) {
    profilerCompanyCriteriaErroredSections = Object.fromEntries(
      Object.values(PROFILER_SEARCH_CATEGORIES).map((categoryName) => {
        const sectionFieldNames = Object.values(PROFILER_SEARCH_FIELDS)
          .filter((fieldConfig) => fieldConfig.SECTION === categoryName)
          .map((fieldConfig) => fieldConfig.NAME);
        const errored = state.profilerCompanyCriteriaValidationFields.invalidFields.some(
          ({ name }) => sectionFieldNames.includes(name)
        );
        return [categoryName, errored];
      })
    );
  }

  return (
    <StaticPageLayout
      page="search-form"
      component={
        state.isFundsInvestmentCriteriaMobileFiltersDisplayed ||
        state.isProfilerCompanyCriteriaMobileFiltersDisplayed
          ? 'mobile-filter'
          : 'general'
      }>
      {state.isFundsInvestmentCriteriaMobileFiltersDisplayed ? (
        <SearchFormMobileSection
          section={state.fundsInvestmentCriteriaMobileFilterSection}
          onBack={state.toggleFundsInvestmentCriteriaMobileFilters}
          fields={fundsInvestmentCriteriaFields}
        />
      ) : state.isProfilerCompanyCriteriaMobileFiltersDisplayed ? (
        <SearchFormMobileSection
          section={state.profilerCompanyCriteriaMobileFilterSection}
          onBack={state.toggleProfilerCompanyCriteriaMobileFilters}
          fields={profilerCompanyCriteriaFields}
        />
      ) : (
        <div className="search-container by-agent-default">
          <h1 key={title} className="form-title">
            {title}
          </h1>
          <div className="search-fields-container">
            {isFundSearch &&
              (state.fundsSearchCategoryFields[
                FUND_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME
              ][0] === UI_OPTIONS[UI_OPTION_KEYS.FUND_SEARCH_CATEGORY]['Investment criteria'] ? (
                <>
                  <FundsInvestmentCriteriaSearchFormDesktop
                    fields={fundsInvestmentCriteriaFields}
                    isSearchDisabled={state.isFundsInvestmentCriteriaSearchDisabled}
                    onSearch={onSearch}
                    uiFormState={state.fundsInvestmentCriteriaUIFormState}
                    tabs={desktopTabs}
                    categoryFields={state.fundsSearchCategoryFields}
                    setCategoryFieldValue={state.setFundsSearchCategoryFieldValue}
                    categoryOptions={state.fundsCategories}
                  />
                  <FundsInvestmentCriteriaSearchFormMobile
                    onShowSection={(section) =>
                      state.setFundsInvestmentCriteriaMobileFilterSection(section)
                    }
                    fieldsCount={state.fundsInvestmentCriteriaFieldsCount}
                    isSearchDisabled={state.isFundsInvestmentCriteriaSearchDisabled}
                    onSearch={onSearch}
                    fields={fundsInvestmentCriteriaFields}
                    erroredSections={fundsInvestmentCriteriaErroredSections}
                    uiFormState={state.fundsInvestmentCriteriaUIFormState}
                    categoryFields={state.fundsSearchCategoryFields}
                    setCategoryFieldValue={state.setFundsSearchCategoryFieldValue}
                    categoryOptions={state.fundsCategories.filter(({ disabled }) => !disabled)}
                  />
                </>
              ) : null)}
            {isPortfolioSearch &&
              (state.portfoliosSearchCategoryFields[
                PORTFOLIO_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME
              ][0] ===
              UI_OPTIONS[UI_OPTION_KEYS.PORTFOLIO_SEARCH_CATEGORY]['Investment criteria'] ? (
                <>
                  <PortfoliosInvestmentCriteriaSearchFormDesktop
                    fields={portfoliosInvestmentCriteriaFields}
                    isSearchDisabled={state.isPortfoliosInvestmentCriteriaSearchDisabled}
                    onSearch={onSearch}
                    tabs={desktopTabs}
                    categoryFields={state.portfoliosSearchCategoryFields}
                    setCategoryFieldValue={state.setPortfoliosSearchCategoryFieldValue}
                    categoryOptions={state.portfoliosCategories}
                  />
                  <PortfoliosInvestmentCriteriaSearchFormMobile
                    searchTypeInput={
                      fundsInvestmentCriteriaFields[SEARCH_FIELDS.SEARCH_TYPE_MOBILE.ID]
                    }
                    isSearchDisabled={state.isPortfoliosInvestmentCriteriaSearchDisabled}
                    onSearch={onSearch}
                    fields={portfoliosInvestmentCriteriaFields}
                    categoryFields={state.portfoliosSearchCategoryFields}
                    setCategoryFieldValue={state.setPortfoliosSearchCategoryFieldValue}
                    categoryOptions={state.portfoliosCategories.filter(({ disabled }) => !disabled)}
                  />
                </>
              ) : null)}
            {isProfilerSearch &&
              (state.profilerSearchCategoryFields[
                PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME
              ][0] === UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company criteria'] ? (
                <>
                  <ProfilerCompanyCriteriaSearchFormDesktop
                    fields={profilerCompanyCriteriaFields}
                    isSearchDisabled={state.isProfilerCompanyCriteriaSearchDisabled}
                    onSearch={onSearch}
                    tabs={desktopTabs}
                    categoryFields={state.profilerSearchCategoryFields}
                    setCategoryFieldValue={state.setProfilerSearchCategoryFieldValue}
                    categoryOptions={state.profilerCategories}
                  />
                  <ProfilerCompanyCriteriaSearchFormMobile
                    onShowSection={(section) =>
                      state.setProfilerCompanyCriteriaMobileFilterSection(section)
                    }
                    fieldsCount={state.profilerCompanyCriteriaFieldsCount}
                    isSearchDisabled={state.isProfilerCompanyCriteriaSearchDisabled}
                    onSearch={onSearch}
                    fields={fundsInvestmentCriteriaFields}
                    erroredSections={profilerCompanyCriteriaErroredSections}
                    categoryFields={state.profilerSearchCategoryFields}
                    setCategoryFieldValue={state.setProfilerSearchCategoryFieldValue}
                    categoryOptions={state.profilerCategories.filter(({ disabled }) => !disabled)}
                  />
                </>
              ) : state.profilerSearchCategoryFields[
                  PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME
                ][0] === UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company name'] ? (
                <>
                  <ProfilerCompanyNameSearchFormDesktop
                    onSearch={onSearch}
                    tabs={desktopTabs}
                    categoryFields={state.profilerSearchCategoryFields}
                    setCategoryFieldValue={state.setProfilerSearchCategoryFieldValue}
                    categoryOptions={state.profilerCategories}
                  />
                  <ProfilerCompanyNameSearchFormMobile
                    onSearch={onSearch}
                    fields={fundsInvestmentCriteriaFields}
                    categoryFields={state.profilerSearchCategoryFields}
                    setCategoryFieldValue={state.setProfilerSearchCategoryFieldValue}
                    categoryOptions={state.profilerCategories.filter(({ disabled }) => !disabled)}
                  />
                </>
              ) : state.profilerSearchCategoryFields[
                  PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY.NAME
                ][0] ===
                UI_OPTIONS[UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]['Company bulk search'] ? (
                <>
                  <ProfilerCompanyBulkSearchFormDesktop
                    onSearch={onSearch}
                    tabs={desktopTabs}
                    categoryFields={state.profilerSearchCategoryFields}
                    setCategoryFieldValue={state.setProfilerSearchCategoryFieldValue}
                    categoryOptions={state.profilerCategories}
                  />
                  <ProfilerCompanyBulkSearchFormMobile
                    onSearch={onSearch}
                    fields={fundsInvestmentCriteriaFields}
                    categoryFields={state.profilerSearchCategoryFields}
                    setCategoryFieldValue={state.setProfilerSearchCategoryFieldValue}
                    categoryOptions={state.profilerCategories.filter(({ disabled }) => !disabled)}
                  />
                </>
              ) : null)}
          </div>
          {!isFromInvestorView && (
            <div className="onboarding-container">
              <OnboardingSteps />
            </div>
          )}
        </div>
      )}
    </StaticPageLayout>
  );
});

export default AgentView;
