import { observer } from 'mobx-react';
import IconButton, {
  ICON_BUTTON_TYPES,
  ICON_BUTTON_ICONS
} from '../../../components/buttons/IconButton';
import { useStore } from '../../../store/store';
import { formatNumber } from '../../../utils/utils';

const WatchlistCompanyResult = observer(
  ({ company, displayDetails = () => {}, setIsAddRemoveWatchlistPopupOpen = () => {} }) => {
    const { utilsStore, profilerProjectStore } = useStore();

    return (
      <div key={company.id} className="item">
        <div className="cols">
          <div className="col company-name">
            <div className="label">Company name</div>
            <div className="value">{company.name || 'N/A'}</div>
          </div>
          <div className="col">
            <div className="label">Company HQ</div>
            <div className="value">{company.headquarters || 'N/A'}</div>
          </div>
          <div className="col">
            <div className="label">Industry</div>
            <div className="value">
              {company.industries && company.industries.length
                ? utilsStore.shortenList(company.industries, 3).join(', ')
                : 'N/A'}
            </div>
          </div>
          <div className="col">
            <div className="label">Value chain</div>
            <div className="value">
              {company.valueChain && company.valueChain.length
                ? utilsStore.shortenList(company.valueChain, 3).join(', ')
                : 'N/A'}
            </div>
          </div>
          <div className="col">
            <div className="label">Products</div>
            <div className="value">
              {company.products && company.products.length
                ? utilsStore.shortenList(company.products, 3).join(', ')
                : 'N/A'}
            </div>
          </div>
          <div className="col company-revenue">
            <div className="label">Revenue (€M)</div>
            <div className="value right-align-desktop">
              {company.revenue ? (
                <>
                  {company.revenue}
                  <span className="year">
                    &lsquo;
                    {company.revenueYear?.toString?.()?.slice?.(-2) || 'n/a'}
                  </span>
                </>
              ) : (
                'N/A'
              )}
            </div>
          </div>
          <div className="col no-border company-employees">
            <div className="label">Employees</div>
            <div className="value right-align-desktop">
              {!isNaN(company.employees) ? (
                <>
                  {formatNumber((+company.employees).toFixed(2))}
                  <span className="year">
                    &lsquo;
                    {company.employeesYear?.toString?.()?.slice?.(-2) || 'n/a'}
                  </span>
                </>
              ) : (
                'N/A'
              )}
            </div>
          </div>
        </div>
        <div className="actions watchlist">
          {' '}
          <div className="col no-border view-details-btn">
            <IconButton
              filled
              type={ICON_BUTTON_TYPES.BLUE}
              onClick={() => displayDetails(company)}
              innerText="Check updates"
              disabled={!!profilerProjectStore.removingFromWatchlistEntries[company.id]}
            />
          </div>
          <div className="col no-border">
            <IconButton
              id="remove-from-watchlist-button"
              type={ICON_BUTTON_TYPES.RED}
              icon={ICON_BUTTON_ICONS.X_LG}
              tooltipText="Remove from watchlist"
              onClick={() => setIsAddRemoveWatchlistPopupOpen(company)}
              disabled={profilerProjectStore.isAddingToProfilerWatchlist}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default WatchlistCompanyResult;
