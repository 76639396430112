import { UI_OPTION_KEYS } from './uiOptionKeys';

export const UI_OPTIONS = {
  [UI_OPTION_KEYS.SEARCH_TYPE]: {
    Funds: 'funds',
    'Portfolio Co.': 'portfolios',
    .../*['dev', 'stg']*/ ([].includes(process.env.REACT_APP_ENVIRONMENT)
      ? { Corporates: 'investprofiles' }
      : {}),
    Profiler: 'profiler'
  },
  [UI_OPTION_KEYS.FUND_SEARCH_CATEGORY]: {
    'Investment criteria': 'investmentCriteria',
    'Private equity fund name': 'privateEquityFundName',
    'Fund name': 'fundName'
  },
  [UI_OPTION_KEYS.PORTFOLIO_SEARCH_CATEGORY]: {
    'Investment criteria': 'investmentCriteria',
    'Portfolio company name': 'portfolioCompanyName'
  },
  [UI_OPTION_KEYS.PROFILER_SEARCH_CATEGORY]: {
    'Company criteria': 'companyCriteria',
    'Company name': 'companyName',
    'Company bulk search': 'companyBulkSearch'
  }
};

export const UI_OPTIONS_UTILS = Object.fromEntries(
  Object.entries(UI_OPTIONS).map(([key, values]) => [
    key,
    Object.entries(values).map(([name, value]) => ({ name, value }))
  ])
);
