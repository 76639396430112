import Input from '../../../../components/inputs/Input';
import {
  USER_DEACTIVATE_ACCOUNT_FIELDS,
  getErrorFields,
  USER_PROFILE_FIELDS
} from '../../../../utils/constants/fields';
import { useStore } from '../../../../store/store';
import { useLocalObservable } from 'mobx-react';
import { generateMessage, mapFieldsToState } from '../../../../utils/utils';
import { Link } from 'react-router-dom';
import { paths } from '../../../../utils/constants/routes';
import { Fragment, useEffect, useRef } from 'react';
import * as Portal from '@radix-ui/react-portal';

const useDeactivateAccountTab = () => {
  const { authStore, utilsStore } = useStore();
  const deactivatePopupContainerRef = useRef(null);
  const state = useLocalObservable(() => ({
    fields: mapFieldsToState(USER_DEACTIVATE_ACCOUNT_FIELDS),
    setFieldValue: (field = {}, value) => {
      state.fields[field.NAME] = value;
    },
    isDeactivatePopupOpen: false,
    setIsDeactivatePopupOpen: (value = false) => (state.isDeactivatePopupOpen = value),
    get isRestoreDisabled() {
      return (
        !Object.values(state.fields).find((v) => !!v) ||
        authStore.isAuthenticating ||
        authStore.userInfo?.isDeactivatingAccount
      );
    },
    get isSaveDisabled() {
      return (
        state.isRestoreDisabled ||
        state.validationFields.invalidFields.filter((f) => !f.isOnSubmit).length ||
        (state.onSubmitErrorState && state.validationFields.invalidFields.length)
      );
    },
    onRestore: () => {
      state.fields = mapFieldsToState(USER_DEACTIVATE_ACCOUNT_FIELDS);
    },
    onSave: () => {
      if (state.validationFields.invalidFields.length) {
        if (!state.onSubmitErrorState) {
          state.setOnSubmitErrorState(true);
        }
        return;
      }

      if (state.isDeactivatePopupOpen) {
        state.setIsDeactivatePopupOpen(false);
        authStore.deactivateAccount();
      } else {
        state.setIsDeactivatePopupOpen(true);
      }
    },
    onSubmitErrorState: false,
    setOnSubmitErrorState: (value = false) => (state.onSubmitErrorState = value),
    get validationFields() {
      const validations = getErrorFields([USER_DEACTIVATE_ACCOUNT_FIELDS.EMAIL], state.fields);

      if (
        state.fields[USER_DEACTIVATE_ACCOUNT_FIELDS.EMAIL.NAME] !==
        authStore.userInfo?.[USER_PROFILE_FIELDS.EMAIL.NAME]
      ) {
        validations.messages.push(
          generateMessage(
            "Email addresses don't match",
            false,
            USER_DEACTIVATE_ACCOUNT_FIELDS.EMAIL.NAME,
            true
          )
        );
        validations.invalidFields.push({
          name: USER_DEACTIVATE_ACCOUNT_FIELDS.EMAIL.NAME,
          isOnSubmit: true
        });
      }

      return validations;
    }
  }));

  useEffect(() => {
    const clickEvent = (e) => {
      if (
        deactivatePopupContainerRef?.current &&
        e?.target?.id !== 'logout-menu-button' &&
        !deactivatePopupContainerRef.current.contains(e.target) &&
        state.isDeactivatePopupOpen
      ) {
        state.setIsDeactivatePopupOpen(false);
      }
    };
    document.addEventListener('click', clickEvent, { capture: true });
    return () => {
      document.removeEventListener('click', clickEvent, { capture: true });
    };
  }, []);

  useEffect(() => {
    if (state.isDeactivatePopupOpen) {
      utilsStore.lockScroll(true);
    } else {
      utilsStore.lockScroll(false);
    }
  }, [utilsStore, state.isDeactivatePopupOpen]);

  const { isRestoreDisabled, isSaveDisabled, onRestore, onSave } = state;

  const tabMobileHeader = (
    <div className="mobile-tab-header">
      <Link to={paths.USER_PROFILE_SETTINGS} className="backlink">
        Back to settings
      </Link>
      <div className="title">Deactivate account</div>
      <p>Follow these steps in order to deactivate your account</p>
      <p>1.Type in your email address</p>
      <p>2.Confirm your account deactivation</p>
      <p>3.Receive a confirmation from our team</p>
    </div>
  );

  const tabBody = authStore.userInfo?.isDeactivatingAccount ? (
    <div className="form-body form-row-layout">
      <div className="confirm-container">
        <Link to={paths.USER_PROFILE_SETTINGS} className="backlink">
          Back to settings
        </Link>
        <div className="title">Your account has been deactivated.</div>
        <div className="body">
          In case you have questions, please contact us:{' '}
          <a href="mailto:support@transact.digital">support@transact.digital</a>
        </div>
      </div>
    </div>
  ) : (
    <div className="form-body form-row-layout">
      <div className="row">
        <div className="col">
          <Input
            field={USER_DEACTIVATE_ACCOUNT_FIELDS.EMAIL}
            value={state.fields[USER_DEACTIVATE_ACCOUNT_FIELDS.EMAIL.NAME]}
            setFieldValue={state.setFieldValue}
            showOnSubmitErrorState={state.onSubmitErrorState}
            messages={state.validationFields.messages}
            disabled={authStore.isAuthenticating}
          />
        </div>
      </div>
    </div>
  );

  const view = (
    <Fragment key="tab-deactivate-account">
      {!authStore.userInfo?.isDeactivatingAccount && tabMobileHeader}
      {tabBody}
      {state.isDeactivatePopupOpen && (
        <Portal.Root>
          <div className="transparent-overlay">
            <div className="container" ref={deactivatePopupContainerRef}>
              <div className="title">Do you really want to deactivate your account?</div>
              <div className="actions">
                <Link
                  to="#"
                  className="btn btn-primary"
                  onClick={(e) => e.preventDefault() & state.setIsDeactivatePopupOpen(false)}>
                  No, keep my account
                </Link>
                <a className="btn btn-outline" onClick={state.onSave}>
                  Yes, deactivate my account
                </a>
              </div>
            </div>
          </div>
        </Portal.Root>
      )}
    </Fragment>
  );

  return { view, isRestoreDisabled, isSaveDisabled, onRestore, onSave };
};

export default useDeactivateAccountTab;
