import { observer } from 'mobx-react';
import { PROFILER_SEARCH_CATEGORIES } from '../../../../utils/constants/searchCategories';
import { PROFILER_SEARCH_CATEGORY_FIELDS, SEARCH_FIELDS } from '../../../../utils/constants/fields';
import MultiSelect from '../../../../components/inputs/MultiSelect';
import { useStore } from '../../../../store/store';
import { ICON_BUTTON_ICONS } from '../../../../components/buttons/IconButton';

const ProfilerCompanyCriteriaSearchFormMobile = observer(
  ({
    onShowSection = null,
    fields,
    fieldsCount,
    isSearchDisabled,
    onSearch,
    erroredSections = {},
    categoryFields,
    setCategoryFieldValue,
    categoryOptions
  }) => {
    const { utilsStore, profilerAccessStore } = useStore();

    const onSetSection = (section) => {
      if (typeof onShowSection === 'function') {
        onShowSection(section);
      }
    };

    if (utilsStore.windowWidth > 1200) {
      return null;
    }

    return (
      <form className="mobile" onSubmit={onSearch}>
        {profilerAccessStore.isProfilerDemo && (
          <div
            className={`profiler-demo-info-message${
              profilerAccessStore.demoSearchExceededLimit ? ' exceeded' : ''
            }`}>
            <div className="img-wrap">
              <img src={ICON_BUTTON_ICONS.EXCLAMATAION_CIRCLE} />
            </div>
            <div className="text">
              {profilerAccessStore.demoSearchExceededLimit ? (
                <>
                  You&apos;ve used all {profilerAccessStore.demoSearchMaxCount} demo searches -
                  upgrade to Full access to continue exploring.
                </>
              ) : (
                <>
                  You have {profilerAccessStore.demoSearchRemainingCount} out of{' '}
                  {profilerAccessStore.demoSearchMaxCount} demo searches remaining - upgrade to Full
                  access to unlock unlimited searches.
                </>
              )}
            </div>
          </div>
        )}
        <div className="mobile-search-form-title">Select search type of preference</div>
        {fields[SEARCH_FIELDS.SEARCH_TYPE_MOBILE.ID]}
        <MultiSelect
          field={PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE}
          value={categoryFields[PROFILER_SEARCH_CATEGORY_FIELDS.SEARCH_CATEGORY_MOBILE.NAME]}
          setFieldValue={setCategoryFieldValue}
          values={categoryOptions}
          disabled={profilerAccessStore.demoSearchExceededLimit}
        />
        {Object.values(PROFILER_SEARCH_CATEGORIES).map((section) => {
          const { filled, total } = fieldsCount[section];
          const btnClassNames = ['btn', 'btn-next'];
          if (erroredSections[section]) {
            btnClassNames.push('error');
          }
          if (profilerAccessStore.demoSearchExceededLimit) {
            btnClassNames.push('disabled');
          }
          return (
            <button
              className={btnClassNames.join(' ')}
              key={section}
              onClick={() => {
                if (profilerAccessStore.demoSearchExceededLimit) {
                  return;
                }
                onSetSection(section);
                window.scrollTo({ top: 0 });
              }}>
              {section} {filled}/{total}
            </button>
          );
        })}
        <button type="submit" className="btn btn-primary" disabled={isSearchDisabled}>
          Search
        </button>
      </form>
    );
  }
);

export default ProfilerCompanyCriteriaSearchFormMobile;
