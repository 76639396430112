import { Store } from '../store'; // eslint-disable-line no-unused-vars
import { makeAutoObservable, runInAction } from 'mobx';
import { API_ENDPOINTS } from '../../api/endpoints';
import {
  ADMIN_DASHBOARD_TABS,
  ADMIN_DASHBOARD_USER_GROUPS_ARRAY_SELECTABLE
} from '../../pages/AdminDashboard/constants';
import { mapData } from '../../api/dataMappers';
import {
  FUND_FIELDS,
  PORTFOLIO_FIELDS,
  INVEST_PROFILE_FIELDS,
  COMPANY_PROFILE_FIELDS,
  USER_PROFILE_FIELDS
} from '../../utils/constants/fields';

const getInitialFiltersDisplayUsersManagement = () => {
  return JSON.parse(
    JSON.stringify({
      filterCompanyType: '',
      filterConfirmationStatus: false,
      filterApproveStatus: '',
      filterOnlyMasterGroup: false
    })
  );
};

const getInitialFiltersDisplayUsersActivities = () => {
  return JSON.parse(
    JSON.stringify({
      filterCompanyType: '',
      filterOnlyMasterGroup: false
    })
  );
};

class AdminDashboardStore {
  /**
   * @param {Store} root
   */
  constructor(root) {
    makeAutoObservable(this);
    this.root = root;
  }

  get pageSize() {
    return 8;
  }

  reset = () => {
    this.tab = ADMIN_DASHBOARD_TABS.USER_MANAGEMENT;

    this.usersManagementList = [];
    this.loadUsersManagementError = null;
    this.isLoadingUsersManagement = false;
    this.filtersDisplayUsersManagement = getInitialFiltersDisplayUsersManagement();
    this.filtersUsersManagement = getInitialFiltersDisplayUsersManagement();
    this.usersManagementPage = 1;
    this.usersManagementMaxPage = 1;
    this.usersManagementTotalCount = 0;

    this.usersActivitiesList = [];
    this.loadUsersActivitiesError = null;
    this.isLoadingUsersActivities = false;
    this.filtersDisplayUsersActivities = getInitialFiltersDisplayUsersActivities();
    this.filtersUsersActivities = getInitialFiltersDisplayUsersActivities();
    this.usersActivitiesPage = 1;
    this.usersActivitiesMaxPage = 1;
    this.usersActivitiesTotalCount = 0;

    this.investVehiclesList = [];
    this.loadInvestVehiclesError = null;
    this.isLoadingInvestVehicles = false;
    this.investVehiclesFilterType = '';
    this.investVehiclesPage = 1;
    this.investVehiclesMaxPage = 1;
    this.investVehiclesTotalCount = 0;

    this.isUpdatingUser = false;
  };

  tab = ADMIN_DASHBOARD_TABS.USER_MANAGEMENT;

  usersManagementList = [];
  loadUsersManagementError = null;
  isLoadingUsersManagement = false;
  filtersDisplayUsersManagement = getInitialFiltersDisplayUsersManagement();
  filtersUsersManagement = getInitialFiltersDisplayUsersManagement();
  usersManagementPage = 1;
  usersManagementMaxPage = 1;
  usersManagementTotalCount = 0;

  usersActivitiesList = [];
  loadUsersActivitiesError = null;
  isLoadingUsersActivities = false;
  filtersDisplayUsersActivities = getInitialFiltersDisplayUsersActivities();
  filtersUsersActivities = getInitialFiltersDisplayUsersActivities();
  usersActivitiesPage = 1;
  usersActivitiesMaxPage = 1;
  usersActivitiesTotalCount = 0;

  investVehiclesList = [];
  loadInvestVehiclesError = null;
  isLoadingInvestVehicles = false;
  investVehiclesFilterType = '';
  investVehiclesPage = 1;
  investVehiclesMaxPage = 1;
  investVehiclesTotalCount = 0;

  isUpdatingUser = false;

  loadUsersManagementList = (page = 1) => {
    if (this.isLoadingUsersManagement) {
      return;
    }

    runInAction(() => {
      this.usersManagementList = [];
      this.isLoadingUsersManagement = true;
      this.filtersUsersManagement = JSON.parse(JSON.stringify(this.filtersDisplayUsersManagement));
      this.usersManagementPage = page;
      this.usersManagementMaxPage = 1;
      this.loadUsersManagementError = null;
      this.usersManagementTotalCount = 0;
    });

    const filters = this.filtersUsersManagement;
    const body = { page: page - 1, pageSize: this.pageSize };
    if (filters.filterCompanyType && filters.filterCompanyType !== 'all') {
      body.filterCompanyType = filters.filterCompanyType;
    }
    if (filters.filterApproveStatus) {
      body.filterApproveStatus = filters.filterApproveStatus;
    }
    if (filters.filterConfirmationStatus) {
      body.filterConfirmationStatus = true;
    }
    if (filters.filterOnlyMasterGroup) {
      body.filterOnlyMasterGroup = true;
    }

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.ADMIN_LIST_USERS,
      body,
      onSuccess: (response) => {
        this.usersManagementList = response.users;
        this.usersManagementMaxPage = response.maxPage;
        this.usersManagementTotalCount = response.totalCount;
      },
      onError: (errorMessage) => {
        this.loadUsersManagementError = errorMessage || 'Failed to obtain users management list.';
      },
      onFinally: () => {
        this.isLoadingUsersManagement = false;
      }
    });
  };

  loadUsersActivitiesList = (page = 1) => {
    if (this.isLoadingUsersActivities) {
      return;
    }

    runInAction(() => {
      this.usersActivitiesList = [];
      this.isLoadingUsersActivities = true;
      this.filtersUsersActivities = JSON.parse(JSON.stringify(this.filtersDisplayUsersActivities));
      this.usersActivitiesPage = page;
      this.usersActivitiesMaxPage = 1;
      this.loadUsersActivitiesError = null;
      this.usersActivitiesTotalCount = 0;
    });

    const filters = this.filtersUsersActivities;
    const body = { page: page - 1, pageSize: this.pageSize };
    if (filters.filterCompanyType && filters.filterCompanyType !== 'all') {
      body.filterCompanyType = filters.filterCompanyType;
    }
    if (filters.filterOnlyMasterGroup) {
      body.filterOnlyMasterGroup = true;
    }

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.ADMIN_LIST_USERS,
      body,
      onSuccess: (response) => {
        this.usersActivitiesList = response.users;
        this.usersActivitiesMaxPage = response.maxPage;
        this.usersActivitiesTotalCount = response.totalCount;
      },
      onError: (errorMessage) => {
        this.loadUsersActivitiesError = errorMessage || 'Failed to obtain users activities list.';
      },
      onFinally: () => {
        this.isLoadingUsersActivities = false;
      }
    });
  };

  loadInvestVehiclesList = (page = 1) => {
    if (this.isLoadingInvestVehicles) {
      return;
    }

    runInAction(() => {
      this.investVehiclesList = [];
      this.isLoadingInvestVehicles = true;
      this.investVehiclesPage = page;
      this.investVehiclesMaxPage = 1;
      this.loadInvestVehiclesError = null;
      this.investVehiclesTotalCount = 0;
    });

    const body = { page: page - 1, pageSize: this.pageSize };
    if (this.investVehiclesFilterType && this.investVehiclesFilterType !== 'all') {
      body.filterType = this.investVehiclesFilterType;
    }

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.ADMIN_LIST_FPI,
      body,
      onSuccess: (response) => {
        const mapperFields = {
          fund: FUND_FIELDS,
          portfolioCompany: PORTFOLIO_FIELDS,
          investProfile: INVEST_PROFILE_FIELDS
        };

        this.investVehiclesList = response.fpis.map((entry) => {
          const parsedEntry = mapData(entry, mapperFields[entry.entryType] || mapperFields.fund);
          parsedEntry.companyProfile = mapData(entry.companyProfile || {}, COMPANY_PROFILE_FIELDS);
          parsedEntry.user = mapData(entry.user || {}, USER_PROFILE_FIELDS);
          parsedEntry.contact = {};
          parsedEntry.contact.userName =
            (
              parsedEntry.user[USER_PROFILE_FIELDS.FIRST_NAME.NAME] +
              ' ' +
              parsedEntry.user[USER_PROFILE_FIELDS.LAST_NAME.NAME]
            ).trim() || 'N/A';
          parsedEntry.contact.companyName =
            parsedEntry.companyProfile[COMPANY_PROFILE_FIELDS.COMPANY_NAME.NAME] ||
            parsedEntry.companyName ||
            'N/A';

          const [userCity, userCountry] = [
            parsedEntry.user[USER_PROFILE_FIELDS.CITY.NAME],
            this.root.utilsStore.getOptionName(parsedEntry.user, USER_PROFILE_FIELDS.COUNTRY)?.[0]
          ];
          const [companyCity, companyCountry] = [
            parsedEntry.companyProfile[COMPANY_PROFILE_FIELDS.CITY.NAME],
            this.root.utilsStore.getOptionName(
              parsedEntry.companyProfile,
              COMPANY_PROFILE_FIELDS.COUNTRY
            )?.[0]
          ];

          if (userCity || userCountry) {
            parsedEntry.contact.location = userCity;
            if (userCountry) {
              parsedEntry.contact.location = parsedEntry.contact.location
                ? `${parsedEntry.contact.location}, ${userCountry}`
                : userCountry;
            }
          } else {
            parsedEntry.contact.location = companyCity;
            if (companyCountry) {
              parsedEntry.contact.location = parsedEntry.contact.location
                ? `${parsedEntry.contact.location}, ${companyCountry}`
                : companyCountry;
            }
          }

          if (!parsedEntry.contact.location) {
            parsedEntry.contact.location = 'N/A';
          }

          const [userPhone, companyPhone] = [
            parsedEntry.user[USER_PROFILE_FIELDS.PHONE_NUMBER.NAME],
            parsedEntry.companyProfile[COMPANY_PROFILE_FIELDS.PHONE_NUMBER.NAME]
          ];

          if (userPhone) {
            parsedEntry.contact.phone = userPhone;
          } else {
            parsedEntry.contact.phone = companyPhone;
          }

          if (!parsedEntry.contact.phone) {
            parsedEntry.contact.phone = 'N/A';
          }

          const [userEmail, companyEmail] = [
            parsedEntry.user[USER_PROFILE_FIELDS.EMAIL.NAME],
            parsedEntry.companyProfile[COMPANY_PROFILE_FIELDS.CONTACT_EMAIL.NAME]
          ];

          if (userEmail) {
            parsedEntry.contact.email = userEmail;
          } else {
            parsedEntry.contact.email = companyEmail;
          }

          if (!parsedEntry.contact.email) {
            parsedEntry.contact.email = 'N/A';
          }

          return parsedEntry;
        });

        this.investVehiclesMaxPage = response.maxPage;
        this.investVehiclesTotalCount = response.totalCount;
      },
      onError: (errorMessage) => {
        this.loadInvestVehiclesError = errorMessage || 'Failed to obtain invest profiles list.';
      },
      onFinally: () => {
        this.isLoadingInvestVehicles = false;
      }
    });
  };

  clearUsersManagementFilters = () => {
    runInAction(() => {
      this.filtersDisplayUsersManagement = getInitialFiltersDisplayUsersManagement();
    });
    if (
      JSON.stringify(this.filtersDisplayUsersManagement) !==
      JSON.stringify(this.filtersUsersManagement)
    ) {
      this.loadUsersManagementList();
    }
  };

  clearUsersActivitiesFilters = () => {
    runInAction(() => {
      this.filtersDisplayUsersActivities = getInitialFiltersDisplayUsersActivities();
    });
    if (
      JSON.stringify(this.filtersDisplayUsersActivities) !==
      JSON.stringify(this.filtersUsersActivities)
    ) {
      this.loadUsersActivitiesList();
    }
  };

  updateUserGroups = (
    user = {},
    cognitoGroups = [],
    onSuccess = () => {},
    onError = () => {},
    onFinally = () => {}
  ) => {
    if (this.isUpdatingUser) {
      return;
    }

    runInAction(() => {
      this.isUpdatingUser = true;
    });

    this.root.makeRequest({
      endpoint: API_ENDPOINTS.ADMIN_UPDATE_USER,
      body: { id: user.id, cognitoGroups },
      onSuccess: (response) => {
        let newGroups = user.cognitoGroups.slice();
        ADMIN_DASHBOARD_USER_GROUPS_ARRAY_SELECTABLE.forEach((group) => {
          if (cognitoGroups.includes(group.value) && !newGroups.includes(group.value)) {
            newGroups.push(group.value);
          } else if (!cognitoGroups.includes(group.value) && newGroups.includes(group.value)) {
            newGroups = newGroups.filter((g) => g !== group.value);
          }
        });
        user.cognitoGroups = newGroups;

        onSuccess(response);
      },
      onError: (errorMessage) => {
        onError(errorMessage);
      },
      onFinally: () => {
        this.isUpdatingUser = false;
        onFinally();
      }
    });
  };
}

export default AdminDashboardStore;
