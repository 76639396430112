import { observer } from 'mobx-react';
import { useStore } from '../../../../store/store';
import { paths } from '../../../../utils/constants/routes';
import useHistory from '../../../../hooks/useHistory';

const OnboardingSteps = observer(() => {
  const { authStore, fundsStore } = useStore();
  const { navigate } = useHistory();

  const showFundsOnboarding = fundsStore.fundOnboardingData.show;
  const fundsOnboardingPending = !fundsStore.fundOnboardingData.completed;

  return (
    <>
      <h2>Onboarding</h2>
      <div className="cards-container">
        {!!authStore.userInfo.isMasterUser && (
          <div
            className={`card${!authStore.isUserProfileCompanyCardPending ? ' no-hover' : ''}`}
            onClick={() =>
              authStore.isUserProfileCompanyCardPending && navigate(paths.COMPANY_PROFILE)
            }>
            <div className="heading">
              <div className="icon-onboarding step-2"></div>
              {!authStore.isUserProfileCompanyCardPending ? (
                <div className="badge success">Completed</div>
              ) : (
                <div className="badge info">Pending</div>
              )}
            </div>
            <div className="body">
              {authStore.isUserProfileCompanyCardPending ? (
                <h6 className="title-link">Complete your company profile</h6>
              ) : (
                <h6>Complete your company profile</h6>
              )}
              <p>For access to our solution complete your profile</p>
            </div>
          </div>
        )}

        <div
          className={`card${!authStore.isUserProfileCardPending ? ' no-hover' : ''}`}
          onClick={() => authStore.isUserProfileCardPending && navigate(paths.USER_PROFILE)}>
          <div className="heading">
            <div className="icon-onboarding step-1"></div>
            {!authStore.isUserProfileCardPending ? (
              <div className="badge success">Completed</div>
            ) : (
              <div className="badge info">Pending</div>
            )}
          </div>
          <div className="body">
            {authStore.isUserProfileCardPending ? (
              <h6 className="title-link">Complete your user account</h6>
            ) : (
              <h6>Complete your user account</h6>
            )}
            <p>To improve collaboration complete your user profile</p>
          </div>
        </div>

        {showFundsOnboarding && (
          <div
            className={`card${!fundsOnboardingPending ? ' no-hover' : ''}`}
            onClick={() =>
              fundsOnboardingPending &&
              navigate(fundsStore.fundsForReview.length ? paths.FUNDS : paths.PORTOFLIO_COMPANIES)
            }>
            <div className="heading">
              <div className="icon-onboarding step-funds-onboarding"></div>
              {!fundsOnboardingPending ? (
                <div className="badge success">Completed</div>
              ) : (
                <div className="badge info">Pending</div>
              )}
            </div>
            <div className="body">
              {fundsOnboardingPending ? (
                <h6 className="title-link">Complete your fund onboarding</h6>
              ) : (
                <h6>Complete your fund onboarding</h6>
              )}
              <p>Review your invest profiles to receive most relevant deal flow</p>
            </div>
          </div>
        )}

        {!authStore.userInfo.isMasterUser && !showFundsOnboarding && <div className="card empty" />}
      </div>
    </>
  );
});

export default OnboardingSteps;
