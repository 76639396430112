import { observer, useLocalObservable } from 'mobx-react';
import { useStore } from '../../store/store';
import { useEffect } from 'react';
import { paths } from '../../utils/constants/routes';
import StaticPageLayout from '../../components/layouts/StaticPageLayout';
import GeneralLoader from '../../components/loaders/GeneralLoader';
import GeneralError from '../../components/errors/GeneralError';
import useHistory from '../../hooks/useHistory';
import {
  PROJECT_TYPE_TABS,
  encodeProjectsParams,
  decodeProjectsParams,
  SELECTED_PROJECT_TABS,
  PROJECT_LIST_SORT
} from './constants';
import ProjectsList from './components/ProjectsList';
import ProjectView from './components/ProjectView';
import { UI_OPTIONS } from '../../utils/constants/uiOptions';
import { UI_OPTION_KEYS } from '../../utils/constants/uiOptionKeys';
import ProfilerProjectView from '../ProfilerProjects/components/ProfilerProjectView';

// const projectsListingPageSize = 8;

const Projects = observer(() => {
  const { location, navigate } = useHistory();
  const { projectStore, profilerProjectStore } = useStore();

  const state = useLocalObservable(() => ({
    params: {},
    setParams: (params = {}) => {
      state.params = params;
    },
    get selectedProject() {
      const list =
        state.params.projectTypeTab === PROJECT_TYPE_TABS.SAVED
          ? projectStore.projects.concat(profilerProjectStore.projects)
          : state.params.projectTypeTab === PROJECT_TYPE_TABS.ARCHIVED
          ? projectStore.archivedProjects.concat(profilerProjectStore.archivedProjects)
          : [];
      const foundEntry = list.find((s) => s.id == state.params.selectedProjectId);
      return foundEntry;
    },
    get showProjectsError() {
      return projectStore.loadProjectsError && !projectStore.allProjects.length;
    },
    get showProfilerProjectsError() {
      return profilerProjectStore.loadProjectsError && !profilerProjectStore.allProjects.length;
    },
    get showActiveProjectsList() {
      return (
        (!state.showProjectsError || !state.showProfilerProjectsError) &&
        state.params.projectTypeTab === PROJECT_TYPE_TABS.SAVED &&
        !state.selectedProject
      );
    },
    get showArchivedProjectsList() {
      return (
        (!state.showProjectsError || !state.showProfilerProjectsError) &&
        state.params.projectTypeTab === PROJECT_TYPE_TABS.ARCHIVED &&
        !state.selectedProject
      );
    },
    get showProjectsList() {
      return state.showActiveProjectsList || state.showArchivedProjectsList;
    },
    get showProjectView() {
      return (
        (!state.showProjectsError || !state.showProfilerProjectsError) &&
        state.params.selectedProjectId &&
        state.selectedProject
      );
    }
  }));

  useEffect(() => {
    if (projectStore.isLoadingProjectsData || profilerProjectStore.isLoadingProjectsData) {
      return;
    }

    // params - projectTypeTab, projectTypePage, projectSort, selectedProjectId, selectedProjectTab, selectedProjectTabPage, projectProfileFilter, projectSearchReasonFilter

    let [urlParams, params, shouldNavigate] = [null, {}, false];
    try {
      urlParams = new URLSearchParams(location.search);
      params = decodeProjectsParams(urlParams.get('params'));
    } catch (ex) {
      params = { projectTypeTab: PROJECT_TYPE_TABS.SAVED, projectTypePage: 1 };
    }

    if (typeof params !== 'object' || params === null || params === undefined) {
      params = {};
    }

    if (!Object.values(PROJECT_TYPE_TABS).includes(params.projectTypeTab)) {
      params.projectTypeTab = PROJECT_TYPE_TABS.SAVED;
      shouldNavigate = true;
    }

    if (!Object.values(PROJECT_LIST_SORT).includes(params.projectSort)) {
      params.projectSort = PROJECT_LIST_SORT.DATE_DESC;
      shouldNavigate = true;
    }

    const projectsList =
      params.projectTypeTab === PROJECT_TYPE_TABS.SAVED
        ? projectStore.projects.concat(profilerProjectStore.projects)
        : projectStore.archivedProjects.concat(profilerProjectStore.archivedProjects);
    // let projectsListPages = Math.ceil(projectsList.length / projectsListingPageSize);
    // if (projectsListPages === 0) {
    //   projectsListPages = 1;
    // }

    // if (
    //   isNaN(params.projectTypePage) ||
    //   params.projectTypePage > projectsListPages ||
    //   params.projectTypePage < 1
    // ) {
    //   if (isNaN(params.projectTypePage) || params.projectTypePage < 1) {
    //     params.projectTypePage = 1;
    //   } else if (params.projectTypePage > projectsListPages) {
    //     params.projectTypePage = projectsListPages;
    //   }
    //   shouldNavigate = true;
    // }

    if (isNaN(params.projectTypePage)) {
      params.projectTypePage = 1;
      shouldNavigate = true;
    }

    if (params.selectedProjectId) {
      const foundProject = projectsList.find((p) => p.id === params.selectedProjectId);
      if (!foundProject) {
        params.selectedProjectId = null;
        params.selectedProjectTab = null;
        params.selectedProjectTabPage = null;
        shouldNavigate = true;
      } else {
        if (!Object.values(SELECTED_PROJECT_TABS).includes(params.selectedProjectTab)) {
          params.selectedProjectTab = SELECTED_PROJECT_TABS.RESULTS;
          shouldNavigate = true;
        }
      }
    }

    if (shouldNavigate) {
      navigate(paths.PROJECTS + '?params=' + encodeProjectsParams(params));
    }

    state.setParams(params);
  }, [
    state,
    projectStore,
    profilerProjectStore,
    projectStore.allProjects,
    profilerProjectStore.allProjects,
    projectStore.isLoadingProjectsData,
    profilerProjectStore.isLoadingProjectsData,
    projectStore.projects.length,
    profilerProjectStore.projects.length,
    projectStore.archivedProjects.length,
    profilerProjectStore.archivedProjects.length,
    location.search
  ]);

  if (
    projectStore.initialLoadProjects ||
    profilerProjectStore.initialLoadProjects ||
    ((projectStore.isLoadingProjects || profilerProjectStore.isLoadingProjects) &&
      !projectStore.allProjects.length &&
      !profilerProjectStore.allProjects.length)
  ) {
    return (
      <StaticPageLayout page="projects-listing" hideMobileFooter={true}>
        <GeneralLoader />
      </StaticPageLayout>
    );
  }

  return (
    <StaticPageLayout page="projects-listing" hideMobileFooter={true}>
      {state.showProjectsError && state.showProfilerProjectsError && (
        <div className="projects-listing-error-group">
          <GeneralError
            errorMessage={projectStore.loadProjectsError}
            actionMessage="You may want to:"
            withHomePageButton={true}
            actionButtontext="Try again"
            onActionButtonClick={projectStore.loadProjects}
          />
          <GeneralError
            errorMessage={profilerProjectStore.loadProjectsError}
            actionMessage="You may want to:"
            withHomePageButton={true}
            actionButtontext="Try again"
            onActionButtonClick={profilerProjectStore.loadProjects}
            withLogoutButton
          />
        </div>
      )}
      {state.showProjectsList && (
        <ProjectsList
          key="projects-list"
          listType={state.params.projectTypeTab}
          params={state.params}
        />
      )}
      {state.showProjectView &&
        (state.selectedProject.searchType === UI_OPTIONS[UI_OPTION_KEYS.SEARCH_TYPE].Profiler ? (
          <ProfilerProjectView
            key={state.selectedProject.id}
            project={state.selectedProject}
            params={state.params}
            isFromAgentView
          />
        ) : (
          <ProjectView
            key={state.selectedProject.id}
            project={state.selectedProject}
            params={state.params}
          />
        ))}
    </StaticPageLayout>
  );
});

export default Projects;
